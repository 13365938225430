import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'; 

import { InputFieldAuth, TextareaAutosizeRedux } from "../../../../../app/component/common/FieldComponent";
import { CompanyActions } from "../../../../../app/actions/company.action";

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email field is required!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }

    if (!values.name) {
        errors.name = 'Subject field is required!'
    }

    if (!values.message) {
        errors.message = 'Message field is required!'
    }
    return errors
}

class AsideEmailSupport extends Component {

    constructor(props) {
        super(props);
        // this.state={
        //     open:this.props.showSupportEmail,
        // }
    }

    _handleClose = () => {
        // this.setState({
        //     open:false
        // });
        this.props.hideProjectEmail();
    };

    _handleSubmit = (values) =>{
        // let {registerData, register} = this.props
        // let valuesToSend = {...registerData,...values}
        // register(valuesToSend)
        this.props.sendMail(values);
        
    }

    render() {
        const { handleSubmit, submitting, pristine } = this.props
        return (
                <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.props.showSupportEmail}
                >
                    <DialogTitle id="alert-dialog-title" className="text-center">Dokkit Support<br />Get in touch with us<br /></DialogTitle>
                    <DialogContent>
                    <p className="text-center mb-10">we will get back to you as soon as we can.</p>
                    <DialogContentText id="alert-dialog-description">
                        <Field 
                            placeholder="Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="name"
                            required={true}
                            component={InputFieldAuth}/>
                        <Field 
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="email"
                            component={InputFieldAuth}/>
                        <Field 
                            placeholder="Message"
                            required={true}
                            minRows={4}
                            maxRows={6}
                            width={1}
                            name="message"
                            component={TextareaAutosizeRedux}/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={this._handleClose}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        cancel
                        </button>
                        <button
                            // type="submit"
                            onClick={handleSubmit(this._handleSubmit)}
                            disabled={submitting}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            <span>{submitting ? 'Submitting..': 'Submit' }</span>
                            {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                    </DialogActions>                   
                </Dialog>    
        );
    }
}

AsideEmailSupport = reduxForm({
    form: 'AsideEmailSupport',
    validate,
    enableReinitialize: true
})(AsideEmailSupport);

const mapStateToProps = (state, ownProps) => {
	return {
        showSupportEmail: state.company.showSupportEmail,
        initialValues:{
            email: state.auth.user.email,
            name: state.auth.user.name 
        }
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    hideProjectEmail: () => { dispatch({ type : "COMPANY/EMAIL/OPTION/TOGGLE" }) },
   sendMail: (data) => dispatch(CompanyActions.sendSupportEmail(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(AsideEmailSupport);