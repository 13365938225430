export const AuthActions = {
    login,
    sendOtp,
    register,
    forgotPassword,
    resetPassword,
    logout,
    changePassword
}

function login(loginData){
    return { type : "AUTH/LOGIN", payload: loginData }
}

function sendOtp(registerData){
    return { type : "AUTH/REGISTER/SEND_OTP", payload: registerData }
}

function register(registerData){
    return { type : "AUTH/REGISTER/USER", payload: registerData }   
}

function forgotPassword(email){
    return { type : "AUTH/FORGOTPASSWORD/SEND", payload: email }   
}

function resetPassword(email){
    return { type : "AUTH/RESETPASSWORD", payload: email }   
}

function logout(){
    return { type: "AUTH/LOGOUT" }
}

function changePassword(data){
    return {type: "AUTH/CHANGEPASSWORD", payload:data }
}