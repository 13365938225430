import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset, change } from 'redux-form';
import store from '../utils/store'

// Get all contractor
function* getAll({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/contractor',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "CONTRACTOR/GET/ALL/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Contractor
function* create({ payload }){
  const form_name = 'AddContractor'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try{
      const response =  yield race({
          success: call(getResponse, {
              url:'/api/v1/contractor',
              method: 'POST',
              data: payload.newData,              
          })
      });
        if(response.success){
            if(payload.newData.isProject === true){
                yield put({ 
                    type: "PROJECT/CONTRACTOR/DROPDOWN/UPDATE/SUCCESS", 
                    payload: response.success.data.data
                });
                const form = store.getState().form;
                const prevContractors = form.ProjectForm.values['contractors'] || [];
                yield put(change('ProjectForm', 'contractors',  [...prevContractors, response.success.data.data]))
            }else{
                yield put({ 
                    type: "CONTRACTOR/CREATE/SUCCESS", 
                    payload: response.success.data.data
                });
            }
            
           
            alertInfo('success', 'Contractor Created Successfully');
            document.body.classList.remove("openRightBar");
            //   this.props.dispatch(reset(form_name));
            payload.resetForm();
            yield put({ type: "CONTRACTOR/SIDEBAR/TOGGLE", });
        }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch (error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
          yield put({ 
              type: "GLOBAL/FORM_ERROR", 
              payload: {
                  error: data.data, 
                  form_name: form_name
              } 
          });
      }
  }
}

// Delete one contractor
function* deleteOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/contractor/${payload}`,
              method: 'DELETE',
          })
      });
      if(response.success){
          yield put({
              type: "CONTRACTOR/DELETE/ONE/SUCCESS",
              payload: response.success.data.data
           });
           alertInfo('success', 'Contrcator Deleted Successfully');
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Get one Contractor
function* getOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/contractor/${payload}`,
              method: 'GET',
          })
      });
      if(response.success){
          yield put({
              type: "CONTRACTOR/GET/ONE/SUCCESS",
              payload: response.success.data.data
           });
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Update one contractor
function* updateOneAccess({ payload }){
    console.log("We are in payload", payload);
    const contractor_id = payload.id;
    delete payload.id;
    const form_name = 'UpdateContractor'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:`/api/v1/contractor/access/${contractor_id}`,
                method: 'PUT',
                data: payload
            })
        });
        if(response.success){
            yield put({
                type: "CONTRACTOR/UPDATE/ONE/SUCCESS",
                payload: response.success.data.data
             });
              alertInfo('success', 'Contractor access updated successfully')
            
              yield put({ type: "CONTRACTOR/SIDEBAR/TOGGLE", });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch(error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error

        console.log("We are here in c atch", data);
        yield put({ 
            type: "GLOBAL/FORM_ERROR", 
            payload: {
                error: data.data, 
                form_name: form_name
            } 
        });
    }
  }
// Update one contractor
function* updateOne({ payload }){
  const contractor_id = payload.newData.id;
  delete payload.newData.id;
  const form_name = 'UpdateContractor'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/contractor/${contractor_id}`,
              method: 'PUT',
              data: payload.newData
          })
      });
      if(response.success){
          yield put({
              type: "CONTRACTOR/UPDATE/ONE/SUCCESS",
              payload: response.success.data.data
           });
            alertInfo('success', 'Contractor updated successfully')
            document.body.classList.remove("openRightBar");
            // this.props.dispatch(reset(form_name));
            payload.resetForm();
            yield put({ type: "CONTRACTOR/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch(error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      const { response: { data } } = error
      yield put({ 
          type: "GLOBAL/FORM_ERROR", 
          payload: {
              error: data.data, 
              form_name: form_name
          } 
      });
  }
}

function* getAllContractor({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/contractor',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "CONTRACTOR/GET/ALL/CONTRACTORS/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

export default function* contractorSaga() {
    yield all([
      takeLatest("CONTRACTOR/GET/ALL", getAll),
      takeLatest("CONTRACTOR/CREATE", create),
      takeLatest("CONTRACTOR/DELETE/ONE", deleteOne),
      takeLatest("CONTRACTOR/GET/ONE", getOne),
      takeLatest("CONTRACTOR/UPDATE/ONE", updateOne),
      takeLatest("CONTRACTOR/UPDATE/ONE/ACCESS", updateOneAccess),
      takeLatest("CONTRACTOR/GET/ALL/CONTRACTORS/REQUEST", getAllContractor),
  ]);
}