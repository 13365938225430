import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { ProjectActions } from '../../actions/project.action';
import { StockDetailActions } from '../../actions/stock_detail.action';
import { templateActions } from '../../actions/template.action';
import RichTextEditor from 'react-rte';
import TextEditorRedux from '../common/TextEditorRedux'
import Application from '../../../_metronic/layout/components/drag-accordion/Accordion';
import {Form } from 'react-bootstrap';
import AddStockAttachment from '../../../_metronic/layout/components/right-sidebar/stock-details/stock-attachment/AddStockAttachment'
import { InputFieldCompanyInfo, InputFieldRedux, AutoCompleteFieldRedux } from '../../component/common/FieldComponent';
import { alertInfo, getTemplateID } from '../../utils/common.helper';


const validate = values => {

    const errors = {}

    return errors
}

class AttachmentFrom extends Component {

    constructor(props) {
        super(props);
    }

    onReset = () => {
        this.props.resetForm()
    }

    handleGroupName = (e) => {
        let group_name = e.target.value;
        // let group_name = (e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '';
        this.setState({
            groupName: group_name
        })
    }

    handleSubmit = (values) => {
        let { project_details, attachment_items, stocks_listing, workType, templates, templateSet } = this.props,
        selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id,
        template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "COVER")
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);

        const data = {
            attachment  : attachment_items,
            project_id  : project_details.id,
            workType : workTypeObj.shortcode
        }

        this.props.saveProjectAttachment(data)
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.props.attachmentAccordionDrag({oldIndex, newIndex})
    };

    addNewStockGroup = () => {
        let { attachment_id, attachment_items } = this.props;
        
        if (this.state && this.state.groupName){
            var regex = /^[A-Za-z0-9 ]+$/
 
            //Validate TextBox value against the Regex.
            var isValid = regex.test(this.state.groupName);
            if (!isValid) {
                alertInfo("error", "Please remove characters such as '&' and numbers from the attachment group name and try again.");
            }else{
                function convertToRoman(num) {
                    var roman = {M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1};
                    var str = '';
                  
                    for (var i of Object.keys(roman)) {
                      var q = Math.floor(num / roman[i]);
                      num -= q * roman[i];
                      str += i.repeat(q);
                    }
                  
                    return str;
                  }
                  var hasNumber = /\d/;
                  // var titleArray = templateOptions.CoverTitle.split(" ");
                  let result = this.state.groupName;
                  if(hasNumber.test(this.state.groupName)){
                    var r = /\d+/g;
                    var m;
                    // console.log("yahoo1===>", result);
                    while ((m = r.exec(this.state.groupName)) != null) {
                      let roman = convertToRoman(m[0])
                      console.log(m[0], roman);
                      result = this.state.groupName.replace(m[0], roman);
                    }
                    // console.log("yahoo2===>", result);
                  }
                  this.setState({
                    groupName: result
                })
                this.props.addNewAttachmentGroup(result);
                this.props.dispatch(change('ProjectAttachmentForm', 'group_name', ''))
            }
            
        } else {
            alertInfo('error', 'Please add group name first')
        }

    }

    handleAvailableAttachment = (e) => {
        let att = document.getElementsByClassName("check-available-attachment")[0].id
        if(att == '0'){
            alertInfo('error', 'Please select attachments groups from Setup');
        }
    }

    addNewStockAttachment = () => {
        let { attachment_id, attachment_items } = this.props
        // console.log("attachment_id, attachment_items", attachment_id, attachment_items);

        if(attachment_id){
            let findIndex = _.findIndex(attachment_items, function(item) { return item.shortcode == attachment_id.shortcode })
            if(attachment_id && findIndex !== -1){
                alertInfo('error', 'attachments item already exist')
                return false
            }
            let newItem = {
                files: [],
                label: attachment_id.label,
                shortcode: attachment_id.shortcode,
                id: attachment_id.value
            }
            this.props.addNewAttachmentAccordion(newItem, () => { this.form.dispatchEvent(new Event('submit'))} )
        }else{
            alertInfo('error', 'Select attachments')
        }
    }

    removeStockAttachment = (data) => {
        if(this.props.user.type < 3){
            this.props.removeAttachmentAccordion(data)
        }
    }

    render() {
        const { handleSubmit, submitting, group_attachments, attachment_items, selectedAttachmentGroup, getAttachmentGroups, selectAttachmentGroup, getSelectAttachmentGroup, user } = this.props
        getAttachmentGroups();
        getSelectAttachmentGroup();
        
        var finalgroupAttachments = [];
        var finalgroupAttachments = group_attachments.map(attachmentGroupObj => ({ ...attachmentGroupObj, ...selectedAttachmentGroup.find(selectedAttachmentGroupObj => selectedAttachmentGroupObj.id === attachmentGroupObj.id) }))
        const group_attachment_option = [];
        const group_attachment_option2 = [];

        let selected_group = _.keys(_.groupBy(attachment_items, 'shortcode'));
        if(group_attachments){
            finalgroupAttachments.map((groups=>{
                if(_.indexOf(selected_group,groups.shortcode ) == -1){
                    if(groups.checked == true){
                        group_attachment_option.push(groups);
                    }
                }

            }))
        }
       
        if(_.isEmpty(group_attachment_option)){
            if(group_attachments){
                finalgroupAttachments.map((groups=>{
                    if(_.indexOf(selected_group,groups.shortcode ) == -1){
                        group_attachment_option2.push(groups);
                    }
    
                }))
            }   
        }

        // console.log("finalgroupAttachments-->", _.isEmpty(group_attachment_option), finalgroupAttachments, group_attachments);

        return (
            <Fragment>
                {/* {_.isEmpty(group_attachment_option) && <div className="spinner"></div>} */}
                <form onSubmit={handleSubmit(this.handleSubmit)} ref={ (ref) => { this.form = ref; } }>
                    <div className="card-body p-0 mb-5 d-flex res_card_header justify-content-between align-items-center flex-row">
                        <div className="col-md-8">
                            <h3>New Section Group</h3>
                            <p className="session-group-text">Add a new section group and then drag and drop your file or use the upload button to search for it on your computer. Reorder the attachments in a section or the sections themselves by dragging them up or down using their handler on the left. Each grouping will show up in the final manual with a labelled cover sheet.</p>

                        </div>
                        <div className="col-md-4">
                            <div className="d-flex full_width_res custom_width_select check-available-attachment" id={(_.isEmpty(group_attachment_option) && _.isEmpty(group_attachment_option2))?0:1} onClick={this.handleAvailableAttachment}>
                                <Field
                                    name="attachment_id"
                                    disabled={user.type < 4 ?false:true}
                                    component={AutoCompleteFieldRedux}
                                    options={_.isEmpty(group_attachment_option)?group_attachment_option2:group_attachment_option}
                                />
                                <button 
                                    type="button" 
                                    disabled={user.type < 4 ?false:true}
                                    className="btn py-2 ml-3 btn-primary"
                                    onClick={this.addNewStockAttachment}
                                >
                                    Add New
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 mb-5 d-flex res_card_header justify-content-between align-items-center flex-row">
                        <div className="col-md-8">
                            <p className="session-group-text">To add a custom attachment group type the name and click 'Add Group' </p>

                        </div>
                        <div className="col-md-4">
                            <div className="d-flex full_width_res custom_width_select">
                                <Field
                                    name="group_name"
                                    label="Group Name"
                                    variant="outlined"
                                    readOnly={false}
                                    disabled={user.type < 4 ?false:true}
                                    component={InputFieldRedux}
                                    onChange={this.handleGroupName}
                                    className="group_name"
                                />
                                <button
                                    type="button"
                                    disabled={user.type < 4 ?false:true}
                                    className="btn py-2 ml-3 btn-primary"
                                    onClick={this.addNewStockGroup}
                                >
                                    Add Group
                                </button>
                            </div>
                        </div>
                    </div>
                    <AddStockAttachment 
                        onSortEnd={this.onSortEnd}
                        data={attachment_items}
                        onRemove={(item)=>this.removeStockAttachment(item)}
                        submitForm={() => { this.form.dispatchEvent(new Event('submit')) }}
                    />
                    <div style={{marginTop: '20px', float: 'right'}}>
                        <button type="submit" disabled={user.type < 4 ?false:true} className="btn ml-3 btn-primary">Save</button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

AttachmentFrom = reduxForm({
    validate,
    form: 'ProjectAttachmentForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(AttachmentFrom);

const selector = formValueSelector('ProjectAttachmentForm');

const mapStateToProps = (state, ownProps) => {
    let group_attachments = _.sortBy(state.project.group_attachments,'title');
    return {
        attachment_id           : selector(state, 'attachment_id'),
        project_details         : state.project.project_details,
        user                    : state.auth.user,
        group_attachments       : group_attachments,
        workType                : state.templates.workType.data,
        templateSet             : state.templates.templateSet.data,
        templates               : state.templates.templates.data,
        attachment_items        : state.stock_detail.attachment_items || [],
        selectedAttachmentGroup : state.templates.templates.selectedAttachmentGroup,
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveProjectAttachment: (data) => dispatch(ProjectActions.saveProjectAttachment(data)),
    getAllGroupAttachments: () => dispatch(ProjectActions.getAllGroupAttachments()),
    getAllAttachments: (data) => dispatch(ProjectActions.getAllAttachments(data)),
    onChangeFieldValue: ({fieldName, fieldValue}) => dispatch(change('ProjectAttachmentForm', fieldName,  fieldValue)),
    addNewAttachmentAccordion: (data) => dispatch(StockDetailActions.addNewAttachmentAccordion(data)),
    addNewAttachmentGroup: (data) => dispatch(StockDetailActions.addNewAttachmentGroup(data)),
    attachmentAccordionDrag: (data) => dispatch(StockDetailActions.attachmentAccordionDrag(data)),
    removeAttachmentAccordion: (data) => dispatch(StockDetailActions.removeAttachmentAccordion(data)),
    resetAttachmentAccordion: () => dispatch(StockDetailActions.resetAttachmentAccordion()),
    getAttachmentGroups: (data) => dispatch(templateActions.getAttachmentGroups(data)),
    selectAttachmentGroup: (data) => dispatch(templateActions.selectAttachmentGroup(data)),
    getSelectAttachmentGroup: (data) => dispatch(templateActions.getSelectAttachmentGroup(data)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentFrom)
