import React from 'react';

class DragDropArea extends React.Component {

    constructor(props){
        super(props)
    }
    render(){
        let disabledStyle = this.props.disabled ? {pointerEvents:'none'} : {};
        let message = this.props.disabled ? 'Uploading file.....': 'Drag & Drop files here';
        return(
            <div style={disabledStyle}>
                <div className="dg-dp-content">
                    <div className="dg-dp-container">
                        <div className="dg-dp-drop-container"
                        onDragOver={(e)=>e.preventDefault()}
                        onDragEnter={(e)=>e.preventDefault()}
                        onDragLeave={(e)=>e.preventDefault()}
                        onDrop={this.props.handleDrop}>
                            <div className="dg-dp-drop-message">
                                <div className="flaticon-upload dg-dp-full-size"></div>
                                {message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DragDropArea;