import React from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';
import { Dialog, Button } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { DialogTitle } from '@material-ui/core';
import {Form } from 'react-bootstrap';
import _ from 'lodash'
import Ripples from 'react-ripples'
import { GlobalActions } from "../../../actions/global.action";
import { ProjectActions } from "../../../actions/project.action";
import { StockDetailActions } from "../../../actions/stock_detail.action";
import { SearchComponent } from '../../common/SearchComponent'
import { alertInfo, getTemplateID } from '../../../utils/common.helper';

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
    { id: 'manufacturer', numeric: false, disablePadding: false, label: 'Manufacturer' },
    { id: 'fitting_accessory', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'range_part', false: false, disablePadding: false, label: 'Part #' },
    { id: 'attachments', numeric: false, disablePadding: false, label: 'Attachments' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    
                </TableCell>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={orderBy === row.id ? createSortHandler(row.id) : null}
                            hideSortIcon={row.id !== 'title'}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

class ProjectStockListing extends React.Component {

    state = {
        order: 'asc',
        orderBy: 'manufacturer',
        page: 0,
        dense: false,
        open: false,
        deleteRecord: null,
        rowsPerPage: 10,
        group: null,
        manufacturer: ''
    }

    _toggleDeleteModal = (id) => {
        this.setState({
            open: !this.state.open,
            deleteRecord: id
        })
    }

    componentDidMount() {
        this.props.getAllStockGroup();
        // this.props.getAllStocksByStockGroup();
    }

    handleRequestSort = (e, property) => {
        let { order, orderBy, page} = this.state;
        if(!order){
            order = 'asc';
        }
        const isDesc = orderBy === property && order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => {
            this.props.getAllStocksByStockGroup({ sort: property, order: order === 'asc' ? -1 : 1, page, group: this.props.group_id })
        })
    }

    handleChangePage = (e, newPage) => {
        const { rowsPerPage, order, orderBy } = this.state;
        const new_page = newPage + 1;
        if (this.props.stocks_listing.length) {
            this.setState({ page: newPage })
            let extraFilters = {};
            if(order && orderBy){
                extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
            }
            this.props.getAllStocksByStockGroup({ current_page: new_page, per_page: rowsPerPage, ...extraFilters, group: this.props.group_id });
        }
    }

    openSidebar = () => {
        this.props.openRightSidebar('add_new_stock_details')
    }

    handleChangeRowsPerPage = (e) => {
        this.setState({ 
            rowsPerPage: e.target.value,
            page: 0,
         })
        this.props.getAllStocksByStockGroup({ per_page: e.target.value });
    }

    handleDelete = (id) => this.props.deleteStock(id)

    filterByGroup = (event) => {
        this.setState({group: event.target.value}, ()=> {
            this.props.getAllStocksByStockGroup({
                rowsPerPage : this.state.rowsPerPage,
                group       : this.state.group,
                page        : this.state.page,
                manufacturer      : this.state.manufacturer
            });
        })
    }

    filterBySearch = (event) => {
        this.setState({manufacturer: event.target.value}, ()=> {
            this.props.getAllStocksByStockGroup({
                rowsPerPage : this.state.rowsPerPage,
                group       : this.state.group,
                page        : this.state.page,
                manufacturer      : this.state.manufacturer
            });
        })
    }

    addToProject = () => {
        let { stocks_listing, workType, templates, templateSet } = this.props,
        selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id,
        template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MANU_SCHED"),
        
        selected_stocks = []
        _.filter(stocks_listing, { 'selected': true }).map(stock => selected_stocks.push(stock));
        let stock_ids = _.map(selected_stocks, 'id')
        let workTypeObj = _.find(workType, ['id', carried_out_id]);
        this.props.stockAddToProject({
            project_id: this.props.project_details.id,
            template_id: template_id,
            workType: workTypeObj.shortcode,
            stock_id: stock_ids,
            selected_stocks: selected_stocks
        })
    }

    render() {
        const { dense, rowsPerPage, page, order, orderBy, } = this.state;
        const { stocks_listing, stock_group_listing, pagination, requesting, user} = this.props;
        return (
            <>
                {/* border-top */}
                <div className="card-body p-0 res_card_header pt-10 mb-5 d-flex justify-content-between align-items-center flex-row">
                    <h3>Available stock</h3>
                    <div className="d-flex full_width_res">
                        <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                            <Form.Control as="select"  value={this.state.group_id} onChange={this.filterByGroup}>
                            <option value="">Select Group</option>
                            {stock_group_listing.map((group, index) => {
                                return  <option value={group.id}>{group.label}</option>
                            })}
                            </Form.Control>
                        </Form.Group>
                        <button type="button" onClick={this.openSidebar} disabled={user.type < 4?false:true} className="btn ml-3 btn-primary">Add New</button>
                    </div>
                </div>
                <div className="card_header d-flex align-items-center mb-5 justify-content-between">
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                        <SearchComponent 
                            label={"Search"}
                            margin={"normal"}
                            name={"search_field"}
                            handleChange={this.filterBySearch}
                            helperText={"Enter at least 3 characters to search"}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: '24px', }}>
                    <Paper 
                        style={{ width: '100%', marginBottom: '16px', }}
                        ref={element => {
                            if (element) element.style.setProperty('box-shadow', 'none', 'important');
                        }}
                    >
                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                style={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    listing={stocks_listing}
                                />
                                <TableBody>
                                    {stocks_listing && stocks_listing.length === 0 && (
                                        <TableRow tabIndex={-1}>
                                            <TableCell colSpan={5} align={"center"}>No Records Found!</TableCell>
                                        </TableRow>
                                    )}
                                    {stocks_listing && stocks_listing.length > 0 && stocks_listing.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell padding="checkbox" className={`${user.type < 4 ?'cursor-pointer':'cursor-not-allowed'}`}>
                                                    <Checkbox
                                                        disabled={row.isAdded || user.type > 3}
                                                        checked={row.selected}
                                                        onClick={() => { this.props.selectSingleStock({ id: row.id, selected: row.selected || false }) }}
                                                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.manufacturer}</TableCell>
                                                <TableCell>{row.fitting_accessories}</TableCell>
                                                <TableCell>{row.range_part}</TableCell>
                                                <TableCell>{!_.isEmpty(row.stock_attachment) && (row.stock_attachment.attachment_count || "0")}</TableCell>
                                                <TableCell>
                                                    <button
                                                        type="button"
                                                        disabled={user.type < 3?false:true}
                                                        className="text-danger flaticon-delete bg-transparent border-0"
                                                        onClick={() => this._toggleDeleteModal(row.id)}
                                                    ></button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {requesting && <div className="spinner"></div>}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={pagination ? pagination.total : -1}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            labelDisplayedRows={({ from, to, count }) => {
                                if (pagination === null) {
                                    return `0-0 of 0`
                                }
                                return `${from}-${to} of ${count}`
                            }}
                        />
                    </Paper>

                    <div className={`${user.type < 4 ?'cursor-pointer':'cursor-not-allowed'}`} style={{marginTop: '20px', float: 'left'}}>
                            {/* <button type="submit" onClick={this.addToProject} className="btn ml-3 btn-primary bg-transparent text-primary">Add To Project</button> */}
                            <Ripples color="rgba(0,0,0,0.2)">
                                    <Button
                                        className="bg-green border-green mr-5"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={user.type < 4?false:true}
                                        // disabled={create_manual.requesting || submitting}
                                        type="button"
                                        onClick={this.addToProject}
                                        style={{color: 'white'}}
                                    >
                                        Add To Project
                                    </Button>
                                 </Ripples> 
                        </div>

                    <Dialog
                        className="model-support"
                        modal={false}
                        contentClassName="model-top-overlay no-overlay"
                        open={this.state.open}
                    >
                        <DialogTitle id="alert-dialog-title">Delete Stock Item Services?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure, you want to delete this stock item?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button
                                onClick={this._toggleDeleteModal}
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                            >
                                No
                            </button>
                            <button
                                onClick={() => { this.handleDelete(this.state.deleteRecord); this._toggleDeleteModal() }}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                            >
                            <span>Delete</span>
                            </button>
                            </DialogActions>
                    </Dialog>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        project_details: state.project.project_details,
        stock_group_listing: state.project.stock_group_listing.listing,
        stocks_listing: state.project.stocks_listing.listing,
        pagination: state.project.stocks_listing.pagination,
        user : state.auth.user,
        requesting : state.project.stocks_listing.requesting,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    selectSingleStock: (data) => dispatch(ProjectActions.selectSingleStock(data)),
    getAllStockGroup: (data) => dispatch(ProjectActions.getAllStockGroup(data)),
    getAllStocksByStockGroup: (data) => dispatch(ProjectActions.getAllStocksByStockGroup(data)),
    stockAddToProject: (data) => dispatch(ProjectActions.stockAddToProject(data)),
    deleteStock: (data) => dispatch(ProjectActions.deleteStock(data)),
    openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(ProjectStockListing);