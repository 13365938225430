export const UserActions = {
    getAllUser,
    createUser,
    deleteUser,
    updateUser,
    setUserDetails
}

function getAllUser(data){
    return { type : "USER/GET/REQUEST", payload: data }
}

function createUser(data){
    return { type : "USER/ADD/REQUEST", payload: data }
}

function deleteUser(data){
    return { type : "USER/DELETE/REQUEST", payload: data }
}

function updateUser(data){
    return { type : "USER/UPDATE/REQUEST", payload: data }
}

function setUserDetails(data){
    return { type : "USER/DETAILS/SET/SUCCESS", payload: data }
}