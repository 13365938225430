import React from 'react';
import { connect } from 'react-redux';
import Listing from '../../../container/contractor-service/Listing';
import { Field, reduxForm, reset } from 'redux-form';
import { InputFieldRedux } from '../../../../app/component/common/FieldComponent'
import { MainServiceActions } from '../../../../app/actions/main_service.action';



const validate = (values)=>{

  const errors = {}
  if (!values.title) {
      errors.title = 'Title field is required!'
  } else if (values.title.length > 25) {
      errors.title = 'Maximum 25 characters allowed!'
  }
  return errors
}
class ContractorServiceList extends React.Component {

  handleSubmit = (data) => {
    data.form = "ContractorServiceList"
    this.props.createMainService({data, reset:this.resetForm});
    // this.props.reset('ContractorServiceList')
  }

  resetForm = () =>{
    this.props.reset('ContractorServiceList')
  }

  render() {
    const { handleSubmit, submitting, pristine, } = this.props;
    return (
      <div>
        <div className="card-header d-flex align-items-center justify-content-between px-3 custom_res_block">
          <h3 className="m-0">Contractor Services</h3>
          <div className="right-rofm_todo_list d-flex align-items-center justify-content-between">
            {/* Implement add contractor services using redux form */}
            <form className="d-flex align-items-center" onSubmit={handleSubmit(this.handleSubmit)}>
              <Field
                type="text"
                name="title"
                className="form-control rounded-0 rounded-left py-4 border-bottom add_custom_input "
                component={InputFieldRedux}
              />
              <button
                type="submit"
                className="btn btn-primary list_add_btn rounded-0 "
                disabled={submitting || pristine}
              >
                Add New
                </button>
            </form>
          </div>
        </div>
        <Listing />
      </div>
    );
  }
}

ContractorServiceList = reduxForm({
  form: 'ContractorServiceList',
  validate,
  enableReinitialize: true
})(ContractorServiceList);

const mapDispatchToProps = (dispatch, ownProps) => ({
  createMainService: (values) => dispatch(MainServiceActions.create(values)),
})

export default connect(
  null,
  mapDispatchToProps
)(ContractorServiceList);