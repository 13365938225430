import React from 'react';
import { Dialog } from '@material-ui/core';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { TextareaAutosizeRedux, renderImageField } from '../common/FieldComponent';
import { TextareaAutosize, InputLabel, OutlinedInput, MenuItem, FormControl, InputAdornment, IconButton, Input } from '@material-ui/core';


class ReasonPopup extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            reason:"",
            userId: this.props.userId
        }
    }

    _submit = () =>{
        this.props.onClickYes({
            reason: this.state.reason,
            userId: this.state.userId
        })

    }

    render(){
        return(
            <Dialog
                className="model-support"
                modal={false}
                contentClassName="model-top-overlay no-overlay"
                open={this.props.isOpen}
            >
                <DialogTitle id="alert-dialog-title">{this.props.heading}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>{this.props.message}</p> 
                        <TextareaAutosize
                            minRows={40}
                            maxRows={60}
                            width={1}
                            placeholder="Please enter the reason"
                            label="Reason"
                            style={{minWidth: '100%', minHeight: 50}}
                            onChange={(e) => { this.setState({ reason: e.target.value})}}
                            />
                    </DialogContentText>
                    
                </DialogContent>
                <DialogActions>
                    {this.props.onClickNo && <button
                        onClick={this.props.onClickNo}
                        type="button"
                        className="btn btn-light-default font-weight-bold px-9 py-4 my-3"
                    >
                        {this.props.noButtonText}
                    </button>}

                    <button
                        onClick={this._submit}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                    >
                        {this.props.yesButtonText}
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ReasonPopup;