/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import ChangePassSidebar from '../../right-sidebar/ChangePassSidebar'
import { connect } from 'react-redux'
import { GlobalActions } from "../../../../../app/actions/global.action";

class QuickUser extends Component {
 

   logoutClick = () => {
     const history = useHistory();
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      history.push("/logout");
  };

  openSidebar = (event) => {
    this.props.openRightSidebar('change_password')
    // document.body.classList.add("openRightBarChangePass");
  }

render(){
  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">&amp;</h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
            className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/300_21.jpg"
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                James Jones
              </a>
              <div className="text-muted mt-1">Application Developer</div>
              <div className="navi mt-2">
                <span onClick={this.openSidebar} className="mb-4 d-block cursor-pointer">Change Password... </span>
              </div>
              <button className="btn btn-light-primary btn-bold" onClick={this.logoutClick}>Sign out</button>
            </div>
          </div>
        </div>
        <ChangePassSidebar />
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickUser)
