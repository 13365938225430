import React from 'react';
import { connect } from 'react-redux';
import {CompanyActions} from '../../../../../app/actions/company.action'

class AsideMenuBootUp extends React.Component {
    componentDidMount(){
        this.props.getTrialPeriod();
    }

    render(){
        return(
        <>
        </>)
    }
}

const mapStateToProps = (state, props) => {
    return {

    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getTrialPeriod: () => {dispatch(CompanyActions.getTrialPeriod())},
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AsideMenuBootUp);