export const GlobalActions = {
    openRightSidebar,
    closeRightSidebar
}

function openRightSidebar(data){
    return { type : "GLOBAL/OPEN_SIDEBAR/SUCCESS", payload: data }
}

function closeRightSidebar(data){
    return { type : "GLOBAL/CLOSE_SIDEBAR/SUCCESS", payload: data }
}