import { stopSubmit, startSubmit, reset } from 'redux-form';
import { put, all, takeEvery } from 'redux-saga/effects';
// import { takeLatest } from 'redux-saga';
import { alertInfo } from '../utils/common.helper';

function* formStartSubmit(data) {
    yield put(startSubmit(data.form_name));
}

function* formStopSubmit(data) {
    yield put(stopSubmit(data.form_name));
}

function* formStopAndResetSubmit(data) {
    yield put(stopSubmit(data.form_name));
    yield put(reset(data.form_name));
}

function* formErrorHandler({ payload }) {
    let { error, form_name } = payload
    // alertInfo('error', form_name)
    yield put(stopSubmit(form_name, error));
}

function* formSuccessHandler({ payload }) {
    yield put(stopSubmit(payload.form_name));
}

export default function* globalSaga() {
    yield all([
      takeEvery("GLOBAL/FORM_START", formStartSubmit),
      takeEvery("GLOBAL/FORM_STOP", formStopSubmit),
      takeEvery("GLOBAL/FORM_ERROR", formErrorHandler),
      takeEvery("GLOBAL/FORM_SUCCESS", formSuccessHandler),   
      takeEvery("GLOBAL/FORM_STOP_AND_RESET", formStopAndResetSubmit),   
  ]);
}