import _ from 'lodash'

let initialValues = {
    userlisting: {
        requesting: false,
        data: [],
    },
    userDetails: {}
}

export const userReducer = (state = initialValues, action) => {
    switch (action.type) {
        
        case 'USER/GET/START':
            return {
                ...state,
                userlisting: {
                    ...state.userlisting,
                    requesting: true,
                },
            }

        case 'USER/GET/SUCCESS':
            return {
                ...state,
                userlisting: {
                      ...state.userlisting,
                      data: [ ...action.payload.users ],
                      pagination: { ...action.payload.pagination },
                      requesting: false,
                },
            }

        case 'USER/ADD/SUCCESS':
          return {
              ...state,
              userlisting: {
                    ...state.userlisting,
                    data: [ action.payload, ...state.userlisting.data],
                    pagination: {
                      total_pages: state.userlisting.pagination.total_pages + 1
                    }
              },
        }

        case 'USER/DELETE/SUCCESS':
            const id_del = action.payload.id;
            const newuserlisting = _.filter(state.userlisting.data, user => user._id !== id_del)
            return {
                ...state,
                userlisting: {
                    ...state.userlisting,
                    data: newuserlisting,
                    pagination: {
                      total_pages: state.userlisting.pagination.total_pages - 1
                    }
                }
            }

        case 'USER/UPDATE/SUCCESS':
          let updatedUsers = state.userlisting && [...state.userlisting.data] || []
          const id_edit = action.payload.id;
          const indexOfUpdatedUser = _.findIndex(state.userlisting.data, user => user._id === id_edit)
          if(indexOfUpdatedUser !== -1){
            updatedUsers[indexOfUpdatedUser].name =  action.payload.name
            updatedUsers[indexOfUpdatedUser].email =  action.payload.email
            updatedUsers[indexOfUpdatedUser].type =  action.payload.type
          }
          return {
            ...state,
            userlisting: {
              ...state.userlisting,
              data: updatedUsers
            }

        }

        case 'USER/DETAILS/SET/SUCCESS':
          return {
            ...state,
            userDetails: action.payload,
        }

        default:
            return {
                ...state
            }
    }
}