import React from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

import { toAbsoluteUrl, } from "../../_helpers";
import { connect } from 'react-redux';
import { ContractorActions } from "../../../app/actions/contractor.action";
import { GlobalActions } from "../../../app/actions/global.action";

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Contractor' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  // { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
  { id: 'service', numeric: false, disablePadding: false, label: 'Service Provided' },
  { id: 'access', numeric: false, disablePadding: false, label: 'Access' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  let tooltipIcon = <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">When selected, contractors will be given access to the system to upload their files.</Tooltip>}
    >
        <p>Access <i className="fa fa-info-circle tooltip-icon" aria-hidden="true"></i></p>
    </OverlayTrigger>;
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, i) => (
          <TableCell
            key={i}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length > 1}
              direction={order}
              // onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              onClick={(row.label == 'Actions' || row.label == 'Service Provided' || row.label == 'Access') ? null: createSortHandler(row.id)}
              hideSortIcon={row.id !== 'name' || listing.length < 2}
            >
            <p>{row.label == "Access"?tooltipIcon:row.label}</p>
              
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class Listing extends React.Component {

  state = {
    order: 'asc',
    orderBy: '',
    page: 0,
    dense: false,
    rowsPerPage: 10,
    open: false,
    deleteRecord: null
  }

  _toggleDeleteModal = (id) => {
    this.setState({
      open: !this.state.open,
      deleteRecord: id
    })
  }

  componentDidMount() {
    this.props.contractorListing();
  }

  handleRequestSort = (e, property) => {
    const { order, orderBy,rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property,page:0 }, () => {
      if(property == 'address'){
        property = 'address_line_1';
      }
      if(property == 'service'){
        property = 'service_id';
      }
      this.props.contractorListing({ sort: property, order: order === 'asc' ? -1 : 1, per_page: rowsPerPage })
    })
  }

  handleChangePage = (e, newPage) => {
    let { rowsPerPage, order, orderBy  } = this.state;
    const new_page = newPage + 1;
    if (this.props.listing.length) {
      this.setState({ page: newPage })
      const isDesc =  order === 'asc';
      // this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property },() => {
        if(orderBy == 'address'){
          orderBy = 'address_line_1';
        }
        if(orderBy == 'service'){
          orderBy = 'service_id';
        }
        if(!orderBy){
          orderBy = 'name';
        }
      this.props.contractorListing({ current_page: new_page, per_page: rowsPerPage, sort: orderBy, order: order === 'asc' ? 1 : -1 ,per_page: rowsPerPage});
    }
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
      page: 0,
      order: 'asc',
      orderBy: 'name',
     })
    this.props.contractorListing({ per_page: e.target.value });
  }

  handleDelete = (id) => {
    this.props.deleteContractor(id)
  }

  openUpdateContractorSidebar = (id) => {
    this.props.getContractor(id);
    this.props.openRightSidebar('update_contractor')
    // document.getElementById('update-contractor-sidebar').style.display = 'block';
    // document.body.classList.add("openRightBar");
    this.props.toggleSidebar();
  }

  updateContractorAccess = (e, contractor) => {
    if(contractor.email){
      let data = {
        id:contractor.id,
        email:contractor.email,
        name:contractor.label,
        user_access : e.target.checked
      }
      this.props.updateContractorAccess(data)
    }

  }

  render() {
    const { dense, rowsPerPage, page, order, orderBy, } = this.state;
    const { listing, pagination, requesting } = this.props;
    return (
      <div style={{ width: '100%', marginTop: '24px', }}>
        <Paper style={{ width: '100%', marginBottom: '16px', }}
          ref={element => {
            if (element) element.style.setProperty('box-shadow', 'none', 'important');
          }}>
          <div style={{ overflowX: 'auto' }}>

            <Table
              style={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                listing={listing}
              />
              <TableBody>
                {!requesting && listing && listing.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                  </TableRow>
                )}
                {listing && listing.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateContractorSidebar(row.id)}>
                        {row.label}
                      </TableCell>

                      <TableCell style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateContractorSidebar(row.id)}>{row.email}</TableCell>
                      {/* <TableCell>{'Static Phone'}</TableCell> */}
                      <TableCell  style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateContpointerractorSidebar(row.id)}>{row.main_service && row.main_service.service_name}</TableCell>
                      <TableCell  style={{ cursor: 'pointer' }}
                        onChange={(e) => this.updateContractorAccess(e, row)}>
                        <div style={{ cursor: 'pointer' }} class="custom-control custom-switch">
                          <input style={{ cursor: 'pointer' }} disabled={row.email?false:true} type="checkbox" class="custom-control-input" id={row.id} defaultChecked={row.user_access}/>
                          <label title={row.email?'':"Please update email id first."} style={{ cursor: 'pointer' }} class="custom-control-label" for={row.id}></label>
                        </div>
                      </TableCell>
                      <TableCell>
                        <button className="trash_button" onClick={() => this._toggleDeleteModal(row.id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}
                        /></button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {requesting && <div class="spinner"></div>}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={pagination ? pagination.total : -1}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              if (pagination === null) {
                return `0-0 of 0`
              }
              return `${from}-${to} of ${count}`
            }}
          />
        </Paper>
        <Dialog
          className="model-support"
          modal={false}
          contentClassName="model-top-overlay no-overlay"
          open={this.state.open}
        >
          <DialogTitle id="alert-dialog-title">Delete Contractor?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to delete this contractor ?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this._toggleDeleteModal}
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
            >
              No
                  </button>
            <button
              // type="submit"
              onClick={() => { this.handleDelete(this.state.deleteRecord); this._toggleDeleteModal() }}
              // disabled={submitting||pristine}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3"
            >
              <span>Delete</span>
              {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


}

const mapStateToProps = (state, ownProps) => {
  const { contractorlisting: { data, pagination, requesting } } = state.contractor;
  return {
    listing: data,
    pagination: pagination,
    requesting: requesting
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  contractorListing: (data) => dispatch(ContractorActions.getAll(data)),
  updateContractorAccess: (values) => dispatch(ContractorActions.updateOneAccess(values)),
  deleteContractor: (data) => dispatch(ContractorActions.deleteOne(data)),
  getContractor: (data) => dispatch(ContractorActions.getOne(data)),
  toggleSidebar: () => dispatch(ContractorActions.toggleSidebar()),
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);