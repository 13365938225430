import _ from 'lodash'

let initialValues = {
  customerlisting: {
    requesting: false,
    data: [],
    pagination: null,
    detail: {},
    requestingDetail:false,
    filter: ""
  },
  isSidebarOpen: false,
}

export const customerReducer = (state = initialValues, action) => {
    switch (action.type) {
       //Toggle sidebar
       case 'CUSTOMER/SIDEBAR/TOGGLE':
        return {
            ...state,
            isSidebarOpen: !state.isSidebarOpen,
        }
      //Get all customers
        case 'CUSTOMER/GET/ALL':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    requesting: true,
                },
        }
        case 'CUSTOMER/GET/ALL/SUCCESS':
            return {
                ...state,
                customerlisting: {
                      ...state.customerlisting,
                      data: [ ...action.payload.data ],
                      pagination: { ...action.payload.pagination },
                      requesting: false,
                },
        }
        case 'CUSTOMER/GET/ALL/ERROR':
            return {
                ...state,
                customerlisting: {
                      ...state.customerlisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
         //Delete one customer
        case 'CUSTOMER/DELETE/ONE':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    // requesting: true,
                },
        }
        case 'CUSTOMER/DELETE/ONE/SUCCESS':
            const id_del = action.payload.id;
            const newCustomerListing = _.filter(state.customerlisting.data, customer => customer.id !== id_del)
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    data: newCustomerListing,
                    pagination: {
                      total: state.customerlisting.pagination.total - 1
                    },
                    // requesting: false,
                },
        }
        case 'CUSTOMER/DELETE/ONE/ERROR':
            return {
                ...state,
                customerlisting: {
                      ...state.customerlisting,
                      errors: action.payload.data,
                    //   requesting: false,
                },
        }
        // Get one customer
        case 'CUSTOMER/GET/ONE':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    requestingDetail: true,
                },
        }
        case 'CUSTOMER/GET/ONE/SUCCESS':
            const customer = action.payload;
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    detail: { ...customer},
                    requestingDetail: false,
                },
        }
        case 'CUSTOMER/GET/ONE/ERROR':
            return {
                ...state,
                customerlisting: {
                      ...state.customerlisting,
                      errors: action.payload.data,
                      requestingDetail: false,
                },
        }
        // Create one customer
        case 'CUSTOMER/CREATE':
          return {
              ...state,
              customerlisting: {
                    ...state.customerlisting,
                    // requesting: true,
              },
        }
        case 'CUSTOMER/CREATE/SUCCESS':
          return {
              ...state,
              customerlisting: {
                    ...state.customerlisting,
                    data: [ action.payload, ...state.customerlisting.data ],
                    pagination: {
                      total: state.customerlisting.pagination.total + 1
                    },
                    // requesting: false,
              },
        }
        case 'CUSTOMER/CREATE/ERROR':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
        }

        // Update one customer
        case 'CUSTOMER/UPDATE/ONE/ACCESS':
          return {
              ...state,
              customerlisting: {
                    ...state.customerlisting,
                    // requesting: true,
              },
        }

        // Update one customer
        case 'CUSTOMER/UPDATE/ONE':
          return {
              ...state,
              customerlisting: {
                    ...state.customerlisting,
                    // requesting: true,
              },
        }
        case 'CUSTOMER/UPDATE/ONE/SUCCESS':
          const id_edit = action.payload.id;
          const indexOfUpdatedCustomer = _.findIndex(state.customerlisting.data, customer => customer.id === id_edit)
          if(indexOfUpdatedCustomer !== -1){
            state.customerlisting.data.splice(indexOfUpdatedCustomer, 1, action.payload)
          }
          return {
            ...state,
            customerlisting: {
                ...state.customerlisting,
                data: state.customerlisting.data,
                // requesting: false,
            },
        }
        case 'CUSTOMER/UPDATE/ONE/ERROR':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
        }
        // Save filter value
        case 'CUSTOMER/FILTER/SAVE':
          return {
              ...state,
              customerlisting: {
                    ...state.customerlisting,
                    filter: action.payload
              },
        }
        default:
            return {
                ...state
            }
    }
}