import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import Toggle from 'material-ui/Toggle';
// import Checkbox from 'material-ui/Checkbox';
// import RaisedButton from 'material-ui/RaisedButton';
// import CircularProgress from 'material-ui/CircularProgress';
import { Form, Button } from 'react-bootstrap';
import { injectStripe } from 'react-stripe-elements';
import Iframe from 'react-iframe'
import { CardNumberElement, CardCVCElement, CardExpiryElement, CardElement } from 'react-stripe-elements';
import queryString from 'query-string';
import _ from 'lodash';
import { Link } from "react-router-dom";

import { SubscriptionActions } from "../../../app/actions/subscription.action";
import { CompanyActions } from "../../../app/actions/company.action";
import ConfirmationPopup from '../../../app/component/common/ConfirmationPopup'
// import * as actions from '../../../Actions/payment';
import { connect } from 'react-redux';
import { alertInfo } from '../../../../src/app/utils/common.helper'
// import PropTypes from 'prop-types';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payBtnClicked: false,
            buttonDisable: false,
            url: '',
            openPop:false,
            popupMessage:"",
            popHeading:"",
            termCheck: false
        };
        this.handleClick = this.handleClick.bind(this);
        let params = this.props.location && queryString.parse(this.props.location.search)


        // this.props.stripe.confirmCardPayment(params.client_secret, {
        //     payment_method: {
        //         card: {
        //             token: "tok_1HZdcjDWHqk2O3AcxcgR2WRw",
        //         },
        //     },
        // }).then(function(result) {
        //     // Handle result.error or result.paymentIntent
        // })
    }

    // componentWillReceiveProps(nextProps) {
    //     let checkButtonstatus = nextProps.signUp.ispaymentRequest;
    //     this.setState({ buttonDisable: checkButtonstatus });
    // }
    
    _alertPopUp = (heading, message) => {
        this.props.setPopMessage(message);
        this.props.setPopHeading(heading);
        this.props.failedPopUp();
    }
    handleClick(e) {
        let checktnc = e.target.checked ? '' : 'Please select terms of service.';
        this.props.setTncText(checktnc);
    }
    // handleSubmit = (ev) => {

    //     ev.preventDefault();
    //     this.props.stripe.createToken({ name: this.props.auth.name }).then(({ token }) => {
    //         if (typeof (token) != 'undefined' && token !== '') {
    //             var customerEmail = this.props.customerEmail;
    //             var customerName = this.props.customerName;
    //             var subscriptionType = this.props.subscriptionType;
    //             var customerUserId = this.props.customerUserId;
    //             var customerCompanyId = this.props.customerCompanyId;
    //             var request = {
    //                 token: token,
    //                 customerEmail: customerEmail,
    //                 customerName: customerName,
    //                 customerUserId: customerUserId,
    //                 customerCompanyId: customerCompanyId,
    //                 subscriptionType: subscriptionType
    //             };

    //             this.props.stripe.createSource({
    //                 type: 'three_d_secure',
    //                 amount: 1099,
    //                 currency: "usd",
    //                 three_d_secure: {
    //                     card: token.card.id
    //                 },
    //                 redirect: {
    //                     return_url: `${process.env.REACT_APP_URL}company-subscription`
    //                 }
    //             }).then(async (result) =>  {
    //                 if (result.error) {
    //                     // PaymentIntent client secret was invalid
    //                 } else {
    //                     // window.location = result.source.redirect.url
    //                     // this.setState({
    //                     //     url:result.source.redirect.url
    //                     // })
    //                     if(result.source.three_d_secure.three_d_secure == 'required'){
    //                         this.setState({url: result.source.redirect.url})
    //                         const cardElement = this.props.elements.getElement('card');
    //                         const paymentMethod = await this.props.stripe.createPaymentMethod({
    //                             type: 'card',
    //                             card: cardElement,
    //                             billing_details: {
    //                               // Include any additional collected billing details.
    //                               name: customerName,
    //                             },
    //                           })
    //                         //   this.props.subscribePlan(request);
    //                         this.props.createPaymentIntent({payment_method_id: paymentMethod.paymentMethod.id, subscription_type: subscriptionType, amount:4500, subscription_id: this.props.selected_subscription });
   
    //                     } else{
    //                         this.props.subscribePlan(request);
    //                     }
                        
    //                 }
                    
    //             });
    //         }
    //     });

    // }

    _handleSubmit= async (ev)=>{
        ev.preventDefault();
        var customerEmail = this.props.customerEmail;
        var customerName = this.props.customerName;
        var subscriptionType = this.props.subscriptionType;
        var customerUserId = this.props.customerUserId;
        var customerCompanyId = this.props.customerCompanyId;
        this.props.requestStart();
        const cardElement = this.props.elements.getElement('cardNumber');
        const paymentMethod = await this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                // Include any additional collected billing details.
                name: customerName,
            },
            })
        //   this.props.subscribePlan(request);
        if(paymentMethod.paymentMethod){
            this.props.createPaymentIntent({payment_method_id: paymentMethod.paymentMethod.id, subscription_type: subscriptionType, amount:4500, subscription_id: this.props.selected_subscription });
        }else{
            this.props.requestStartError();
        }
   
    }

    attemptConfirmation = () =>{
        let that = this;
        const cardElement = this.props.elements.getElement('cardNumber');
        let customerName = this.props.customerName;
        let customerEmail = this.props.customerEmail;
        let subscriptionType = this.props.subscriptionType;
        let customerUserId = this.props.customerUserId;
        let customerCompanyId = this.props.customerCompanyId;
        if(this.props.client_secret_for_auth.payment_intent_client_secret && !this.props.three_d_secure_processing){
            this.props.toggle3dSecureLoading(); //toggle flag to 3d processing
            this.props.stripe
            .confirmCardPayment(this.props.client_secret_for_auth.payment_intent_client_secret, {
                payment_method: {
                card: cardElement,
                billing_details: {
                    name: customerName,
                },
                },
                setup_future_usage: 'off_session',
            })
            .then(function(result) {
                that.props.createPaymentIntentReset();
                if(result.error){
                    that.setState({
                        popHeading: "Payment Failed",
                        popupMessage: result.error.message,
                        openPop: true
                    });
                }else{
                    that.props.paymentSuccess(that.props.selected_subscription)
                    alertInfo('success', that.props.payment_detail_success_message);
                    that.props.getTrialPeriod({
                        trial: false, days: 0, allowance: 10, subscribed: true,
                      });
                    that.props.loadUpdatedAllowance();

                }
                that.props.toggle3dSecureLoading(); //toggle flag to 3d processing end
                // that.props.getCurrentPlan();
                if (!result.error) {
                    window.location.reload();
                }
            });
        }

    }

    _toggleTermCheck = () =>{
      this.setState({
        termCheck: !this.state.termCheck
      })  
    }
    render() {
        const amount = (
            <span className="pay-amount-text">
                Pay &pound; {this.props.amountToBePaid}
            </span>
        );
        if(!_.isEmpty(this.props.client_secret_for_auth)){
            this.attemptConfirmation();
        }

        return (
            <div className="card-details-box">
                {/* {this.state.url && 
                    <Iframe 
                        position="absolute"
                        url={this.state.url}
                        width="200px"
                        height="450px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                    />
                } */}
                {/* <form onSubmit={this.handleSubmit} className="payment-form">
                    <Row>
                        <Col md={12} className="margb20px">
                            <fieldset>
                                <label>Card Number</label>
                                <CardNumberElement />
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="margb20px">
                            <fieldset>
                                <span className="card-image"><img src="/media/img/calendar.png" alt="paymeny" /></span>
                                <label>Expiration date</label>
                                <CardExpiryElement />
                            </fieldset>
                        </Col>
                        <Col md={6} className="margb20px">
                            <fieldset>
                                <span className="card-image"><img src="/media/img/cvc.png" alt="paymeny" /></span>
                                <label>CVC</label>
                                <CardCVCElement />
                            </fieldset>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <RaisedButton
                                label={this.state.buttonDisable ? <CircularProgress size={32} color="#fff" /> : 'Start my Free 14-Day Trial'}
                                type="submit"
                                labelColor="#919191"
                                buttonStyle={{ background: '#e5e5e5' }}
                                fullWidth={true}
                                className={(this.props.signUp.setStep === 2 || window.localStorage.getItem('stepCompleted') === '1') ? "btn-white" : "pay-btn btn-white"}
                                disabled={((this.props.signUp.setStep === 2 && this.props.signUp.tncSignupText === '') || (this.props.signUp.tncSignupText === '' && window.localStorage.getItem('stepCompleted') === '1')) && !this.state.buttonDisable ? false : true}
                            />

                            <div className="terms-conditions-signup signup-terms-condition">
                                <label><Checkbox onClick={this.handleClick} className="terms-check" />
                                    <span>I agree to the Dokkit <a href="https://www.dokkit.co.uk/termsofservice" target="_blank">Terms of Service</a> and understand that this is a
                      recurring subscription. I will be billed automatically on a recurring
                      basis unless I cancel.</span>
                                </label>
                            </div>
                            {
                                this.props.signUp.tncSignupText && <p className="error">{this.props.signUp.tncSignupText}</p>
                            }
                        </Col>
                    </Row>
                </form> */}
                <p className="mb-10">
                    No charge until your free trial period ends. Cancel anytime. <a href="https://www.dokkit.co.uk/pricing/" target="_blank">Learn more</a>.
                </p>
                <Form autoComplete="off" onSubmit={this._handleSubmit} >
                    <Form.Group >
                        <CardNumberElement />
                    </Form.Group>
                    <div className="row">
                        <div className="col-md-7">
                            <Form.Group >
                                <CardExpiryElement />
                            </Form.Group>
                        </div>
                        <div className="col-md-5">
                            <Form.Group >
                                <CardCVCElement />
                            </Form.Group>
                        </div>
                    </div>
                    {/* <CardElement style={{base: {fontSize: '18px'}}} hidePostalCode={true} /> */}
                    <Button disabled={(this.props.payment_detail_requesting 
                        || !this.props.selected_subscription 
                        || (this.props.client_secret_for_auth && true))
                        || !this.state.termCheck} className="d-block bg-green border-green full-width" type="submit">
                        Update Plan
                    </Button>
                    <div className="form-group d-flex mt-4">
                        <label className="checkbox mr-2">
                            <input
                            onClick={this._toggleTermCheck}
                            type="checkbox"
                            name="acceptTerms"
                            className="mb-0" />
                            <span className="ml-2" />
                        </label>
                        <span>Please select <a target="_blank" href="https://www.dokkit.co.uk/termsofservice/" target="_blank">terms of service.</a></span>
                    </div>
                </Form>
                <ConfirmationPopup
                    heading={this.state.popHeading}
                    message={this.state.popupMessage}
                    yesButtonText="ok"
                    isOpen={this.state.openPop}
                    onClickYes={()=>{
                        this.setState({
                            openPop: false
                        })
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { selected_subscription } = state.subcription;
    return {
        signUp: state.signup,
        auth: state.auth.user,
        client_secret_for_auth: state.subcription.client_secret_for_auth,
        payment_detail_requesting: state.subcription.payment_detail_requesting,
        selected_subscription,
        payment_detail_success_message: state.subcription.payment_detail_success_message,
        three_d_secure_processing : state.subcription && state.subcription.three_d_secure_processing
    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    requestStart:() =>dispatch ({type:"SUBSCRIPTION/PAYMENTMETHOD/REQUEST"}),
    requestStartError :() =>dispatch ({type:"SUBSCRIPTION/CREATEPAYMENTINTENT/ERROR"}),
    subscribePlan: (values) => dispatch(SubscriptionActions.subscribePlan(values)),
    createPaymentIntent: (values) => dispatch(SubscriptionActions.createPaymentIntent(values)),
    createPaymentIntentReset: () => dispatch(SubscriptionActions.createPaymentIntentReset()),
    getCurrentPlan: ()=> dispatch(SubscriptionActions.getCurrentPlan()),
    paymentSuccess: (values) => dispatch({type:"SUBSCRIPTION/CURRENT/PAYMENTSUCCESS", payload:{subscription_id:values}}),
    getTrialPeriod: (values) => dispatch({type: 'COMPANY/GET/TRIAL_PERIOD/SUCCESS',payload:values}),
    toggle3dSecureLoading: () => dispatch({type: 'SUBSCRIPTION/THREE_D_SECURE/TOGGLE'}),
    loadUpdatedAllowance : () => dispatch(CompanyActions.getTrialPeriod())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectStripe(CheckoutForm));