import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
//import Cookies from 'universal-cookie';
import BasePage from "./BasePage";

import {Layout} from "../../_metronic/layout";
import { AuthPage } from "../pages/AuthPage";
import CompanyInfo from '../pages/CompanyInfo'
import CompanySubscription from '../pages/CompanySubscription'

import Logout from "../component/Auth/Logout";

export function Routes() {
    
    let {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    let {auth} = useSelector(
        ({auth}) => ({
            auth: auth.user,
        }),
        shallowEqual
    );

    let {company} = useSelector(
        ({company}) => ({
            company: company.trial,
        }),
        shallowEqual
    );
        let allowance = company && company.allowance;
        let days = company && company.days;
        let subscribed = company && company.subscribed;
        let trial = company && company.trial;
        const location = useLocation();
        // console.log("useLocation", location.pathname);
    return (
        
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                
                    <Layout>
                        {auth.profile_completed === 1 ? 
                            (trial || subscribed ? <BasePage auth={auth} /> : (location.pathname == '/company_info' ? <Route><CompanyInfo /></Route>:<Route><CompanySubscription /></Route>))
                        :  <Route><CompanyInfo /></Route>}
                    </Layout> 
                
            )}
        </Switch>
    );
}
