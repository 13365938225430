export const MainServiceActions = {
  create,
  getAll,
  getOne,
  updateOne,
  deleteOne,
  saveFilterValue,
}

function create(data){
  return { type : "MAINSERVICE/CREATE", payload: data }
}

function getAll(data){
  return { type : "MAINSERVICE/GET/ALL", payload: data }
}

function getOne(id){
  return { type : "MAINSERVICE/GET/ONE", payload: id }
}

function updateOne(id){
  return { type : "MAINSERVICE/UPDATE/ONE", payload: id }
}

function deleteOne(id){
  return { type : "MAINSERVICE/DELETE/ONE", payload: id }
}

function saveFilterValue(data){
  return { type : "MAINSERVICE/FILTER/SAVE", payload: data }
}