export const StockDetailActions = {
  createStockDetail,
  update,
  getAll,
  getOne,
  updateStockDetail,
  deleteOne,
  saveFilterValue,
  toggleSidebar,
  getAllStockAttachments,
  deleteOneStockAttachmentTitle,
  addOneStockAttachmentTitle,
  uploadStockAttachment,
  removeUploadAttachFile,
  addNewAttachmentAccordion,
  addNewAttachmentGroup,
  deleteAttachmentGroup,
  removeAttachmentAccordion,
  attachmentAccordionDrag,
  addNewAttachmentFile,
  attachmentFileDrag,
  removeAttachmentFile,
  resetAttachmentAccordion
}

function createStockDetail(data) {
  return { type: "STOCKDETAIL/CREATE", payload: data }
}

function update(data) {
  return { type: "STOCKDETAIL/UPDATE", payload: data }
}

function getAll(data) {
  return { type: "STOCKDETAIL/GET/ALL", payload: data }
}

function getOne(id) {
  return { type: "STOCKDETAIL/GET/ONE", payload: id }
}

function updateStockDetail(data) {
  return { type: "STOCKDETAIL/UPDATE/ONE", payload: data }
}

function deleteOne(id) {
  return { type: "STOCKDETAIL/DELETE/ONE", payload: id }
}

function saveFilterValue(data) {
  return { type: "STOCKDETAIL/FILTER/SAVE", payload: data }
}

function toggleSidebar() {
  return { type: "STOCKDETAIL/SIDEBAR/TOGGLE" }
}

function getAllStockAttachments() {
  return { type: "STOCKDETAIL/STOCKATTACHMENT/GET" }
}

function deleteOneStockAttachmentTitle(data) {
  return { type: "STOCKDETAIL/STOCKATTACHMENT/DELETE/ONE", payload: data }
}

function addOneStockAttachmentTitle(data) {
  return { type: "STOCKDETAIL/STOCKATTACHMENT/ADD/ONE", payload: data }
}

function uploadStockAttachment(values) {
  return {
    type: "STOCKDETAIL/STOCKATTACHMENT/UPLOAD", payload: values
  }
}

function removeUploadAttachFile(data) {
  return {
    type: "STOCKDETAIL/UPLOADATTACHMENT/REMOVE", payload: data
  }
}

function addNewAttachmentAccordion(data) {
  console.log("All data", data);
  return {type: "STOCKDETAIL/ADD/NEW/ATTACHMENT/ACCORDION", payload: data}
}

function addNewAttachmentGroup(data) {
  return { type: "STOCKDETAIL/ADD/NEW/ATTACHMENT/GROUP", payload: data }
}

function deleteAttachmentGroup(data) {
  return { type: "STOCKDETAIL/DELETE/ATTACHMENT/GROUP", payload: data }
}

function removeAttachmentAccordion(data) {
  return {type: "STOCKDETAIL/REMOVE/ATTACHMENT/ACCORDION", payload: data}
}

function resetAttachmentAccordion(data) {
  return {type: "STOCKDETAIL/RESET/ATTACHMENT/ACCORDION", payload: data}
}

function attachmentAccordionDrag(data) {
  return {type: "STOCKDETAIL/ATTACHMENT/ACCORDION/DRAG", payload: data}
}

function addNewAttachmentFile(data) {
  return {type: "STOCKDETAIL/ADD/NEW/ATTACHMENT/FILE", payload: data}
}

function removeAttachmentFile(data) {
  return {type: "STOCKDETAIL/REMOVE/ATTACHMENT/FILE", payload: data}
}

function attachmentFileDrag(data) {
  return {type: "STOCKDETAIL/ATTACHMENT/FILE/DRAG", payload: data}
}