import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import SVG from "react-inlinesvg";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { toAbsoluteUrl, } from "../../../_helpers";
import { connect } from 'react-redux';
import { ProjectActions } from "../../../../app/actions/project.action";


function createData(contractors, email, phone, service_provide, action) {
    return { contractors, email, phone, service_provide, action };
}

const rows = [
    createData('Satelite and Aerial Services', 'abcd@yahoo.com', '(846) 943-0444', 'Aerial/Satellite work', '1142', 'Delete')
];


const headRows = [
    { id: 'contractors', numeric: false, disablePadding: true, label: 'Contractors' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
    { id: 'service_provide', false: false, disablePadding: false, label: 'Service Provided' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {

    const { order, orderBy, onRequestSort } = props;
    
    const createSortHandler = property => event => onRequestSort(event, property);

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const rowsPerPageOptions = [10, 15, 25, 50];

class Listing extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            order: 'asc',
            orderBy: 'calories',
            selected: [],
            page: 0,
            dense: false,
            rowsPerPage: 5
        })
    }

    componentDidMount() {
        this.props.contractorListing();
    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({
            order: isDesc ? 'asc' : 'desc',
            orderBy: property
        })
    }

    handleDelete = (id) => this.props.deleteStockDetail(id)

    render() {
        const { order, orderBy, selected, page, dense, rowsPerPage } = this.state;
        const { listing, pagination, requesting, } = this.props;
        const isSelected = name => selected.indexOf(name) !== -1;
        
        return (
            <div style={{ 'width': '100%' }}>
                <Paper style={{ 'width': '100%', 'marginBottom': '16px', 'boxShadow': 'none !important' }}
                    ref={element => {
                    if (element) element.style.setProperty('box-shadow', 'none', 'important');
                }}>
                    <div style={{ 'overflowX': 'auto' }}>
                        <Table
                            style={{ 'minWidth': '750' }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                listing={listing}
                            />
                            <TableBody>
                                {!requesting && listing && listing.length === 0 && (
                                    <TableRow tabIndex={-1}>
                                        <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                                    </TableRow>
                                )}
                                {listing && listing.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.phone && row.phone || 'N/A'}</TableCell>
                                            <TableCell>{row.main_service && row.main_service.service_name || 'N/A'}</TableCell>
                                            <TableCell>
                                                <button 
                                                    className="trash_button" 
                                                    onClick={() => this._toggleDeleteModal(row.id)}
                                                >
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}/>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={pagination ? pagination.total : -1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => {
                            if (pagination === null) {
                                return `0-0 of 0`
                            }
                            return `${from}-${to} of ${count}`
                        }}
                    />
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        listing     : [{
            name: 'Vijay',
            email: 'vijay@getnada.com',
            phone: '9838111071',
            service_provide: 'Development'
        },{
            name: 'ssVijay 2',
            email: 'ssvijay@getnada.com2',
            phone: '0098381110712',
            service_provide: 'aaaDevelopment2'
        }],
        pagination  : state.project.contractlisting.pagination,
        requesting  : state.project.contractlisting.requesting
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    contractorListing: (data) => dispatch(ProjectActions.getcontractorListing(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
