import { fork, all } from 'redux-saga/effects';
import globalSaga from './globalSagas';
import authSaga from './authSagas';
import siteSaga from './siteSagas';
import customerSaga from './customerSagas';
import contractorSaga from './contractorSagas';
import mainServiceSaga from './mainServiceSagas';
import stockSaga from './stockSagas';
import stockDetailSaga from './stockDetailSagas';
import subscriptionSaga from './subscriptionSagas';
import companySaga from './companySagas';
import projectSaga from './projectSagas';
import templateSaga from './templateSagas';
import importerSaga from './importerSagas';
import userSaga from './userSagas';

export default function* rootSaga() {
  yield all([
    fork(globalSaga),
    fork(authSaga),
    fork(siteSaga),
    fork(companySaga),
    fork(customerSaga),
    fork(contractorSaga),
    fork(mainServiceSaga),
    fork(stockSaga),
    fork(stockDetailSaga),
    fork(subscriptionSaga),
    fork(projectSaga),
    fork(templateSaga),
    fork(importerSaga),
    fork(userSaga),
  ]);
}