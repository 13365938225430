import React, { Component } from "react";
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'; 
import { Link } from "react-router-dom";

import { AuthActions } from '../../../actions/auth.action';
import { InputFieldAuth } from "../../common/FieldComponent";


const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email field is required!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }
    return errors
}

class ForgotPasswordForm extends Component{
 
    render(){
        const { handleSubmit, submitting, pristine, forgotPassword } = this.props
        return(
            <form
                onSubmit={handleSubmit(forgotPassword)}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                <h3 className="font-size-h1">Forgotten Password ?</h3>
                <div className="text-muted font-weight-bold mb-5 pb-4">
                  Enter your email to reset your password
                </div>
                {/* begin: email */}
                        <Field 
                            placeholder="User email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="email"
                            component={InputFieldAuth}/>
                    {/* end: email */}
                <div className="form-group d-flex flex-wrap flex-center mb-0">
                <Link to="/auth">
                    <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                    Cancel
                    </button>
                </Link>
                <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={submitting||pristine}
                >
                    {submitting ? 'Submitting..': 'Submit' }
                </button>
                </div>
            </form>
        )
    }
}
ForgotPasswordForm = reduxForm({
    form: 'ForgotPasswordForm',
    validate,
    enableReinitialize: true
})(ForgotPasswordForm);


const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (values)=>{dispatch(AuthActions.forgotPassword({values}))}
})

export default connect(null, mapDispatchToProps)(ForgotPasswordForm)
