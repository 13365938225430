import React, { Component } from 'react';
import Header from '../component/Project/header';
import {useHistory} from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from "react-redux";

const Projects = () => {
    let {company} = useSelector(
        ({company}) => ({
            company: company.trial,
        }),
        shallowEqual
    );
    const dispatch = useDispatch()
    let label ="";
    // console.log("company ==> ", company, company.subscribed);
    if(company.trial && process.env.REACT_APP_FREE_TRIAL_DAYS == company.days ){
        label = `Your ${company.days}-day free trial starts now`
    }

    if(company.trial && process.env.REACT_APP_FREE_TRIAL_DAYS > company.days){
        label = `You have ${company.days} days of free trial left`
    }
	const history = useHistory();
    return (<>
        {!company.hidetrial && company.trial && company.subscribed != true && <div className="bg-warning pos-relative p-4 mb-5 text-center d-flex align-items-center justify-content-center">
            <p className="m-0">{label}</p>
            <button className="bg-transparent border-0 text-white flaticon-cancel" onClick={()=>{
                dispatch({type: 'COMPANY/REMOVE/TRIAL'})
            }}></button>
        </div>}
        <Header history={history}/>
    </>)
}

export default Projects;
