export const ImporterActions = {
    getSchema,
    postImporterData
  }
  
  function getSchema(data){
    return { type : "IMPORTER/SCHEMA/GET", payload: data }
  }

  function postImporterData(data){
    return { type : "IMPORTER/IMPORT/POST", payload: data }
  }
