const validate = values => {
  const errors = {}
  
  if (!values.stock_id) {
    errors.stock_id = 'Required Field';
  }else if (values.stock_id && !values.stock_id.value) {
    errors.stock_id = 'Required Field';
  }
  if (!values.manufacturer) {
    errors.manufacturer = 'Required Field';
  }
  if (!values.fitting_accessories) {
    errors.fitting_accessories = 'Required Field';
  }
  if (!values.range_part) {
    errors.range_part = 'Required Field';
  }
  // if (!values.stock_attachment_id) {
  //   errors.stock_attachment_id = 'Required Field';
  // }
  return errors
}

export default validate;