import React, { Component, Fragment } from 'react';
import SVG from "react-inlinesvg";
import { Link, Redirect } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import SitesSidebar from './SiteForm';
import GeneralForm from './GenrealForm';
import CustomerSidebar from './CustomerForm'
import ContractorForm from './ContractorForm';
import WorkedCarriedOutForm from './WorkedCarriedOutForm';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ProjectActions } from '../../actions/project.action';
import { templateActions } from '../../actions/template.action';
import AddContractor from '../../../_metronic/layout/components/right-sidebar/contractors/AddContractor'

const validate = values => {

    const errors = {}
    
    if (values.project_ref) {
        if (!/^[\w\-\s]+$/i.test(values.project_ref)) {
            errors.project_ref = 'Reference is invalid (must be Alphanumeric)'
        }
    }

    if (!values.status) {
        errors.status = 'Status is required'
    }
  
    if (!values.completion_date) {
        errors.completion_date = 'Job completion date is required'
    }

    if (!values.customer) {
        errors.customer = 'Customer is required'
    }

    if (!values.site) {
        errors.site = 'Site is required'
    }

    if (!values.template_set_id) {
        errors.template_set_id = 'Select Manual'
    }

    // if (!values.contractors || _.isEmpty(values.contractors)) {
    //     errors.contractors = 'Contractors is required'
    // }

    if (!values.carried_out || _.isEmpty(values.carried_out)) {
        errors.carried_out = 'Select work type'
    }

    return errors
}

class ProjectFrom extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () =>{
      this.props.getTemplateSetListing()
      this.props.getTemplatesListing()
      this.props.getWorkType()
    }

    handleSubmit = (values) => {
        let data = {},
        carried_out = [],
        contractors = []

        if(values.carried_out){
            values.carried_out.map((item, index) => {
                carried_out.push(item.id)
            })     
        }
        
        if(values.contractors){
            values.contractors.map((item, index) => {
                contractors.push(item.id)
            })     
        }

        let template_set = {
            id: values.site.value,
            template_set_name: values.site.label
        }

        data.manual_id          = values.manual_id
        // data.manual_type        = values.manual_type
        data.project_ref        = values.project_ref
        data.completion_date    = moment(values.completion_date, 'MM/DD/YYYY').format('MM/DD/YYYY')
        data.customer_id        = values.customer.value
        data.site_id            = values.site.value
        data.template_set_id    = values.template_set_id.value
        data.status             = values.status.value
        data.workType_id        = carried_out
        data.contractors_id     = contractors

        this.props.createProject(data)
       
    }

    render() {
        const { handleSubmit, submitting  } = this.props
        // if(!this.props.selected){
        //     return <Redirect to={{ redirect: "/projects" }} />
        // }

        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <div className="card-stretch gutter-b">
                       
                        <div className="card card-custom mb-8">
                            <div className="card-body border-bottom-light border-bottom d-flex justify-content-between align-items-center flex-row p-4">
                                <h3 className="mb-0">
                                    <Link to="/projects">
                                        <i className="icon text-primary cursor-pointer fas fa-long-arrow-alt-left mr-1">
                                        </i>
                                    </Link>
                                    Project #
                                </h3>
                            </div>
                            <GeneralForm />
                        </div>

                        <ContractorForm />
                        <WorkedCarriedOutForm  {...this.props} isEdit={false}/>

                        <div className="card card-custom mb-6">
                            <div className="card-body border-bottom-light border-bottom res_card_header d-flex justify-content-between align-items-center flex-row p-4">
                                <div className="d-flex align-items-center">
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={submitting}
                                    type="submit"
                                    style={{color: 'white'}}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>

                    </div>
                </form>
                <CustomerSidebar />
                <SitesSidebar />
                <AddContractor isProject={true}/>
            </Fragment>
        )
    }
}

ProjectFrom = reduxForm({
    validate,
    form: 'ProjectForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(ProjectFrom);

const mapStateToProps = (state, ownProps) => {
    const { dropdownListing: { data, requesting } } = state.project;
    let selected = state.project.selectedDropdown;
    let project_details = state.project.project_details
    let manual_id;
    _.map(data, (i) =>{
        if(i.shortcode == selected){
            selected = i.title
            manual_id = i.id
        }
    });
    return {
        initialValues: {
            manual_type: selected,
            manual_id,
        },
        selected,
      }
}

const mapDispatchToProps = (dispatch) => ({
    createProject: (data) => dispatch(ProjectActions.createProject(data)),
    getWorkType: () => dispatch(templateActions.getWorkType()),
    getTemplateSetListing: () => dispatch(templateActions.getTemplateSet()),
    getTemplatesListing: () => dispatch(templateActions.getTemplates()),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFrom)
