// import { takeEvery, takeLatest } from 'redux-saga';
import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
// import _ from 'lodash';
import history from '../utils/history.helper'


// Login user
function* login({ payload }) {
    const form_name = 'LoginForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'POST',
                url: '/api/v1/user/login'
            })
        });
        if (response.success.data) {
            let { token, user } = response.success.data.data
            let userdata = { user: { ...user, token } }
            // new Cookies().set('user', userdata, { path: '/' })
            yield put({ type: "AUTH/LOGIN/SUCCESS", payload: userdata });
            if (user && user.profile_completed === 0) {
                history.push("/company_info")
            } else {
                history.push("/")
            }
            window.location.reload();
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

// send OTP to Email
function* sendOtp({ payload }) {

    const form_name = 'RegisterForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });

    try {
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'POST',
                url: '/api/v1/user/send-otp'
            })
        });
        if (response.success) {
            yield put({
                type: "AUTH/REGISTER/SEND_OTP/SUCCESS",
                payload: response.success.data.data
            });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

// Register User
function* register({ payload }) {
    const form_name = 'ValidateEmail'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                data: payload,
                method: 'POST',
                url: '/api/v1/user/signup'
            })
        });
        if (response.success) {
            yield put({
                type: "AUTH/REGISTER/USER/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('info', "User registered")
            let { token, user } = response.success.data.data
            let userdata = { user: { ...user, token } }
            yield put({ type: "AUTH/LOGIN/SUCCESS", payload: userdata });
            if (user && user.profile_completed === 0) {
                history.push("/company_info")
            } else {
                history.push("/")
            }
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

//Forgot password send mail
function* forgotPassword({ payload }) {
    const form_name = 'ForgotPasswordForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'POST',
                url: '/api/v1/user/forget-password'
            })
        });
        if (response.success) {
            alertInfo('info', response.success.data.message)
            history.push("/auth/login")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

//reset password
function* resetPassword({ payload }) {
    const form_name = 'ResetPasswordForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'POST',
                url: '/api/v1/user/reset-password'
            })
        });
        if (response.success) {
            alertInfo('info', response.success.data.message)
            history.push("/auth/login")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', error.response.data.message)
        }
    }
}

//logout user
// function* logOut({payload}) {
//   try {
//     yield put({ type: "AUTH/LOGOUT" });
//   } catch {
//     alertInfo('error', '')
//   }
// }

//change password
function* changesPassword({payload}){
    const form_name = 'ChangePassSidebar'
    try{
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'POST',
                url: '/api/v1/user/change-password'
            })
        });
        if (response.success) {
            payload.hideform();
            alertInfo('info', response.success.data.message)
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    }catch(error){
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', error.response.data.message)
        }
    }
}

export default function* authSaga() {
    yield all([
        takeLatest("AUTH/LOGIN", login),
        takeLatest("AUTH/REGISTER/SEND_OTP", sendOtp),
        takeLatest("AUTH/REGISTER/USER", register),
        takeLatest("AUTH/FORGOTPASSWORD/SEND", forgotPassword),
        takeLatest("AUTH/RESETPASSWORD", resetPassword),
        //   takeLatest("AUTH/LOGOUT", logOut),
        takeLatest("AUTH/CHANGEPASSWORD",changesPassword),
    ]);
}