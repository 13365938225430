import React from 'react';
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, reset, } from 'redux-form';
import { SiteActions } from '../../../../../app/actions/site.action';
import { CustomerActions } from '../../../../../app/actions/customer.action';
import { InputFieldRedux, SelectFieldRedux, AutoCompleteFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'
import { GlobalActions } from "../../../../../app/actions/global.action";

class UpdateSite extends React.Component {

    componentDidMount(){
        this.props.customerListing({ per_page: 0 });
    }

    hideSidebar = () => {
       this.props.closeRightSidebar('update_site')
        // document.body.classList.remove("openRightBar");
        // document.getElementById('update-site-sidebar').style.display = 'none';
        // this.props.toggleSidebar();
    }

    handleSubmit = (data) => {
        const customer_id = data.customer_id && data.customer_id.value
        const newData = { ...data, customer_id, ...{ id : (this.props.site_detail ? this.props.site_detail.id : 0) }}
        this.props.updateSite({newData, resetForm: this.hideSidebar});
    }

    render(){
      const { handleSubmit, right_sidebar, submitting, pristine, customer_listing, requesting } = this.props
      return (
        <>
          {right_sidebar && 
            <div id="update-site-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Update Site</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                        {requesting ? <div class="spinner"></div> :
                        <>
                        <Field
                              label={'Select Customer'}
                              name="customer_id"
                              component={AutoCompleteFieldRedux}
                              options={customer_listing}
                            />
                            <Field
                              label={'Site Name'}
                              placeholder="Enter site name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 1'}
                              placeholder="Enter address line 1"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_1"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 2'}
                              placeholder="Enter address line 2"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_2"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'City'}
                              placeholder="Enter city"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'County'}
                              placeholder="Enter county"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="county"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Post Code'}
                              placeholder="Enter post code"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="postcode"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Reference'}
                              placeholder="Enter reference"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="reference"
                              component={InputFieldRedux}
                            />
                            <div className="d-flex justify-content-end">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Update
                              </Button>
                            </div>
                        </>}
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}

UpdateSite = reduxForm({
  form: 'UpdateSite',
  validate,
  enableReinitialize: true
})(UpdateSite);

UpdateSite = connect(state => {
  const { detail } = state.site.sitelisting
  const customer = {
    label: detail.customer && (detail.customer.name || ""),
    value: detail.customer && (detail.customer.id || ""),
  }
  return {
    initialValues: {
      title: detail.label,
      address_line_1: detail.address_line_1,
      address_line_2: detail.address_line_2,
      city: detail.city,
      county: detail.county,
      postcode: detail.postcode,
      reference: detail.reference,
      customer_id: customer,
    }
  }
},
)(UpdateSite)

const mapStateToProps = (state, ownProps) => {
  const { sitelisting: { detail, requestingDetail} } = state.site;
  const { customerlisting: { data, } } = state.customer;
  const { isSidebarOpen } = state.site;
  return {
    customer_listing: data,
    site_detail: detail,
    isSidebarOpen,
    requesting: requestingDetail,
    right_sidebar: state.global.right_sidebars['update_site']
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSite: (data) => dispatch(SiteActions.updateOne(data)),
  customerListing: (data) => dispatch(CustomerActions.getAll(data)),
  toggleSidebar: () => dispatch(SiteActions.toggleSidebar()),
  resetForm:()=>{dispatch(reset('AddSite'))},
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSite)
