import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm, reset } from 'redux-form'; 
import { Form } from 'react-bootstrap'
import _ from 'lodash';

import { FileTemplateRedux } from "../common/FieldComponent";
import { templateActions } from "../../actions/template.action";

class AddManualModalToggle extends Component {

    constructor(props) {
        super(props);
        this.state={
            open:this.props.showManualModal,
        }
    }

    _handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancelSelection()
        this.props.cancelAddManualModalToggle();
    };

    _handleSubmit = (values) =>{
        let value = { work_type: this.props.manualaToBeAdded, template_set_id: this.props.set.id };
        this.props.addManualTemplateSet({data:value});
    }

    _registerFileToUpload = (e) => {
		const files = [...e.target.files];
		this.setState({
			uploadFiles: files
		});
	}

    render() {
        const { handleSubmit, submitting, pristine, manualaToBeAdded } = this.props
        return (
                <Dialog 
                    className="model-support template_modal_dialog"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.open}
                >
                    <DialogTitle id="alert-dialog-title">{<h2>You are about to add a new manual. Please read the <a href="https://www.dokkit.co.uk/help" target="_blank">Help</a> pages</h2>}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Manuals: {_.values(manualaToBeAdded).join(", ")} 
                    {/* Manual to add: {manualaToBeAdded}  */}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={this._handleClose}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        Cancel
                        </button>
                        <button
                            onClick={handleSubmit(this._handleSubmit)}
                            disabled={submitting}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            <span>{submitting === true ? "Submitting.." : "Submit"}</span>
                        </button>
                    </DialogActions>                   
                </Dialog>    
        );
    }
}

AddManualModalToggle = reduxForm({
    form: 'AddManualModalToggle',
    enableReinitialize: true
})(AddManualModalToggle);

const mapStateToProps = (state, ownProps) => {
    const { templates: { workType, templateSet, templates } } = state;
	return {
        showManualModal: templates.showManualModal,
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    cancelAddManualModalToggle: (data) => { dispatch({ type : "TEMPLATES/ADDMANAUAL/TOGGLE"}) },
    addManualTemplateSet: (data) => { dispatch(templateActions.addManualTemplateSet(data)) },
})

export default connect(mapStateToProps, mapDispatchToProps)(AddManualModalToggle);