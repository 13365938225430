const validate = values => {
  const errors = {}
  if (!values.customer_id) {
      errors.customer_id = 'Customer field is required'
  }
  if (!values.title) {
      errors.title = 'Name field is required'
  }else if (!/^[A-Za-z0-9\'\;\.\-\/\s]+$/.test(values.title)) {
    errors.title = 'Invalid name'
  } else if (values.title.length > 50) {
      errors.title = 'Maximum 50 characters allowed'
  }else if(!values.title.trim()){
    errors.title = 'Name field is required'
  }
  if (values.reference) {
    if(!/^[\w\-\s]+$/i.test(values.reference)){
      errors.reference = 'Reference is invalid (must be Alphanumeric)'
    }
  }

  return errors
}

export default validate;