import _ from 'lodash'

let initialValues = {
    contractorlisting: {
        requesting: false,
        data: [],
        pagination: null,
        detail: {},
        requestingDetail: false,
        filter: ""
    },
    isSidebarOpen: false,
    contractorsDropdown: []
}

export const contractorReducer = (state = initialValues, action) => {
    switch (action.type) {
        //Toggle sidebar
        case 'CONTRACTOR/SIDEBAR/TOGGLE':
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            }
            //Get all contractors
        case 'CONTRACTOR/GET/ALL':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    requesting: true,
                },
            }
        case 'CONTRACTOR/GET/ALL/SUCCESS':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    data: [...action.payload.data],
                    pagination: {...action.payload.pagination },
                    requesting: false,
                },
            }
        case 'CONTRACTOR/GET/ALL/ERROR':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    errors: action.payload.data,
                    requesting: false,
                },
            }
            //Delete one contractor
        case 'CONTRACTOR/DELETE/ONE':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    //   requesting: true,
                },
            }
        case 'CONTRACTOR/DELETE/ONE/SUCCESS':
            const id_del = action.payload.id;
            const newcontractorlisting = _.filter(state.contractorlisting.data, contractor => contractor.id !== id_del)
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    data: newcontractorlisting,
                    pagination: {
                        total: state.contractorlisting.pagination.total - 1
                    },
                    //   requesting: false,
                },
            }
        case 'CONTRACTOR/DELETE/ONE/ERROR':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
            }
            // Get one contractor
        case 'CONTRACTOR/GET/ONE':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    requestingDetail: true,
                },
            }
        case 'CONTRACTOR/GET/ONE/SUCCESS':
            const contractor = action.payload;
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    detail: {...contractor },
                    requestingDetail: false,
                },
            }
        case 'CONTRACTOR/GET/ONE/ERROR':
            return {
                ...state,
                contractorlisting: {
                    ...state.contractorlisting,
                    errors: action.payload.data,
                    requestingDetail: false,
              },
      }
      // Create one contractor
      case 'CONTRACTOR/CREATE':
        return {
            ...state,
            contractorlisting: {
                  ...state.contractorlisting,
                //   requesting: true,
            },
      }
      case 'CONTRACTOR/CREATE/SUCCESS':
        return {
            ...state,
            contractorlisting: {
                  ...state.contractorlisting,
                  data: [ action.payload, ...state.contractorlisting.data],
                  pagination: {
                    total: state.contractorlisting.pagination.total + 1
                  },
                //   requesting: false,
            },
      }
      case 'CONTRACTOR/CREATE/ERROR':
          return {
              ...state,
              contractorlisting: {
                  ...state.contractorlisting,
                  errors: action.payload.data,
                //   requesting: false,
              },
      }
      // Update one contractor
      case 'CONTRACTOR/UPDATE/ONE':
        return {
            ...state,
            contractorlisting: {
                  ...state.contractorlisting,
                //   requesting: true,
            },
      }

    // Update one contractor
    case 'CONTRACTOR/UPDATE/ONE/ACCESS':
        return {
            ...state,
            contractorlisting: {
                    ...state.contractorlisting,
                //   requesting: true,
            },
        }
      case 'CONTRACTOR/UPDATE/ONE/SUCCESS':
        const id_edit = action.payload.id;
        const indexOfUpdatedContractor = _.findIndex(state.contractorlisting.data, contractor => contractor.id === id_edit)
        if(indexOfUpdatedContractor !== -1){
          state.contractorlisting.data.splice(indexOfUpdatedContractor, 1, action.payload)
        }
        return {
          ...state,
          contractorlisting: {
              ...state.contractorlisting,
              data: state.contractorlisting.data,
            //   requesting: false,
          },
      }
      case 'CONTRACTOR/UPDATE/ONE/ERROR':
          return {
              ...state,
              contractorlisting: {
                  ...state.contractorlisting,
                  errors: action.payload.data,
                //   requesting: false,
              },
      }
      // Save filter value
      case 'CONTRACTOR/FILTER/SAVE':
        return {
            ...state,
            contractorlisting: {
                  ...state.contractorlisting,
                  filter: action.payload
            },
      }

    case 'CONTRACTOR/GET/ALL/CONTRACTORS/SUCCESS':
        return {
            ...state,
            contractorsDropdown: action.payload.data,
        }

    case 'PROJECT/CONTRACTOR/DROPDOWN/UPDATE/SUCCESS':
        let updateContractors = [...state.contractorsDropdown, action.payload]
        return {
            ...state,
            contractorsDropdown: updateContractors
        }

      default:
          return {
              ...state
          }
    }
}