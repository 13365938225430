export const StockActions = {
  create,
  getAll,
  getOne,
  updateOne,
  deleteOne,
  saveFilterValue,
  toggleSidebar,
  clearStockOne
}

function create(data){
  return { type : "STOCK/CREATE", payload: data }
}

function getAll(data){
  return { type : "STOCK/GET/ALL", payload: data }
}

function getOne(id){
  return { type : "STOCK/GET/ONE", payload: id }
}

function updateOne(id){
  return { type : "STOCK/UPDATE/ONE", payload: id }
}

function deleteOne(id){
  return { type : "STOCK/DELETE/ONE", payload: id }
}

function saveFilterValue(data){
  return { type : "STOCK/FILTER/SAVE", payload: data }
}

function toggleSidebar(){
  return { type : "STOCK/SIDEBAR/TOGGLE" }
}

function clearStockOne(){
  return { type : "STOCK/DETAIL/REMOVE" }
}