import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, formValueSelector, change, reset } from 'redux-form';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { Dialog, MenuItem } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { SelectStaticFieldRedux, } from '../common/FieldComponent'
import { ImporterActions } from '../../actions/importer.action'
import history from '../../utils/history.helper'

const REQUIRED_FIELD = {
    site:["Name", "Customer Name"],
    customer:["Name"],
    contractor:["Name", "Main Service"],
    stock:["Manufacturer", "Fitting Accessories", "Range Part"]
}
class ModalToMapData extends Component{

    constructor (props) {
        super(props)
        this.state= {
            module : "",
        }
    }

    componentDidMount() {
        const {firstRow, dispatch} = this.props
        _.map(firstRow,(option,i)=>{
            this.props.dispatch(change("ImporterForm","mapping_to["+i+"]","exclude"))
        })
    }

    _handleSubmit = (values) =>{
        let error = false;
        const errorList = [];
        let data = new FormData();
        if (_.isObject(values.file) && values.file) {
            data.append('file_to_import', values.file[0])
        }
        this.setState(
            {
                module:values.module
            }
        )

        data.append('module', values.module);
        data.append('headers', values.headers);
        data.append('mapping_to', values.mapping_to);
        _.forEach(REQUIRED_FIELD[values.module], (option, i)=> {
            // _.forEach(this.props.schema.data, (option, i)=> {
            if(values.mapping_to && values.mapping_to.indexOf(option) === -1 && option!=="exclude" ){
                errorList.push(option)
                error = true
            }
        })
        if(error){
            this.props.dispatch({type:'IMPORTER/IMPORT/VALIDATION/TRUE', payload:{errorList, error}})
            return false;
        }
        this.props.dispatch({type:'IMPORTER/IMPORT/VALIDATION/TRUE', payload:{errorList, error}})
        this.props.postImporter({data,values});
    }
    validateSelect = (value, allValues) => {
        if (!value) {
            return 'required';
        }
      }

    _handleClose = ()=>{

        const {firstRow, dispatch} = this.props
        _.map(firstRow,(option,i)=>{
            this.props.dispatch(change("ImporterForm","mapping_to["+i+"]","exclude"))
        })
        
        this.props.handleClose();
    }

    _resetform = () =>{
        // const {firstRow, dispatch} = this.props
        // _.map(firstRow,(option,i)=>{
        //     this.props.dispatch(change("ImporterForm","mapping_to["+i+"]",""))
        // })
        this.props.formReset();
        this.props.resetForm();
        this.props.handleClose();
    }

    render(){
        const {handleSubmit, submitting,
                pristine, firstRow,
                schema, mapping_to,
                importerResultData, importerRequest,
                modalOpen, importerValidation} = this.props;
        const schemaOptions = _.map(schema.data, (option, i)=> {
            
            let disbaled=false;
            if(mapping_to && mapping_to.indexOf(option) >-1 && option!='exclude' ){
                disbaled=true;
            }
            return <MenuItem key={i} value={option} disabled={disbaled}>{option}</MenuItem>
        })
        const options = _.map(firstRow, (option, i)=> {
            console.log("firstRow==========>",firstRow)
            // let label_to_show = firstRow[i].slice(0, 15)+firstRow[i].length>15 ? "..." : ""
            
            let limit_extended = firstRow[i].length>15 ? "..." : "";
            let label_to_show = firstRow[i].slice(0, 15);

            // let optionLabel = option;
            // let optionlimit = option.length>15 ? "..." : ""

            return <tr>
                <td>
                    <div></div>
                </td>
                <td>
                <Field
                    name={"mapping_to["+i+"]"}
                    label={label_to_show+limit_extended}
                    id="select-module"
                    variant="outlined"
                    component={SelectStaticFieldRedux}
                    validate={this.validateSelect}
                        >{schemaOptions}
                </Field>
                </td>
            </tr>
            
        })
        let total, skipped, errorReport ;
        if(importerResultData){
            total = <tr><td className="importer-success-mssg total-imported"><h3>Total Imported</h3></td><td>{importerResultData.imported}</td></tr>
            skipped = <tr><td className="importer-success-mssg total-skipped"><h3>Total Skipped</h3></td><td>{importerResultData.skipped}</td></tr>
            errorReport = _.map(importerResultData.invalidData,(option, i)=>{
                return <tr>
                            {/* <td>Row no. {option.position}
                            </td> */}
                            <td>{option.reason}</td>
                        </tr>
                })
        }
        
        return (
            <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={modalOpen}
                >
                    
                    <DialogTitle id="alert-dialog-title">{<h2>{importerResultData ? "File Imported Successfully" : "Map your CSV data"}</h2>}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{importerValidation.error && "Required options are  missing: "+importerValidation.errorList.join(", ")}</div>
                    </div>
                     <table className="full-width-layout_table">
                         {total}
                         {skipped}
                     </table>
                     {importerRequest ? <div class="m-spinnercustom">
                        <span></span>
                        <span></span>
                        <span></span>
                     </div> :
                        <div className="full-width-layout_table">
                            {errorReport  ? <h3>Logs:</h3> : ""}
                            {importerResultData  ? <ul>{errorReport}</ul> : <table className="full-width-layout_table">{options}</table>}
                        </div>
                     }
                     
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {importerResultData
                        ?<button
                            onClick={this._resetform}
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        Import again
                        </button>
                        :<button
                            onClick={this._handleClose}
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        cancel
                        </button>}
                        

                        {importerResultData 
                        ?   <button
                                onClick={()=>{ this._resetform(); history.push((this.state.module == 'stock') ? this.state.module : this.state.module+'s')}}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                            >
                                <span>View {this.state.module+'s'}</span>
                            </button>
                        :   <button
                                onClick={handleSubmit(this._handleSubmit)}
                                disabled={importerRequest || pristine}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                            >
                                <span>{importerRequest? "Submitting.." : "Submit"}</span>
                            </button>}
                        
                    </DialogActions>                   
                </Dialog> 
        )
    }
}

ModalToMapData = reduxForm({
    form: 'ImporterForm',
    enableReinitialize: true
  })(ModalToMapData);
  const selector = formValueSelector('ImporterForm')
  const mapStateToProps = (state, ownProps) => {
    const selected_mapping_to = selector(state, 'mapping_to')
    const selected_module  = selector(state, 'module');

    const {importerResult, modalOpen, importerValidation, firstRow} = state.importer;
    return {
        mapping_to: selected_mapping_to,
        importerResultData: importerResult.data,
        importerRequest: importerResult.requesting,
        modalOpen,
        selected_module,
        importerValidation

    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => ({
    handleClose: ()=>{dispatch({ type : 'IMPORTER/TOGGLE'})},
    postImporter: (data) =>{ dispatch(ImporterActions.postImporterData(data)) },
    resetForm:()=>{dispatch(reset('ImporterForm'))}
  })

  export default connect(mapStateToProps, mapDispatchToProps)(ModalToMapData)