import React from "react";
import QuickUserToggler  from "../extras/QuiclUserToggler";

export function Topbar() {
  return (
    <div className="topbar">
      <QuickUserToggler />
    </div>
  );
}
