import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';

// Get all sites
function* getAll({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/contractor/service',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "MAINSERVICE/GET/ALL/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Site
function* create({ payload }) {
    let form_name = payload.data.form;
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/contractor/service',
                method: 'POST',
                data: payload.data
            })
        });
        if (response.success) {
            yield put({
                type: "MAINSERVICE/CREATE/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Main Service Created Successfully');
            payload.toggleAddService && payload.toggleAddService();
            payload.reset();
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

// Delete one site
function* deleteOne({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/contractor/service/${payload}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "MAINSERVICE/DELETE/ONE/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Main Service deleted successfully')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


// Get one Site
function* getOne({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/contractor/service/${payload}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "MAINSERVICE/GET/ONE/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Update one Main Service
function* updateOne({ payload }) {
    const main_service_id = payload.id;
    delete payload.id;
    const form_name = 'UpdateMainService'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/contractor/service/${main_service_id}`,
                method: 'PUT',
                data: payload
            })
        });
        if (response.success) {
            yield put({
                type: "MAINSERVICE/UPDATE/ONE/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Main Service updated successfully')
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error
        yield put({
            type: "GLOBAL/FORM_ERROR",
            payload: {
                error: data.data,
                form_name: form_name
            }
        });
    }
}

export default function* siteSaga() {
    yield all([
        takeLatest("MAINSERVICE/GET/ALL", getAll),
        takeLatest("MAINSERVICE/CREATE", create),
        takeLatest("MAINSERVICE/DELETE/ONE", deleteOne),
        takeLatest("MAINSERVICE/GET/ONE", getOne),
        takeLatest("MAINSERVICE/UPDATE/ONE", updateOne),
    ]);
}