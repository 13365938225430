import _ from 'lodash'

let initialValues = {
    dropdownListing: {
        requesting: false,
        data: [],
    },
    customerDropDown: {
        data: []
    },
    siteDropDown: {
        data: []
    },
    projectlisting: {
        requesting: true,
        data: [],
        pagination: null
    },
    contractlisting: {
        requesting: false,
        data: [],
        pagination: null
    },
    customerlisting: {
        requesting: false,
        data: [],
        pagination: null
    },
    sitelisting: {
        requesting: false,
        data: [],
        pagination: null
    },
    selectedDropdown: null,
    project_details: {},
    group_attachments: [],
    project_scope: {},
    installationType: false,
    showProjectEmail: false,
    ProjectEmail: null,
    projectIdToMail: null,
    stock_group_listing: {
        listing: [],
        pagination: {}
    },
    stocks_listing: {
        requesting: false,
        listing: [],
        pagination: {}
    },
    project_stocks_listing: {
        requesting: false,
        listing: [],
        pagination: {}
    },
    create_manual: {
        requesting: false,
        error_message: '',
        success_message: '',
    },
    manual_task_status: []
}


export const projectReducer = (state = initialValues, action) => {
    switch (action.type) {

        case'PROJECT/UPDATE/INSTALLATION/TYPE/SUCCESS':
        return {
            ...state,
            thirdParty: action.payload.thirdParty == ""?"third party":action.payload.thirdParty
        };
        case 'PROJECT/DROPDOWN/LIST/GET/SUCCESS':
            return {
                ...state,
                dropdownListing: {
                    requesting: false,
                    data: action.payload,
                }
            }
        case 'PROJECT/CUSTOMER/DROPDOWN/LIST/GET/SUCCESS':
            return {
                ...state,
                customerDropDown: {
                    data: action.payload && action.payload.data || []
                },
            }
        case 'PROJECT/SITE/DROPDOWN/LIST/GET/SUCCESS':
            return {
                ...state,
                siteDropDown: {
                    data: action.payload && action.payload.data || []
                },
            }
        case 'PROJECT/GET/ALL/REQUEST':
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    requesting: true,
                },
            }
        case 'PROJECT/GET/ALL/SUCCESS':
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    data: [...action.payload.data],
                    pagination: { ...action.payload.pagination },
                    requesting: false,
                },
            }
        case 'PROJECT/SEND/MAIL/REQUEST/STOP':
            let req_project_id_ = action.payload.id,
                _allprojects = state.projectlisting.data && [...state.projectlisting.data] || []
            let _reqIndex = _.findIndex(_allprojects, project => project.id === req_project_id_)
            if (_reqIndex !== undefined && _reqIndex !== -1) _allprojects[_reqIndex].requesting = false
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    data: _allprojects
                }
            };
        case 'PROJECT/CONTRACTOR/LIST/SUCCESS':
            return {
                ...state,
                contractlisting: {
                    ...state.contractlisting,
                    data: [...action.payload.data],
                    pagination: { ...action.payload.pagination },
                    requesting: false,
                },
            }
        case 'PROJECT/DROPDOWN/SELECTED':
            return {
                ...state,
                selectedDropdown: action.payload
            }
        // Save filter value
        case 'PROJECT/FILTER/SAVE':
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    filter: action.payload
                },
            }

        case 'PROJECT/CUSTOMER/LIST/REQUEST':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    requesting: true,
                },
            }

        case 'PROJECT/CUSTOMER/LIST/SUCCESS':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    data: [...action.payload.data],
                    pagination: { ...action.payload.pagination },
                    requesting: false,
                },
            }

        case 'PROJECT/CUSTOMER/LIST/ERROR':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    requesting: false,
                },
            }

        case 'PROJECT/SITE/LIST/REQUEST':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    requesting: true,
                },
            }

        case 'PROJECT/SITE/LIST/SUCCESS':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    data: [...action.payload.data],
                    pagination: { ...action.payload.pagination },
                    requesting: false,
                },
            }

        case 'PROJECT/SITE/LIST/ERROR':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    requesting: false,
                },
            }

        case 'PROJECT/CUSTOMER/CREATE/SUCCESS':
            return {
                ...state,
                customerlisting: {
                    ...state.customerlisting,
                    data: [action.payload, ...state.customerlisting.data],
                    requesting: false,
                },
            }
        case 'PROJECT/SITE/CREATE/SUCCESS':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    data: [action.payload, ...state.sitelisting.data],
                    requesting: false,
                },
            }

        case 'PROJECT/GET/SINGLE_PROJECT_DETAILS/SUCCESS':
            return {
                ...state,
                project_details: action.payload
            }

        case 'PROJECT/RESET/SINGLE_PROJECT_DETAILS/SUCCESS':
            return {
                ...state,
                project_details: {}
            }

        case 'PROJECT/GET/ALL/GROUP/ATTACHMENTS/SUCCESS':
            return {
                ...state,
                group_attachments: action.payload
            }

        case 'PROJECT/GET/SCOPE_OF_WORK/SUCCESS':
            return {
                ...state,
                project_scope: action.payload
            }

        case 'PROJECT/GET/ALL/STOCKS/SUCCESS':
            return {
                ...state,
                stock_group_listing: {
                    listing: [...action.payload.data],
                    pagination: { ...action.payload.pagination }
                }
            }
        case 'PROJECT/GET/ALL/STOCKS_DETAILS/REQUEST': {
            return {
                ...state,
                stocks_listing: {
                    requesting: true,
                }
            }
        }
        case 'PROJECT/GET/ALL/STOCKS_DETAILS/SUCCESS':
            let allStocks = [...action.payload.data],
                allProjectStocks = state.project_stocks_listing && [...state.project_stocks_listing.listing] || []
            allProjectStocks.map((project_stock, index) => {
                let stockIndex = _.findIndex(allStocks, item => item.id === project_stock.stock_id.id)
                if (stockIndex !== undefined && stockIndex !== -1) {
                    allStocks[stockIndex].isAdded = true
                }
            })
            return {
                ...state,
                stocks_listing: {
                    requesting: false,
                    listing: [...allStocks],
                    pagination: { ...action.payload.pagination }
                }
            }

        case 'PROJECT/ADD/NEW/STOCKS_DETAILS/SUCCESS':
            return {
                ...state,
                stocks_listing: {
                    listing: [action.payload, ...state.stocks_listing.listing],
                    pagination: { ...state.stocks_listing.pagination }
                }
            }

        case 'PROJECT/DELETE/STOCKS_DETAILS/SUCCESS':
            const id_del = action.payload.id;
            const newStockDetailListing = _.filter(state.stocks_listing.listing, stockdetail => stockdetail.id !== id_del)
            return {
                ...state,
                stocks_listing: {
                    listing: newStockDetailListing,
                    pagination: {
                        total: state.stocks_listing.pagination.total - 1
                    }
                }
            }

        case 'PROJECT/STOCK/SELECT_SINGLE_STOCK/SUCCESS':
            const stocks = [...state.stocks_listing.listing]
            let findIndex = _.findIndex(stocks, { 'id': action.payload.id })
            if (findIndex !== undefined) stocks[findIndex].selected = !action.payload.selected

            return {
                ...state,
                stocks_listing: {
                    listing: stocks,
                    pagination: state.stocks_listing.pagination
                }
            };
            break;

        case 'PROJECT/STOCK/ADD_TO_PROJECT/SUCCESS':
            let stocksListing = [...state.stocks_listing.listing],
                all_stocks = state.stocks_listing.listing

            action.payload.map((stock, index) => {
                let stock_index = _.findIndex(stocksListing, { 'id': stock.id })
                stocksListing[stock_index].isAdded = true
                stocksListing[stock_index].selected = false
            });
            let newProjectStock = []
            action.payload.map((item, index) => {
                newProjectStock.push({
                    id: item.id,
                    stock_id: {
                        company_id: item.company_id,
                        fitting_accessories: item.fitting_accessories,
                        group_id: item.group_id,
                        id: item.id,
                        manufacturer: item.manufacturer,
                        range_part: item.range_part,
                        stock_attachment: item.stock_attachment,
                    }
                })
            })
            return {
                ...state,
                project_stocks_listing: {
                    listing: [...newProjectStock, ...state.project_stocks_listing.listing],
                    pagination: state.project_stocks_listing.pagination
                },
                stocks_listing: {
                    listing: stocksListing,
                    pagination: state.stocks_listing.pagination
                }

            };
            break;

        case 'PROJECT/GET/ALL/PROJECT/STOCK/REQUEST':
            return {
                ...state,
                project_stocks_listing: {
                    ...state.project_stocks_listing,
                    requesting: true
                }
            }

        // case 'PROJECT/STOCK/ATTACHMENT/ADD_TO_PROJECT/SUCCESS':
        //     return {
        //         ...state,
        //         project_stocks_listing: {
        //             ...state.project_stocks_listing,
        //             listing: action.payload
        //         }
        //     }

        case 'PROJECT/GET/ALL/PROJECT/STOCK/SUCCESS':
            return {
                ...state,
                project_stocks_listing: {
                    listing: action.payload.data,
                    pagination: action.payload.pagination,
                    requesting: false
                }
            };
            break;

        case 'PROJECT/DELETE/PROJECT/STOCK/SUCCESS':
            // console.log("here i am", action.payload)
            const updatedProjectStockListing = _.filter(state.project_stocks_listing.listing, item => item.id !== action.payload.id)
            let allstocksListing = [...state.stocks_listing.listing]
            let stocks_index = _.findIndex(allstocksListing, { 'id': action.payload.stock_id.id })
            allstocksListing[stocks_index].isAdded = false

            return {
                ...state,
                project_stocks_listing: {
                    ...state.project_stocks_listing,
                    listing: updatedProjectStockListing
                },
                stocks_listing: {
                    listing: allstocksListing,
                    pagination: state.stocks_listing.pagination
                }
            };
            break;

        case 'PROJECT/CREATE/MANUAL/REQUEST/START':
            return {
                ...state,
                create_manual: {
                    requesting: true,
                    error_message: '',
                    success_message: ''
                }
            };
            break;
        case 'PROJECT/MANUAL/STATUS/SUCCESS':
            return {
                ...state,
                manual_task_status: [...state.manual_task_status, action.payload.data.status]
            };
            break;

        case 'PROJECT/CREATE/MANUAL/STATUS/CLOSE':
            return {
                ...state,
                manual_task_status: []
            };
            break;

        case 'PROJECT/CREATE/MANUAL/SUCCESS':
            let payload = action.payload,
                projectsList = state.projectlisting.data && [...state.projectlisting.data] || []
            let proIndex = _.findIndex(projectsList, project => project.id === state.project_details.id)
            if (proIndex !== undefined && proIndex !== -1) projectsList[proIndex].pdf_key = action.payload.pdf_key
            return {
                ...state,
                project_details: {
                    ...state.project_details,
                    pdf_key: payload.pdf_key
                },
                projectlisting: {
                    ...state.projectlisting,
                    data: projectsList
                },
                create_manual: {
                    requesting: false,
                    success_message: payload.message,
                    error_message: ''
                },
            };
            break;

        case 'PROJECT/CREATE/MANUAL/REQUEST/STOP':
            return {
                ...state,

            };
            break;

        case 'PROJECT/REMOVE/SUCCESS':
            return {
                ...state,
                project_details: {}
            };
            break;


        case 'PROJECT/SEND/MAIL/REQUEST/START':
            let req_project_id = action.payload.id,
                allprojects = state.projectlisting.data && [...state.projectlisting.data] || []
            let reqIndex = _.findIndex(allprojects, project => project.id === req_project_id)
            if (reqIndex !== undefined && reqIndex !== -1) allprojects[reqIndex].requesting = true
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    data: allprojects
                }
            };
            break;

        case 'PROJECT/SEND/MAIL/REQUEST/SUCCESS':
            let succ_project_id = action.payload.id,
                allProjects = state.projectlisting.data && [...state.projectlisting.data] || []
            let succIndex = _.findIndex(allProjects, project => project.id === succ_project_id)
            if (succIndex !== undefined && succIndex !== -1) allProjects[succIndex].requesting = false
            return {
                ...state,
                projectlisting: {
                    ...state.projectlisting,
                    data: allProjects
                },
                showProjectEmail: !state.showProjectEmail,
                ProjectEmail: null,
                projectIdToMail: null,
            };
            break;

        case 'PROJECT/EMAIL/OPTION/TOGGLE':
            return {
                ...state,
                showProjectEmail: !state.showProjectEmail,
                ProjectEmail: action.payload.email,
                projectIdToMail: action.payload.id,
            }

        case 'PROJECT/GET/INSTALLATION_TYPE/SUCCESS':
            return {
                ...state,
                installationType: action.payload.installationTypeSelf,
                thirdParty: action.payload.thirdParty

            }

        case 'PROJECT/ADD/NEW/ATTACHMENT/GROUP/SUCCESS':
            return {
                ...state,
                group_attachments: action.payload.data
            }



        default:
            return {
                ...state,
            }
    }

}