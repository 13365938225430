import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

// Get all customer
function* getAll({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/customer',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "CUSTOMER/GET/ALL/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Customer
function* create({ payload }){
  const form_name = payload.data.form_name
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try{
      const response =  yield race({
          success: call(getResponse, {
              url:'/api/v1/customer',
              method: 'POST',
              data: payload.data,              
          })
      });
      if(response.success){
          yield put({ 
            type: "CUSTOMER/CREATE/SUCCESS", 
            payload: response.success.data.data
          });
          alertInfo('success', 'Customer created successfully')
          document.body.classList.remove("openRightBar");
          payload.resetForm();
          yield put({ type: "CUSTOMER/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch (error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      if(error.response){
          const { response: { data } } = error
          yield put({ 
              type: "GLOBAL/FORM_ERROR", 
              payload: {
                  error: data.data, 
                  form_name: form_name
              } 
          });
      }
  }
}

// Delete one customer
function* deleteOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/customer/${payload}`,
              method: 'DELETE',
          })
      });
      if(response.success){
          yield put({
              type: "CUSTOMER/DELETE/ONE/SUCCESS",
              payload: response.success.data.data
           });
           alertInfo('success', 'Customer deleted successfully')
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Get one Customer
function* getOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/customer/${payload}`,
              method: 'GET',
          })
      });
      if(response.success){
          yield put({
              type: "CUSTOMER/GET/ONE/SUCCESS",
              payload: response.success.data.data
           });
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Update one customer access
function* updateOneAccess({ payload }){
    const customer_id = payload.id;
    delete payload.id;
    const form_name = 'UpdateCustomer'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:`/api/v1/customer/access/${customer_id}`,
                method: 'PUT',
                data: payload
            })
        });
        if(response.success){
            yield put({
                type: "CUSTOMER/UPDATE/ONE/SUCCESS",
                payload: response.success.data.data
             });
              alertInfo('success', 'Customer updated successfully')
              yield put({ type: "CUSTOMER/SIDEBAR/TOGGLE", });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch(error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error
        yield put({ 
            type: "GLOBAL/FORM_ERROR", 
            payload: {
                error: data.data, 
                form_name: form_name
            } 
        });
    }
}

// Update one customer
function* updateOne({ payload }){
  const customer_id = payload.data.id;
  delete payload.id;
  const form_name = 'UpdateCustomer'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/customer/${customer_id}`,
              method: 'PUT',
              data: payload.data
          })
      });
      if(response.success){
          yield put({
              type: "CUSTOMER/UPDATE/ONE/SUCCESS",
              payload: response.success.data.data
           });
            alertInfo('success', 'Customer updated successfully')
            document.body.classList.remove("openRightBar");
            // this.props.dispatch(reset(form_name));
            payload.resetForm();
            yield put({ type: "CUSTOMER/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch(error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      const { response: { data } } = error
      yield put({ 
          type: "GLOBAL/FORM_ERROR", 
          payload: {
              error: data.data, 
              form_name: form_name
          } 
      });
  }
}

export default function* customerSaga() {
    yield all([
      takeLatest("CUSTOMER/GET/ALL", getAll),
      takeLatest("CUSTOMER/CREATE", create),
      takeLatest("CUSTOMER/DELETE/ONE", deleteOne),
      takeLatest("CUSTOMER/GET/ONE", getOne),
      takeLatest("CUSTOMER/UPDATE/ONE", updateOne),
      takeLatest("CUSTOMER/UPDATE/ONE/ACCESS", updateOneAccess),
  ]);
}