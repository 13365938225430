import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

// Get all sites
function* getAll({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/site',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "SITE/GET/ALL/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Site
function* create({ payload }){
  const form_name = 'AddSite'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try{
      const response =  yield race({
          success: call(getResponse, {
              url:'/api/v1/site',
              method: 'POST',
              data: payload.newData,              
          })
      });
      if(response.success){
          yield put({ 
            type: "SITE/CREATE/SUCCESS", 
            payload: response.success.data.data
          });
          alertInfo('success', 'Site created successfully')
          document.body.classList.remove("openRightBar");
          payload.resetForm();
          yield put({ type: "SITE/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch (error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      if(error.response){
          const { response: { data } } = error
          yield put({ 
              type: "GLOBAL/FORM_ERROR", 
              payload: {
                  error: data.data, 
                  form_name: form_name
              } 
          });
          //alertInfo('error', error.response.data);
      }
      
  }
}

// Delete one site
function* deleteOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/site/${payload}`,
              method: 'DELETE',
          })
      });
      if(response.success){
          yield put({
              type: "SITE/DELETE/ONE/SUCCESS",
              payload: response.success.data.data
           });
           alertInfo('success', 'Site deleted successfully')
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}


// Get one Site
function* getOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/site/${payload}`,
              method: 'GET',
          })
      });
      if(response.success){
          yield put({
              type: "SITE/GET/ONE/SUCCESS",
              payload: response.success.data.data
           });
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Update one Site
function* updateOne({ payload }){
  const site_id = payload.newData.id;
  delete payload.newData.id;
  const form_name = 'UpdateSite'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/site/${site_id}`,
              method: 'PUT',
              data: payload.newData
          })
      });
      if(response.success){
          yield put({
              type: "SITE/UPDATE/ONE/SUCCESS",
              payload: response.success.data.data
           });
          alertInfo('success', 'Site updated successfully')
          document.body.classList.remove("openRightBar");
        //   this.props.dispatch(reset(form_name));
          payload.resetForm();
          yield put({ type: "SITE/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch(error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      const { response: { data } } = error
      yield put({ 
          type: "GLOBAL/FORM_ERROR", 
          payload: {
              error: data.data, 
              form_name: form_name
          } 
      });
  }
}

export default function* siteSaga() {
    yield all([
      takeLatest("SITE/GET/ALL", getAll),
      takeLatest("SITE/CREATE", create),
      takeLatest("SITE/DELETE/ONE", deleteOne),
      takeLatest("SITE/GET/ONE", getOne),
      takeLatest("SITE/UPDATE/ONE", updateOne),
  ]);
}