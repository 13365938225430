import React, { Component, Fragment, PureComponent } from 'react';
import Listing from '../../../_metronic/container/projects/contractors/Listing';
import { Form, DropdownButton, Dropdown, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm, reset, change, formValueSelector } from 'redux-form';
import { FormControl, MenuItem, InputLabel } from '@material-ui/core';
import { ContractorActions } from "../../../app/actions/contractor.action";
import { connect } from 'react-redux';
import _ from 'lodash'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import SVG from "react-inlinesvg";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl, } from "../../../_metronic/_helpers";
import ConfirmationPopup from "../common/ConfirmationPopup";
import { templateActions } from '../../../app/actions/template.action';
import Select from 'react-select';
import { object } from 'underscore';
const headRows = [
    { id: 'works', numeric: false, disablePadding: true, label: 'Works' },
    { id: 'Scope of works?', numeric: false, disablePadding: false, label: 'Scope' },
    { id: 'Attachments', numeric: false, disablePadding: false, label: 'Attachment' },
    { id: 'Stock', false: false, disablePadding: false, label: 'Stock' },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
];

const ErrorInput = ({ meta: { submitFailed, error } }) => {
    return <p className="full-width text-right">
        {submitFailed && error && <span style={{ color: 'red' }}>{error}</span>}
    </p>
}


function EnhancedTableHead(props) {

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                    >
                        <TableSortLabel>{row.label}</TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

class ContractorForm extends PureComponent {

    state = {
        isOpen: false,
        showPopUp: false,
        openRemoveWork: false,
        selectedOption:null
    }

    componentDidMount() {
        this.props.getAllContractor({ per_page: 0, sort: 'name', order: 1 });
        this.props.getSelectWorkType();
    }
    
    handleSelect = (eventKey, event) => {
        this.setState({ selectedOption:eventKey.id });
        
        selectedWorkType = _.filter(this.props.selectedWorkType, {checked: true})
        
        let finalWorkType = selectedWorkType.length > 0 ? selectedWorkType : this.props.workType;

        var finalWorkTypeList = finalWorkType.map(workTypeObj => ({ ...workTypeObj, ...this.props.workType.find(selectedWorkTypeObj => selectedWorkTypeObj.id === workTypeObj.id) }))
        finalWorkTypeList = _.orderBy(finalWorkTypeList , ['shortcode'],['asc'])
        
        
        let worked_carried = !_.isEmpty(this.props.carried_out) ? [...this.props.carried_out] : [],
            { template_set_id, templateSet, workType, selectedWorkType } = this.props,
            manualOptions = [],
            findIndex = _.findIndex(worked_carried, (item) => item.id == eventKey.id)
            
            
        if (eventKey && findIndex === -1) {
            if (template_set_id && template_set_id !== undefined) {
                let templateSetObj = _.find(templateSet, ['id', template_set_id.value]);
                let selected_manuals = _.sortBy(_.keys(templateSetObj.selected_manuals));
                finalWorkTypeList.map((manual, index) => {
                    let manualIndex = _.findIndex(finalWorkTypeList, function (item) { return item.shortcode == manual.shortcode });
                    if (manualIndex !== -1) manualOptions.push(finalWorkTypeList[manualIndex])
                })
                
            }
            let manualOptionsSetObj = _.find(manualOptions, ['id', eventKey.id]);
          
            // worked_carried.push(manualOptions[eventKey])
            worked_carried.push(manualOptionsSetObj)
            this.onChangeFieldValue(worked_carried)
        }
        // return 1
    }

    handleClick = (eventKey, event) => {
        let { template_set_id } = this.props
        if (!template_set_id) {
            this.setState({
                showPopUp: true
            });
        }
    }

    onRemove = (id) => {
        let worked_carried = _.remove(this.props.carried_out, (item) => item.id != id)
        this.onChangeFieldValue(worked_carried)
    }

    onChangeFieldValue = (data) => {
        this.props.onChangeFieldValue({
            fieldName: 'carried_out',
            fieldValue: data
        })
    }

    onEdit = (id) => {
        let { template_set_id, trigger, user } = this.props
        if (this.props.isEdit === true) {
            this.props.history.push(`/project_detail/${id}/${template_set_id.value}`)
            if (trigger) {
                trigger();
            }
        } else {
            this.setState({
                openPop: true
            })
        }
    }

    render() {
        let { workType, templateSet, carried_out, template_set_id, selectedWorkType, user, selected_contractor_work } = this.props,
            templateSetObj = {},
            manualOptions = [],
            carried_out_options = [];
            // console.log("Carried_out", carried_out, selected_contractor_work);
            if(user.type==3){
                carried_out = carried_out.filter(a => selected_contractor_work.some(b => a.id === b.id));  
            } 

        let WorksCarriedOut = <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-top">Choose one or more types of work carried out to start adding attachments and more info</Tooltip>}
        >
            <p>Works carried out <i className="fa fa-info-circle tooltip-icon" aria-hidden="true"></i></p>
        </OverlayTrigger>;

        if (template_set_id && template_set_id !== undefined) {
            templateSetObj = _.find(templateSet, ['id', template_set_id.value]);

            if (templateSetObj && templateSetObj.selected_manuals !== undefined) {
                var selected_manuals = _.sortBy(_.keys(templateSetObj.selected_manuals));
                selected_manuals.map((manual, index) => {
                    let findIndex = _.findIndex(workType, function (item) { return item.shortcode == manual });
                    if (findIndex !== -1) manualOptions.push(workType[findIndex])
                })
            }

        }
        let iconClass;

        selectedWorkType = _.isEmpty(_.find(selectedWorkType, {checked: true})) ?  manualOptions : _.filter(selectedWorkType, {checked: true})
        
        let carried_out_ids = carried_out.map(a => a.id);
        
        selectedWorkType = selectedWorkType.map(object => {
            if (carried_out_ids.includes(object.id)) {
                return {...object, isdisabled: true};
            }else if(template_set_id == undefined){
                return {...object, isdisabled: true};
            }else{
                return {...object}
            }
        });
        
        const customStyles = {
            control: (styles) => ({ ...styles, width: "200px" }),
          }

       
        return (
            <div className="card card-custom mb-6">
                <div className="card-body border-bottom-light border-bottom res_card_header d-flex justify-content-between align-items-center flex-row p-4">
                    <h3 className="mb-0 light-color-inner">{WorksCarriedOut}
                        {/* <span>(Scope of works? Attachments?Stocks)</span> */}
                    </h3>
                    <div className={`d-flex align-items-center flex-column ${user.type < 3 ?'cursor-pointer':'cursor-not-allowed'}`}>
                        <div className="d-flex align-items-center">
                            <label className="text-dark-50 mr-3 mb-0">Work type:</label>
                            {
                            //   ( !_.isEmpty(selectedWorkType) && !_.isEmpty(manualOptions) ) &&  <DropdownButton
                            //     as={ButtonGroup}
                            //     key={2}
                            //     id={`dropdown-variants`}
                            //     variant={'variant'}
                            //     title={_.isEmpty(carried_out) ? 'Select Work Type' : `${carried_out.length} Works Selected`}
                            //     onSelect={this.handleSelect}
                            //     onClick={this.handleClick}
                            // >
                            //         {
                            //             !_.isEmpty(selectedWorkType) ? selectedWorkType.map((item, i) => {
                            //                     return <Dropdown.Item
                            //                         key={item.id}
                            //                         disabled={_.findIndex(carried_out, { id: item.id }) !== -1 ? true : false}
                            //                         eventKey={i}>
                            //                         {item.label}
                            //                     </Dropdown.Item>


                            //             }) : manualOptions.map((item, i) => {
                            //                 return <Dropdown.Item
                            //                     key={item.id}
                            //                     disabled={_.findIndex(carried_out, { id: item.id }) !== -1 ? true : false}
                            //                     eventKey={i}>
                            //                     {item.label}
                            //                 </Dropdown.Item>
                            //             })
                            //         }

                            //     </DropdownButton>
                        }
                       
                            <Select
                                styles={customStyles} 
                                value={this.state.selectedOption}
                                onChange={this.handleSelect}
                                isSearchable={false}
                                isDisabled={user.type < 3 ?false:true}
                                // options={selectedWorkType}
                                options={!_.isEmpty(selectedWorkType) ? selectedWorkType:manualOptions}
                                placeholder={carried_out ? `${carried_out.length} work type selected`:'Select...' } 
                                isOptionDisabled={(option) => option.isdisabled}
                                // isOptionDisabled={(manualOptions) => _.find(carried_out, function(carried) {
                                //     if (carried.id == manualOptions.id) {
                                //         return true;
                                //     }
                                // })}
                            />
                        </div>
                        <Field
                            name="carried_out"
                            component={ErrorInput}
                        />
                    </div>

                </div>

                <div className="card-body project-contractors-listing">
                    <div style={{ 'width': '100%' }}>
                        <Paper style={{ 'width': '100%', 'marginBottom': '16px', 'boxShadow': 'none !important' }}
                            ref={element => {
                                if (element) element.style.setProperty('box-shadow', 'none', 'important');
                            }}>
                            <div style={{ 'overflowX': 'auto' }}>
                                <Table
                                    style={{ 'minWidth': '750' }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <EnhancedTableHead listing={carried_out} />
                                    <TableBody>
                                        {carried_out && carried_out.length === 0 && (
                                            <TableRow tabIndex={-1}>
                                                <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                                            </TableRow>
                                        )}
                                        {carried_out && carried_out.map((row, index) => {
                                            
                                            switch (row.label) {
                                                case 'Audio':
                                                    iconClass = 'fa fa-volume-up';
                                                    break;
                                                case 'Electrical':
                                                    iconClass = 'fa fa-plug';
                                                    break;
                                                case 'Fire Detection':
                                                    iconClass = 'fa fa-fire';
                                                    break;
                                                case 'Mechanical':
                                                    iconClass = 'fa fa-wrench';
                                                    break;
                                                case 'Security':
                                                    iconClass = 'fa fa-shield-alt';
                                                    break;
                                                case 'Voice':
                                                    iconClass = 'fa fa-phone-volume';
                                                    break;
                                                case 'Other':
                                                    iconClass = 'fa fa-briefcase';
                                                    break;
                                                case 'Flooring':
                                                    iconClass = 'fa fa-th';
                                                    break;
                                                case 'Roofing':
                                                    iconClass = 'fa fa-hotel';
                                                    break;
                                                case 'HVAC':
                                                    iconClass = 'fa fa-fan';
                                                    break;
                                                case 'Renewables':
                                                    iconClass = 'fa fa-refresh';
                                                    break;
                                                case 'Carpentry':
                                                    iconClass = 'fa fa-hammer';
                                                    break;
                                                case 'CCTV':
                                                    iconClass = 'fa fa-video-camera';
                                                    break;
                                                case 'Ceilings':
                                                    iconClass = 'fa fa-lightbulb-o';
                                                    break;
                                                case 'Drylining':
                                                    iconClass = 'fa fa-home';
                                                    break;
                                                case 'Fire Stopping':
                                                    iconClass = 'fa fa-fire-extinguisher';
                                                    break;
                                                case 'Gas':
                                                    iconClass = 'fa fa-fire';
                                                    break;
                                                case 'Glass':
                                                    iconClass = 'fa fa-columns';
                                                    break;
                                                case 'Joinery':
                                                    iconClass = 'fa fas fa-hammer';
                                                    break;
                                                case 'Kitchens':
                                                    iconClass = 'fa  fa-coffee';
                                                    break;
                                                case 'Plumbing':
                                                    iconClass = 'fa fa-bath';
                                                    break;
                                                case 'Stairs and Balustrades':
                                                    iconClass = 'fa fa-align-justify';
                                                    break;
                                                case 'Structural Metalwork':
                                                    iconClass = 'fa  fa-gavel';
                                                    break;
                                                case 'Access Control':
                                                    iconClass = 'fa fa-solid fa-box';
                                                    break;
                                                case 'Air Testing':
                                                    iconClass = 'fa fa-solid fa-wind';
                                                    break;
                                                case 'Alternative Fuel':
                                                    iconClass = 'fa fa-solid fa-gas-pump';
                                                    break;
                                                case 'Balconies':
                                                    iconClass = 'fa fa-vihara';
                                                    break;
                                                case 'Broadband':
                                                    iconClass = 'fa fa-solid fa-wifi';
                                                    break;
                                                case 'Catering Equipment':
                                                    iconClass = 'fa fa-solid fa-utensils';
                                                    break;
                                                case 'Cladding':
                                                    iconClass = 'fa fa-solid fa-bars';
                                                    break;
                                                case 'Decoration':
                                                    iconClass = 'fa fa-solid fa-tree';
                                                    break;
                                                case 'Doors':
                                                    iconClass = 'fa fa-solid fa-door-closed';
                                                    break;
                                                case 'Drainage':
                                                    iconClass = 'fa fa-solid fa-tint';
                                                    break;
                                                case 'EV Charging':
                                                    iconClass = 'fa fa-solid fa-charging-station';
                                                    break;
                                                case 'Hazardous Waste':
                                                    iconClass = 'fa fa-solid fa-radiation-alt';
                                                    break;
                                                case 'Insulation':
                                                    iconClass = 'fa fa-solid fa-igloo';
                                                    break;
                                                case 'Intruder Alarms':
                                                    iconClass = 'fa fa-solid fa-bell';
                                                    break;
                                                case 'Ironmongery':
                                                    iconClass = 'fa fa-solid fa-lock-open';
                                                    break;
                                                case 'Landscaping':
                                                    iconClass = 'fa fa-solid fa-seedling';
                                                    break;
                                                case 'Lifts':
                                                    iconClass = 'fa fa-solid fa-sort';
                                                    break;
                                                case 'Masonry':
                                                    iconClass = 'fa fa-solid fa-grip-horizontal';
                                                    break;
                                                case 'Metal Work':
                                                    iconClass = 'fa fa-solid fa-anchor';
                                                    break;
                                                case 'Natural Stone':
                                                    iconClass = 'fa fa-solid fa-cube';
                                                    break;
                                                case 'Panelling':
                                                    iconClass = 'fa fa-solid fa-couch';
                                                    break;
                                                case 'Sanitaryware':
                                                    iconClass = 'fa fa-solid fa-toilet';
                                                    break;
                                                case 'Swimming Pool':
                                                    iconClass = 'fa fa-solid fa-swimmer';
                                                    break;
                                                case 'Wall Finishes':
                                                    iconClass = 'fa fa-solid fa-paint-roller';
                                                    break;
                                                case 'Water Feature':
                                                    iconClass = 'fa fa-solid fa-water';
                                                    break;
                                                case 'Waterproofing':
                                                    iconClass = 'fa fa-hand-holding-water';
                                                    break;
                                                case 'Windows':
                                                    iconClass = 'fa fa-solid fa-columns';
                                                    break;
                                                    
                                                case 'BMS':
                                                    iconClass = 'fa fa-solid fa-tasks';
                                                    break;
                                                case 'Building Fabric':
                                                    iconClass = 'fa fa-solid fa-building';
                                                    break;
                                                case 'Health and Safety':
                                                    iconClass = 'fa fa-solid fa-medkit'; //head-side-mask
                                                    break;
                                                case '-Project Info':
                                                    iconClass = 'fa fa-solid fa-file-alt';
                                                    break;
                                                case 'Safety':
                                                    iconClass = 'fa fa-solid fa-hard-hat';
                                                    break;
                                                case 'Structural':
                                                    iconClass = 'fa fa-solid fa-layer-group';
                                                    break;
                                                case 'Utilities':
                                                    iconClass = 'fa fa-solid fa-bolt';
                                                    break;
                                                case 'Tiling':
                                                    iconClass = 'fa fa-solid fa-clone';
                                                    break;
                                                case 'Vertical Transportation':
                                                    iconClass = 'fa fa-solid fa-grip-lines-vertical';
                                                    break;
                                                case 'Water Services':
                                                    iconClass = 'fa fa-solid fa-faucet';
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell component="th" id={row.value} scope="row" >
                                                        <a href="javaScript:void(0)" className="d-block" onClick={() => { this.onEdit(row.id) }}>
                                                            <i className={iconClass}></i>{row.label}
                                                        </a>
                                                    </TableCell>

                                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => { this.onEdit(row.id) }}>
                                                        {row.scope_status >= 0 &&
                                                            <button type="button" className="d-block trash_button check-button">
                                                                <CheckIcon />
                                                            </button>
                                                        }
                                                    </TableCell>

                                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => { this.onEdit(row.id) }}>
                                                        {row.attachment_status >= 0 &&
                                                            <button type="button" className="d-block trash_button check-button">
                                                                <CheckIcon />
                                                            </button>
                                                        }
                                                    </TableCell>

                                                    <TableCell style={{ cursor: 'pointer' }} onClick={() => { this.onEdit(row.id) }}>
                                                        {row.stock_status >= 0 &&
                                                            <button type="button" className="trash_button check-button">
                                                                <CheckIcon fill="red" />
                                                            </button>
                                                        }
                                                    </TableCell>

                                                    <TableCell>
                                                    {user.type < 3 && <button
                                                            className="trash_button"
                                                            onClick={()=> (row.scope_status >= 0 || row.attachment_status >= 0 || row.stock_status >= 0 ) ? this.setState({
                                                                openRemoveWork: true,
                                                                removeWorkId:row.id
                                                            }):this.onRemove(row.id)}
                                                        >
                                                            
                                                            <HighlightOffIcon />
                                                        </button>
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </div>
                </div>
                <ConfirmationPopup
                    heading="Remove Work Type"
                    message="Are you sure you want to remove this work type?"
                    noButtonText="No"
                    yesButtonText="Yes"
                    isOpen={this.state.openRemoveWork}
                    onClickYes={() => {
                        this.onRemove(this.state.removeWorkId)
                        this.setState({
                            openRemoveWork: false
                        });
                    }}
                    onClickNo={() => {
                        this.setState({
                            openRemoveWork: false
                        })
                    }}
                />
                <ConfirmationPopup
                    heading=""
                    message="Choose a manual type above to display work types"
                    yesButtonText="ok"
                    isOpen={this.state.showPopUp}
                    onClickYes={() => {
                        this.setState({
                            showPopUp: false
                        })
                    }}
                />
                <ConfirmationPopup
                    heading=""
                    message="Save the project first"
                    yesButtonText="ok"
                    isOpen={this.state.openPop}
                    onClickYes={() => {
                        this.setState({
                            openPop: false
                        })
                    }}
                />
            </div>
        )
    }
}

ContractorForm = reduxForm({
    form: 'ProjectForm',
    destroyOnUnmount: false
})(ContractorForm)

const selector = formValueSelector('ProjectForm');

const mapStateToProps = (state, ownProps) => {
    let contractor_works = state.project.project_details.contractor_works;
    
    let result = contractor_works&& contractor_works.find(({ contractor_email }) => contractor_email === state.auth.user.parent_email?state.auth.user.parent_email:state.auth.user.email);
    
    let show_work_type = result?result.selected:[]
    
    let selected_contractor_work = _.sortBy(show_work_type, 'label') || [];
    let carried_out = _.sortBy(selector(state, 'carried_out'), 'label') || [];
    let workType = _.sortBy(state.templates.workType.data, 'label') || [];

    // Need to replace the code to the best place
    state.templates.templates.selectedData.sort(function(a, b) {
        var nameA = a.shortcode.toUpperCase(); // ignore upper and lowercase
        var nameB = b.shortcode.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
    });
      
    return {
        template_set_id: selector(state, 'template_set_id') || undefined,
        user : state.auth.user,
        carried_out: carried_out,
        workType: workType,
        templateSet: state.templates.templateSet.data,
        templates: state.templates.templates.data,
        selectedWorkType: state.templates.templates.selectedData,
        contractor_works: state.project.project_details.contractor_works,
        selected_contractor_work:selected_contractor_work
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllContractor: (data) => dispatch(ContractorActions.getAllContractor(data)),
    toggleSidebar: () => dispatch(ContractorActions.toggleSidebar()),
    onChangeFieldValue: ({ fieldName, fieldValue }) => dispatch(change('ProjectForm', fieldName, fieldValue)),
    getSelectWorkType: () => dispatch(templateActions.getSelectWorkType()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractorForm);
