import React, {Component} from "react";
import {Dropdown} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Link , useHistory} from 'react-router-dom';
import { connect } from 'react-redux'
import { GlobalActions } from "../../../../app/actions/global.action";
import store from '../../../../app/utils/store'
import  history  from '../../../../app/utils/history.helper';

class QuickUserToggler extends Component {

    constructor(props){
        super(props)
    }

    logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
          toggle.click();
        }
        history.push("/logout");
        window.location.reload();
    };

  openSidebar(event) {
    // this.props.openRightSidebar('change_password')
    store.dispatch(GlobalActions.openRightSidebar('change_password'))
    // document.body.classList.add("openRightBarChangePass");
  }
  // const layoutProps = useMemo(() => {
  //   return {
  //     offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
  //   };
  // }, [uiService]);

  render(){
    const { user } = this.props
    return (<>
          {(user.type == 1 || (user.type == 3 && !user.parent_email) || (user.type == 4 && !user.parent_email)) ? 
          <Dropdown drop="down" alignRight>
          <Dropdown.Toggle className="bg-transparent d-flex align-items-center border-0 p-0">
              <div className="text-primary">
                {user.type == 1?user.company_name:user.name}<i className="text-primary ml-2 icon flaticon2-settings"></i>
              </div>
          </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="navi navi-hover py-2">
            {user.type == 1 && <li className="py-2 px-5">
              <Link to="/company_info">
                <i className="icon-md far fa-building mr-2"></i> Company info
              </Link>
            </li>}
            <li className="py-2 px-5">
              <Link to="/users">
                <i className="icon-md fas fa-user mr-2"></i> Users
              </Link>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>:
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle className="bg-transparent d-flex align-items-center border-0 p-0">
          <div className="text-primary">
            {user.company_name}
          </div>
        </Dropdown.Toggle>
               
      </Dropdown>

      }
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle className="bg-transparent d-flex align-items-center border-0 p-0">
                <div className="text-primary">
                  <>
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                      {user.name}
                    </span>
                    <span className="symbol symbol-35 symbol-light-success  pulse pulse-primary">                
                        <span className="symbol-label font-size-h5 font-weight-bold"> 
                        <i className="fas fa-user text-primary pr-0"></i>
                         <span className="pulse-ring"></span>
                        </span>
                       
                    </span>
                  </>
                </div>
            </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
            <ul className="navi navi-hover py-2">
              <li className="py-2 px-5">
                <Link to="#" onClick={this.openSidebar}>
                  <i className="icon-md fa fa-lock mr-2"></i>Change Password
                </Link>
              </li>
              <li className="py-2 px-5">
                <Link to="#" onClick={this.logoutClick}>
                  <i className="icon-md fas fa-sign-out-alt"></i> Sign Out
                </Link>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </>
  );
  }
}


const mapStateToProps = (state, ownProps) => {
    return {
      user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(QuickUserToggler)
