const validate = values => {
  const errors = {}
  
  if (!values.title) {
    errors.title = 'Required Field'
  }else if (!/^[A-Za-z0-9\'\;\.\-\/\s]+$/.test(values.title)) {
    errors.title = 'Invalid name'
  } else if (values.title.length > 50) {
      errors.title = 'Not more than 50 characters allowed'
  }else if(!values.title.trim()){
    errors.title = 'Required Field'
  }
  return errors
}

export default validate;