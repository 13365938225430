import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { InputFieldCompanyInfo, renderImageField } from '../common/FieldComponent';
import { CompanyActions } from '../../actions/company.action';

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!values.name) {
        errors.name = 'Required'
    }
    if (!values.user_name) {
        errors.user_name = 'Required'
    }
    // if (!values.address_line_1) {
    //     errors.address_line_1 = 'Required'
    // }
    // if (!values.address_line_2) {
    //     errors.address_line_2 = 'Required'
    // }
    // if (!values.city) {
    //     errors.city = 'Required'
    // }
    // if (!values.county) {
    //     errors.county = 'Required'
    // }
    // if (!values.postcode) {
    //     errors.postcode = 'Required'
    // }
    // if (!values.website) {
    //     errors.website = 'Required'
    // }
    // if (!values.phone) {
    //     errors.phone = 'Required'
    // }
    return errors
}

const mimeType = "image/jpeg,image/jpg,image/png"
const minSize = 10240;
const maxSize = 2097152;
const userImage = '/media/img/company_profile.png';

class CompanyDetailForm extends Component {

    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            file: null,
            imageFile: null,
            selectedOption: ""
        };
    }

    componentDidMount(){
        this.props.getCompanyInfo()
    }

    _handleSubmit = (values) => {
        
        
        let data = new FormData();

        if(values.userImage){
            this.setState({
                imageFile: values.userImage[0]
            });
            data.append('logo', values.userImage[0])
        }
        data.append('name', values.name)
        data.append('userName', values.userName)
        data.append('email', values.email)
        data.append('address_line_1', values.address_line_1)
        data.append('address_line_2', values.address_line_2)
        data.append('mobile_number', values.mobile_number)
        data.append('company_name', values.company_name)
        data.append('city', values.city)
        data.append('county', values.county)
        data.append('postcode', values.postcode) 
        data.append('website', values.website)
        data.append('phone', values.phone)
        this.props.CompanyInfo(data);
    }

    _handleOnUpload = (e) => {
        
        if (e.target.files[0] && mimeType.includes(e.target.files[0].type)) {
            this.setState({
                file: e.target.files[0],
                imageFile: URL.createObjectURL(e.target.files[0])
            });
        }
       
    }


    render() {
        const { handleSubmit, submitting, company_details, companyName } = this.props
        let userPic;
        if (this.props.userImage && this.props.userImage[0]) {
            if (mimeType.includes(this.props.userImage[0].type)) {
                userPic = URL.createObjectURL(this.props.userImage[0])
            } else {
                userPic = userImage
            }
        } else {
            userPic = company_details.logo && company_details.logo || userImage
        }
        let label = "";
        if(!companyName){
            label = `Please update your company details to start creating projects. Your company name is enough, you can always update the rest later on.`;
        }
        
        return (<>
            {!companyName && <div className="bg-warning pos-relative p-4 mb-5 text-center d-flex align-items-center justify-content-center">
                <p className="m-0">{label}</p>
            </div>}
            <div className="card card-custom mb-8">
                <div className="card-body border-bottom-light border-bottom d-flex justify-content-between align-items-center flex-row p-4">
                    <h3 className="mb-0">Company Info</h3>
                </div>
                <div className="card-body pv-5 mb-8">
                    <form onSubmit={handleSubmit(this._handleSubmit)} >

                        <div className="company_profile_info mb-6">
                            <figure>
                                <img src={userPic} alt="Company Profile" />
                            </figure>
                            <div className='company-info-note'>
                                <p>Note: There is no need to add all of your company details as updating the Company Name will be enough to start creating a project.</p>
                                <Field
                                    name="userImage"
                                    component={renderImageField}
                                    handleOnUpload={this._handleOnUpload}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="name"
                                    label="Company"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                    readOnly={companyName ? true : false}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="userName"
                                    label="User name"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>
                        </div>

                        <div className="row">

                           

                            {/* <div className="col-lg-6 col-md-6">
                                <Field
                                    name="email"
                                    label="Email"
                                    margin="normal"
                                    variant="outlined"
                                    // readOnly={true}
                                    component={InputFieldCompanyInfo}
                                />
                            </div> */}

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="address_line_1"
                                    label="Address Line 1"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="address_line_2"
                                    label="Address Line 2"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="city"
                                    label="City"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="county"
                                    label="County"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="postcode"
                                    label="Post Code"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="website"
                                    label="Website"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <Field
                                    name="phone"
                                    label="Telephone"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>

                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-12">
                            <p><span className="schema-modal">Note: </span><span className="schema-list">The details entered above will be visible in the completed manual.</span></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-4 align-self-end"
                                        disabled={submitting}
                                    >
                                        Update
                                    </button>
                                    {submitting && <span className="ml-3 spinner spinner-white"></span>}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>)
    }
}
CompanyDetailForm = reduxForm({
    form: 'CompanyInfoForm',
    validate,
    enableReinitialize: true
})(CompanyDetailForm)


const mapStateToProps = (state, ownProps) => {
    let company_details = state.company.company_details
    const selector = formValueSelector("CompanyInfoForm");
    return {
        company_details,
        userImage: selector(state, 'userImage'),
        companyName: company_details.company_id && company_details.company_id.name || '',
        initialValues: {
            name: company_details.company_id && company_details.company_id.name || '',
            userName: state.auth.user && state.auth.user.name || '',
            address_line_1: company_details && company_details.address_line_1 || '',
            address_line_2: company_details && company_details.address_line_2 || '',
            city: company_details && company_details.city || '',
            county: company_details && company_details.county || '',
            postcode: company_details && company_details.postcode || '',
            // email: company_details && company_details.email || state.auth.user.email,
            phone: company_details && company_details.phone || '',
            website: company_details && company_details.website || '',
        }
      }
}

const mapDispatchToProps = (dispatch) => ({
    CompanyInfo: (values) => {dispatch(CompanyActions.companyDetailForm({values}))},
    getCompanyInfo: (values) => {dispatch(CompanyActions.getCompanyInfo({values}))}
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailForm)