import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm, reset } from 'redux-form'; 
import { Form } from 'react-bootstrap'
import _ from 'lodash';

import { FileTemplateRedux } from "../common/FieldComponent";
import { templateActions } from "../../actions/template.action";

import { alertInfo } from '../../../app/utils/common.helper';

const limit = 15 * 1024 * 1024
const fileValidation = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']

const validate = values => {
    const errors = {}
    if (!values.file) {
        errors.file = 'Required'
    }
    return errors
}

class UpdateTemplate extends Component {

    constructor(props) {
        super(props);
        this.state={
            open:this.props.showUpdateForm,
        }
    }

    _handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancelUpdateTemplate();
    };

    _handleSubmit = (values) =>{
        let {uploadTemplateToServer, uploadTemplate} = this.props
        // values.id = uploadTemplate.id;

        const data = new FormData()
        if (_.isObject(values.file) && values.file) {
            data.append('file', values.file[0])
        }

        uploadTemplateToServer({data, resetForm: this.props.resetForm, id:uploadTemplate.id})
    }

    _registerFileToUpload = (e) => {
		const files = [...e.target.files];
		this.setState({
			uploadFiles: files
		});
	}
    onChange =(e)=>{
        let selectedFile = e.target.files[0]
        if (selectedFile.size > limit) {
            alertInfo('error', `Attachment size should not be greater than ${limit / 1024 / 1024} MB`);
            e.preventDefault()
            return false
        } else if (fileValidation.indexOf(selectedFile.type) === -1) {
            alertInfo('error', 'Only .docx files are allowed')
            e.preventDefault()
            return false
        } 
    }
    render() {
        const { handleSubmit, submitting, pristine, uploadTemplate, showUpdateFormid } = this.props
        if(uploadTemplate && (showUpdateFormid ==uploadTemplate.id )){
            return (
                <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.open}
                >
                    <DialogTitle id="alert-dialog-title">{uploadTemplate && uploadTemplate.title.replace('Stairs Balistrades', 'Stairs Balustrades')}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <Field 
                        name="file"
                        component={FileTemplateRedux}
                        onChange={(e) => this.onChange(e)}
                    />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={this._handleClose}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        Cancel
                        </button>
                        <button
                            // type="submit"
                            onClick={handleSubmit(this._handleSubmit)}
                            disabled={submitting || pristine}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            <span>{submitting === true ? "Submitting.." : "Submit"}</span>
                            {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                    </DialogActions>                   
                </Dialog>    
        );
        }
        return(
            <></>
        )
        
    }
}

UpdateTemplate = reduxForm({
    form: 'UpdateTemplate',
    validate,
    enableReinitialize: true
})(UpdateTemplate);

const mapStateToProps = (state, ownProps) => {
    const { templates: { workType, templateSet, templates } } = state;
	return {
        showUpdateForm: templates.showUpdateForm,
        showUpdateFormid: templates.showUpdateFormid,
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    cancelUpdateTemplate: (data) => { dispatch({ type : "TEMPLATES/UPDATETEMPLATE/TOGGLE"}) },
    uploadTemplateToServer: (data) => { dispatch(templateActions.uploadTemplate(data)) },
    resetForm:()=>{dispatch(reset('UpdateTemplate'))}
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTemplate);