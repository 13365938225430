export const ProjectActions = {
    getAll,
    getDropdownListing,
    selectedDropdown,
    getcontractorListing,
    saveFilterValue,
    getCustomerListing,
    getSiteListing,
    createCustomer,
    createSite,
    createProject,
    getProjectDetails,
    resetProjectDetails,
    updateProject,
    saveScopeOfWork,
    getAllGroupAttachments,
    saveProjectAttachment,
    getAllAttachments,
    getAllScopeOfWork,
    getAllStockGroup,
    getAllStocksByStockGroup,
    deleteStock,
    selectSingleStock,
    stockAddToProject,
    getAllProjectStock,
    createManual,
    removeProject,
    deleteProjectStock,
    stockAttachmentCheckbox,
    sendMail,
    downloadFile,
    getInstallationType,
    saveInstallationType,
    getManualStatus
}

function getAll(data) {
    return { type: "PROJECT/GET/ALL", payload: data }
}

function getDropdownListing(data) {
    return { type: "PROJECT/DROPDOWN/LIST/GET", payload: data }
}

function getcontractorListing(data) {
    return { type: "PROJECT/CONTRACTOR/LIST/GET", payload: data }
}

function selectedDropdown(data) {
    return { type: "PROJECT/DROPDOWN/SELECTED", payload: data }
}

function saveFilterValue(data) {
    return { type: "PROJECT/FILTER/SAVE", payload: data }
}

function getCustomerListing(data){
    return { type: "PROJECT/CUSTOMER/LIST/GET", payload: data }
}

function getSiteListing(data){
    return { type: "PROJECT/SITE/LIST/GET", payload: data }
}

function createCustomer(data){
    return { type : "PROJECT/CUSTOMER/CREATE", payload: data }
}

function createSite(data){
    return { type : "PROJECT/SITE/CREATE", payload: data }
}

function createProject(data){
    return { type : "PROJECT/CREATE/REQUEST", payload: data }
}
  
function getProjectDetails(data) {
    return { type: "PROJECT/GET/SINGLE_PROJECT_DETAILS/REQUEST", payload: data }
}

function resetProjectDetails(data) {
    return { type: "PROJECT/RESET/SINGLE_PROJECT_DETAILS/SUCCESS", payload: data }
}

function updateProject(data){
    return { type : "PROJECT/UPDATE/REQUEST", payload: data }
}

function saveScopeOfWork(data){
    return { type : "PROJECT/SAVE/SCOPE_OF_WORK/REQUEST", payload: data }
}

function getAllScopeOfWork(data) {
    return { type: "PROJECT/GET/SCOPE_OF_WORK/REQUEST", payload: data }
}

function getAllGroupAttachments(data) {
    return { type: "PROJECT/GET/ALL/GROUP/ATTACHMENTS/REQUEST", payload: data }
}

function saveProjectAttachment(data) {
    return { type: "PROJECT/SAVE/GROUP/ATTACHMENTS/REQUEST", payload: data }
}

function getAllAttachments(data) {
    return { type: "PROJECT/GET/ALL/ATTACHMENTS/REQUEST", payload: data }
}

function getAllStockGroup(data) {
    return { type: "PROJECT/GET/ALL/STOCKS/REQUEST", payload: data }
}

function getAllStocksByStockGroup(data) {
    return { type: "PROJECT/GET/ALL/STOCKS_DETAILS/REQUEST", payload: data }
}

function deleteStock(data) {
    return { type: "PROJECT/DELETE/STOCKS_DETAILS/REQUEST", payload: data }
}

function selectSingleStock(data) {
    return { type: "PROJECT/STOCK/SELECT_SINGLE_STOCK/SUCCESS", payload: data }
}

function stockAddToProject(data) {
    return { type: "PROJECT/STOCK/ADD_TO_PROJECT/REQUEST", payload: data }
}

function getAllProjectStock(data) {
    return { type: "PROJECT/GET/ALL/PROJECT/STOCK/REQUEST", payload: data }
}

function deleteProjectStock(data) {
    return { type: "PROJECT/DELETE/PROJECT/STOCK/REQUEST", payload: data }
}

function stockAttachmentCheckbox(data) {
    return { type: "PROJECT/STOCK/ATTACHMENT/REQUEST", payload: data }
}

function createManual(data) {
    return { type: "PROJECT/CREATE/MANUAL/REQUEST", payload: data }
}

function getManualStatus(data) {
    return { type: "PROJECT/MANUAL/STATUS/REQUEST", payload: data }
}

function removeProject(data) {
    return { type: "PROJECT/REMOVE/REQUEST", payload: data }
}

function sendMail(data) {
    return { type: "PROJECT/SEND/MAIL/REQUEST", payload: data }
}

function downloadFile(data) {
    return { type: "PROJECT/DOWNLOAD/FILE/REQUEST", payload: data }
}

function getInstallationType(data){
    return { type: "PROJECT/GET/INSTALLATION_TYPE/REQUEST", payload: data }
}

function saveInstallationType(data){
    return { type : "PROJECT/SAVE/INSTALLATION_TYPE/REQUEST", payload: data }
}
