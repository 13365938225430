import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { red, blue } from '@material-ui/core/colors';
import { InputFieldRedux } from '../../component/common/FieldComponent'
import { MainServiceActions } from '../../actions/main_service.action';

const validate = (values)=>{
    const errors = {}
    if (!values.title) {
        errors.title = 'Required Field'
    } else if (values.title.length > 25) {
        errors.title = 'Not more than 25 characters allowed'
    }
    return errors

}

class AddNewService extends React.Component {

  handleSubmit = (data) => {
    data.form = "AddMainService"
    this.props.createMainService({data, resetForm:this.props.resetForm, toggleAddService: this.props.toggleAddService});
  }

  render() {
    const { handleSubmit, submitting, pristine, } = this.props;
    let classes = (submitting || pristine)  ? "add disabled" : "add";
    return (
    <>
      <div className="add-new-service-custom">
        <Field
            label={'Service'}
            placeholder="Add New Service"
            margin="normal"
            variant="outlined"
            type="text"
            name="title"
            component={InputFieldRedux}
        />
        {/* <button 
            type="submit" 
            className="btn btn-primary list_add_btn rounded-0"
            disabled={submitting || pristine} 
        >
            Add New
        </button> */}
        <CheckCircleOutlineIcon className={classes}  title="Add" onClick={handleSubmit(this.handleSubmit)} style={{ color: blue[500] }}/>
        <CancelIcon className="cancel"  title="cancel" onClick={this.props.toggleAddService} style={{ color: red[500] }} />
        </div>
    </>

    );
  }
}

AddNewService = reduxForm({
  form: 'AddMainService',
  validate,
  enableReinitialize: true
})(AddNewService);
  
const mapDispatchToProps = (dispatch, ownProps) => ({
  createMainService: (values) => dispatch(MainServiceActions.create(values)),
  resetForm: () => { dispatch(reset('AddMainService')) }
})

export default connect(
  null,
  mapDispatchToProps
)(AddNewService);