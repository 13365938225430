import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm, reset } from 'redux-form'; 
import _ from 'lodash';
import { templateActions } from "../../actions/template.action";

const validate = values => {
    const errors = {}
    if (!values.otp) {
        errors.otp = 'Required'
    }
    return errors
}

class DeleteTemplateSet extends Component {

    constructor(props) {
        super(props);
        this.state={
            open:this.props.showTemplateModal,
            deleteTempSet: this.props.deleteTempSet
        }
    }

    _handleClose = () => {
        this.setState({
            open:false
        });
        this.props.deleteToggle();
    };

    _handleSubmit = (values) =>{
        let tmpset_id = this.props.deleteTempSet.id;
        let {deleteTemplateSet} = this.props
        deleteTemplateSet({tmpset_id,clearModal:this.props.clearModal})
    }

    render() {
        const { handleSubmit, submitting, pristine, deleteTempSet } = this.props
        return (
            <Dialog
            modal={true}
            open={!_.isEmpty(deleteTempSet)}
        >
       <DialogTitle id="alert-dialog-title">Template Set Name : {deleteTempSet.template_set_name}</DialogTitle>
            <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Selected Manuals : {_.keys(deleteTempSet.selected_manuals).join(", ")}    
          </DialogContentText>
          <p className="text-danger">This can not be undone. It will be deleted permanently.</p>
       </DialogContent>
       <DialogActions>
          <button
                onClick={this.props.clearModal}
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                disabled={submitting}
          >
             Cancel
          </button>
          <button
                onClick={handleSubmit(this._handleSubmit)}
                disabled={submitting}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
          >
                <span>{submitting === true ? "Deleting.." : "Delete"}</span>
                {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
          </button>
       </DialogActions> 
        </Dialog>    
        );
    }
}

DeleteTemplateSet = reduxForm({
    form: 'DeleteTemplateSet',
    validate,
    enableReinitialize: true
})(DeleteTemplateSet);

const mapStateToProps = (state, ownProps) => {
    const { templates: { workType, templateSet, templates } } = state;
	return {
        showTemplateModal: templateSet.showTemplateSetFormDelete,
        // registerData: state.auth.register.data
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteToggle: (data) => { dispatch({ type : "TEMPLATES/TEMPLATESET/DELETE/TOGGLE"}) },
    deleteTemplateSet: (data) => dispatch(templateActions.deleteTemplateSet(data)),
    resetForm:()=>{dispatch(reset('DeleteTemplateSet'))}
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTemplateSet);