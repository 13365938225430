import React from 'react'; 
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper} from '@material-ui/core';
import SVG from "react-inlinesvg";
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { toAbsoluteUrl, } from "../../_helpers";
import { dateFormat } from "../../../app/utils/common.helper"
import { connect } from 'react-redux';
import { CustomerActions } from "../../../app/actions/customer.action";
import { GlobalActions } from "../../../app/actions/global.action";

const rowsPerPageOptions = [ 10, 15, 25, 50 ];

const headRows = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'reference', numeric: false, disablePadding: false, label: 'Reference' },
  { id: 'access', numeric: false, disablePadding: false, label: 'Access' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  let tooltipIcon = <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">When selected, customers will be given access to the system to view their projects.</Tooltip>}
    >
        <p>Access <i className="fa fa-info-circle tooltip-icon" aria-hidden="true"></i></p>
    </OverlayTrigger>;

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length>1}
              direction={order}
              // onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              onClick={(row.label == 'Actions'|| row.label == 'Access') ? null: createSortHandler(row.id)}
              hideSortIcon={row.id !== 'title' || listing.length < 2}
            >
              {/* {row.label} */}
              <p>{row.label == "Access"?tooltipIcon:row.label}</p>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class Listing extends React.Component {

  state = {
    order: 'asc',
    orderBy: '',
    page: 0,
    dense: false,
    rowsPerPage: 10,
    open:false,
    deleteRecord:null
  }

  componentDidMount(){
    this.props.customerListing();
  }

  _toggleDeleteModal = (id) =>{
    this.setState({
      open:!this.state.open,
      deleteRecord:id
    })
  }

  handleRequestSort = (e, property) => {
    const { order, orderBy,rowsPerPage } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property, page:0 },() => {
      if(property == 'address'){
        property = 'address_line_1';
      }
      this.props.customerListing({ sort: property, order: order === 'asc' ? -1 : 1 ,per_page: rowsPerPage})
    })
  }

  handleChangePage = (e, newPage) => {
    let { rowsPerPage,order, orderBy } = this.state;
    const new_page = newPage + 1;
    if(this.props.listing.length){
      this.setState({ page: newPage })
      // this.props.customerListing({ current_page: new_page, per_page: rowsPerPage });
      const isDesc =  order === 'asc';
      // this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property },() => {
        if(orderBy == 'address'){
          orderBy = 'address_line_1';
        }
        if(!orderBy){
          orderBy = 'title';
        }
        this.props.customerListing({ current_page: new_page, sort: orderBy, order: order === 'asc' ? 1 : -1 ,per_page: rowsPerPage})
      // })
    }
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
      page: 0,
      order: 'asc',
      orderBy: 'title',
     })
    this.props.customerListing({ per_page: e.target.value });
  }

  handleDelete = (id) => this.props.deleteCustomer(id)

  updateCustomerAccess = (e, customer) => {
    if(customer.email){
      let data = {
        id:customer.id,
        email:customer.email,
        name:customer.label,
        user_access : e.target.checked
      }
      this.props.updateCustomerAccess(data)
    }
  }

  openUpdateCustomerSidebar = (id) => {
    this.props.getCustomer(id);
    this.props.openRightSidebar('update_customer')
    // document.getElementById('update-customer-sidebar').style.display = 'block';
    // document.body.classList.add("openRightBar");
    this.props.toggleSidebar();
  }

  render(){
    const { dense, rowsPerPage, page, order, orderBy, } = this.state;
    const { listing, pagination, requesting, } = this.props;
    return (
      <div style={{width: '100%', marginTop: '24px',}}>
        <Paper style={{ width: '100%', marginBottom: '16px',}}
        ref={element => { 
          if (element) element.style.setProperty('box-shadow', 'none', 'important'); 
        }}>
          <div style={{overflowX: 'auto'}}>
            <Table
              style={{minWidth: 750}}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                listing={listing}
              />
              <TableBody>
                {!requesting && listing && listing.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                  </TableRow>
                )}
                {listing && listing.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell 
                          style={{cursor: 'pointer'}} 
                          onClick={() => this.openUpdateCustomerSidebar(row.id)}>
                            {row.label}
                        </TableCell>
                        <TableCell style={{cursor: 'pointer'}} 
                          onClick={() => this.openUpdateCustomerSidebar(row.id)}>{row.address_line_1}{row.address_line_2 && `, `+row.address_line_2}</TableCell>
                        <TableCell style={{cursor: 'pointer'}} 
                          onClick={() => this.openUpdateCustomerSidebar(row.id)}>{dateFormat(row.created_at)}</TableCell>
                        <TableCell style={{cursor: 'pointer'}} 
                          onClick={() => this.openUpdateCustomerSidebar(row.id)}>{row.reference}</TableCell>
                        <TableCell  style={{ cursor: 'pointer' }}
                          onChange={(e) => this.updateCustomerAccess(e, row)}>
                          <div style={{ cursor: 'pointer' }} class="custom-control custom-switch">
                            <input style={{ cursor: 'pointer' }} disabled={row.email?false:true} type="checkbox" class="custom-control-input" id={row.id} defaultChecked={row.user_access}/>
                            <label title={row.email?'':"Please update email id first."} style={{ cursor: 'pointer' }} class="custom-control-label" for={row.id}></label>
                          </div>
                        </TableCell>
                        <TableCell style={{cursor: 'pointer'}} >
                            {/* <button className="trash_button" onClick={() => this.handleDelete(row.id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")} */}
                            <button className="trash_button" onClick={() => this._toggleDeleteModal(row.id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}
                            /></button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {requesting && <div className="spinner"></div>}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={pagination ? pagination.total : -1}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              if(pagination === null){
                return `0-0 of 0`
              }
              return `${from}-${to} of ${count}`
            }}
          />
        </Paper>
        <Dialog 
              className="model-support"
              modal={false}
              contentClassName="model-top-overlay no-overlay"
              open={this.state.open}
          >
              <DialogTitle id="alert-dialog-title">Delete Customer?</DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure, you want to delete this customer?
                <br />You will lose all the associated site data with it!
              </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <button
                  onClick={this._toggleDeleteModal}
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                  >
                  cancel
                  </button>
                  <button
                      onClick={() => {this.handleDelete(this.state.deleteRecord);this._toggleDeleteModal()}}
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                  >
                      Delete
                  </button>
              </DialogActions>                   
        </Dialog>    
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customerlisting: { data, pagination, requesting, } } = state.customer;
  return {
    listing: data,
    pagination: pagination,
    requesting
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  customerListing: (data) => dispatch(CustomerActions.getAll(data)),
  updateCustomerAccess: (values) => dispatch(CustomerActions.updateOneAccess(values)),
  deleteCustomer: (data) => dispatch(CustomerActions.deleteOne(data)), 
  getCustomer: (data) => { dispatch(CustomerActions.getOne(data))},
  toggleSidebar: () => dispatch(CustomerActions.toggleSidebar()),
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);