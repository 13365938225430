import React from 'react'; 
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper} from '@material-ui/core';
import SVG from "react-inlinesvg";
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

import { toAbsoluteUrl, } from "../../../_metronic/_helpers";
import { dateFormat } from "../../utils/common.helper"
import { connect } from 'react-redux';
import { UserActions } from "../../actions/user.action";
import { GlobalActions } from "../../actions/global.action";

const rowsPerPageOptions = [ 10, 15, 25, 50 ];

const headRows = [
    { id: 'User name', numeric: false, disablePadding: false, label: 'User name' },
    { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'Role', numeric: false, disablePadding: false, label: 'Role' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, listing } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                            align={row.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                        <TableSortLabel
                            active={orderBy === row.id && listing.length>1}
                            direction={order}
                            onClick={orderBy === row.id ? createSortHandler(row.id) : null}
                            hideSortIcon={row.id !== 'title' || listing.length < 2}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

class UserListing extends React.Component {

    state = {
        order: 'asc',
        orderBy: 'title',
        page: 0,
        dense: false,
        rowsPerPage: 10,
        open:false,
        deleteRecord:null
    }

    componentDidMount(){
        this.props.getAllUser();
    }

    _toggleDeleteModal = (id) =>{
        this.setState({
            open:!this.state.open,
            deleteRecord:id
        })
    }

    handleRequestSort = (e, property) => {
        const { order, orderBy, } = this.state;
        const isDesc = orderBy === property && order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property },() => {
            this.props.getAllUser({ sort: property, order: order === 'asc' ? -1 : 1 })
        })
    }

    handleChangePage = (e, newPage) => {
        const { rowsPerPage } = this.state;
        const new_page = newPage + 1;
        if(this.props.listing.length){
            this.setState({ page: newPage })
            this.props.getAllUser({ current_page: new_page, per_page: rowsPerPage });
        }
    }

    handleChangeRowsPerPage = (e) => {
        this.setState({ 
            rowsPerPage: e.target.value,
            page: 0,
        })
        this.props.getAllUser({ per_page: e.target.value });
    }

    handleDelete = (id) => this.props.deleteUser(id)

    openUpdateCustomerSidebar = (data) => {
        this.props.setUserDetails(data)
        this.props.openRightSidebar('update_user')
    }

    render(){
        const { dense, rowsPerPage, page, order, orderBy, } = this.state;
        const { listing, pagination, requesting,user } = this.props;
        return (
            <div style={{width: '100%', marginTop: '24px',}}>
                <Paper style={{ width: '100%', marginBottom: '16px',}}
                    ref={element => { 
                        if (element) element.style.setProperty('box-shadow', 'none', 'important'); 
                    }}
                >
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            style={{minWidth: 750}}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                listing={listing}
                            />
                            <TableBody>

                                {!requesting && listing && listing.length === 0 && (
                                    <TableRow tabIndex={-1}>
                                        <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                                    </TableRow>
                                )}

                                {listing && listing.map((row, index) => {
                                    let user_type = 'Admin';
                                    switch(row.type){
                                        case 1: user_type = 'Admin';
                                        break;
                                        case 2: user_type = 'Team Member';
                                        break;
                                        case 3: user_type = 'Contractor';
                                        break;
                                        case 4: user_type = 'Customer';
                                        break;
                                        default: user_type = 'Admin'
                                    }
                                    
                                    if(row.type == 3 && row.parent_email){
                                        user_type = 'Sub Contractor';
                                    }
                                    if(row.type == 4 && row.parent_email){
                                        user_type = 'Sub Customer';
                                    }
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <TableCell 
                                                style={{cursor: 'pointer'}} 
                                                onClick={() => {
                                                    if(!((user.type == 3 || user.type == 4) && (user.email == row.email))){
                                                        this.openUpdateCustomerSidebar(row)
                                                    }
                                                
                                                }}
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{user_type}</TableCell>
                                            <TableCell>
                                                <button className="trash_button" disabled={ user.email == row.email } onClick={() => this._toggleDeleteModal(row._id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}/></button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {requesting && <div className="spinner"></div>}
                    </div>

                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={pagination ? pagination.total_pages : -1}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => {
                          if(pagination === null){
                            return `0-0 of 0`
                          }
                          return `${from}-${to} of ${count}`
                        }}
                    />
                </Paper>

                <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.open}
                >
                    <DialogTitle id="alert-dialog-title">Delete User?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                            onClick={this._toggleDeleteModal}
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        > cancel </button>
                        <button
                            onClick={() => {this.handleDelete(this.state.deleteRecord);this._toggleDeleteModal()}}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            Delete
                        </button>
                    </DialogActions>                   
                </Dialog> 

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user:state.auth.user,
        listing: state.user.userlisting.data,
        pagination: state.user.userlisting.pagination,
        requesting: state.user.userlisting.requesting
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllUser: (data) => dispatch(UserActions.getAllUser(data)),
    deleteUser: (data) => dispatch(UserActions.deleteUser(data)), 
    setUserDetails: (data) => dispatch(UserActions.setUserDetails(data)), 
    openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})


export default connect(mapStateToProps,mapDispatchToProps)(UserListing);