import React from "react";
import ForgotPasswordForm from './container/ForgetPasswordForm'

function ForgotPassword(props) {

  return (      
        <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-10">
          <figure>
            <img className="login-logo" src="/media/img/logo_white.png" alt="logo-white" />
          </figure>
        </div>
          <div className="text-center mb-10 mb-lg-20">
           <ForgotPasswordForm/>
          </div>
        </div>
  );
}

export default ForgotPassword;
