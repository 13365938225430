const validate = values => {
  const errors = {}
  if (!values.title) {
      errors.title = 'Title is required'
  } else if (values.title.length > 50) {
        errors.title = 'Maximum 50 characters allowed'
  }else if (!/^[A-Za-z0-9\'\;\.\-\/\s]+$/.test(values.title)) {
    errors.title = 'Invalid title'
  }else if(!values.title.trim()){
        errors.title = 'Title is required'
  }

  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
  }
  } 
  // if (!values.address_line_1) {
  //     errors.address_line_1 = 'Address Line 1 is required'
  // }else if(!values.address_line_1.trim()){
  //   errors.address_line_1 = 'Address Line 1 is required'
  // }
  // if (!values.address_line_2) {
  //     errors.address_line_2 = 'Address Line 2 is required'
  // }else if(!values.address_line_2.trim()){
  //   errors.address_line_2 = 'Address Line 2 is required'
  // }
  // if (!values.city) {
  //     errors.city = 'City is required'
  // }else if(!values.city.trim()){
  //   errors.city = 'City is required'
  // }
  // if (!values.county) {
  //     errors.county = 'County is required'
  // }else if(!values.county.trim()){
  //   errors.county = 'County is required'
  // }
  // if (!values.postcode) {
  //     errors.postcode = 'Postcode is required'
  // }else if(!values.postcode.trim()){
  //   errors.postcode = 'Postcode is required'
  // }
  if (values.reference) {
    if(!/^[\w\-\s]+$/i.test(values.reference)){
      errors.reference = 'Reference is invalid (must be Alphanumeric)'
    }
  }
  return errors
}

export default validate;