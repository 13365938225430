import React from 'react';
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, reset, } from 'redux-form';
import { StockActions } from '../../../../../app/actions/stock.action';
import { CustomerActions } from '../../../../../app/actions/customer.action';
import { InputFieldRedux, SelectFieldRedux, AutoCompleteFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'

class UpdateStock extends React.Component {

    hideSidebar = () => {
        document.body.classList.remove("openRightBar");
        document.getElementById('update-stock-sidebar').style.display = 'none';
        this.props.toggleSidebar();
    }

    handleSubmit = (data) => {
        const stock_id = data.stock_id && data.stock_id.value
        const newData = { ...data, stock_id, ...{ id : (this.props.stock_detail ? this.props.stock_detail.id : 0) }}
        this.props.updateStock(newData);
    }

    render(){
      const { handleSubmit, submitting, pristine, customer_listing, } = this.props
      return (
        <>
            <div id="update-stock-sidebar" style={{display: 'none'}} className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Update Stock</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                              label={'Stock Name'}
                              placeholder="Enter stock name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <div className="d-flex justify-content-end">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Update
                              </Button>
                            </div>
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
        </>
      )
    }
}

UpdateStock = reduxForm({
  form: 'UpdateStock',
  validate,
  enableReinitialize: true
})(UpdateStock);

UpdateStock = connect(state => {
  const { detail } = state.stock.stocklisting
  return {
    initialValues: {
      title: detail.title,
    }
  }
},
)(UpdateStock)

const mapStateToProps = (state, ownProps) => {
  const { stocklisting: { detail, } } = state.stock;
  const { isSidebarOpen } = state.stock;
  return {
    stock_detail: detail,
    isSidebarOpen
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateStock: (data) => dispatch(StockActions.updateOne(data)),
  toggleSidebar: () => dispatch(StockActions.toggleSidebar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStock)
