import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Topbar} from "./Topbar";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import {AnimateLoading} from "../../../_partials/controls";
import {Helmet} from "react-helmet";
import store from '../../../../app/utils/store';
import { useLocation } from 'react-router-dom'
export function Header() {
  const state = store.getState();
  const user = state.auth ? state.auth.user:null;
  // console.log("state.auth.user", state.auth.user);
  // let auth = localStorage.getItem('persist:v705-demo1-auth')
  // let user = JSON.parse(JSON.parse(auth).user)
  let user_email = user&&user.email;
  let user_name = user&&user.name;
  let user_created_at = new Date(user&&user.created_at).valueOf();
  let APP_ID = "h8dm76k7";
  
  const uiService = useHtmlClassService();
  const location = useLocation();
  const openTab = location.pathname.substring(1).toUpperCase().split('/')
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}

      >
        <Helmet>
          <script>{`
            if(window.location.origin == 'https://dokkit.app'){
              window.intercomSettings = {
              app_id: '${APP_ID}',
              name: '${user_name}', // Full name
              email: '${user_email}', // Email address
              created_at: ${user_created_at} // Signup date as a Unix timestamp
              };
            }
          `}</script>
          <script>{`
            if(window.location.origin == 'https://dokkit.app'){
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            }
          `}</script>
        </Helmet>
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}>
          {/* {openTab[0]} */}
          {(openTab[0] == "TEMPLATES" ||
            openTab[0] == "SETUP" ||
            openTab[0] == "COMPANY_INFO" ||
            openTab[0] == "COMPANY-SUBSCRIPTION" ) && <span className="text-dark-50  font-size-base d-none d-md-inline mr-3">
            {openTab[0].replace('-', ' ').replace('_', ' ')}
          </span>}
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
