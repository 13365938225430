import _ from 'lodash'

let initialValues = {
  sitelisting: {
    requesting: false,
    data: [],
    pagination: null,
    detail: {},
    requestingDetail: false,
    filter: "",
  },
  isSidebarOpen: false,
}

export const siteReducer = (state = initialValues, action) => {
    switch (action.type) {
        //Toggle sidebar
        case 'SITE/SIDEBAR/TOGGLE':
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            }
        //Get all sites
        case 'SITE/GET/ALL':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    requesting: true,
                },
        }
        case 'SITE/GET/ALL/SUCCESS':
            return {
                ...state,
                sitelisting: {
                      ...state.sitelisting,
                      data: [ ...action.payload.data ],
                      pagination: { ...action.payload.pagination },
                      requesting: false,
                },
        }
        case 'SITE/GET/ALL/ERROR':
            return {
                ...state,
                sitelisting: {
                      ...state.sitelisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        //Delete one site
        case 'SITE/DELETE/ONE':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    // requesting: true,
                },
        }
        case 'SITE/DELETE/ONE/SUCCESS':
            const id_del = action.payload.id;
            const newSiteListing = _.filter(state.sitelisting.data, site => site.id !== id_del)
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    data: newSiteListing,
                    pagination: {
                      total: state.sitelisting.pagination.total - 1
                    },
                    // requesting: false,
                },
        }
        case 'SITE/DELETE/ONE/ERROR':
            return {
                ...state,
                sitelisting: {
                      ...state.sitelisting,
                      errors: action.payload.data,
                    //   requesting: false,
                },
        }
        // Get one site
        case 'SITE/GET/ONE':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    requestingDetail: true,
                },
        }
        case 'SITE/GET/ONE/SUCCESS':
            const site = action.payload;
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    detail: { ...site},
                    requestingDetail: false,
                },
        }
        case 'SITE/GET/ONE/ERROR':
            return {
                ...state,
                sitelisting: {
                      ...state.sitelisting,
                      errors: action.payload.data,
                      requestingDetail: false,
                },
        }
        // Create one site
        case 'SITE/CREATE':
          return {
              ...state,
              sitelisting: {
                    ...state.sitelisting,
                    // requesting: true,
              },
        }
        case 'SITE/CREATE/SUCCESS':
          return {
              ...state,
              sitelisting: {
                    ...state.sitelisting,
                    data: [ action.payload,...state.sitelisting.data,  ],
                    pagination: {
                      total: state.sitelisting.pagination.total + 1
                    },
                    // requesting: false,
              },
        }
        case 'SITE/CREATE/ERROR':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
        }
        // Update one site
        case 'SITE/UPDATE/ONE':
          return {
              ...state,
              sitelisting: {
                    ...state.sitelisting,
                    // requesting: true,
              },
        }
        case 'SITE/UPDATE/ONE/SUCCESS':
          const id_edit = action.payload.id;
          const indexOfUpdatedSite = _.findIndex(state.sitelisting.data, site => site.id === id_edit)
          if(indexOfUpdatedSite !== -1){
            state.sitelisting.data.splice(indexOfUpdatedSite, 1, action.payload)
          }
          return {
            ...state,
            sitelisting: {
                ...state.sitelisting,
                data: state.sitelisting.data,
                // requesting: false,
            },
        }
        case 'SITE/UPDATE/ONE/ERROR':
            return {
                ...state,
                sitelisting: {
                    ...state.sitelisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
        }
        // Save filter value
        case 'SITE/FILTER/SAVE':
          return {
              ...state,
              sitelisting: {
                    ...state.sitelisting,
                    filter: action.payload
              },
        }
        default:
            return {
                ...state
            }
    }
}