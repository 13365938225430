import _ from 'lodash'
import React from 'react';
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';

import validate from './validation.js'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { alertInfo } from '../../utils/common.helper';
import { UserActions } from "../../actions/user.action";
import { InputFieldRedux, AutoCompleteFieldRedux, SelectFieldRedux } from "../../component/common/FieldComponent";
import { GlobalActions } from "../../actions/global.action";

class AddUser extends React.Component {
    
    constructor(props){
        super(props)
    }
     
    hideSidebar = () => {
        this.props.closeRightSidebar('add_new_user')
        this.props.resetForm();
    }
    
    handleSubmit = (values) => {
        let data = {}
        data.name = values.name
        data.email = values.email
        data.type = parseInt(values.type.value)
        this.props.createUser(data)
    }
    
    render() {
        const { handleSubmit, right_sidebar, submitting, user } = this.props
        let arr_options = [{
            label: 'Admin',
            id: '1'
        },{
            label: 'Team Member',
            id: '2'
        }]
        if(process.env.REACT_APP_MASTER_ADMIN_EMAIL == user.email){
            arr_options.push({
                label: 'Contractor',
                id: '3'
            },{
                label: 'Customer',
                id: '4'
            })
        }

        if(user.type == 3){
            arr_options = [{
                label: 'Sub Contractor',
                id: '3'
            }]
        }
        if(user.type == 4){
            arr_options = [{
                label: 'Sub Customer',
                id: '4'
            }]
        }

        return (
            <>
            {right_sidebar &&
            <div id="add-stock-detail-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh MuiDialog-papers">
                    
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Create new user</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                          
                            <form onSubmit={handleSubmit(this.handleSubmit)}>
                                
                                <Field
                                    label={'Name'}
                                    placeholder="Enter user name"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="name"
                                    component={InputFieldRedux}
                                />
                                
                                <Field
                                    label={'Email'}
                                    placeholder="Enter user email"
                                    type="email"
                                    margin="normal"
                                    variant="outlined"
                                    name="email"
                                    component={InputFieldRedux}
                                />

                                <Field
                                    label={'Role'}
                                    placeholder="Select role"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="type"
                                    component={AutoCompleteFieldRedux}
                                    className="mt-3"
                                    options={arr_options}
                                />

                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={submitting}
                                        type="submit">
                                        Save
                                    </Button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
            }
            </>
        )
    }
}

AddUser = reduxForm({
    validate,
    form: 'AddUser'
})(AddUser);

const mapStateToProps = (state, ownProps) => {
    return {
        right_sidebar  : state.global.right_sidebars['add_new_user'],
        user : state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => { dispatch(reset('AddUser')) },
    createUser: (data) => dispatch(UserActions.createUser(data)),
    closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)