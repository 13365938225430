import _ from 'lodash'

let initialValues = {
  importerOptions:{
    "":"Select",
    site: "Site",
    contractor: "Contractor",
    customer: "Customer",
    stock: "Stock"
  },
  schema:{
      data:[],
      requesting:false
  },
  modalOpen:false,
  firstRow:[],
  importerResult:{
      data:null,
      requesting:false
  },
  importerValidation:{
      errorList:[],
      error:false
  }
}

export const importerReducer = (state = initialValues, action) => {
    switch (action.type) {
        //requested selected module shema
        case 'IMPORTER/SCHEMA/GET/REQUEST':
            return {
                ...state,
                schema: {
                    ...state.schema,
                    requesting: true,
                },
        }
        //Get selected module shema
        case 'IMPORTER/SCHEMA/GET/SUCCESS':
            return {
                ...state,
                schema: {
                    ...state.schema,
                    data:['exclude',...action.payload.data],
                    requesting: false,
                },
        }

         //failed to get selected module shema
        case 'IMPORTER/SCHEMA/GET/ERROR':
            return {
                ...state,
                schema: {
                    ...state.schema,
                    requesting: false,
                },
        }

        case 'IMPORTER/TOGGLE':
            return{
                ...state,
                modalOpen: !state.modalOpen,
                importerResult:initialValues.importerResult
            }

        case 'IMPORTER/FIRST/ROW/DATA':{
            return{
                ...state,
                firstRow: action.data
            }
        }

        //requested Importer Data
        case 'IMPORTER/IMPORT/POST/REQUEST':
            return {
                ...state,
                importerResult: {
                    ...state.importerResult,
                    requesting: true,
                },
        }
        //POST Importer success
        case 'IMPORTER/IMPORT/POST/SUCCESS':
            return {
                ...state,
                importerResult: {
                    ...state.importerResult,
                    data:action.payload.data,
                    requesting: false,
                },
        }

        //importer failed
        case 'IMPORTER/IMPORT/POST/ERROR':
            return {
                ...state,
                importerResult: {
                    ...state.importerResult,
                    requesting: false,
                },
        }

        //importer required feild validation
        case 'IMPORTER/IMPORT/VALIDATION/TRUE':
            return{
                ...state,
                importerValidation: {
                    errorList: action.payload.errorList,
                    error:action.payload.error
                }
            }

        default:
            return {
                ...state
            }
    }
}