import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

function* getAllUser({ payload }){
    yield put({ type: "USER/GET/START"});
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/user',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "USER/GET/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* createUser({ payload }){
    const form_name = 'AddUser'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try{
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/user',
                method: 'POST',
                data: payload,              
            })
        });
        if(response.success){
            
            if(response.success.data.status == 201){
                alertInfo('info', 'User created successfully');
                return true;
            }

            yield put({ 
                type: "USER/ADD/SUCCESS", 
                payload: response.success.data.data
            });
            yield put({type: 'GLOBAL/CLOSE_SIDEBAR/SUCCESS', payload: 'add_new_user'})
            
            alertInfo('success', 'User created successfully')
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({ 
                type: "GLOBAL/FORM_ERROR", 
                payload: {
                    error: data.data, 
                    form_name: form_name
                } 
            });
        }
    }
}

function* deleteUser({ payload }){
  try {
        const response =  yield race({
            success: call(getResponse, {
                url:`/api/v1/user/${payload}`,
                method: 'DELETE',
            })
        });
        if(response.success){
            yield put({
                type: "USER/DELETE/SUCCESS",
                payload: {id: payload}
            });
            alertInfo('success', 'User deleted successfully')
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* updateUser({ payload }){
    const form_name = 'UpdateUser'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:`/api/v1/user/${payload.id}`,
                method: 'PUT',
                data: payload
            })
        });
        if(response.success){
            yield put({
                type: "USER/UPDATE/SUCCESS",
                payload: response.success.data.data.user
            });
            yield put({type: 'GLOBAL/CLOSE_SIDEBAR/SUCCESS', payload: 'update_user'})
            alertInfo('success', 'User updated successfully')
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch(error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error
        alertInfo('error', data.message)
        yield put({ 
            type: "GLOBAL/FORM_ERROR", 
            payload: {
                error: data.data, 
                form_name: form_name
            } 
        });
    }
}

export default function* userSaga() {
    yield all([
      takeLatest("USER/GET/REQUEST", getAllUser),
      takeLatest("USER/ADD/REQUEST", createUser),
      takeLatest("USER/DELETE/REQUEST", deleteUser),
      takeLatest("USER/UPDATE/REQUEST", updateUser)
  ]);
}