import React from 'react';
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, reset } from 'redux-form';
import { StockActions } from '../../../../../app/actions/stock.action';
import { InputFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'
import { GlobalActions } from "../../../../../app/actions/global.action";

class AddStock extends React.Component {

    hideSidebar = () => {
        this.props.closeRightSidebar('add_new_stock_group')
        // document.body.classList.remove("openRightBar");
        // this.props.toggleSidebar();
        this.props.resetForm();
    }

    handleSubmit = (data) => {
        this.props.createStock({ data, resetForm: this.hideSidebar })
    }

    render(){
      const { handleSubmit, right_sidebar, submitting, pristine, } = this.props
      return (
        <>
          {right_sidebar && 
            <div id="add-stock-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Create new</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                              label={'Stock Group Name'}
                              placeholder="Enter stock name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <div className="d-flex justify-content-end">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Save
                              </Button>
                            </div>
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}

AddStock = reduxForm({
  form: 'AddStock',
  validate,
  enableReinitialize: true,
})(AddStock);

const mapStateToProps = (state, ownProps) => {
    return {
        right_sidebar : state.global.right_sidebars['add_new_stock_group'],
    }
}

const mapDispatchToProps = (dispatch) => ({
  createStock: (data) => dispatch(StockActions.create(data)),
  toggleSidebar: () => dispatch(StockActions.toggleSidebar()),
  resetForm:()=>{dispatch(reset('AddStock'))},
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStock)
