import React, { Component } from 'react';

import { connect } from "react-redux";
import { arrayInsert, Field, reduxForm, reset } from 'redux-form';
import { Accordion, Card, Button, CardColumns } from 'react-bootstrap'
import { Tooltip, Table, TableBody, TableCell, TableContainer, TableRow, Paper, MenuItem } from '@material-ui/core';
import SVG from "react-inlinesvg";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import _ from 'lodash';
import Moment from 'react-moment';

import { templateActions } from "../../actions/template.action";
import { toAbsoluteUrl, } from "../../../_metronic/_helpers";
import UpdateTemplate from './UpdateTemplate';
import AddManualModalToggle from './AddManualModalToggle';
import { SelectMultipleStaticFieldRedux, } from '../../component/common/FieldComponent';

class Template extends Component {

	constructor(props) {
		super(props);
		const { template_set } = this.props;
		let selectedManuals = _.keys(template_set.selected_manuals);
		this.state = {
			values: [],
			uploadTemplate: null,
			selectManual: ""
		}
	}

	componentDidMount() {
		this.props.getSelectWorkType();
	}

	handleChange = (event, values, index) => {
		this.setState({ values })
	}

	_cancelSelection = () => {
		this.setState({
			values: []
		})
	}

	_templateUploadModal = (t) => {
		this.setState({
			uploadTemplate: t
		})
		this.props.uploadModalToggle({ id: t.id })
	}

	_handleSubmit = (values) => {
		let manuals = this.state.values;
		if (manuals.length === 0) {
			this.setState({ selectManual: "Oop's..!! No manual selected." })
			return false;
		} else {
			this.setState({ selectManual: '' });
			this.props.AddManualModalToggle({ id: this.props.template_set.id })
		}
	}


	_renderGroupedItems = () => {
		let { template_set, template } = this.props;
		let groupedItems;

		let selectedManuals = _.keys(template_set.selected_manuals)
		var arr = ['COVER'];

		let totalSelectedWork = 0;

		this.props.selectedWorkType.map( (workList) => {
			if(workList.checked == true) totalSelectedWork++;
		})


		this.props.selectedWorkType.filter(function (selectedWorkTypeObj) {
			if(selectedWorkTypeObj.checked || totalSelectedWork == 0 ) {
				arr.push(selectedWorkTypeObj.shortcode)
			}
		});

		var checkedWorkType = template.data.filter(function (workTypeObj) {
			return arr.includes(workTypeObj.category)
		});

		var finalWorkTypeList = checkedWorkType.length > 0 ? checkedWorkType.map(
				checkedWorkTypeObj => ({
					...checkedWorkTypeObj,
					...template.data.find(templateDataObj => templateDataObj.category === checkedWorkTypeObj.shortcode)
				})
			) : template.data

		if(totalSelectedWork == 0) {
			finalWorkTypeList = template.data;
		}

		const filteredItems = _.filter(finalWorkTypeList, (t) => {
			return !t.subtype
		})
		let groups = _.groupBy(filteredItems, 'category');

		let manualsWithoutCover = _.omit(groups, ['COVER']);
		let manualCover = _.pick(groups, ['COVER']);

		manualsWithoutCover = _(manualsWithoutCover).toPairs().sortBy(0).fromPairs().value();
		groups = _.merge(manualCover, manualsWithoutCover);

		groupedItems = _.map(groups, (group, groupName) => {

			let manualTypes = ['MAIN', 'COVER', 'MANU_SCHED'];
			let sortedGroup = _.sortBy(group, (t) => {
				return _.indexOf(manualTypes, t.type);
			});

			let check = _.indexOf(selectedManuals, groupName);
			if (check !== -1) {
				let items = sortedGroup.map((t, i) => {
					var lastupdated;
					if (t.updated_at) {
						var now = new Date(t.updated_at);
						lastupdated = now;
					}
					return (
						<TableRow key={`template-${t.title}-${i}`}>
							<TableCell className="border-0" align="left">{t.title.replace('Stairs Balistrades', 'Stairs Balustrades')}</TableCell>
							<TableCell className="border-0" align="center">
								{"Updated: "}{lastupdated ?
									<Moment format=" MMM-DD-YYYY HH:mm">
										{lastupdated}
									</Moment>
									: ''}</TableCell>
							<TableCell className="border-0" align="center">
								{t.id ?
									<a target="_blank" href={process.env.REACT_APP_API_URL + `api/v1/manual/templates/${t.id}/download`}><GetAppIcon /> Download</a>
									:
									'No Templates to download'
								}
							</TableCell>
							<TableCell className="border-0" align="right"><a href="#!" onClick={(e) => { this._templateUploadModal(t) }}><PublishIcon />{"Upload"}</a></TableCell>
						</TableRow>
					);
				});
				let groupNameText = '';
				let iconClass = '';
				switch (groupName) {
					case 'COVER':
						groupNameText = 'Front Page';
						iconClass = 'fa fa-file';
						break;
					case 'AUDIO_VISUAL':
						groupNameText = 'Audio Visual';
						iconClass = 'fa fa-volume-up';
						break;
					case 'ELECTRICAL':
						groupNameText = 'Electrical';
						iconClass = 'fa fa-plug';
						break;
					case 'FIRE_DETECTION':
						groupNameText = 'Fire Detection';
						iconClass = 'fa fa-fire';
						break;
					case 'MECHANICAL':
						groupNameText = 'Mechanical';
						iconClass = 'fa fa-wrench';
						break;
					case 'SECURITY':
						groupNameText = 'Security';
						iconClass = 'fa fa-shield-alt';
						break;
					case 'VOICE_DATA':
						groupNameText = 'Voice Data'
						iconClass = 'fa fa-phone-volume';
						break;
					case 'FLOORING':
						groupNameText = 'Flooring';
						iconClass = 'fa fa-th';
						break;
					case 'OTHER_WORKS':
						groupNameText = 'Other Works';
						iconClass = 'fa fa-briefcase';
						break;
					case 'ROOFING':
						groupNameText = 'Roofing';
						iconClass = 'fa fa-hotel';
						break;
					case 'HVAC':
						groupNameText = 'HVAC'
						iconClass = 'fa fa-fan';
						break;
					case 'RENEWABLES':
						groupNameText = 'Renewables'
						iconClass = 'fa fa-refresh';
						break;
					case 'CARPENTRY':
						groupNameText = 'Carpentry'
						iconClass = 'fa fa-hammer';
						break;
					case 'CCTV':
						groupNameText = 'CCTV'
						iconClass = 'fa fa-video-camera';
						break;
					case 'CEILINGS':
						groupNameText = 'Ceilings'
						iconClass = 'fa fa-lightbulb-o';
						break;
					case 'DRYLINING_PLASTERWORK':
						groupNameText = 'Drylining and Plasterwork'
						iconClass = 'fa fa-home';
						break;
					case 'FIRE_STOPPING':
						groupNameText = 'Fire Stopping'
						iconClass = 'fa fa-fire-extinguisher';
						break;
					case 'GAS_OIL':
						groupNameText = 'Gas and Oil'
						iconClass = 'fa fa-fire';
						break;
					case 'GLASS_GLAZING':
						groupNameText = 'Glass and Glazing'
						iconClass = 'fa fa-columns';
						break;
					case 'JOINERY':
						groupNameText = 'Joinery'
						iconClass = 'fa fas fa-hammer';
						break;
					case 'KITCHENS':
						groupNameText = 'Kitchens'
						iconClass = 'fa  fa-coffee';
						break;
					case 'PLUMBING':
						groupNameText = 'Plumbing'
						iconClass = 'fa fa-bath';
						break;
					case 'STAIRS_BALUSTRADES':
						groupNameText = 'Stairs and Balustrades'
						iconClass = 'fa fa-align-justify';
						break;
					case 'STRUCTURAL_METALWORK':
						groupNameText = 'Structural Metalwork'
						iconClass = 'fa  fa-gavel';
						break;
					case 'ACCESS_CONTROL':
						groupNameText = 'Access Control'
						iconClass = 'fa fa-solid fa-box';
						break;
					case 'AIR_TESTING':
						groupNameText = 'Air Testing'
						iconClass = 'fa fa-solid fa-wind';
						break;
					case 'ALTERNATIVE_FUEL':
						groupNameText = 'Alternative Fuel'
						iconClass = 'fa fa-solid fa-gas-pump';
						break;
					case 'BALCONIES':
						groupNameText = 'Balconies'
						iconClass = 'fa fa-vihara';
						break;
					case 'BROADBAND':
						groupNameText = 'Broadband'
						iconClass = 'fa fa-solid fa-wifi';
						break;
					case 'CATERING_EQUIPMENT':
						groupNameText = 'Catering Equipment'
						iconClass = 'fa fa-solid fa-utensils';
						break;
					case 'CLADDING':
						groupNameText = 'Cladding'
						iconClass = 'fa fa-solid fa-bars';
						break;
					case 'DECORATION':
						groupNameText = 'Decoration'
						iconClass = 'fa fa-solid fa-tree';
						break;
					case 'DOORS':
						groupNameText = 'Doors'
						iconClass = 'fa fa-solid fa-door-closed';
						break;
					case 'DRAINAGE':
						groupNameText = 'Drainage'
						iconClass = 'fa fa-solid fa-tint';
						break;
					case 'EV_CHARGING':
						groupNameText = 'EV Charging'
						iconClass = 'fa fa-solid fa-charging-station';
						break;
					case 'HAZARDOUS_WASTE':
						groupNameText = 'Hazardous Waste'
						iconClass = 'fa fa-solid fa-radiation-alt';
						break;
					case 'INSULATION':
						groupNameText = 'Insulation'
						iconClass = 'fa fa-solid fa-igloo';
						break;
					case 'INTRUDER_ALARMS':
						groupNameText = 'Intruder Alarms'
						iconClass = 'fa fa-solid fa-bell';
						break;
					case 'IRONMONGERY':
						groupNameText = 'Ironmongery'
						iconClass = 'fa fa-solid fa-lock-open';
						break;
					case 'LANDSCAPING':
						groupNameText = 'Landscaping'
						iconClass = 'fa fa-solid fa-seedling';
						break;
					case 'LIFTS':
						groupNameText = 'Lifts'
						iconClass = 'fa fa-solid fa-sort';
						break;
					case 'MASONRY':
						groupNameText = 'Masonry'
						iconClass = 'fa fa-solid fa-grip-horizontal';
						break;
					case 'METAL_WORK':
						groupNameText = 'Metal Work'
						iconClass = 'fa fa-solid fa-anchor';
						break;
					case 'NATURAL_STONE':
						groupNameText = 'Natural Stone'
						iconClass = 'fa fa-solid fa-cube';
						break;
					case 'PANELLING':
						groupNameText = 'Panelling'
						iconClass = 'fa fa-solid fa-couch';
						break;
					case 'SANITARYWARE':
						groupNameText = 'Sanitaryware'
						iconClass = 'fa fa-solid fa-toilet';
						break;
					case 'SWIMMING_POOL':
						groupNameText = 'Swimming Pool'
						iconClass = 'fa fa-solid fa-swimmer';
						break;
					case 'WALL_FINISHES':
						groupNameText = 'Wall Finishes'
						iconClass = 'fa fa-solid fa-paint-roller';
						break;
					case 'WATER_FEATURE':
						groupNameText = 'Water Feature'
						iconClass = 'fa fa-solid fa-water';
						break;
					case 'WATERPROOFING':
						groupNameText = 'Waterproofing'
						iconClass = 'fa fa-hand-holding-water';
						break;
					case 'WINDOWS':
						groupNameText = 'Windows'
						iconClass = 'fa fa-solid fa-columns';
						break;
					
					case 'BMS':
						groupNameText = 'BMS'
						iconClass = 'fa fa-solid fa-tasks';
						break;
					case 'BUILDING_FABRIC':
						groupNameText = 'Building Fabric'
						iconClass = 'fa fa-solid fa-building';
						break;
					case 'HEALTH_AND_SAFETY':
						groupNameText = 'Health and Safety'
						iconClass = 'fa fa-solid fa-medkit';//head-side-mask
						break;
					case '-PROJECT_INFO':
						groupNameText = '-Project Info'
						iconClass = 'fa fa-solid fa-file-alt';
						break;
					case 'SAFETY':
						groupNameText = 'Safety'
						iconClass = 'fa fa-solid fa-hard-hat';
						break;
					case 'STRUCTURAL':
						groupNameText = 'Structural'
						iconClass = 'fa fa-solid fa-layer-group';
						break;
					case 'UTILITIES':
						groupNameText = 'Utilities'
						iconClass = 'fa fa-solid fa-bolt';
						break;
					case 'TILING':
						groupNameText = 'Tiling'
						iconClass = 'fa fa-solid fa-clone';
						break;
					case 'VERTICAL_TRANSPORTATION':
						groupNameText = 'Vertical Transportation'
						iconClass = 'fa fa-grip-lines-vertical';
						break;
					case 'WATER_SERVICES':
						groupNameText = 'Water Services'
						iconClass = 'fa fa-solid fa-faucet';
						break;
					default:
						break;
				}
				return (
					<Card key={`${groupName}`} eventKey={`template-group-${groupName}`}>
						<Card.Header className="d-flex align-items-center justify-content-between inner_card_header">
							<Accordion.Toggle as={Button} variant="link" eventKey={`template-group-${groupName}`}>
								<span><i className={iconClass}></i>  {groupNameText}</span>
								<i className="icon add_button ki ki-plus"></i>
							</Accordion.Toggle>
							<div>
							</div>
						</Card.Header>
						<Accordion.Collapse eventKey={`template-group-${groupName}`} className="inner_table_wrap">
							<Table>
								<TableBody>
									{items}
								</TableBody>
							</Table>
						</Accordion.Collapse>
					</Card>
				);
			}

		});
		return groupedItems;
	}

	_getManualOptions = () => {
		const { workType: { data }, template, template_set } = this.props;
		const manualOptions = [];
		let selectedManuals = _.keys(template_set.selected_manuals);

		let groups = _.groupBy(template.data, 'category');
		let allManuals = _.keys(groups);
		let newManuals = _.differenceWith(allManuals, selectedManuals, _.isEqual);
		newManuals = newManuals.sort();
		if (!newManuals) {
			return null;
		}
		for (let i in data) {
			if (_.indexOf(newManuals, data[i].shortcode) > -1) {
				manualOptions.push(
					<MenuItem
						key={`manual-${i}`}
						checked={this.state.values && this.state.values.indexOf(data[i].id) > -1}
						value={data[i].shortcode}>{data[i].title}</MenuItem>
				)
			}
		}
		return manualOptions;
	}

	render() {
		let groupedItems = null;
		if (this.props.template && this.props.template.data && this.props.template.data.length > 0) {
			groupedItems = this._renderGroupedItems();
		} else {
			groupedItems = 'No templates found for that company.';
		}

		const values = this.state.values ? this.state.values : [];
		let menuItem = this._getManualOptions();
		const { handleSubmit, submitting, pristine } = this.props
		return (
			<Accordion className="accordion_custom_style">
				{groupedItems}
				{/* remove thise */}
				{/* <div className="col-md-7 mt-2">
					<Field
						name="work_type"
						label="Select new templates"
						id="select-module"
						variant="outlined"
						multiple={true}
						values={values}
						onChange={this.handleChange}
						component={SelectMultipleStaticFieldRedux}
					>
						{menuItem}
					</Field>
					<button
						onClick={handleSubmit(this._handleSubmit)}
						disabled={submitting || pristine}
						className="btn btn-primary font-weight-bold px-9 py-4 my-3 pull-right"
					>
						<span>{submitting === true ? "Submitting.." : "Submit"}</span>
					</button>
					<p style={{ color: 'red', padding: "10px 0px" }}>{this.state.selectManual}</p>
				</div> */}
				{this.props.showUpdateForm && <UpdateTemplate uploadTemplate={this.state.uploadTemplate} />}
				{this.props.showManualModal && this.props.showManualModalid == this.props.template_set.id && <AddManualModalToggle manualaToBeAdded={[this.state.values]} set={this.props.template_set} cancelSelection={this._cancelSelection} />}
			</Accordion>
		);
	}
}

Template = reduxForm({
	form: 'Template',
	enableReinitialize: true
})(Template);

const mapStateToProps = (state, ownProps) => {
	const { templates: { workType } } = state;
	let template = { ...state.templates.templates }
	let template_items = template.data || []
	let templateProps = []
	for (let i in ownProps.template_set.templates) {
		templateProps.push(ownProps.template_set.templates[i])
	}
	template.data = _.filter(template_items, function (o) {
		return _.indexOf(templateProps, o.id) > -1;
	});
	return {
		showUpdateForm: template.showUpdateForm,
		showManualModal: template.showManualModal,
		showManualModalid: template.showManualModalid,
		template,
		// template_set,
		workType,
		selectedWorkType: state.templates.templates.selectedData

	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	uploadModalToggle: (data) => { dispatch({ type: "TEMPLATES/UPDATETEMPLATE/TOGGLE", payload: { data } }) },
	AddManualModalToggle: (data) => { dispatch({ type: "TEMPLATES/ADDMANAUAL/TOGGLE", payload: { data } }) },
	createtemplate_set: (data) => { dispatch(templateActions.createtemplate_set(data)) },
	resetForm: () => { dispatch(reset('Template')) },
	getSelectWorkType: (data) => dispatch(templateActions.getSelectWorkType(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Template);