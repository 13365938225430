import React from 'react';
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, reset, } from 'redux-form';
import { CustomerActions } from '../../../../../app/actions/customer.action';
import { InputFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'
import { GlobalActions } from "../../../../../app/actions/global.action";
import Ripples from 'react-ripples'

class UpdateCustomer extends React.Component {

    hideSidebar = () => {
        this.props.closeRightSidebar('update_customer')
        // document.body.classList.remove("openRightBar");
        // document.getElementById('update-customer-sidebar').style.display = 'none';
        this.props.resetForm();
    }

    handleSubmit = (data) => {
      data = { ...data, ...{ id : (this.props.customer_detail ? this.props.customer_detail.id : 0) }}
      this.props.updateCustomer({data, resetForm: this.hideSidebar});
      // this.props.dispatch(reset('UpdateCustomer'));
      // this.hideSidebar();
    }

    render(){
      const { handleSubmit, right_sidebar, submitting, pristine, requesting } = this.props
      return (
        <>
          {right_sidebar && 
            <div id="update-customer-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Update Customer</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                        {requesting ? <div class="spinner"></div> :
                        <>
                          <Field
                              label={'Customer Name'}
                              placeholder="Enter customer name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Email'}
                              placeholder="Enter email"
                              type="email"
                              margin="normal"
                              variant="outlined"
                              name="email"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 1'}
                              placeholder="Enter address line 1"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_1"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 2'}
                              placeholder="Enter address line 2"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_2"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'City'}
                              placeholder="Enter city"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'County'}
                              placeholder="Enter county"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="county"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Postcode'}
                              placeholder="Enter postcode"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="postcode"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Reference'}
                              placeholder="Enter reference"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="reference"
                              component={InputFieldRedux}
                              className="mb-4 "
                            />
                            <div className="d-flex justify-content-end">
                              <Ripples color="rgba(0,0,0,0.2)"> 
                                <Button 
                                  variant="contained" 
                                  color="primary"
                                  size="large"
                                  className="m-0"
                                  disabled={submitting || pristine } 
                                  type="submit">
                                    Update
                                </Button>
                              </Ripples>  
                            </div>
                        </>}
                        
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}

UpdateCustomer = reduxForm({
  form: 'UpdateCustomer',
  validate,
  enableReinitialize: true
})(UpdateCustomer);

UpdateCustomer = connect(state => {
  const { detail } = state.customer.customerlisting
  let initialValues = {};
  initialValues = {
    title: detail.label,
    address_line_1: detail.address_line_1,
    address_line_2: detail.address_line_2,
    city: detail.city,
    county: detail.county,
    postcode: detail.postcode,
  };
  if(detail.email){
    initialValues.email = detail.email;
  }
  if(detail.reference){
    initialValues.reference = detail.reference;
  }
  return {
    right_sidebar: state.global.right_sidebars['update_customer'],
    initialValues
  }
},
)(UpdateCustomer)

const mapStateToProps = (state, ownProps) => {
  const { customerlisting: { detail,requestingDetail } } = state.customer;
  return {
    customer_detail: detail,
    requesting: requestingDetail
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCustomer: (id, values) => dispatch(CustomerActions.updateOne(id, values)),
  resetForm:()=>{dispatch(reset('UpdateCustomer'))},
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomer)
