import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import Toggle from 'material-ui/Toggle';
// import Checkbox from 'material-ui/Checkbox';
// import RaisedButton from 'material-ui/RaisedButton';
// import CircularProgress from 'material-ui/CircularProgress';
import { Form, Button } from 'react-bootstrap';
import { injectStripe } from 'react-stripe-elements';
import Iframe from 'react-iframe'
import { CardNumberElement, CardCVCElement, CardExpiryElement, CardElement } from 'react-stripe-elements';
import queryString from 'query-string';
import _ from 'lodash';
import { alertInfo } from '../../../../src/app/utils/common.helper';

import { SubscriptionActions } from "../../../app/actions/subscription.action";
import ConfirmationPopup from '../../../app/component/common/ConfirmationPopup'
// import * as actions from '../../../Actions/payment';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

class UpdatePlan extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            openPop:false,
            popupMessage:"",
            popHeading:"",
            termCheck: false
        }
    }

    _alertPopUp = (heading, message) => {
        this.props.setPopMessage(message);
        this.props.setPopHeading(heading);
        this.props.failedPopUp();
    }

    _updatePlan = () =>{
        this.props.updatePlan({subscription_id: this.props.selected_subscription});
    }
    _attemptConfirmation = () =>{
        let that = this;
        if(this.props.client_secret_for_auth.payment_intent_client_secret && !this.props.three_d_secure_processing){
            this.props.toggle3dSecureLoading(); //toggle flag to 3d processing
            this.props.stripe
            .confirmCardPayment(this.props.client_secret_for_auth.payment_intent_client_secret, {
                // payment_method: {
                // // card: cardElement,
                // billing_details: {
                //     name: customerName,
                // },
                // },
                setup_future_usage: 'off_session',
            })
            .then(function(result) {
                that.props.createPaymentIntentReset();
                if(result.error){
                    that.setState({
                        popHeading: "Payment Failed",
                        popupMessage: result.error.message,
                        openPop: true
                    });
                }else{
                    that.props.paymentSuccess(that.props.selected_subscription)
                    alertInfo('success', that.props.payment_detail_success_message);

                }
                that.props.toggle3dSecureLoading(); //toggle flag to 3d processing end
                // that.props.getCurrentPlan();
                if (!result.error) {
                    window.location.reload();                    
                }
            });
        }

    }

    _toggleTermCheck = () =>{
        this.setState({
          termCheck: !this.state.termCheck
        })  
      }

    render() {
        if(this.props.client_secret_for_auth){
            this._attemptConfirmation();
        }
        const { selected_subscription, planData, taxData, user, pre_selected_plan } = this.props;
        return(
            <>
            <Button  disabled={ (selected_subscription == pre_selected_plan) || this.props.payment_detail_requesting || (this.props.client_secret_for_auth && true) || !this.state.termCheck } className="d-block bg-green border-green full-width" type="submit" onClick={this._updatePlan}>
                Update Plan
            </Button>
            <div className="form-group d-flex mt-4">
                <label className="checkbox mr-2">
                    <input
                    onClick={this._toggleTermCheck}
                    type="checkbox"
                    name="acceptTerms"
                    className="mb-0"/>
                            <span className="ml-2" />
                </label>
                <span>Please select <a target="_blank" href="https://www.dokkit.co.uk/termsofservice/">terms of service.</a></span>
            </div>
            <ConfirmationPopup
                heading={this.state.popHeading}
                message={this.state.popupMessage}
                yesButtonText="ok"
                isOpen={this.state.openPop}
                onClickYes={()=>{
                    this.setState({
                        openPop: false
                    })
                }}
            />
            </>
        )
        
    }
}

const mapStateToProps = (state, props) => {
    const { selected_subscription } = state.subcription;
    return {
        signUp: state.signup,
        auth: state.auth.user,
        selected_subscription,
        pre_selected_plan: state.subcription.pre_selected_plan, 
        payment_detail_requesting: state.subcription.payment_detail_requesting,
        client_secret_for_auth: state.subcription.client_secret_for_auth,
        payment_detail_success_message: state.subcription.payment_detail_success_message,
        three_d_secure_processing : state.subcription && state.subcription.three_d_secure_processing
    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    createPaymentIntentReset: () => dispatch(SubscriptionActions.createPaymentIntentReset()),
    updatePlan: (values) => dispatch(SubscriptionActions.updatePlan(values)),
    paymentSuccess: (values) => dispatch({type:"SUBSCRIPTION/CURRENT/PAYMENTSUCCESS", payload:{subscription_id:values}}),
    toggle3dSecureLoading: () => dispatch({type: 'SUBSCRIPTION/THREE_D_SECURE/TOGGLE'})
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectStripe(UpdatePlan));