import _ from 'lodash'
import React from 'react';
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';

import validate from './validation.js'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { alertInfo } from '../../utils/common.helper';
import { UserActions } from "../../actions/user.action";
import { InputFieldRedux, AutoCompleteFieldRedux } from "../../component/common/FieldComponent";
import { GlobalActions } from "../../actions/global.action";

class UpdateUser extends React.Component {
    
    constructor(props){
        super(props)
    }
     
    hideSidebar = () => {
        this.props.closeRightSidebar('update_user')
        this.props.resetForm();
    }
    
    handleSubmit = (values) => {
        let data = {}
        data.id = this.props.userDetails._id
        data.full_name = values.name
        data.email = values.email
        data.type = parseInt(values.type.value)
        this.props.updateUser(data)
    }
    
    render() {
        const { handleSubmit, right_sidebar, submitting, user, current_user_type } = this.props
        let arr_options = [{
            label: 'Admin',
            id: '1'
        },{
            label: 'Team Member',
            id: '2'
        }]
        if(process.env.REACT_APP_MASTER_ADMIN_EMAIL == user.email){
            arr_options.push({
                label: 'Contractor',
                id: '3'
            },{
                label: 'Customer',
                id: '4'
            })
        }
        if(user.type == 3 || current_user_type == 5){
            arr_options = [{
                label: 'Sub Contractor',
                id: '3'
            }]
        }
        if(user.type == 4 || current_user_type == 6){
            arr_options = [{
                label: 'Sub Customer',
                id: '4'
            }]
        }

        return (
            <>
            {right_sidebar &&
            <div id="add-stock-detail-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh MuiDialog-papers">
                    
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Update user</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                          
                            <form onSubmit={handleSubmit(this.handleSubmit)}>
                                
                                <Field
                                    label={'Name'}
                                    placeholder="Enter user name"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="name"
                                    component={InputFieldRedux}
                                />
                                
                                <Field
                                    label={'Email'}
                                    placeholder="Enter user email"
                                    type="email"
                                    margin="normal"
                                    variant="outlined"
                                    name="email"
                                    disabled={true}
                                    component={InputFieldRedux}
                                />

                                <Field
                                    label={'Role'}
                                    placeholder="Select role"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="type"
                                    component={AutoCompleteFieldRedux}
                                    className="mt-3"
                                    options={arr_options}
                                />

                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={submitting}
                                        type="submit">
                                        Update
                                    </Button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
            }
            </>
        )
    }
}

UpdateUser = reduxForm({
    validate,
    form: 'UpdateUser',
    enableReinitialize: true,
})(UpdateUser);

const selector = formValueSelector('UpdateUser');

const mapStateToProps = (state, ownProps) => {
    let user_type = {label: 'Admin', value: '1'};
    // console.log("state.user.userDetails", state.user.userDetails.type);
    switch(state.user.userDetails.type){
        case 1: user_type = {label: 'Admin', value: '1'};
        break;
        case 2: user_type = {label: 'Team Member', value: '2'};
        break;
        case 3: user_type = {label: 'Contractor', value: '3'};
        break;
        case 4: user_type = {label: 'Customer', value: '4'};
        break;
        default: user_type = {label: 'Admin', value: '1'}
    }
    let current_user_type = state.user.userDetails.type;
    if(state.user.userDetails.type == 3 && state.user.userDetails.parent_email){
        user_type = {
            label: 'Sub Contractor',
            value: '3'
        };
        current_user_type = 5;
    }
    if(state.user.userDetails.type == 4 && state.user.userDetails.parent_email){
        user_type = {
            label: 'Sub Customer',
            value: '4'
        };
        current_user_type = 6;
    }
    return {
        user: state.auth.user,
        current_user_type:current_user_type,
        userDetails: state.user.userDetails,
        right_sidebar  : state.global.right_sidebars['update_user'],
        initialValues: {
            name: state.user.userDetails.name,
            email: state.user.userDetails.email,
            // type: state.user.userDetails.type == 1 ? {label: 'Admin', value: '1'} : {label: 'Team Member', value: '2'}
            type: user_type

        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => { dispatch(reset('UpdateUser')) },
    updateUser: (data) => dispatch(UserActions.updateUser(data)),
    closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser)