import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Hidden } from "@material-ui/core";
import _ from 'lodash';

import { SubscriptionActions } from "../../../app/actions/subscription.action";
import history from "../../../app/utils/history.helper"

class PlanListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            selectedPlan: ""
        }
    }

    componentDidMount = () => {
        this.props.planListing();
        // this.props.getCurrentPlan();
    }

    handleChecked = (event) => {
        this.setState({
            checked: !this.state.checked
        })
        this.props.togglePriority();
        // this.props.dispatch({type:'SUBSCRIPTION/CHANGE/SUBSCRIPTION/TYPE'})
    }

    handleSelectPlan = (plan) => {
        this.setState({
            selectedPlan: plan._id
        })
        this.props.selectedSubscription(plan._id);
    }

    render() {
        const { planData, taxData, requesting, selected_id, selected_priority } = this.props;
        let yearlyOrder = ['Business Starter Yearly', 'Business Essential Yearly', 'Business Enterprise Yearly'];
        let monthlyOrder = ['Business Starter Monthly', 'Business Essential Monthly', 'Business Enterprise Monthly'];

        let monthly = planData.filter(subs => (subs.duration_type === "MONTHLY"))
        let yearly = planData.filter(subs => (subs.duration_type === "YEARLY"))

        yearly = _.sortBy(yearly, (t) => {
            return _.indexOf(yearlyOrder, t.title);
        });

        monthly = _.sortBy(monthly, (t) => {
            return _.indexOf(monthlyOrder, t.title);
        });

        let monthlyPlan = monthly.map((month, index) => {
            return (
                month.type >= 9 && <li className={`mt-2`} key={index}>
                    <input type="radio" name="plan" className="plan_check" onClick={() => this.handleSelectPlan(month)} defaultChecked={month._id == selected_id } />
                    <div className="plan_list_item">
                        <h4>{month.title}<small>{`${month.allowance} projects`}</small> </h4>
                        <div className='price-tag-wrap'>
                            <h2 className="d-flex aligin-items-center justify-content-center">
                                <span>{month.price}<small>per month</small>
                                </span>
                            </h2>
                        </div>
                        {/* <h2 className="d-flex aligin-items-center justify-content-center">
                            <small>{(monthly[index+1]) ? index+1 : "unlimited"} User</small>
                        </h2> */}
                        <Link className="btn btn-primary bg-transparent border-primary text-primary d-block" to="/#">
                            Select
                    </Link>
                    </div>
                </li>
            );
        })

        let enterprisePlan = (
             (
                <li className={`mt-2`} key="monthly-contact">
                    <div className="plan_list_item">
                        <h4>Business Enterprise<small>12+ projects</small> </h4>
                        <div className='price-tag-wrap'>
                            <p className="d-flex aligin-items-center justify-content-center">
                                For more than 12 projects per month please contact us for a bespoke quotation
                            </p>
                        </div>
                        
                        <Link className="btn btn-primary bg-transparent border-primary text-primary d-block" to="#" target="_blank" rel="noopener noreferrer" onClick={()=> window.location.href='mailto:info@dokkit.co.uk'}>
                            Contact Us
                        </Link>
                    </div>
                </li>
            )
        );


        let yearlyPlan = yearly.map((year, index) => {
            return (
                year.type >= 9 && <li className="mt-2" key={index}>
                    <input type="radio" name="plan" className="plan_check" onClick={() => this.handleSelectPlan(year)} defaultChecked={year._id == selected_id } />
                    <div className="plan_list_item">
                        <h4>{year.title}<small>{`${year.allowance} projects`}</small> </h4>
                        <div className='price-tag-wrap'>
                            <h2 className="d-flex aligin-items-center justify-content-center">
                                <span>{year.price}<small>per year</small>
                                </span>
                            </h2>
                        </div>
                        <Link className="btn btn-primary bg-transparent border-primary text-primary d-block" to="/#" >
                            Select
                        </Link>
                    </div>
                </li>
            );
        })

        let yearlyenterprisePlan = (
            (
               <li className={`mt-2`} key="yearly-contact">
                   <div className="plan_list_item">
                       <h4>Business Enterprise<small>144+ projects</small> </h4>
                       <div className='price-tag-wrap'>
                        <p className="d-flex aligin-items-center justify-content-center">
                                For more than 144 projects per year please contact us for a bespoke quotation
                        </p>
                       </div>
                       
                       <Link className="btn btn-primary bg-transparent border-primary text-primary d-block" to="#" target="_blank" rel="noopener noreferrer" onClick={()=> window.location.href='mailto:info@dokkit.co.uk'}>
                           Contact Us
                       </Link>
                   </div>
               </li>
           )
       );
        if (planData && planData[0]) {
            const selectedPlanDetail = planData[0];
            let basePrice = selectedPlanDetail.price;
            let basePriceThousandPlace = basePrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let taxApplied = 0;
            taxData.forEach((tax) => {
                taxApplied += parseFloat(basePrice * tax.percentage / 100);
            });
            let taxAppliedThousandPlace = taxApplied.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //converted to thousand place

            let totalAmoutIncVat = 0;
            totalAmoutIncVat = (basePrice + taxApplied).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //converted to thousand place
        }

        return (<>
            <div className="col-lg-6 col-xxl-8">
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-body border-bottom-light border-bottom p-5 d-flex justify-content-between align-items-center flex-row company-subscription-head">
                        <h3 className="m-0"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left" onClick={()=> history.goBack()}><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg></Link>Company Subscription</h3>
                    </div>
                    <div className="card-body p-5">
                        <div className="col-lg-12">
                            <p>Subscriptions are changed at the end of the billing period unless you are upgrading then they are effective immediately. For more information visit our <a href="http://www.dokkit.co.uk/Pricing" >Pricing page</a> or <a href="https://app.dokkit.co.uk/subscription-plan" >contact Support.</a></p>
                        </div>
                        <div className="d-flex justify-content-center w-100">
                            <div className="custom-checkbox_months" >
                                <input type="checkbox" className="month_yearly_checkbox" onChange={this.handleChecked} checked={!selected_priority}/>
                                <label className="yearly_checkbox_wrap">
                                    <span className="monthly" >Monthly</span>
                                    <span className="yearly" >Yearly</span>
                                </label>
                            </div>
                        </div>
                        <div className="card-body py-5 px-0 align-items-center">

                            <div>
                                    <ul className={`subscription_list_wrap m-0 p-0 ${selected_priority ? 'd-none': 'd-flex'}`}>
                                        {monthlyPlan}
                                        {enterprisePlan}
                                    </ul>
                                    <ul className={`subscription_list_wrap ${!selected_priority ? 'd-none': 'd-flex'} m-0 p-0 mt-3 `}>
                                        {yearlyPlan}
                                        {yearlyenterprisePlan}
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    planListing: () => dispatch(SubscriptionActions.getAllPlan()),
    selectedSubscription: (values) => dispatch(SubscriptionActions.selectedSubscription(values)),
    getCurrentPlan: ()=> dispatch(SubscriptionActions.getCurrentPlan()),
    togglePriority: ()=>dispatch({type:'SUBSCRIPTION/CHANGE/SUBSCRIPTION/TYPE'})
})

const mapStateToProps = (state, ownProps) => {
    const { plan: { planData, taxData, requesting, } } = state.subcription;
    return {
        planData,
        taxData,
        requesting,
        selected_id: state.subcription.selected_subscription,
        selected_priority: state.subcription.selected_priority,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanListing);