import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import moment from 'moment'
import { Form } from 'react-bootstrap'
import _ from 'lodash';
import { FormControlLabel,RadioGroup, Radio, MenuItem, FormControl, Button } from '@material-ui/core';
import { connect } from 'react-redux'
import { ProjectActions } from '../../actions/project.action';
import { templateActions } from '../../actions/template.action';
import { InputFieldCompanyInfo } from '../../../app/component/common/FieldComponent'

import { getTemplateID } from '../../utils/common.helper'

const radioButton = ({ input, meta: { touched, error }, children, ...rest }) => (
    <FormControl>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
      {touched &&
        ((error && <span style={{ color: 'red' }}>{error}</span>))
      }
    </FormControl>
  )

class ScopeOfWork extends Component {

    state = {
        installationType:null

    }

    componentDidMount = () =>{
        let { workType, templates, templateSet } = this.props;
        // console.log("yahoo", templates);   
        let selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id;

        // console.log("hhhhh");
        let template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MAIN")
        // console.log("hhhhh2");
        let workTypeObj = _.find(workType, ['id', carried_out_id]);
        this.props.getWorkType()
        this.props.getInstallationType({
            project_id: this.props.project_details.id,
            workType: workTypeObj.shortcode  
        })
    }


    handleSubmit = (values) => {
        let { workType, templates, templateSet, project_details } = this.props
        let selected_template_id = this.props.match.params.template_id
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        let carried_out_id = this.props.match.params.id
        let template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MAIN")
        values.project_id = project_details.id
        values.template_id = template_id
        values.installationTypeSelf = this.state.installationType
        values.workType = workTypeObj.shortcode;
        delete values.headers 
        this.props.saveInstallationType(values)
    }

    _toggleInstallationType = (value)=>{
        this.setState({
            installationType:value.target.value
        })
    }

    handleOnChnge = (e) => {
        // console.log("e", e.target.value);
    }

    render() {
        const { handleSubmit, submitting, companyName, installationType, user  } = this.props
        let installValue = this.state.installationType ? this.state.installationType : (installationType && installationType.toString() || "false")
        
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Installation</Form.Label><br />

                        <Field name="headers" component={radioButton}>
                            <table>
                                <tr aria-colspan={2}>
                                    <FormControlLabel value={false} disabled={user.type < 3 ?false:true} control={<Radio />} label={`System installed by ${companyName}`} onChange={this._toggleInstallationType}  checked={installValue=='false' && true}/>
                                </tr>
                                <tr>
                                    <td>
                                        <FormControlLabel value={true} disabled={user.type < 3 ?false:true} control={<Radio />} label="Existing system installed previously by " onChange={this._toggleInstallationType} checked={installValue=='true' && true}/>
                                    </td>
                                    <td>
                                        <Field
                                        type="text"
                                        name="thirdParty"
                                        title='third party'
                                        className="thirdParty"
                                        component={InputFieldCompanyInfo}
                                        onChange={this.handleOnChnge}
                                        readOnly={user.type < 3 ?false:true}
                                        />
                                    </td>
                                </tr>
                            </table>
                        </Field>
                    </Form.Group>
                    <div style={{marginTop: '20px', float: 'right'}}>
                    <button type="submit" disabled={submitting || user.type < 3 ?false:true} className="btn ml-3 btn-primary">{submitting ? 'Submitting': 'Save'}</button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

ScopeOfWork = reduxForm({
    form: 'installationTypeForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
})(ScopeOfWork);

const mapStateToProps = (state, ownProps) => {
    // console.log("state.project", state.project);
    let initialValues = {
        thirdParty : state.project.thirdParty??'third party',
    }
    return {
        user: state.auth.user,
        companyName: state.auth.user && state.auth.user.company_name,
        installationType:state.project.installationType,
        thirdParty: state.project.thirdParty,
        initialValues
    }
}

const mapDispatchToProps = (dispatch) => ({
    getWorkType: () => dispatch(templateActions.getWorkType()),
    getInstallationType: (data) => dispatch(ProjectActions.getInstallationType(data)),
    saveInstallationType: (data) => dispatch(ProjectActions.saveInstallationType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ScopeOfWork)
