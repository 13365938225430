import _ from 'lodash'

let initialValues = {
  mainservicelisting: {
    requesting: false,
    data: [],
    pagination: null,
    detail: {},
    filter: ""
  },
}

export const mainServiceReducer = (state = initialValues, action) => {
    switch (action.type) {
        //Get all main services
        case 'MAINSERVICE/GET/ALL':
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    requesting: true,
                },
        }
        case 'MAINSERVICE/GET/ALL/SUCCESS':
            return {
                ...state,
                mainservicelisting: {
                      ...state.mainservicelisting,
                      data: [ ...action.payload.data ],
                      pagination: { ...action.payload.pagination },
                      requesting: false,
                },
        }
        case 'MAINSERVICE/GET/ALL/ERROR':
            return {
                ...state,
                mainservicelisting: {
                      ...state.mainservicelisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        //Delete one main service
        case 'MAINSERVICE/DELETE/ONE':
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    requesting: true,
                },
        }
        case 'MAINSERVICE/DELETE/ONE/SUCCESS':
            const id_del = action.payload.id;
            const newMainServiceListing = _.filter(state.mainservicelisting.data, main_service => main_service.id !== id_del)
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    data: newMainServiceListing,
                    pagination: {
                      total: state.mainservicelisting.pagination.total - 1
                    },
                    requesting: false,
                },
        }
        case 'MAINSERVICE/DELETE/ONE/ERROR':
            return {
                ...state,
                mainservicelisting: {
                      ...state.mainservicelisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        // Get one main service
        case 'MAINSERVICE/GET/ONE':
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    requesting: true,
                },
        }
        case 'MAINSERVICE/GET/ONE/SUCCESS':
            const main_service = action.payload;
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    detail: { ...main_service},
                    requesting: false,
                },
        }
        case 'MAINSERVICE/GET/ONE/ERROR':
            return {
                ...state,
                mainservicelisting: {
                      ...state.mainservicelisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        // Create one mainservice
        case 'MAINSERVICE/CREATE':
          return {
              ...state,
              mainservicelisting: {
                    ...state.mainservicelisting,
                    requesting: true,
              },
        }
        case 'MAINSERVICE/CREATE/SUCCESS':
          return {
              ...state,
              mainservicelisting: {
                    ...state.mainservicelisting,
                    data: [ action.payload, ...state.mainservicelisting.data,],
                    pagination: {
                      total: state.mainservicelisting.pagination.total + 1
                    },
                    requesting: false,
              },
        }
        case 'MAINSERVICE/CREATE/ERROR':
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    errors: action.payload.data,
                    requesting: false,
                },
        }
        // Update one mainservice
        case 'MAINSERVICE/UPDATE/ONE':
          return {
              ...state,
              mainservicelisting: {
                    ...state.mainservicelisting,
                    requesting: true,
              },
        }
        case 'MAINSERVICE/UPDATE/ONE/SUCCESS':
          const id_edit = action.payload.id;
          const indexOfUpdatedMainService = _.findIndex(state.mainservicelisting.data, main_service => main_service.id === id_edit)
          if(indexOfUpdatedMainService !== -1){
            state.mainservicelisting.data.splice(indexOfUpdatedMainService, 1, action.payload)
          }
          return {
            ...state,
            mainservicelisting: {
                ...state.mainservicelisting,
                data: state.mainservicelisting.data,
                requesting: false,
            },
        }
        case 'MAINSERVICE/UPDATE/ONE/ERROR':
            return {
                ...state,
                mainservicelisting: {
                    ...state.mainservicelisting,
                    errors: action.payload.data,
                    requesting: false,
                },
        }
        default:
            return {
                ...state
            }
    }
}