import React from 'react';
import {
  Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow,
  TableSortLabel, Paper,
} from '@material-ui/core';


import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import SVG from "react-inlinesvg";
import { toAbsoluteUrl, } from "../../_helpers";
import { connect } from 'react-redux';
import { MainServiceActions } from "../../../app/actions/main_service.action";
import { Field, reduxForm, reset, change } from 'redux-form';
import { InputFieldRedux } from '../../../app/component/common/FieldComponent'

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'action', numeric: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length > 1}
              direction={order}
              onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              hideSortIcon={row.id !== 'title' || listing.length < 2}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const validate = (values) => {

  const errors = {}
  if (!values.title) {
    errors.title = 'Required Field'
  } else if (values.title.length > 25) {
    errors.title = 'Not more than 25 characters allowed'
  }
  return errors
}
class Listing extends React.Component {

  state = {
    order: '',
    orderBy: 'title',
    page: 0,
    dense: false,
    rowsPerPage: 10,
    isEditing: false,
    open: false,
    rowId: null,
  }

  componentDidMount() {
    this.props.mainServiceListing();
  }

  _toggleDeleteModal = (id) => {
    this.setState({
      open: !this.state.open,
      deleteRecord: id
    })
  }

  handleRequestSort = (e, property) => {
    let { order, orderBy, page} = this.state;
    if(!order){
      order = 'asc';
    }
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => {
      this.props.mainServiceListing({ sort: property, order: order === 'asc' ? -1 : 1, page })
    })
  }

  handleChangePage = (e, newPage) => {
    const { rowsPerPage, order, orderBy } = this.state;
    const new_page = newPage + 1;
    this.setState({ page: newPage })
    let extraFilters = {};
    if(order && orderBy){
      extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
    }
    this.props.mainServiceListing({ current_page: new_page, per_page: rowsPerPage, ...extraFilters });
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
      page: 0,
     })
    this.props.mainServiceListing({ per_page: e.target.value });
  }

  handleDelete = (id) => {
    this.props.deleteMainService(id)
  }

  handleEdit = (id, service_name) => {
    this.setState({
      isEditing: true,
      rowId: id,
    })
    this.props.dispatch(change("UpdateMainService", "title",service_name))
  }
  _closeHandleEdit = () => {
    this.setState({
      isEditing: true,
      rowId: null,
    })
  }

  handleSubmit = async (data) => {
    data.id = this.state.rowId
    await this.props.updateMainService(data);
    this._closeHandleEdit();
  }

  render() {
    const { dense, rowsPerPage, page, order, orderBy, isEditing, rowId } = this.state;
    const { listing, pagination, requesting, handleSubmit, submitting, pristine } = this.props;
    return (
      <div style={{ width: '100%', marginTop: '24px', }}>
        <Paper style={{ width: '100%', marginBottom: '16px', }}
          ref={element => {
            if (element) element.style.setProperty('box-shadow', 'none', 'important');
          }}>
          <div style={{ overflowX: 'auto' }}>
            <Table
              style={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                listing={listing}
              />
              <TableBody>
                {listing && listing.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                  </TableRow>
                )}
                {listing && listing.length > 0 && listing.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      {isEditing && rowId && rowId === row.id
                        ? <TableCell align={"left"} className="d-flex align-items-center">
                          <Field
                            label={'Main Service name'}
                            placeholder="Enter main service title"
                            type="text"
                            margin="normal"
                            variant="outlined"
                            name="title"
                            className="pb-3 pt-0 input_padding_custom mt-3 "
                            component={InputFieldRedux}
                          />
                          <span className="pl-3"> 
                            <span title="Add">
                                <CheckCircleOutlineIcon
                                  className="add text-success cursor-pointer"
                                  disabled={submitting || pristine}
                                  title="Add"
                                  onClick={handleSubmit(this.handleSubmit)}
                                  style={{ color: 'blue[500]' }}
                                />
                            </span>
                            <span title="Cancel">    
                                <CancelIcon
                                  className="cancel text-danger cursor-pointer"
                                  title="cancel"
                                  onClick={this._closeHandleEdit}
                                  style={{ color: 'red[500]' }}
                                />
                            </span>    
                          </span>
                        </TableCell>
                        : <TableCell align={"left"}>{row.service_name}</TableCell>
                      }
                      <TableCell align={"right"}>
                        <button
                          type="button"
                          className="text-primary flaticon-edit bg-transparent border-0"
                          onClick={() => this.handleEdit(row.id, row.service_name)}
                        ></button>
                        <button
                          type="button"
                          className="text-danger flaticon-delete bg-transparent border-0"
                          onClick={() => this._toggleDeleteModal(row.id)}
                        ></button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={pagination ? pagination.total : -1}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>

        {/* Delete Modal start */}

        <Dialog
          className="model-support"
          modal={false}
          contentClassName="model-top-overlay no-overlay"
          open={this.state.open}
        >
          <DialogTitle className="pb-0" id="alert-dialog-title">Delete Contractor Services?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to delete this contractor services?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this._toggleDeleteModal}
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
            >
              No
            </button>
            <button
              onClick={() => { this.handleDelete(this.state.deleteRecord); this._toggleDeleteModal() }}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3"
            >
              <span>Delete</span>
            </button>
          </DialogActions>
        </Dialog>
        {/* Delete Modal start */}
      </div>
    );
  }
}

Listing = reduxForm({
  form: 'UpdateMainService',
  validate,
  enableReinitialize: true
})(Listing);

Listing = connect(state => {
  const { detail } = state.main_service.mainservicelisting
  return {
    initialValues: {
      title: detail.title,
    }
  }
},
)(Listing)

const mapStateToProps = (state, ownProps) => {
  const { mainservicelisting: { data, pagination, requesting, } } = state.main_service;
  return {
    listing: data,
    pagination: pagination,
    requesting: requesting,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  mainServiceListing: (data) => dispatch(MainServiceActions.getAll(data)),
  deleteMainService: (data) => dispatch(MainServiceActions.deleteOne(data)),
  updateMainService: (data) => dispatch(MainServiceActions.updateOne(data)),
  getMainService: (data) => dispatch(MainServiceActions.getOne(data)),
  resetForm: () => { dispatch(reset('UpdateMainService')) }
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);