export const SubscriptionActions = {
  getAllPlan,
  selectedSubscription,
  subscribePlan,
  createPaymentIntent,
  createPaymentIntentReset,
  getCurrentPlan,
  updatePlan
}

function getAllPlan() {
  return {
    type: "SUBSCRIPTION/ALLPLAN",
    //  payload: loginData
  }
}

function selectedSubscription(data) {
  return { type: "SUBSCRIPTION/SELECTEDPLAN", payload: data }
}

function subscribePlan(data) {
  return { type: "SUBSCRIPTION/PLAN", payload: data }
}

function createPaymentIntent(data){
  return {type: "SUBSCRIPTION/CREATEPAYMENTINTENT", payload: data}
}

function createPaymentIntentReset(){
  return {type: "SUBSCRIPTION/CREATEPAYMENTINTENT/RESET"}
}

function getCurrentPlan(){
  return {type: "SUBSCRIPTION/CURRENT/PLAN/REQUEST"}
}

function updatePlan(data){
  return {type: "SUBSCRIPTION/UPDATE/PLAN/REQUEST", payload: data}
}