// import { takeEvery, takeLatest } from 'redux-saga';
import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import  history  from '../../app/utils/history.helper';
// Submit Company Details
function* insertCompanyDetails({ payload }) {
    const form_name = 'CompanyInfoForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        yield put({ type: 'COMPANY/INSERT/DEMO_PROJECT_CHECK',payload: null });
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/company-details',
                method: 'POST',
                data: payload.values,
            })
        });
        if (response.success) {
            yield put({
                type: 'COMPANY/INSERT/DETAIL/FORM/SUCCESS',
                payload: response.success.data.data
            });

            yield put({
                type: 'AUTH/UPDATE/SUCCESS',
                payload: response.success.data.data
            });
            yield put({ type: 'COMPANY/INSERT/DEMO_PROJECT_DONE',payload: null });
        }
        alertInfo('success', response.success.data.message)
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        yield put({ type: 'COMPANY/INSERT/DEMO_PROJECT_DONE',payload: null });

        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}


function* getCompanyInfo({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/company-details',
                method: 'GET'
            })
        });
        if (response.success) {
            yield put({
                type: "COMPANY/GET/DETAIL/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getCompanyAccountInfo(){
    try{
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/account-info',
                method: 'GET'
            })
        }); 
        if (response.success) {
            yield put({
                type: "COMPANY/GET/ACCOUNT/INFO/SUCCESS",
                payload: response.success.data.data
            });
        }
    }catch( error ){
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* updateCompanyAccountInfo({payload}){
    const form_name = 'AccountInfoForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/account-info',
                method: 'POST',
                data: payload,
            })
        });
        if (response.success) {
            yield put({
                type: 'COMPANY/UPDATE/ACCOUNT/INFO/SUCCESS',
                payload: response.success.data.data
            });
        }
        alertInfo('success', response.success.data.message)
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

function* cancelSubscription(){
    try{

        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/subscription/cancel',
                method: 'POST',
            })
        });
        if (response.success) {
            yield put({
                type: 'COMPANY/POST/CANCEL/SUBSCRIPTION/SUCCESS',
                payload: response.success.data.data
            });
        }
        alertInfo('success', response.success.data.message)
        history.push('/logout')

    }catch(error){
        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* submitReason(values) {
    // console.log("here is the values", values);
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/subscription/cancel/reason',
                method: 'POST',
                data: values.payload,
            })
        });
        if (response.success) {
            yield put({
                type: 'COMPANY/POST/CANCEL/SUBSCRIPTION/REASON/SUCCESS',
                payload: response.success.data.data
            });
        }
        alertInfo('success', response.success.data.message)
        history.push('/logout')

    } catch (error) {
        if (error.response) {
            let { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getTrialPeriod(){
    try{

        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/get-trial-status',
                method: 'GET'
            })
        }); 
        if (response.success) {
            yield put({
                type: "COMPANY/GET/TRIAL_PERIOD/SUCCESS",
                payload: response.success.data.data
            });
        }

    }catch(error){

    }
}

function* sendSupportEmail({ payload }) {
    const form_name = 'AsideEmailSupport'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/company/send-support-mail`,
                method: 'POST',
                data: payload
            })
        });
        if (response.success) { 
            yield put({
                type: "COMPANY/POST/SUPPORT_EMAIL/SUCCESS",
                payload: payload
            });
            yield put({
                type:"COMPANY/EMAIL/OPTION/TOGGLE"
            })
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error
        if(data){
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
        
        alertInfo('error', data.message)
    }
}

export default function* companySaga() {
    yield all([
        takeLatest("COMPANY/DETAIL/FORM", insertCompanyDetails),
        takeLatest("COMPANY/GET/DETAIL/REQUEST", getCompanyInfo),
        takeLatest("COMPANY/GET/ACCOUNT/INFO/REQUEST", getCompanyAccountInfo),
        takeLatest("COMPANY/POST/ACCOUNT/INFO/REQUEST", updateCompanyAccountInfo),
        takeLatest("COMPANY/POST/CANCEL/SUBSCRIPTION/REQUEST", cancelSubscription),
        takeLatest("COMPANY/POST/CANCEL/SUBSCRIPTION/REASON", submitReason),
        takeLatest("COMPANY/GET/TRIAL_PERIOD/REQUEST", getTrialPeriod),
        takeLatest("COMPANY/POST/SUPPORT_EMAIL/REQUEST", sendSupportEmail),
    ]);
}