import React, { Component } from 'react';
import {Tabs, Tab } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom';
import {Form } from 'react-bootstrap';
import { connect } from 'react-redux'
import _ from 'lodash'
import Listing from '../../_metronic/container/stock-detail/Listing'
import StockListing from '../component/ProjectForm/stock/StockListing';
import Application from '../../_metronic/layout/components/drag-accordion/Accordion';
import ScopeOfWork from '../component/ProjectForm/ScopeOfWork';
import InstallationType from '../component/ProjectForm/InstallationType';
import ProjectStockListing from '../component/ProjectForm/stock/ProjectStockListing';
import AttachmentFrom from '../component/ProjectForm/AttachmentFrom';
import AddStockDetail from '../../_metronic/layout/components/right-sidebar/stock-details/AddStockDetail'
import { alertInfo, getTemplateID } from '../utils/common.helper';
import { ProjectActions } from '../actions/project.action';
import { StockDetailActions } from '../actions/stock_detail.action';

class ProjectDetail extends Component {
    
    constructor(props) {
        super(props);
    }

    getAllAttachments = () =>{
        let { workType, templates, templateSet } = this.props,
        selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id,
        template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "COVER")
        this.props.getAllGroupAttachments()
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        this.props.getAllAttachments({
            project_id  : this.props.project_details.id,
            workType : workTypeObj.shortcode
        })
    }

    onSelect = (tab) => {
        if(tab == 'attachment'){
            this.getAllAttachments()
            return false
        }else{
            this.props.resetAttachmentAccordion()
        }
    }

    render() {
        let { workType, project_details, user } = this.props,
        workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        if(!project_details.id){
            this.props.history.goBack()
            return false
        }

        return (
            <div className="row">
                <div className="col-xxl-12 project_detail_page">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body border-bottom-light border-bottom d-flex justify-content-between align-items-center flex-row">
                            <h3 className="mb-0">
                                <a href="javaScript:void(0)”" onClick={()=>{this.props.history.goBack()}}><i className="icon text-primary cursor-pointer fas fa-long-arrow-alt-left mr-1"></i></a>
                                Project #{project_details.id_to_show}-{workTypeObj && workTypeObj.label}
                            </h3>
                        </div>
                        <div className="card-body pv-5">
                            <Tabs onSelect={this.onSelect} defaultActiveKey="installation" id="uncontrolled-tab-example">

                                <Tab eventKey="installation" title="InstallationType" className=" p-0 py-8">
                                   <InstallationType {...this.props}/>
                                </Tab>

                                <Tab eventKey="scope" title="Scope" className=" p-0 py-8">
                                    <ScopeOfWork {...this.props}/>
                                </Tab>

                                <Tab eventKey="attachment" title="Attachments"  className=" p-0 py-8">
                                    <AttachmentFrom {...this.props} />
                                </Tab>
                                
                                <Tab eventKey="stock" title="Stock"  className=" p-0 py-8">
                                    <ProjectStockListing {...this.props}/>
                                    <StockListing {...this.props}/>
                                    <AddStockDetail {...this.props}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        user:state.auth.user,
        workType : state.templates.workType.data,
        templateSet : state.templates.templateSet.data,
        templates : state.templates.templates.data,
        project_details: state.project.project_details 
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllGroupAttachments: () => dispatch(ProjectActions.getAllGroupAttachments()),
    getAllAttachments: (data) => dispatch(ProjectActions.getAllAttachments(data)),
    resetAttachmentAccordion: () => dispatch(StockDetailActions.resetAttachmentAccordion()),
})

export default connect(mapStateToProps,mapDispatchToProps)(ProjectDetail);
