import _ from 'lodash'
import React from 'react';
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';

import validate from './validation.js'
import { toAbsoluteUrl } from "../../../../_helpers";
import { alertInfo } from '../../../../../app/utils/common.helper';
import AddStockAttachment from './stock-attachment/AddStockAttachment'
import { StockActions } from '../../../../../app/actions/stock.action';
import { StockDetailActions } from '../../../../../app/actions/stock_detail.action';
import { InputFieldRedux, AutoCompleteFieldRedux } from "../../../../../app/component/common/FieldComponent";
import { GlobalActions } from "../../../../../app/actions/global.action";

class AddStockDetail extends React.Component {
    
    constructor(props){
        super(props)
    }
    
    componentDidMount() {
        this.props.stockListing({ per_page: 0 });
        this.props.stockAttachmentListing();
    }
    
    onSortEnd = ({oldIndex, newIndex}) => {
        this.props.attachmentAccordionDrag({oldIndex, newIndex})
    };
    
    hideSidebar = () => {
        this.props.closeRightSidebar('add_new_stock_details')
        // document.body.classList.remove("openRightBar");
        this.props.resetForm();
        // this.props.toggleSidebar();
        this.props.resetAttachmentAccordion()
    }
    
    handleSubmit = (values) => {
        const arrs = this.props.attachment_items.map((image) => ({
          ..._.pick(image, ['files']),
        }));
        let fileCount = []
        arrs.map(res => fileCount.push({count: res.files.length}) )
        let totalFileCount  = _.sumBy(fileCount, function(o) { return o.count; });

        const data = {
            stock_attachment: {
                attachments: this.props.attachment_items,
                attachment_count: totalFileCount,
            },
            fitting_accessories: values.fitting_accessories, 
            manufacturer: values.manufacturer, 
            range_part: values.range_part, 
            group_id: values.stock_id && values.stock_id.value,
            selected_stock_id: this.props.selected_stock_id
        }
        this.props.createStockDetail({data, reset:this.hideSidebar})
    }
    
    addNewStockAttachment = () => {
        let { stock_attachment_id, attachment_items } = this.props
        if(stock_attachment_id){
            let findIndex = _.findIndex(attachment_items, function(item) { return item.shortcode == stock_attachment_id.shortcode })
            if(stock_attachment_id && findIndex !== -1){
                alertInfo('error', 'Stock attachments item already exist')
                return false
            }
            let newItem = {
                files: [],
                label: stock_attachment_id.label,
                shortcode: stock_attachment_id.shortcode,
                id: stock_attachment_id.value
            }
            this.props.addNewAttachmentAccordion(newItem)
        }else{
            alertInfo('error', 'Select stock sttachments')
        }
    }

    removeStockAttachment = (data) => {
        this.props.removeAttachmentAccordion(data)
    }

    render() {
        const { handleSubmit, right_sidebar, attachment_items, submitting, pristine, stock_listing, stock_attachment_listing } = this.props
        return (
            <>
            {right_sidebar &&
            <div id="add-stock-detail-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh MuiDialog-papers">
                    
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Create new stock item</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                          
                            <form onSubmit={handleSubmit(this.handleSubmit)}>
                                
                                <Field
                                    label={'Stock Group'}
                                    name="stock_id"
                                    component={AutoCompleteFieldRedux}
                                    options={stock_listing}
                                />
                                
                                <Field
                                    label={'Manufacturer'}
                                    placeholder="Enter manufacturer name"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="manufacturer"
                                    component={InputFieldRedux}
                                />
                                
                                <Field
                                    label={'Name'}
                                    placeholder="Enter Name"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="fitting_accessories"
                                    component={InputFieldRedux}
                                />

                                <Field
                                    label={'Part #'}
                                    placeholder="Enter part"
                                    type="text"
                                    margin="normal"
                                    variant="outlined"
                                    name="range_part"
                                    component={InputFieldRedux}
                                />

                                <Field
                                    label={'Select Stock Attachments'}
                                    name="stock_attachment_id"
                                    component={AutoCompleteFieldRedux}
                                    options={stock_attachment_listing}
                                    className="mt-5"
                                />

                                <button
                                    type="button"
                                    className="add_new_btn_dotted_border mt-3 mb-5"
                                    onClick={this.addNewStockAttachment}
                                >
                                    <i className="icon flaticon2-plus"></i>Add New
                                </button>

                                <AddStockAttachment 
                                    onSortEnd={this.onSortEnd}
                                    data={attachment_items}
                                    onRemove={(item)=>this.removeStockAttachment(item)}
                                />

                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        // startIcon={<SaveIcon />}
                                        disabled={submitting || pristine}
                                        type="submit">
                                        Save
                                    </Button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
            }
            </>
        )
    }
}

AddStockDetail = reduxForm({
    validate,
    form: 'AddStockDetail',
    enableReinitialize: true,
})(AddStockDetail);

const selector = formValueSelector('AddStockDetail');

const mapStateToProps = (state, ownProps) => {
    return {
        right_sidebar           : state.global.right_sidebars['add_new_stock_details'],
        selected_stock_id       : state.stock.stocklisting.detail.id,
        stock_listing           : state.stock.stocklisting.data,
        stock_attachment_id     : selector(state, 'stock_attachment_id'),
        stock_attachment_listing: state.stock_detail.stockattachmentlisting.listing,
        attachment_items        : state.stock_detail.attachment_items || [],
        initialValues: {
            stock_id: {
                label: state.stock.stocklisting.detail && (startCase(state.stock.stocklisting.detail.label) || ""),
                value: state.stock.stocklisting.detail && (state.stock.stocklisting.detail.id || ""),
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleSidebar:  () => dispatch(StockDetailActions.toggleSidebar()),
    resetForm: () => { dispatch(reset('AddStockDetail')) },
    createStockDetail: (data) => dispatch(StockDetailActions.createStockDetail(data)),
    stockListing: (data) => dispatch(StockActions.getAll(data)),
    stockAttachmentListing: () => dispatch(StockDetailActions.getAllStockAttachments()),
    addNewAttachmentAccordion: (data) => dispatch(StockDetailActions.addNewAttachmentAccordion(data)),
    attachmentAccordionDrag: (data) => dispatch(StockDetailActions.attachmentAccordionDrag(data)),
    removeAttachmentAccordion: (data) => dispatch(StockDetailActions.removeAttachmentAccordion(data)),
    resetAttachmentAccordion: () => dispatch(StockDetailActions.resetAttachmentAccordion()),
    closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStockDetail)