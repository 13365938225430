import React, { Component } from 'react';
//Stripe Payment
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import {injectStripe} from 'react-stripe-elements';

import InjectedCheckoutForm from './paymentForm';
import { Form, Button } from 'react-bootstrap';
import {SubscriptionActions} from '../../../app/actions/subscription.action';
import UpdatePlan from './updatePaymentDeatils';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
class CardPaymentDetail extends Component {

    _updatePlan = () =>{
        this.props.updatePlan({subscription_id: this.props.selected_subscription});
    }

    // _attemptConfirmation = () =>{
    //     let that = this;
    //     // const cardElement = this.props.elements.getElement('card');
    //     // let customerName = this.props.customerName;
    //     // let customerEmail = this.props.customerEmail;
    //     // let subscriptionType = this.props.subscriptionType;
    //     // let customerUserId = this.props.customerUserId;
    //     // let customerCompanyId = this.props.customerCompanyId;
    //     if(this.props.client_secret_for_auth.payment_intent_client_secret){
    //         this.props.stripe
    //         .confirmCardPayment(this.props.client_secret_for_auth.payment_intent_client_secret, {
    //             // payment_method: {
    //             // // card: cardElement,
    //             // billing_details: {
    //             //     name: customerName,
    //             // },
    //             // },
    //             setup_future_usage: 'off_session',
    //         })
    //         .then(function(result) {
    //             that.props.createPaymentIntentReset();
    //             if(result.error){
    //                 that.setState({
    //                     popHeading: "Payment Failed",
    //                     popupMessage: result.error.message,
    //                     openPop: true
    //                 });
    //             }else{
    //                 that.props.paymentSuccess(that.props.selected_subscription)
    //             }
    //             // that.props.getCurrentPlan();
    //         });
    //     }

    // }

    render() {

        // if(this.props.client_secret_for_auth){
        //     this._attemptConfirmation();
        // }

        const { selected_subscription, planData, taxData, user, pre_selected_plan } = this.props;
        let amountToBePaid = 0, subscriptionType = 0, customerEmail = '', customerName = '';
        let customerUserId = '', customerCompanyId = '';

        if (selected_subscription && planData) {
            const selectedPlanDetail = planData.filter((subscription) => (subscription._id === selected_subscription));
            let basePrice = selectedPlanDetail[0] && selectedPlanDetail[0].price;
            subscriptionType = parseInt(selectedPlanDetail[0] && selectedPlanDetail[0].type);
            amountToBePaid = basePrice;
            taxData.forEach(tax => {
                amountToBePaid += ((basePrice / 100) * (tax.percentage));
            });
            var label = selectedPlanDetail[0] && selectedPlanDetail[0].title;
            var basePriceThousandPlace = basePrice && basePrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let taxApplied = 0;
            taxData.forEach((tax) => {
                taxApplied += basePrice && parseFloat(basePrice * tax.percentage / 100);
            });
            var taxAppliedThousandPlace = taxApplied.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //converted to thousand place

            var totalAmoutIncVat = 0;
            totalAmoutIncVat = basePrice && (basePrice + taxApplied).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //converted to thousand place
        }
        if (user) {
            customerEmail = user.email;
            customerName = user.name;
            customerUserId = user.id;
            customerCompanyId = user.company_id;
        }
        let selectedPlan = (
            <div>
                <ul className="plan-summary m-0 p-0 px-5">
                    <li className="border-bottom d-flex align-items-center justify-content-between py-4">
                        <p className="mb-0">{label ? label : 'No Plan Selected Yet'}</p>
                        <p className="mb-0">{`£${basePriceThousandPlace ? basePriceThousandPlace : 0}`}</p>
                    </li>
                    <li className="border-bottom d-flex align-items-center justify-content-between py-4">
                        <p className="mb-0">VAT</p>
                        <p className="mb-0">{`£${taxAppliedThousandPlace ? taxAppliedThousandPlace : 0}`}</p>
                    </li>
                </ul>
                <ul className="plan-summary m-0 p-0 px-5">
                    <li className="border-bottom d-flex align-items-center justify-content-between py-4">
                        <p className="mb-0"><strong>Total Amount</strong></p>
                        <p className="mb-0"><strong>{`£${totalAmoutIncVat ? totalAmoutIncVat : 0}`}</strong></p>
                    </li>
                </ul>
            </div>
        );

        return (<>
            <div className="col-lg-6 col-xxl-4">
                <div className="card card-custom mb-6">
                    <div className="card-body border-bottom-light border-bottom p-5 d-fle       x justify-content-between align-items-center flex-row">
                        <h3 className="m-0">Plan Summary</h3>
                    </div>
                    {selectedPlan}
                </div>
                <div className="card card-custom px-4 py-5">
                   
                    <StripeProvider apiKey={stripePublishableKey}>
                        <Elements>
                        {!pre_selected_plan ? 
                            <InjectedCheckoutForm
                                {...this.props}
                                amountToBePaid={amountToBePaid}
                                subscriptionType={subscriptionType}
                                customerEmail={customerEmail}
                                customerName={customerName}
                                customerUserId={customerUserId}
                                customerCompanyId={customerCompanyId}
                            />
                            : <UpdatePlan />}
                        </Elements>
                    </StripeProvider> 
                    
                </div>
            </div>
        </>);
    }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePlan: (values) => dispatch(SubscriptionActions.updatePlan(values)),
    paymentSuccess: (values) => dispatch({type:"SUBSCRIPTION/CURRENT/PAYMENTSUCCESS", payload:{subscription_id:values}})
})

const mapStateToProps = (state, ownProps) => {
    const { plan: { planData, taxData, requesting, }, selected_subscription } = state.subcription;
    const { user } = state.auth
    return {
        planData,
        taxData,
        requesting,
        selected_subscription,
        user,
        pre_selected_plan: state.subcription.pre_selected_plan, 
        payment_detail_requesting: state.subcription.payment_detail_requesting,
        client_secret_for_auth: state.subcription.client_secret_for_auth,
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardPaymentDetail);