import React, { Component } from 'react';
import { TextField } from "@material-ui/core";
import { GlobalActions } from "../../../../app/actions/global.action";
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form';
import { InputFieldRedux } from "../../../../app/component/common/FieldComponent";
import { AuthActions } from "../../../../app/actions/auth.action";

const validate = values => {
    const errors = {}

    if (!values.old_password) {
        errors.old_password = 'Old Password field is required!'
    }

    if (!values.new_password) {
        errors.new_password = 'New Password field is required!'
    } else if (!/^(?=.*\d).{6,}$/.test(values.new_password)) {
        errors.new_password = 'Must be minimum of 6 characters with one number';
    }
    if (!values.verifyPassword) {
        errors.verifyPassword = 'Verify Password field is required!'
    } else if ( values.new_password !== values.verifyPassword ) {
        errors.verifyPassword = 'Password and Verify Password does not match'
    }
    return errors
}
class ChangePassSidebar extends Component {

    constructor(props) {
        super(props);
    }
   
    hideSidebar = (event) => {
        this.props.dispatch(reset('ChangePassSidebar'))
        this.props.closeRightSidebar('change_password')
        // document.body.classList.remove("openRightBar");
    }
    handleSubmit=(values)=>{
        // e.preventDefault();
        this.props.changeUserPassword({values, hideform:this.hideSidebar})
    }

    render(){
        const { handleSubmit, submitting, pristine } = this.props
        return (
                <>
                    {this.props.right_sidebar && 
                        <div id="add-changepass-sidebar" className="right_sidebar change_pass card card-custom card-stretch gutter-b">
                            <div className="card-body scroll_y_100vh">
                                <div className="card_header d-flex align-items-center justify-content-between mb-4">
                                    <h3 className="mb-0">Change password</h3>
                                    <button onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                    <form autoComplete="off" onSubmit={handleSubmit(this.handleSubmit)}>
                                        {/* <TextField
                                        label="Old Password"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        />
                                       <TextField
                                        label="New Password"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        /> */}
                                        <Field 
                                            label={'Old password'}
                                            placeholder="Old password"
                                            type="password"
                                            name="old_password"
                                            margin="normal"
                                            variant="outlined"
                                            component={InputFieldRedux}/>
                                        <Field 
                                            label={'New password'}
                                            placeholder="New password"
                                            type="password"
                                            name="new_password"
                                            margin="normal"
                                            variant="outlined"
                                            component={InputFieldRedux}/>
                                        {/* <TextField
                                        label="Verify Password"
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        /> */}
                                        <Field 
                                            label={'Verify password'}
                                            placeholder="Verify password"
                                            type="password"
                                            name="verifyPassword"
                                            margin="normal"
                                            variant="outlined"
                                            component={InputFieldRedux}/>
                                           
                                        <div className="d-flex justify-content-end">
                                            <button
                                            type="submit"
                                            className="btn btn-primary mt-4 align-self-end"
                                            disabled={submitting || pristine} 
                                            >Update</button>
                                        </div>
                                    </form>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
        )
    }
}


ChangePassSidebar = reduxForm({
    form: 'ChangePassSidebar',
    validate,
    enableReinitialize: true
  })(ChangePassSidebar);
const mapStateToProps = (state, ownProps) => {
    return {
        right_sidebar : state.global.right_sidebars['change_password'],
    }
}

const mapDispatchToProps = (dispatch) => ({
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
  changeUserPassword: (data) => dispatch( AuthActions.changePassword(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassSidebar)