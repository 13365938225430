import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from "@material-ui/core";
import Listing from '../../_metronic/container/stock-detail/Listing'
import AddStockDetail from '../../_metronic/layout/components/right-sidebar/stock-details/AddStockDetail'
import UpdateStockDetail from '../../_metronic/layout/components/right-sidebar/stock-details/UpdateStockDetail'
import { StockDetailActions } from "../actions/stock_detail.action";
import { StockActions } from "../actions/stock.action";
import _ from 'lodash';
import { SearchComponent } from '../component/common/SearchComponent'
import { Link, Redirect } from 'react-router-dom';
import startCase from 'lodash/startCase'
import { GlobalActions } from "../actions/global.action";

class StockDetail extends Component {

  constructor(props) {
    super(props);
    this.handleChange = _.debounce(this.searchStockDetail, 800)
  }

  openSidebar = () => {
      this.props.openRightSidebar('add_new_stock_details')
      // document.getElementById('add-stock-detail-sidebar').style.display = 'block';
      // document.getElementById('update-stock-detail-sidebar').style.display = 'none';
      // document.body.classList.add("openRightBar");
      // this.props.toggleSidebar();
  }

  searchStockDetail = (e) => {
    const value = e.target.value.replace(/\s+/g, ' ').trim()
      if(this.lastSearchItem == value){
        return false;
      }
    if(value.length === 0 || value.length > 2){
      this.props.saveFilterValue(value);
      this.props.stockDetailListing({ manufacturer: value, group: this.props.location.data && this.props.location.data.id })
      this.lastSearchItem = value;
    }
  }

  clearDetail = () => {
    this.props.clearStockDetail()
  }

  render() {
    if(!this.props.location.hasOwnProperty("data")){
      return <Redirect to="/stock" />
    }
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body border-bottom-light border-bottom pv-5 d-flex justify-content-between align-items-center flex-row">
                            <h3>
                                <Link to="/stock" onClick={this.clearDetail}><i className="icon text-primary cursor-pointer fas fa-long-arrow-alt-left mr-1"></i></Link>
                                  {`Stock / ${startCase(this.props.location.data && this.props.location.data.title)}`}
                            </h3>
                            <Button onClick={this.openSidebar} type="button" className="btn btn-primary">Add New</Button>
                        </div>
                        <div className="card-body pv-5 pt-0">
                            <div className="card_header d-flex align-items-center mb-5 justify-content-between">
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                  <SearchComponent 
                                    label={"Search"}
                                    margin={"normal"}
                                    name={"search_field"}
                                    handleChange={this.handleChange}
                                    helperText={"Enter at least 3 characters to search"}
                                  />
                                </div>
                            </div>
                            <Listing group_id={this.props.location.data && this.props.location.data.id}/>
                        </div>
                    </div>
                </div>
            </div>
            <AddStockDetail /> 
            <UpdateStockDetail />
    </>);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stocklisting: { detail, } } = state.stock;
  return {
    stock: detail,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  stockDetailListing: (data) => dispatch(StockDetailActions.getAll(data)),
  saveFilterValue: (data) => dispatch(StockDetailActions.saveFilterValue(data)),
  // toggleSidebar: () => dispatch(StockDetailActions.toggleSidebar()),
  clearStockDetail: () => dispatch(StockActions.clearStockOne()),
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockDetail);
