import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { authReducer } from './auth.reducer';
import { siteReducer } from './site.reducer';
import { customerReducer } from './customer.reducer';
import { contractorReducer } from './contractor.reducer';
import { mainServiceReducer } from './main_service.reducer';
import { stockReducer } from './stock.reducer';
import { stockDetailReducer } from './stock_detail.reducer';
import { subscriptionReducer } from './subscription.reducer';
import { projectReducer } from './project.reducer';
import { templatesReducer } from './templates.reducer';
import { importerReducer } from './importer.reducer';
import { globalReducer } from './global.reducer';
import { userReducer } from './user.reducer';
import { companyReducer } from './company.reducer';

export default combineReducers({
  form: reduxFormReducer,
  auth: authReducer,
  site: siteReducer,
  customer: customerReducer,
  contractor: contractorReducer,
  main_service: mainServiceReducer,
  stock: stockReducer,
  stock_detail: stockDetailReducer,
  subcription: subscriptionReducer,
  project: projectReducer,
  templates: templatesReducer,
  importer: importerReducer,
  global: globalReducer,
  user: userReducer,
  company: companyReducer,
});

