import React from 'react';
import { connect } from 'react-redux'
import { Button,
  // Icon, FormControl, InputLabel, Select, MenuItem
   } from '@material-ui/core';
import { Field, reduxForm, reset } from 'redux-form';
import { ProjectActions } from '../../actions/project.action';
import { UserActions } from '../../actions/user.action';
//import { alertInfo } from '../../../../../../src/app/utils/common.helper';
import { InputFieldRedux } from "../../component/common/FieldComponent";
import validate from "../../../_metronic/layout/components/right-sidebar/customers/validation"
import { GlobalActions } from "../../actions/global.action";

class CustomerForm extends React.Component {

    hideSidebar = () => {
        this.props.closeRightSidebar('add_new_customer')
        // document.body.classList.remove("openRightBar");
        // this.props.toggleSidebar();
        this.props.resetForm();
    }

    handleSubmit = (data) => { 
      // setTimeout(() => {
      //   let userData = {}
      //     userData.name = data.title
      //     userData.email = data.email
      //     userData.type = 4
      //     userData.superAdmin = true
      //     this.props.createUser(userData)
      //   }, 100);
      this.props.createCustomer({data, resetForm: this.hideSidebar});
      }

    render(){
      const { handleSubmit, right_sidebar, submitting, pristine, } = this.props
      return (
        <>
          {right_sidebar &&
            <div id="add-customer-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Create new Customer</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                              label={'Customer Name'}
                              placeholder="Enter customer name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Email'}
                              placeholder="Enter email"
                              type="email"
                              margin="normal"
                              variant="outlined"
                              name="email"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 1'}
                              placeholder="Enter address line 1"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_1"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 2'}
                              placeholder="Enter address line 2"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_2"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'City'}
                              placeholder="Enter city"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'County'}
                              placeholder="Enter county"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="county"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Postcode'}
                              placeholder="Enter postcode"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="postcode"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Reference'}
                              placeholder="Enter reference"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="reference"
                              component={InputFieldRedux}
                            />
                            <div className="d-flex justify-content-end">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Save
                              </Button>
                            </div>
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}



CustomerForm = reduxForm({
  form: 'CustomerForm',
  validate,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(CustomerForm);

const mapStateToProps = (state, ownProps) => {
  return {
    right_sidebar: state.global.right_sidebars['add_new_customer']
  }
}

const mapDispatchToProps = (dispatch) => ({
  createCustomer: (values) => dispatch(ProjectActions.createCustomer(values)),
  createUser: (data) => dispatch(UserActions.createUser(data)),
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
  resetForm:()=>{dispatch(reset('CustomerForm'))}
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm)
