import React from 'react';
import { useField } from 'formik';
import { Link } from "react-router-dom";
import { TextField, InputLabel, OutlinedInput, MenuItem, FormControl, InputAdornment, IconButton, Input } from '@material-ui/core';
import { FormControlLabel, Radio, FormHelperText, Select as MaterialSelect, TextareaAutosize } from '@material-ui/core';
import Select from 'react-select';
import DatePicker, {CalendarContainer} from "react-datepicker";
import { Form } from 'react-bootstrap'
import CSVReader from 'react-csv-reader'
import startCase from 'lodash/startCase'
import moment from 'moment';

const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  if (props.className && meta.touched) {
    props.className = (meta.touched && meta.error) ? props.className + 'is-invalid' : props.className + 'is-valid';
  }
  return (
    <div className="form-group fv-plugins-icon-container">
      <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{meta.error}</div>
        </div>
      ) : null}
    </div>
  )

}

const CheckBoxTerm = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <label className="checkbox">
        <input {...field} {...props} />
                I agree the{" "}
        <Link to="/terms" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
                </Link>
                .
                <span className="ml-2" />
      </label>
      {meta.touched && meta.error ? (
        <div className="fv-plugins-message-container checkbox-error">
          <div className="fv-help-block">{meta.error}</div>
        </div>
      ) : null}
    </div>
  )

}

const InputFieldRedux = ({
  input,
  label,
  type,
  margin,
  variant,
  helperText,
  disabled,
  className,
  placeholder,
  writeOnce,
  meta: { touched, error, visited } }) => {
  if (className && touched) {
    className = (touched && error) ? className + 'is-invalid' : className + 'is-valid';
  }
  return (
    <div className="fv-plugins-icon-container">
      <TextField
        {...input}
        className={className}
        placeholder={placeholder}
        label={label}
        type={type}
        margin={margin}
        variant={variant}
        helperText={helperText}
        autoComplete="off"
        disabled={!visited && disabled}
      />
      {touched && error ?
        (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        ) : null}
    </div>
  )

}
const CheckBoxTermRedux = ({ input,
  label,
  type,
  disabled,
  className,
  meta: { touched, error } }) => {
  return (
    <div className="form-group">
      <label className="checkbox">
        <input {...input} className={className} type={type} disabled={disabled} />
                I agree the{" "}
        <a href="https://www.dokkit.co.uk/termsofservice/" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
                </a>
                .
                <span className="ml-2" />
      </label>
      {touched && error ? (
        <div className="fv-plugins-message-container checkbox-error">
          <div className="fv-help-block">{error}</div>
        </div>
      ) : null}
    </div>
  )
}

const InputFieldAuth = ({ input,
  label,
  type,
  disabled,
  className,
  placeholder,
  defaultValue,
  meta: { touched, error } }) => {
  if (className && touched) {
    className = (touched && error) ? className + 'is-invalid' : className + 'is-valid';
  }
  return (
    <div className="form-group fv-plugins-icon-container">
      <input {...input} className={className} placeholder={placeholder} type={type} autoComplete="off" disabled={disabled} />
      {touched && error ? (
        <div className="fv-plugins-message-container error">
          <div className="fv-help-block">{error}</div>
        </div>
      ) : null}
    </div>
  )

}

const TextareaAutosizeRedux = ({ input,
  rowsMax,
  rowsMin,
  placeholder,
  defaultValue,
  meta: { touched, error } }) =>{
    return(
        <div className="form-group fv-plugins-icon-container">
        <TextareaAutosize
          {...input}
          rowsMax={rowsMax}
          rowsMax={rowsMin}
          aria-label="Message"
          placeholder={placeholder}
          defaultValue={defaultValue}
          className="text-area-redux"
        />
        {touched && error ? (
          <div className="fv-plugins-message-container error">
            <div className="fv-help-block">{error}</div>
          </div>
        ) : null}
      </div>
    )
  }

const SelectFieldRedux = ({ handleChange, input, label, meta, options, disabled, id, variant, disabledLabel }) => {
  const { onChange } = input;
  const { touched, error } = meta;
  const inputValue = input.value;
  let optGroups = null;
  if (options) {
    optGroups = options.map((gitem, index) => {
      return <option
        value={gitem.id}
        key={gitem.id}>
        {gitem.title}
      </option>
    });
  }

  const _handleChange = (e) => {
    onChange(e.target.value)
    if (handleChange) {
      handleChange(e.target.value)
    }
  }
  disabled = disabled || false

  return (
    <div className="fv-plugins-icon-container">
      {label && <label>{label}</label>}
      <select className="form-control"
        onChange={_handleChange}
        disabled={disabled}
        value={inputValue}
        id={id}>
        <option disabled value="">{disabledLabel}</option>
        {optGroups}
      </select>
      {touched && error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      ) : null}
    </div>
  )
}

const AutoCompleteFieldRedux = ({
  label,
  input,
  options,
  disabled,
  meta: { touched, error },
  className
}) => {

  const formatOptionForSelect = option => ({
    label: startCase(option.label) || startCase(option.service_name),
    value: option.id,
    shortcode: option.shortcode || null
  });

  let newOptions = [];
  options.forEach(option => {
    let newOption = formatOptionForSelect(option)
    newOptions.push(newOption)
  })

  const inputValue = input.value;
  const classToAdd = className || '';
  return (
    <div className={`fv-plugins-icon-container ${disabled?'cursor-not-allowed':'cursor-pointer'}`+classToAdd} >
      {label && <label>{label}</label>}
      <Select
        // {...input}
        label={label}
        options={newOptions}
        value={inputValue}
        defaultValue={""}
        isClearable={false}
        onChange={(option) => input.onChange(option)}
        // onBlur={() => input.onBlur(inputValue)}
        isDisabled = {disabled || false}
      />
      {touched && error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      ) : null}
    </div>
  )
};

const FileFieldRedux = ({ meta: { touched, error }, input: { onChange } }) => (
  <div className="select_upload_csv">
    <div className="fv-plugins-icon-container">
      <Form.Group controlId="upload_file_csv" className="upload_custom_btn">
        <Form.Label>Select file (.csv, .txt)</Form.Label>
        <div className="pos-relative">
          <Form.Control type="file" onChange={(e) => onChange(e.target.files)} accept=".csv,.txt" />
          <span></span>
          <label className="upload_file_btn cursor-pointer" htmlFor="upload_file_csv">
            <i className="fa fa-upload"></i>
                Browse
            </label>
        </div>
      </Form.Group>
    </div>
    {touched && error ? (
      <div className="fv-plugins-message-container">
        <div className="fv-help-block">{error}</div>
      </div>
    ) : null}
  </div>
)

const SelectStaticFieldRedux = ({
  input, label, meta: { touched, error }, children, id, variant, value,
}) => (
    <>
      <FormControl variant={variant} style={{ width: '100%' }}>
        <InputLabel id={id}>{label}</InputLabel>
        <MaterialSelect
          {...input}
          labelId={id}
          id="simple-select-outlined"
          // value={value}
          onChange={input.onChange}
          label={label}
        >
          {children}
        </MaterialSelect>
      </FormControl>
      {touched && (error && <span class="fv-help-block">{error}</span>)}
    </>
  )

const FileUploadRedux = ({
  meta: { touched, error },
  input: { onChange, name },
  label,
  handleUpload,
  handleUploadStock,
  id
}) => {
  return (
    <label className="mb-0 cursor-pointer flex-grow-one">{label}
      <input
        type="file"
        className={"custom_file_input"}
        onChange={(e) => { handleUpload(e.target.files[0], name); }}
      />
    </label>)
}

const SelectMultipleStaticFieldRedux = ({
  input, label, meta: { touched, error }, children, id, variant, values,
}) => (
    <>
      <FormControl variant={variant} style={{ width: '100%', marginTop: '2%' }}>
        <InputLabel id={id}>{label}</InputLabel>
        <MaterialSelect
          {...input}
          labelId={id}
          id="simple-select-outlined"
          value={values}
          onChange={input.onChange}
          label={label}
          // multiple
        >
          {children}
        </MaterialSelect>
      </FormControl>
      {touched && (error && <span class="fv-help-block">{error}</span>)}
    </>
  )

const FileTemplateRedux = ({ meta: { touched, error }, input: { onChange, value } }) => (
  <div>
    <div className="fv-plugins-icon-container">
      <Form.Group controlId="upload_file_csv" className="upload_custom_btn">
        <Form.Label>{value ? 'Selected File' : 'Select File'}</Form.Label>
        <div className="pos-relative cursor-pointer">
          <Form.Control type="file" onChange={onChange} accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
          <span></span>
          <label
            className="upload_file_btn"
            htmlFor="upload_file_csv">
            <i className="fa fa-upload"></i>
            {value ? value[0].name : 'Upload'}
          </label>
        </div>
      </Form.Group>
    </div>
    {touched && error ? (
      <div className="fv-plugins-message-container">
        <div className="fv-help-block">{error}</div>
      </div>
    ) : null}
  </div>
)



const InputFieldCompanyInfo = ({
  input,
  value,
  label,
  margin,
  variant,
  readOnly,
  className,
  meta: { touched, error }
}) => {
  let disable;
  if (readOnly) {
    disable = {
      readOnly: true
    }
  }
  return (
    <>
      <TextField
        {...input}
        label={label}
        margin={margin}
        variant={variant}
        value={input.value}
        className={className??""}
        autoComplete="off"
        InputProps={disable}
      />
      {touched && (error && <span className="fv-help-block">{error}</span>)}
    </>
  )
}
const renderImageField = ({
  handleOnUpload,
  input: { onChange },
  meta: { touched, error }
}) => (
    <>
      <div className="upload_company_profile">
        <input
          type="file"
          onChange={(e) => onChange(e.target.files)}
          id="company_profile"
          accept="image/png, image/jpeg , image/jpg"
        />
        <label className="upload_file_btn" htmlFor="company_profile">
          <i className="fa fa-upload"></i>
          Upload Company Logo
        </label>
      </div>
      {touched &&
        ((error && <span className="fv-help-block">{error}</span>))
      }
    </>
  )

const renderSelectField = ({
  varient,
  label,
  htmlFor,
  inputLabel,
  input,
  options,
  value,
  labelWidth,
  id,
  children,
  meta: { touched, error }
}) => {
  return (
    <>

      <FormControl variant={varient} style={{ 'flex': '1' }}>
        <InputLabel htmlFor={id}>{InputLabel}</InputLabel>
        <Select
          {...input}
          value={value}
          onChange={input.onChange}
        // input={<OutlinedInput id={id} />}
        >
          {children}
        </Select>
      </FormControl>
      {touched && (error && <span className="fv-help-block">{error}</span>)}
    </>
  )
}

const renderDatePickerField = ({
  input,
  meta:{ touched, error },
  type,
  options,
  placeholder,
  minDate,
  maxDate,
  defaultValue,
  variant,
  label,
  disabled }) => {

  const inputValue = input.value;
  disabled = disabled || false

  const MyContainer = ({ className, children }) => {
    return (
      <>
        <CalendarContainer className={className}>
          <div className="datepicker-header">
            <div className="datepicker-year">{moment(inputValue || new Date()).format("YYYY")}</div>
            <div className="datepicker-day">{moment(inputValue || new Date()).format("ddd, MMM D")}</div>
          </div>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </>
    );
  };

  return (
    <>
      <div className="fv-plugins-icon-container date-picker">
      <label>{label}</label>
      <DatePicker
          selected={inputValue}
          onChange={input.onChange}
          placeholderText={placeholder}
          // dateFormat="DD/MM/YYYY"
          minDate={(minDate && minDate) || new Date()
          }
          // maxDate={(maxDate && maxDate) || new Date()}
          disabled={disabled}
          calendarContainer={MyContainer}
          // shouldCloseOnSelect={false}
        />
      </div>
      {touched && error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{error}</div>
        </div>
      ) : null}
    </>
  )

}

export {
  InputField,
  CheckBoxTerm,
  InputFieldRedux,
  CheckBoxTermRedux,
  SelectFieldRedux,
  AutoCompleteFieldRedux,
  InputFieldAuth,
  FileFieldRedux,
  SelectStaticFieldRedux,
  FileUploadRedux,
  InputFieldCompanyInfo,
  renderImageField,
  renderSelectField,
  SelectMultipleStaticFieldRedux,
  FileTemplateRedux,
  renderDatePickerField,
  TextareaAutosizeRedux
}