import { call, put, all, race, takeLatest , takeEvery} from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import  history  from '../utils/history.helper';
import { stopSubmit } from 'redux-form';

// Get Project Listing

function* getAll({ payload }) {
    try {
        yield put({
            type: "PROJECT/GET/ALL/REQUEST",
        });
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/project',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/ALL/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getContractorList({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/contractor',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/CONTRACTOR/LIST/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* getDropdownList({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/type`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/DROPDOWN/LIST/GET/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getCustomerList({payload}){
    try {
        yield put({
            type: "PROJECT/CUSTOMER/LIST/REQUEST",
        });
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/customer',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/CUSTOMER/LIST/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        yield put({
            type: "PROJECT/CUSTOMER/LIST/ERROR",
        });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getSiteList({payload}){
    try {
        yield put({
            type: "PROJECT/SITE/LIST/REQUEST",
        });
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/site',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/SITE/LIST/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        yield put({
            type: "PROJECT/SITE/LIST/ERROR",
        });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Customer
function* createCustomer({ payload }){
    const form_name = 'CustomerForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try{
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/customer',
                method: 'POST',
                data: payload.data,              
            })
        });
        if(response.success){
            yield put({ 
              type: "PROJECT/CUSTOMER/CREATE/SUCCESS", 
              payload: response.success.data.data
            });
            alertInfo('success', 'Customer created successfully')
            document.body.classList.remove("openRightBar");
            payload.resetForm();
            // yield put({ type: "PROJECT/CUSTOMER/SIDEBAR/TOGGLE", });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if(error.response){
            const { response: { data } } = error
            yield put({ 
                type: "GLOBAL/FORM_ERROR", 
                payload: {
                    error: data.data, 
                    form_name: form_name
                } 
            });
        }
    }
  }

// Create Site
function* createSite({ payload }){
    const form_name = 'SiteForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try{
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/site',
                method: 'POST',
                data: payload.newData,              
            })
        });
        if(response.success){
            yield put({ 
              type: "PROJECT/SITE/CREATE/SUCCESS", 
              payload: response.success.data.data
            });
            alertInfo('success', 'Site created successfully')
            document.body.classList.remove("openRightBar");
            payload.resetForm();
            // yield put({ type: "PROJECT/CUSTOMER/SIDEBAR/TOGGLE", });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if(error.response){
            const { response: { data } } = error
            yield put({ 
                type: "GLOBAL/FORM_ERROR", 
                payload: {
                    error: data.data, 
                    form_name: form_name
                } 
            });
        }
    }
  }



function* createProject({ payload }) {
    const form_name = 'ProjectForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/project',
                method: 'POST',
                data: payload,
            })
        });
        if (response.success) {
            // console.log("here is the project",response.success.data )
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
            history.push('/edit_project/'+response.success.data.data.id)
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}

function* getProjectDetails({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/${payload.project_id}`,
                method: 'GET'
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/SINGLE_PROJECT_DETAILS/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* updateProject({ payload }) {
    const form_name = 'ProjectForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/${payload.project_id}`,
                method: 'PUT',
                data: payload,
            })
        });
        if (response.success) {
            if (payload.hide_alert != 'hide_alert')
            {
                alertInfo('success', response.success.data.message)
            }
            yield put(stopSubmit(form_name));
            // yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
            // history.push('/projects')
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}


function* saveScopeOfWork({ payload }) {
    const form_name = 'ScopeOfWorkForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/scope/${payload.project_id}/${payload.workType}`,
                method: 'POST',
                data: payload,
            })
        });
        if (response.success) {
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}


function* getAllGroupAttachments({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/project/attachment/group',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/ALL/GROUP/ATTACHMENTS/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* saveProjectAttachment({ payload }) {
    const form_name = 'ProjectAttachmentForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/attachment/${payload.project_id}/${payload.workType}`,
                method: 'POST',
                data: payload,
            })
        });
        if (response.success) {
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}


function* getAllAttachments({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/attachment/${payload.project_id}/${payload.workType}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/ALL/ATTACHMENTS/SUCCESS",
                payload: response.success.data.data.attachment
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* getAllScopeOfWork({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/scope/${payload.project_id}/${payload.workType}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/SCOPE_OF_WORK/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* getAllStockGroup({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/stock/group',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "PROJECT/GET/ALL/STOCKS/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getAllStocksByStockGroupId({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/stock',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "PROJECT/GET/ALL/STOCKS_DETAILS/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* deleteStock({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/stock/${payload}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/DELETE/STOCKS_DETAILS/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Stock item deleted successfully')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getAllProjectStock({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/stock/${payload.project_id}/${payload.workType}`,
                method: 'GET',
                params: payload
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/ALL/PROJECT/STOCK/SUCCESS",
                payload: response.success.data
            });

            yield put({
                type: "PROJECT/GET/ALL/STOCKS_DETAILS/REQUEST",
            })
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* stockAttachment({ payload }) {
    try {
        console.log("payload=======>",payload);
        
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/stock/${payload.job_id}`,
                method: 'POST',
                data: payload
            })
        });
        if (response.success) {
            console.log("response.success.data.data", response.success.data.data);
            
            // yield put({
            //     type: "PROJECT/GET/ALL/PROJECT/STOCK/REQUEST",
            //     payload: { project_id: payload.project_id, workType: payload.workType }
            // });
            // yield put({
            //     type: "PROJECT/STOCK/ATTACHMENT/ADD_TO_PROJECT/SUCCESS",
            //     payload: response.success.data.data
            // });
            alertInfo('success', response.success.data.message)
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}


function* deleteProjectStock({ payload }) {
    // console.log("payload", payload)
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/stock/${payload.id}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/DELETE/PROJECT/STOCK/SUCCESS",
                payload: payload
            });
            alertInfo('success', 'Stock item removed')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* stockAddToProject({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/stock/${payload.project_id}/${payload.workType}`,
                method: 'POST',
                data: payload
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/ALL/PROJECT/STOCK/REQUEST",
                payload: {project_id: payload.project_id, workType:payload.workType }
            });
            yield put({
                type: "PROJECT/STOCK/ADD_TO_PROJECT/SUCCESS",
                payload: payload.selected_stocks
            });
            alertInfo('success', response.success.data.message)
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* createManual({ payload }) {
    yield put({type: "PROJECT/CREATE/MANUAL/REQUEST/START"});
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/document/${payload.project_id}`,
                method: 'POST'
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/CREATE/MANUAL/SUCCESS",
                payload: {...payload, message: response.success.data.message, pdf_key: response.success.data.data.pdf_key}
            });
        }
    } catch (error) {
        const { response: { data } } = error
        yield put({type: "PROJECT/CREATE/MANUAL/REQUEST/STOP", payload: {message: data || error}});
    }
}

function* getManualStatus({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:`/api/v1/project/manual/status/${payload.project_id}`,
                method: 'GET',
            })
        });
        if(response.success){
            yield put({
                type: "PROJECT/MANUAL/STATUS/SUCCESS",
                payload: response.success.data
             });
        }
    } catch(error) {
        if(error.response){
            const { response: { data } } = error
            yield put({type: "PROJECT/CREATE/MANUAL/STATUS/CLOSE", payload: {message: data || error}});
            alertInfo('error', data.message)
        }
    }
}

function* removeProject({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/${payload.project_id}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/REMOVE/SUCCESS",
                payload: {id: payload.project_id}
            });
            alertInfo('success', response.success.data.message)
            history.push('/projects')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* sendMail({ payload }) {
    const form_name = 'EmailToCustomer'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    yield put({type: "PROJECT/SEND/MAIL/REQUEST/START", payload});
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/document/send-mail/${payload.id}`,
                method: 'POST',
                data: payload
            })
        });
        if (response.success) { 
            yield put({
                type: "PROJECT/SEND/MAIL/REQUEST/SUCCESS",
                payload: payload
            });
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP", form_name });
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        const { response: { data } } = error
        yield put({type: "PROJECT/SEND/MAIL/REQUEST/STOP", payload});
        if(data){
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
        
        alertInfo('error', data.message)
    }
}

function* downloadFile({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/download-file`,
                method: 'POST',
                data: payload
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/DOWNLOAD/FILE/REQUEST/SUCCESS",
                payload: payload
            });
            alertInfo('success', response.success.data.message)
        }
    } catch (error) {
        const { response: { data } } = error
        alertInfo('error', data.message)
    }
}

function* getInstallationType({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/installation-type/${payload.project_id}/${payload.workType}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "PROJECT/GET/INSTALLATION_TYPE/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* saveInstallationType({ payload }) {
    const form_name = 'installationTypeForm'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/project/installation-type/${payload.project_id}/${payload.workType}`,
                method: 'POST',
                data: payload,
            })
        });
        if (response.success) {
            yield put({type: "PROJECT/UPDATE/INSTALLATION/TYPE/SUCCESS",payload: response.success.data.data});
            alertInfo('success', response.success.data.message)
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
        }
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}

export default function* ProjectSaga() {
    yield all([
        takeLatest("PROJECT/GET/ALL", getAll),
        takeLatest("PROJECT/DROPDOWN/LIST/GET", getDropdownList),
        takeLatest("PROJECT/CONTRACTOR/LIST/GET", getContractorList),
        takeLatest("PROJECT/CUSTOMER/LIST/GET", getCustomerList),
        takeLatest("PROJECT/SITE/LIST/GET", getSiteList),
        takeLatest("PROJECT/CUSTOMER/CREATE", createCustomer),
        takeLatest("PROJECT/SITE/CREATE", createSite),
        takeLatest("PROJECT/CREATE/REQUEST", createProject),
        takeLatest("PROJECT/GET/SINGLE_PROJECT_DETAILS/REQUEST", getProjectDetails),
        takeLatest("PROJECT/UPDATE/REQUEST", updateProject),
        takeLatest("PROJECT/SAVE/SCOPE_OF_WORK/REQUEST", saveScopeOfWork),
        takeLatest("PROJECT/GET/ALL/GROUP/ATTACHMENTS/REQUEST", getAllGroupAttachments),
        takeLatest("PROJECT/SAVE/GROUP/ATTACHMENTS/REQUEST", saveProjectAttachment),
        takeLatest("PROJECT/GET/ALL/ATTACHMENTS/REQUEST", getAllAttachments),
        takeLatest("PROJECT/GET/SCOPE_OF_WORK/REQUEST", getAllScopeOfWork),
        takeLatest("PROJECT/GET/ALL/STOCKS/REQUEST", getAllStockGroup),
        takeLatest("PROJECT/GET/ALL/STOCKS_DETAILS/REQUEST", getAllStocksByStockGroupId),
        takeLatest("PROJECT/DELETE/STOCKS_DETAILS/REQUEST", deleteStock),
        takeLatest("PROJECT/STOCK/ADD_TO_PROJECT/REQUEST", stockAddToProject),
        takeLatest("PROJECT/GET/ALL/PROJECT/STOCK/REQUEST", getAllProjectStock),
        takeLatest("PROJECT/DELETE/PROJECT/STOCK/REQUEST", deleteProjectStock),
        takeLatest("PROJECT/STOCK/ATTACHMENT/REQUEST", stockAttachment),
        takeLatest("PROJECT/CREATE/MANUAL/REQUEST", createManual),
        takeLatest("PROJECT/REMOVE/REQUEST", removeProject),
        takeLatest("PROJECT/SEND/MAIL/REQUEST", sendMail),
        takeLatest("PROJECT/DOWNLOAD/FILE/REQUEST", downloadFile),
        takeLatest("PROJECT/GET/INSTALLATION_TYPE/REQUEST", getInstallationType),
        takeLatest("PROJECT/SAVE/INSTALLATION_TYPE/REQUEST", saveInstallationType),
        takeLatest("PROJECT/MANUAL/STATUS/REQUEST", getManualStatus)
    ]);
}