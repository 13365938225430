import React, { Component } from 'react';
import { connect } from 'react-redux'
import { TextField , Button } from "@material-ui/core";
import Listing from '../../_metronic/container/contractor/Listing'
import AddContractor from '../../_metronic/layout/components/right-sidebar/contractors/AddContractor'
import UpdateContractor from '../../_metronic/layout/components/right-sidebar/contractors/UpdateContractor'
import { ContractorActions } from "../actions/contractor.action";
import _ from 'lodash';
import { SearchComponent } from '../component/common/SearchComponent'
import { GlobalActions } from "../actions/global.action";
import Ripples from 'react-ripples'


class Contractor extends Component {
    
  constructor(props) {
    super(props);
    this.handleChange = _.debounce(this.searchContractor, 800)
    this.lastSearchItem = "";
  }

  openSidebar = () => {
      this.props.openRightSidebar('add_new_contractor')
  }

  searchContractor = (e) => {
    const value = e.target.value.replace(/\s+/g, ' ').trim()
    if(this.lastSearchItem == value){
      return false;
    }
    if(value.length === 0 || value.length > 2){
      this.props.saveFilterValue(value);
      this.props.contractorListing({ title: value })
      this.lastSearchItem = value;
    }
  }

   render() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body border-bottom-light border-bottom pv-5 d-flex justify-content-between align-items-center flex-row">
                            <h3>Contractors</h3>
                            <Ripples color="rgba(0,0,0,0.2)"> 
                              <Button variant="contained" color="secondary" onClick={this.openSidebar}>Add New</Button>
                            </Ripples>
                        </div>
                        <div className="card-body pv-5 pt-0 pb-0">
                            <div className="card_header d-flex align-items-center mb-5 justify-content-between">
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                  <SearchComponent 
                                    label={"Search"}
                                    margin={"normal"}
                                    name={"search_field"}
                                    handleChange={this.handleChange}
                                    helperText={"Enter at least 3 characters to search"}
                                  />
                                </div>
                            </div>
                            <Listing />
                        </div>
                    </div>
                </div>
            </div>
            <AddContractor />
            <UpdateContractor />
    </>);
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  contractorListing: (data) => dispatch(ContractorActions.getAll(data)),
  saveFilterValue: (data) => dispatch(ContractorActions.saveFilterValue(data)),
  toggleSidebar: () => dispatch(ContractorActions.toggleSidebar()),
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})


export default connect(
  null,
  mapDispatchToProps
)(Contractor);
