import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

// Get all stock items
function* getAll({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/stock',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/GET/ALL/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Stock item
function* createStockDetail({ payload }) {
    const form_name = 'AddStockDetail'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/stock',
                method: 'POST',
                data: payload.data,
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/CREATE/SUCCESS",
                payload: {...response.success.data.data, selected_stock_id: payload.data.selected_stock_id}
            });

            yield put({
                type: "PROJECT/ADD/NEW/STOCKS_DETAILS/SUCCESS",
                payload: {...response.success.data.data}
            });
            alertInfo('success', 'Stock Item created successfully')
            // document.body.classList.remove("openRightBar");
            // yield put({ type: "STOCKDETAIL/SIDEBAR/TOGGLE", });
            payload.reset();
        }
        yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }

    }
}

// Delete one stock item
function* deleteOne({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/stock/${payload}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/DELETE/ONE/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Stock item deleted successfully')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Delete one attachment group
function* deleteAttachmentGroup({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/project/attachment/group/${payload}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/DELETE/ATTACHMENT/GROUP/SUCCESS",
                payload: response.success.data.data
            });
            alertInfo('success', 'Stock item deleted successfully')
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Get one Stock
function* getOne({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/stock/${payload}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/GET/ONE/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Update one Stock
function* updateStockDetail({ payload }) {
    const stock_detail_id = payload.data.stock_id;
    delete payload.data.id;
    const form_name = 'UpdateStockDetail'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `/api/v1/stock/${stock_detail_id}`,
                method: 'PUT',
                data: payload.data
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/UPDATE/ONE/SUCCESS",
                payload: {...response.success.data.data, selected_stock_id: payload.data.selected_stock_id}
            });
            alertInfo('success', 'Stock item updated successfully')
            document.body.classList.remove("openRightBar");
            yield put({ type: "STOCKDETAIL/SIDEBAR/TOGGLE", });
            yield put({ type: "GLOBAL/FORM_STOP_AND_RESET", form_name });
            payload.reset();
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
            alertInfo('error', data.message)
        }
    }
}

//Get all stock_attachments
function* getAllStockAttachments() {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/manual/section',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "STOCKDETAIL/STOCKATTACHMENT/GET/SUCCESS",
                payload: response.success.data.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

//Upload stock_attachments
function* uploadStockAttachment({ payload }) {
    try {
        //   const response =  yield race({
        //       success: call(getResponse, {
        //           url:'/api/v1/stock/attachment',
        //           method: 'POST',
        //           headers: { 'content-type': 'multipart/form-data' },
        //           data: payload
        //       })
        //   });
        //   if(response.success){
        yield put({
            type: "STOCKDETAIL/STOCKATTACHMENT/UPLOAD/SUCCESS",
            payload: payload.stockData
        });
        payload.handleUpload(payload.stockData);
        alertInfo('success', 'Stock attachment uploaded successfully')
        //   }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }

}

//Add stock_group
function* addStockGroup({ payload }) {
    try {
          const response =  yield race({
              success: call(getResponse, {
                  url:'/api/v1/project/attachment/group/add',
                  method: 'POST',
                  headers: { 'content-type': 'multipart/form-data' },
                  data: {'title':payload}
              })
          });
          if(response.success){
              const result = response.success.data.data.find(({ label }) => label === payload);
              result.label = result.label;
              result.files = [];
              console.log("All data", result);
              
              
              yield put({
                  type: "PROJECT/ADD/NEW/ATTACHMENT/GROUP/SUCCESS",
                  payload: response.success.data
              });
              yield put({
                  type: "STOCKDETAIL/ADD/NEW/ATTACHMENT/ACCORDION",
                  payload: result
              });
            // payload.handleUpload(payload);
            alertInfo('success', 'Stock group uploaded successfully')
          }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }

}

export default function* stockSaga() {
    yield all([
        takeLatest("STOCKDETAIL/GET/ALL", getAll),
        takeLatest("STOCKDETAIL/CREATE", createStockDetail),
        takeLatest("STOCKDETAIL/DELETE/ONE", deleteOne),
        takeLatest("STOCKDETAIL/GET/ONE", getOne),
        takeLatest("STOCKDETAIL/UPDATE/ONE", updateStockDetail),
        takeLatest("STOCKDETAIL/STOCKATTACHMENT/GET", getAllStockAttachments),
        takeLatest("STOCKDETAIL/STOCKATTACHMENT/UPLOAD", uploadStockAttachment),
        takeLatest("STOCKDETAIL/ADD/NEW/ATTACHMENT/GROUP", addStockGroup),
        takeLatest("STOCKDETAIL/DELETE/ATTACHMENT/GROUP", deleteAttachmentGroup),
        
    ]);
}