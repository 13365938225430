import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import moment from 'moment';
import store from './store'
import _ from 'lodash'

export const downloadPdf = (url) => {
        let response = axios({
            method: 'GET',
            url: url,
            responseType: 'blob'
        })

        let downloadedFileName = url.replace(/^.*[\\\/]/, '');
        response.then(success => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const blob = new Blob([success.data]),
            url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = downloadedFileName || "PDF_" + new Date().getTime() + ".pdf";
            a.click();
        }).catch(error => {
            // alertInfo('error', error.response.data.message || error.message)
        })
      }

export function getTemplateID(template_id, carried_out_id, workType, templates, templateSet, type){
    // console.log("hhhh in",template_id, templateSet);
    let templateSetObj  = _.find(templateSet, ['id', template_id]),
    workTypeObj         = _.find(workType, ['id', carried_out_id]);
    let templatesByCat      = _.filter(templates, template => template.category === workTypeObj.shortcode);
    // console.log("hhhh in2", templateSetObj);
    let templatesIds        = _.toArray(templateSetObj.templates),
    ids = []

    ids = _.filter(templatesIds.map(tempID => {
        return _.filter(templatesByCat, template => template.id === tempID)
    }), _.size);

    let selectedTemplateID  = _.filter(ids, temp => temp[0].type === type),
    finalTemplateID         = selectedTemplateID[0][0]
    return finalTemplateID.id
}

export async function getResponse(args) {
  const state = store.getState();
  const token = state.auth.user ? state.auth.user.token : '';
    try {
      const response = await axios({
        ...args,
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      // console.log("res error", error.response);
      if (error.response) {
        if (error.response.status === 401) {
          alertInfo('error', 'Unauthorised access');
        }
        if (error.response.status === 500) {
          // console.log("res error ErrorMesg", error.response.data.message);
          alertInfo2('error', error.response && error.response.data && error.response.data.message);
        }
      } else if (error.request) {
        alertInfo('error', 'There has been a server error please refresh the page and try again');
      } else {
        alertInfo('error', 'Oops something went wrong! please try again later');
      }
      throw error;
    }
  }

let used_toastId = uuidv4();
export function alertInfo(type, message) {
  const new_toastId = uuidv4();

  if (!toast.isActive(used_toastId)) {
    used_toastId = toast[type](message, {
      autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
      toastId: new_toastId,
    });
  }
}

export function alertInfo2(type, message) {
  const new_toastId = uuidv4();

  if (!toast.isActive(used_toastId)) {
    used_toastId = toast[type](message, {
      autoClose: false,
      position: toast.POSITION.TOP_RIGHT,
      toastId: new_toastId,
      onClose: () => window.location.reload()
    });
  }
}

export function dateFormat(date) {
	let formatedData = moment(date).local().format('LL')
	return formatedData;
}
