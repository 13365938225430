import _ from 'lodash'

let initialValues = {
    workType: {
        requesting: false,
        data: [],
    },
    templateSet: {
        requesting: false,
        showTemplateSetForm: false,
        showTemplateSetFormDelete: false,
        data: []
    },
    templates: {
        requesting: false,
        showUpdateForm: false,
        showManualModal: false,
        showUpdateFormid: null,
        showManualModalid: null,
        data: [],
        selectedData: []
    }
}

export const templatesReducer = (state = initialValues, action) => {
    switch (action.type) {
        case 'TEMPLATES/WORKTYPE/GET/REQUEST':
            return {
                ...state,
                workType: {
                    ...state.workType,
                    requesting: true,
                },
            }
        case 'TEMPLATES/WORKTYPE/GET/SUCCESS':
            return {
                ...state,
                workType: {
                    ...state.workType,
                    data: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/WORKTYPE/GET/ERROR':
            return {
                ...state,
                workType: {
                    ...state.workType,
                    requesting: false,
                },
            }

        case 'TEMPLATES/TEMPLATESET/GET/REQUEST':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    requesting: true,
                },
            }
        case 'TEMPLATES/TEMPLATESET/GET/SUCCESS':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    data: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/TEMPLATESET/GET/ERROR':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    requesting: false,
                },
            }

        case 'TEMPLATES/TEMPLATES/GET/REQUEST':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    requesting: true,
                },
            }
        case 'TEMPLATES/TEMPLATES/GET/SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    data: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/TEMPLATES/GET/ERROR':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    requesting: false,
                },
            }
        case 'TEMPLATES/TEMPLATESET/DELETE/SUCCESS':
            const id_del = action.payload.data.id;
            const newTemplateSetListing = _.filter(state.templateSet.data, templateSet => templateSet.id !== id_del)
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    data: newTemplateSetListing,
                    requesting: false,
                },
            }

        case 'TEMPLATES/TEMPLATESET/TOGGLE':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    showTemplateSetForm: !state.templateSet.showTemplateSetForm
                }
            }
        case 'TEMPLATES/TEMPLATESET/DELETE/TOGGLE':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    showTemplateSetFormDelete: !state.templateSet.showTemplateSetFormDelete
                }
            }

        case 'TEMPLATES/TEMPLATESET/CREATE/SUCCESS':
            return {
                ...state,
                templateSet: {
                    ...state.templateSet,
                    data: [...state.templateSet.data, action.payload.data.setInfo,],
                    showTemplateSetForm: !state.templateSet.showTemplateSetForm
                },
                templates: {
                    ...state.templates,
                    data: [...state.templates.data, ...action.payload.data.templates,],
                },
            }

        case 'TEMPLATES/UPDATETEMPLATE/TOGGLE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    showUpdateForm: !state.templates.showUpdateForm,
                    showUpdateFormid: action.payload && action.payload.data.id
                }
            }

        case 'TEMPLATES/ADDMANAUAL/TOGGLE':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    showManualModal: !state.templates.showManualModal,
                    showManualModalid: action.payload && action.payload.data.id
                }
            }

        case 'TEMPLATES/ADDMANAUAL/SUCCESS':
            let templateSet = [...state.templateSet.data];
            let findKey = _.findKey(templateSet, { 'id': action.payload.data.id });
            if (findKey !== undefined) {
                templateSet[findKey] = action.payload.data
            }
            return {
                ...state,
                templates: {
                    ...state.templates,
                    showManualModal: !state.templates.showManualModal
                },
                templateSet: {
                    ...state.templateSet,
                    data: templateSet

                },

            }
            case 'TEMPLATES/SELECTWORKTYPE/GET/REQUEST':
            return {
                ...state,
                workType: {
                    ...state.workType,
                    requesting: true,
                },
            }
        case 'TEMPLATES/SELECTWORKTYPE/GET/SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedData: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/SELECTATTACHMENTGROUP/GET/SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedAttachmentGroup: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/SELECTWORKTYPE/GET/ERROR':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    requesting: false,
                },
            }
        case 'TEMPLATES/ATTACHMENT/GET/SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedAttachmentGroup: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/ATTACHMENT/GET/ERROR':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    requesting: false,
                },
            }
        
        case 'TEMPLATES/ATTACHMENTGROUP/GET/SUCCESS':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    attachmentGroups: [...action.payload.data],
                    requesting: false,
                },
            }
        case 'TEMPLATES/ATTACHMENTGROUP/GET/ERROR':
            return {
                ...state,
                templates: {
                    ...state.templates,
                    requesting: false,
                },
            }

        default:
            return {
                ...state
            }
    }
}