import React, { Component } from "react";
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'; 
import { Link } from "react-router-dom";

import { AuthActions } from '../../../actions/auth.action';
import { InputFieldAuth } from "../../common/FieldComponent";


const validate = values => {
    const errors = {}
    if (!values.password) {
        errors.password = 'Password field is required!'
    } else if (!/^(?=.*\d).{6,}$/.test(values.password)) {
        errors.password = 'Must be minimum of 6 characters with one number'
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Confirm Password field is required!'
    } else if ( values.password !== values.confirmPassword ) {
        errors.confirmPassword = 'Password and Confirm Password does not match'
    }
    return errors
}

class ResetPasswordForm extends Component{
 

    render(){
        const { handleSubmit, submitting, pristine, resetPassword } = this.props
        return(
            <form
                onSubmit={handleSubmit(resetPassword)}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                {/* begin: hidden token */}
                <h3 className="font-size-h1">Reset password</h3>
                <div className="text-muted font-weight-bold">
                  Enter your new password and submit to change your password
                </div>
                <Field 
                        placeholder="Token"
                        type="hidden"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="token"
                        component={InputFieldAuth}/>
                {/* end: Password */}
                {/* begin: Password */}
                    <Field 
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="password"
                        component={InputFieldAuth}/>
                {/* end: Password */}

                {/* begin: Confirm Password */}
                    <Field 
                        placeholder="Confirm Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="confirmPassword"
                        component={InputFieldAuth}/>
                {/* end: Confirm Password */}
                <div className="form-group d-flex flex-wrap flex-center mb-0">
                <Link to="/auth">
                    <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                    Cancel
                    </button>
                </Link>
                <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={submitting||pristine}
                >
                    {submitting ? 'Submitting..': 'Submit' }
                </button>
                </div>
            </form>
        )
    }
}
ResetPasswordForm = reduxForm({
    form: 'ResetPasswordForm',
    validate,
    enableReinitialize: true
})(ResetPasswordForm);

const mapStateToProps = (state, ownProps) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
	return {
        initialValues: {
            token:token
        }
     }
}
const mapDispatchToProps = (dispatch) => ({
    resetPassword: (values)=>{dispatch(AuthActions.resetPassword({values}))}
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
