import _ from 'lodash'
import arrayMove from 'array-move';
import store from '../utils/store'

let initialValues = {
  stockdetaillisting: {
    requesting: false,
    data: [],
    pagination: null,
    detail: {},
    filter: "",
    requestingDetail: false,
  },
  isSidebarOpen: false,
  stockattachmentlisting: {
    requesting: false,
    listing: [],
  },


  stockUploadedAttachmentListing: {
    data: []
  },

  removedstockattachmentlisting: {
    data: []
  },

  attachment_items: []

}
export const stockDetailReducer = (state = initialValues, action) => {
  switch (action.type) {
    //Toggle sidebar
    case 'STOCKDETAIL/SIDEBAR/TOGGLE':
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      }
    //Get all stockdetails
    case 'STOCKDETAIL/GET/ALL':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          requesting: true,
        },
      }
    case 'STOCKDETAIL/GET/ALL/SUCCESS':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
          requesting: false,
        },
      }
    case 'STOCKDETAIL/GET/ALL/ERROR':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          errors: action.payload.data,
          requesting: false,
        },
      }
    //Delete one stockdetail
    case 'STOCKDETAIL/DELETE/ONE':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          requesting: true,
        },
      }
    case 'STOCKDETAIL/DELETE/ONE/SUCCESS':
      const id_del = action.payload.id;
      const newStockDetailListing = _.filter(state.stockdetaillisting.data, stockdetail => stockdetail.id !== id_del)
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          data: newStockDetailListing,
          pagination: {
            total: state.stockdetaillisting.pagination.total - 1
          },
          requesting: false,
        },
      }
    case 'STOCKDETAIL/DELETE/ONE/ERROR':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          errors: action.payload.data,
          requesting: false,
        },
      }
    // Get one stockdetail
    case 'STOCKDETAIL/GET/ONE':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          requestingDetail: true,
        },
      }
    case 'STOCKDETAIL/GET/ONE/SUCCESS':
      const stockdetail = action.payload;
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          detail: { ...stockdetail },
          requestingDetail: false,
        },
        attachment_items: stockdetail.stock_attachment && stockdetail.stock_attachment.attachments
      }
    case 'STOCKDETAIL/GET/ONE/ERROR':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          errors: action.payload.data,
          requestingDetail: false,
        },
      }
    // Create one stockdetail
    case 'STOCKDETAIL/CREATE':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          // requesting: true,
        },
      }
    case 'STOCKDETAIL/CREATE/SUCCESS':
      let newStockListing = [...state.stockdetaillisting.data]
      if(action.payload.group_id === action.payload.selected_stock_id){
        newStockListing = [action.payload,...state.stockdetaillisting.data]
      }
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          data: newStockListing,
          pagination: {
            total: state.stockdetaillisting.pagination && state.stockdetaillisting.pagination.total + 1
          },
          requesting: false,
        },
        attachment_items: []
      }
    case 'STOCKDETAIL/CREATE/ERROR':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          errors: action.payload.data,
          requesting: false,
        },
      }
    // Update one stockdetail
    case 'STOCKDETAIL/UPDATE/ONE':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          // requesting: true,
        },
      }
    case 'STOCKDETAIL/UPDATE/ONE/SUCCESS':
        let id_edit = action.payload.id,
        updateStockListing = [...state.stockdetaillisting.data]
        
        const indexOfUpdatedStockDetail = _.findIndex(updateStockListing, stockdetail => stockdetail.id === id_edit)
      
        if(action.payload.group_id === action.payload.selected_stock_id){
            if (indexOfUpdatedStockDetail !== -1) {
                updateStockListing.splice(indexOfUpdatedStockDetail, 1, action.payload)
            }
        }else{
            updateStockListing.splice(indexOfUpdatedStockDetail, 1)
        }
      
        return {
            ...state,
            stockdetaillisting: {
                ...state.stockdetaillisting,
                data: updateStockListing,
                detail: {},
                requesting: false,
            },
            attachment_items: []
        }

    case 'STOCKDETAIL/UPDATE/ONE/ERROR':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          errors: action.payload.data,
          // requesting: false,
        },
      }
    // Save filter value
    case 'STOCKDETAIL/FILTER/SAVE':
      return {
        ...state,
        stockdetaillisting: {
          ...state.stockdetaillisting,
          filter: action.payload
        },
      }
    // Get stock attachment listing
    case 'STOCKDETAIL/STOCKATTACHMENT/GET':
      return {
        ...state,
        stockattachmentlisting: {
          ...state.stockattachmentlisting,
          requesting: true,
        },
      }
    case 'STOCKDETAIL/STOCKATTACHMENT/GET/SUCCESS':
      const stockAttachmentListing = action.payload;
      let result = stockAttachmentListing.map(({ shortcode }) => shortcode)
      for (let shortcode of result) {
        let o = {};
        o[shortcode] = [];
        state.stockUploadedAttachmentListing.data.push(o)
      }
      return {
        ...state,
        stockattachmentlisting: {
          ...state.stockattachmentlisting,
          // listing: [...state.stockattachmentlisting.listing, ...stockAttachmentListing,],
          listing: action.payload,
          requesting: false,
        },
      }
    case 'STOCKDETAIL/STOCKATTACHMENT/GET/ERROR':
      return {
        ...state,
        stockattachmentlisting: {
          ...state.stockattachmentlisting,
          errors: action.payload.data,
          requesting: false,
        },
      }
    case 'STOCKDETAIL/STOCKATTACHMENT/DELETE/ONE':
      const stock_attachment_title = action.payload;
      const newStockAttachmentListing = _.filter(state.stockattachmentlisting.listing, stockattachment => stockattachment.title !== stock_attachment_title)
      const removedstockattachment = _.find(state.stockattachmentlisting.listing, stockattachment => stockattachment.title === stock_attachment_title)
      return {
        ...state,
        stockattachmentlisting: {
          ...state.stockattachmentlisting,
          listing: newStockAttachmentListing,
        },
        removedstockattachmentlisting: {
          data: [...state.removedstockattachmentlisting.data, removedstockattachment]
        }
      }

    case 'STOCKDETAIL/STOCKATTACHMENT/ADD/ONE':
      const addedstockattachment = action.payload;
      const added_stockattach = _.find(state.removedstockattachmentlisting.data, stockattachment => stockattachment.title === addedstockattachment)
      const newRemovedStockAttachmentListing = _.filter(state.removedstockattachmentlisting.data, stockattachment => stockattachment.title !== addedstockattachment)
      return {
        ...state,
        stockattachmentlisting: {
          ...state.stockattachmentlisting,
          listing: [...state.stockattachmentlisting.listing, added_stockattach]
        },
        removedstockattachmentlisting: {
          data: newRemovedStockAttachmentListing,
        }
      }

    case 'STOCKDETAIL/STOCKATTACHMENT/UPLOAD/SUCCESS':
      let data = action.payload;
      let storeData = state.stockUploadedAttachmentListing.data;
      let keyName = data.stockName;
      storeData.forEach((store, index) => {
        if (Object.keys(store).find(key => [key] == data.stockName)) {
          let arr = Object.values(store);
          arr[0].push(data.name);
          let push = { keyName: arr }
          store = { ...store, ...push }
        }
      })
      return {
        ...state,
        // stockUploadedAttachmentListing: {
        //   ...state.stockUploadedAttachmentListing,
        //   data:
        // }
      }
    case 'STOCKDETAIL/UPLOADATTACHMENT/REMOVE':
      let fileName = action.payload;
      let array = state.stockUploadedAttachmentListing;
      var newArr = _.remove(array, function (n) {
        return n == fileName;
      });
      return {
        ...state
      }

      case 'STOCKDETAIL/ADD/NEW/ATTACHMENT/ACCORDION':
      let updateAccoItems = state.attachment_items && [...state.attachment_items] || []
      let findIndex = _.findIndex(updateAccoItems, function(item) { return item.id == action.payload.id })
        if(action.payload && findIndex == -1){
            var newItem = {
                files: [],
                ...action.payload
            }
        }
      return {
        ...state,
        attachment_items: findIndex == -1 ? [newItem, ...updateAccoItems] : state.attachment_items
      }
      
    case 'STOCKDETAIL/REMOVE/ATTACHMENT/ACCORDION':
        let updatedItems = _.remove(state.attachment_items, (item) => {
              return item.shortcode != action.payload.shortcode;
        });
        return {
          ...state,
          attachment_items: updatedItems
        }

    case 'STOCKDETAIL/RESET/ATTACHMENT/ACCORDION':
        return {
            ...state,
            attachment_items: []
        }

      case 'STOCKDETAIL/ATTACHMENT/ACCORDION/DRAG':
      let { oldIndex, newIndex } = action.payload
      return {
        ...state,
        attachment_items: arrayMove(state.attachment_items, oldIndex, newIndex)
      }

      case 'STOCKDETAIL/ADD/NEW/ATTACHMENT/FILE':
      let newAccordion = state.attachment_items && [...state.attachment_items] || []
      let findIndex1 = _.findIndex(newAccordion, function(item) { return item.shortcode == action.payload.shortcode })
        if(action.payload && findIndex1 !== -1){
          newAccordion[findIndex1] = {
            ...state.attachment_items[findIndex1],
            files: [action.payload, ...state.attachment_items[findIndex1].files]
          }
        }
      return {
        ...state,
        attachment_items: newAccordion 
      }

    case 'STOCKDETAIL/ATTACHMENT/FILE/DRAG':
      let updatedAccordion = state.attachment_items && [...state.attachment_items] || []
      let fileIndex = _.findIndex(updatedAccordion, function(item) { return item.shortcode == action.payload.shortcode })
        if(action.payload && fileIndex !== -1){
            updatedAccordion[fileIndex] = {
              ...state.attachment_items[fileIndex],
              files: arrayMove(state.attachment_items[fileIndex].files, action.payload.oldIndex, action.payload.newIndex)
            }
        }
      return {
        ...state,
        attachment_items: updatedAccordion
      }

      case 'STOCKDETAIL/REMOVE/ATTACHMENT/FILE':
      let updatedAttachements = state.attachment_items && [...state.attachment_items] || [],
        attachmentIndex = _.findIndex(updatedAttachements, function (item) { return item.shortcode == action.payload.shortcode })
        if(action.payload && attachmentIndex !== -1){
            let updatedItem = _.remove(state.attachment_items[attachmentIndex].files, (item) => {
                return item.value != action.payload.value;
            });
            updatedAttachements[attachmentIndex] = {
              ...state.attachment_items[attachmentIndex],
              files: updatedItem
            }
        }
      return {
        ...state,
        attachment_items: updatedAttachements
      }

      case 'PROJECT/GET/ALL/ATTACHMENTS/SUCCESS':
        return {
          ...state,
          attachment_items: action.payload
        }

    default:
      return {
        ...state
      }
  }
}