import React from "react";
import LoginForm from "./container/LoginForm"

function Login(props) {

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-10">
        <figure>
          <img className="login-logo" src="/media/img/logo_white.png" alt="logo-white" />
        </figure>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <LoginForm />
      {/*end::Form*/}
    </div>
  );
}

export default Login;
