export const CustomerActions = {
  create,
  getAll,
  getOne,
  updateOne,
  updateOneAccess,
  deleteOne,
  saveFilterValue,
  toggleSidebar
}

function create(data){
  return { type : "CUSTOMER/CREATE", payload: data }
}

function getAll(data){
  return { type : "CUSTOMER/GET/ALL", payload: data }
}

function getOne(id){
  return { type : "CUSTOMER/GET/ONE", payload: id }
}

function updateOne(id){
  return { type : "CUSTOMER/UPDATE/ONE", payload: id }
}

function updateOneAccess(data){
  return { type : "CUSTOMER/UPDATE/ONE/ACCESS", payload: data }
}

function deleteOne(id){
  return { type : "CUSTOMER/DELETE/ONE", payload: id }
}

function saveFilterValue(data){
  return { type : "CUSTOMER/FILTER/SAVE", payload: data }
}

function toggleSidebar(){
  return { type : "CUSTOMER/SIDEBAR/TOGGLE" }
}