import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'; 

import { InputFieldAuth, TextareaAutosizeRedux } from "../../../app/component/common/FieldComponent";
import { ProjectActions } from "../../../app/actions/project.action";

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email field is required!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }

    if (!values.subject) {
        errors.subject = 'Subject field is required!'
    }

    if (!values.message) {
        errors.message = 'Message field is required!'
    }
    return errors
}

class EmailToCustomer extends Component {

    constructor(props) {
        super(props);
        // this.state={
        //     open:this.props.showProjectEmail,
        // }
    }

    _handleClose = () => {
        // this.setState({
        //     open:false
        // });
        this.props.hideProjectEmail();
    };

    _handleSubmit = (values) =>{
        // let {registerData, register} = this.props
        // let valuesToSend = {...registerData,...values}
        // register(valuesToSend)
        this.props.sendMail({id: this.props.id, ...values});
        
    }

    render() {
        const { handleSubmit, submitting, pristine } = this.props
        return (
                <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.props.showProjectEmail}
                >
                    <DialogTitle id="alert-dialog-title">{"Please provide email to send this manual:"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Field 
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="email"
                            component={InputFieldAuth}/>
                        <Field 
                            placeholder="Subject"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="subject"
                            required={true}
                            component={InputFieldAuth}/>
                        <Field 
                            placeholder="Message"
                            required={true}
                            minRows={4}
                            maxRows={6}
                            width={1}
                            name="message"
                            component={TextareaAutosizeRedux}/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={this._handleClose}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        cancel
                        </button>
                        <button
                            // type="submit"
                            onClick={handleSubmit(this._handleSubmit)}
                            disabled={submitting}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            <span>{submitting ? 'Submitting..': 'Submit' }</span>
                            {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                    </DialogActions>                   
                </Dialog>    
        );
    }
}

EmailToCustomer = reduxForm({
    form: 'EmailToCustomer',
    validate,
    enableReinitialize: true
})(EmailToCustomer);

const mapStateToProps = (state, ownProps) => {
	return {
        showProjectEmail: state.project.showProjectEmail,
        id: state.project.projectIdToMail,
        initialValues: {
            email: state.project.ProjectEmail
        }
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    hideProjectEmail: () => { dispatch({ type : "PROJECT/EMAIL/OPTION/TOGGLE", payload: {data: false} }) },
   sendMail: (data) => dispatch(ProjectActions.sendMail(data)),

})

export default connect(mapStateToProps, mapDispatchToProps)(EmailToCustomer);