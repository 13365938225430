import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form';
import {Tabs, Form, Tab } from 'react-bootstrap'
import { FormControlLabel, Radio, InputLabel, MenuItem, FormHelperText, FormControl, Select, Button } from '@material-ui/core';
import ContractorServiceList from '../../_metronic/layout/components/todo-list/ContractorServiceList'
import { FileFieldRedux, SelectStaticFieldRedux, } from '../component/common/FieldComponent'
import ImporterForm from '../component/setup/ImporterForm'
//import CSVReader from 'react-csv-reader'
import WorkTypeSelectList from '../../_metronic/layout/components/todo-list/WorkTypeSelectList'
import AttachmentGroupsList from '../../_metronic/layout/components/todo-list/AttachmentGroupsList'


const mimeType = ".csv"

class Setup extends Component {

    state = {
      module: "",
      file: null,
      imageFile: null,
    }

    handleChange = (e) => {
      this.setState({ module: e.target.value})
    }

    handleSubmit = (form_Data) => {
      const data = new FormData();
    }

    _handleOnUpload = (e) => {
      if (e.target.files[0] && mimeType.includes(e.target.files[0].type)) {
          this.setState({
              imageFile: URL.createObjectURL(e.target.files[0])
          });
      }
      this.setState({
          file: e.target.files[0]
      })
    };

   render() {
    const { handleSubmit, submitting, pristine,} = this.props;
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body pv-5 padd_for_res pb-2">
                            <Tabs defaultActiveKey="import_data" id="uncontrolled-tab-example">
                                <Tab eventKey="import_data" title="Import data" className="p-5">
                                    <p>Existing information will be updated or if not found a new entry will be created.</p> 
                                    <ImporterForm/>
                                </Tab>
                                <Tab eventKey="contractor_service" title="Contractor services"  className="p-5">
                                    {/* <p>It is very clear from these observations that there is a direct relation between the application rules and core concept of the participant evaluation sample. However, segments of the critical thinking gives a complete experience of The Accomplishment of Significant Software" (Abraham Mclaurin in The Book of the Corporate Ethics and Philosophy) In respect that any further consideration is getting more complicated against the backdrop of the irrelevance of procedure. Furthermore, one should not forget that all approaches to the creation of the structure of the project architecture has a long history of what is conventionally known as specific decisions. What is more, the matter of the mechanism cannot rely only on the ground-breaking technology. Thus a complete understanding is missing.  Though, the objectives of a broad understanding of the bilateral act can be neglected in most cases, it should be realized that the lack of knowledge of impact of the corporate asset growth provides a foundation for the general features and possibilities of the key principles. It is often said that impact of the essence likely complete failure of the supposed theory an initial attempt in development of the referential arguments.</p>  */}
                                    <ContractorServiceList />
                                </Tab>
                                <Tab eventKey="work-type" title="Work Type"  className="p-5">
                                    {/* <p>It is very clear from these observations that there is a direct relation between the application rules and core concept of the participant evaluation sample. However, segments of the critical thinking gives a complete experience of The Accomplishment of Significant Software" (Abraham Mclaurin in The Book of the Corporate Ethics and Philosophy) In respect that any further consideration is getting more complicated against the backdrop of the irrelevance of procedure. Furthermore, one should not forget that all approaches to the creation of the structure of the project architecture has a long history of what is conventionally known as specific decisions. What is more, the matter of the mechanism cannot rely only on the ground-breaking technology. Thus a complete understanding is missing.  Though, the objectives of a broad understanding of the bilateral act can be neglected in most cases, it should be realized that the lack of knowledge of impact of the corporate asset growth provides a foundation for the general features and possibilities of the key principles. It is often said that impact of the essence likely complete failure of the supposed theory an initial attempt in development of the referential arguments.</p>  */}
                                    <WorkTypeSelectList />
                                </Tab>
                                <Tab eventKey="attachment-groups" title="Attachment Groups"  className="p-5">
                                    {/* <p>It is very clear from these observations that there is a direct relation between the application rules and core concept of the participant evaluation sample. However, segments of the critical thinking gives a complete experience of The Accomplishment of Significant Software" (Abraham Mclaurin in The Book of the Corporate Ethics and Philosophy) In respect that any further consideration is getting more complicated against the backdrop of the irrelevance of procedure. Furthermore, one should not forget that all approaches to the creation of the structure of the project architecture has a long history of what is conventionally known as specific decisions. What is more, the matter of the mechanism cannot rely only on the ground-breaking technology. Thus a complete understanding is missing.  Though, the objectives of a broad understanding of the bilateral act can be neglected in most cases, it should be realized that the lack of knowledge of impact of the corporate asset growth provides a foundation for the general features and possibilities of the key principles. It is often said that impact of the essence likely complete failure of the supposed theory an initial attempt in development of the referential arguments.</p>  */}
                                    <AttachmentGroupsList />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
    </>);
  }
}

Setup = reduxForm({
  form: 'ImporterForm',
  //validate,
  enableReinitialize: true
})(Setup);

export default connect(null, null)(Setup)