import React from 'react';
import RichTextEditor from 'react-rte';

class TextEditorRedux extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            value: RichTextEditor.createValueFromString(this.props.input.value, 'html')
        }
    }

    onChange = (value) => {
        this.setState({value})
        const { input: { onChange } } = this.props;
        onChange(value.toString('html'))
    };

    render(){
        const { input } = this.props;
        return(
            <RichTextEditor
                value={this.state.value}
                onChange={this.onChange}
                className="min-height mt-10"
            />
        )
    }
}

export default TextEditorRedux;