import React from 'react';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import CompanyDetailForm from '../component/companyinfo/CompanyDetailForm';
import CompanyAccountForm from '../component/companyinfo/CompanyAccountForm';

const CompanyInfo = () => {
    let data = useSelector(state => state)
    let account_form = ''
    if(data.auth && data.auth.user && data.auth.user.company_name){
        account_form = <CompanyAccountForm />
    }
    return ( < >
        <div className = "row" >
            <div className = "col-xxl-12" >
                <div className = "card-stretch gutter-b" >
                    <CompanyDetailForm />
                    {account_form}
                </div>
            </div>
        </div> 
        </>
    );
}


export default CompanyInfo;