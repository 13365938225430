import React from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

class WelcomePopup extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <Dialog
                className="model-support"
                modal={false}
                contentClassName="model-top-overlay no-overlay"
                open={this.props.isOpen}
            >
                <DialogTitle className="welcome-title-root" id="alert-dialog-title">{this.props.heading}</DialogTitle>
                <DialogContent className="welcome-content-root">
                    <p>{this.props.message}</p> 
                </DialogContent>

                {/* <DialogActions className="welcome-action-root">
                    {this.props.onClickNo && <button
                        onClick={this.props.onClickNo}
                        type="button"
                        className="btn btn-light-default font-weight-bold px-9 py-4 my-3"
                    >
                        {this.props.noButtonText}
                    </button>}

                    {this.props.onClickYes && <button
                        onClick={this.props.onClickYes}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                    >
                        {this.props.yesButtonText}
                    </button>}
                </DialogActions> */}
            </Dialog>
        )
    }
}

export default WelcomePopup;