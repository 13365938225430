const validate = values => {
  const errors = {}
  if (!values.name) {
      errors.name = 'Name field is required'
  }else if (!/^[A-Za-z0-9\'\;\.\-\/\s]+$/.test(values.name)) {
    errors.name = 'Invalid name'
  } else if (values.name.length > 50) {
      errors.name = 'Maximum 50 characters allowed for name'
  }else if(!values.name.trim()){
    errors.name = 'Name field is required'
  }
  if (values.email) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }
  }
  if (values.website) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
          '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i');
    if (!pattern.test(values.website)) {
        errors.website = 'Invalid website address!'
    }
  }
  // if (!values.address_line_1) {
  //     errors.address_line_1 = 'Address Line 1 is required'
  // }else if(!values.address_line_1.trim()){
  //   errors.address_line_1 = 'Address Line 1 is required'
  // }
  // if (!values.address_line_2) {
  //     errors.address_line_2 = 'Address Line 2 is required'
  // }else if(!values.address_line_2.trim()){
  //   errors.address_line_2 = 'Address Line 2 is required'
  // }
  // if (!values.city) {
  //     errors.city = 'City is required'
  // }else if(!values.city.trim()){
  //   errors.city = 'City is required'
  // }
  // if (!values.county) {
  //     errors.county = 'County is required'
  // }else if(!values.county.trim()){
  //   errors.county = 'County is required'
  // }
  // if (!values.postcode) {
  //     errors.postcode = 'Postcode is required'
  // }else if(!values.postcode.trim()){
  //   errors.postcode = 'Postcode is required'
  // }
  if (!values.service_id) {
        errors.service_id = 'Select Service'
    }
  return errors
}

export default validate;