export const templateActions = {
    getWorkType,
    getTemplateSet,
    getTemplateSetByJobId,
    getTemplates,
    getTemplatesByJobId,
    deleteTemplateSet,
    createTemplateSet,
    uploadTemplate,
    addManualTemplateSet,
    selectWorkType,
    selectAttachmentGroup,
    getSelectWorkType,
    getSelectAttachmentGroup,
    getAttachmentGroups
  }
  
  function getWorkType(data){
    return { type : "TEMPLATES/WORKTYPE", payload: data }
  }

  function getTemplateSet(data){
    return { type : "TEMPLATES/TEMPLATESET", payload: data }
  }
  function getTemplateSetByJobId(data){
    return { type : "TEMPLATES/TEMPLATESET/BYJOBID", payload: data }
  }
  function getTemplates(data){
    return { type : "TEMPLATES/TEMPLATES", payload: data }
  }
  
  function getTemplatesByJobId(data){
    return { type : "TEMPLATES/TEMPLATES/BYJOBID", payload: data }
  }
  

  function deleteTemplateSet(data){
    return { type : "TEMPLATES/TEMPLATESET/DELETE", payload: data }
  }

  function createTemplateSet(data){
    return { type : "TEMPLATES/TEMPLATESET/POST", payload: data }
  }

  function uploadTemplate(data){
    return { type: 'TEMPLATES/TEMPLATE/UPDATE', payload: data }
  }

  function addManualTemplateSet(data){
    return { type: 'TEMPLATES/ADDMANAUAL/POST', payload: data }
  }
  
  function selectWorkType(data){
    return { type : "TEMPLATES/WORKTYPE/SELECT", payload: data }
  }
  function selectAttachmentGroup(data){
    return { type : "TEMPLATES/ATTACHMENTGROUP/SELECT", payload: data }
  }
  function getSelectAttachmentGroup(data){
    return { type : "TEMPLATES/SELECTATTACHMENTGROUP", payload: data }
  }
  function getSelectWorkType(data){
    return { type : "TEMPLATES/SELECTEDWORKTYPE", payload: data }
  }
  function getAttachmentGroups(data){
    return { type : "TEMPLATES/ATTACHMENTGROUP", payload: data }
  }