import React, {Suspense} from "react";
import {Redirect, Switch,} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../../_metronic/layout";
import { DashboardPage } from "../pages/DashboardPage";
import Contractor from '../pages/Contractor' 
import Sites from '../pages/Sites'
import Stock from '../pages/Stock'
import Setup from '../pages/Setup' 
import TemplateSet from '../pages/TemplateSet'
import StockDetail from '../pages/StockDetail'
import CompanyInfo from '../pages/CompanyInfo'
import Projects from '../pages/Projects'
import CompanySubscription from '../pages/CompanySubscription'
import AddProject from '../pages/AddProject'
import EditProject from '../pages/EditProject'
import ProjectDetail from '../pages/ProjectDetail'
import Customers from '../pages/Customers' 
import Users from '../pages/Users' 

export default function BasePage(auth) {
   let {auth:{type, parent_email}} = auth;
        return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                  <Redirect exact from="/" to="/projects"/>
                }
                <ContentRoute path="/projects" component={Projects}/>
                <ContentRoute path="/edit_project/:id" component={EditProject}/>
                <ContentRoute path="/project_detail/:id/:template_id" component={ProjectDetail}/>
                {(type == 3 || type == 4) && !parent_email ?<ContentRoute path="/users" component={Users}/>:''}
                {type < 3 ? 
                <>
                    <ContentRoute path="/contractors" component={Contractor}/>
                    <ContentRoute path="/sites" component={Sites}/>
                    <ContentRoute path="/stock" component={Stock}/>
                    <ContentRoute path="/setup" component={Setup}/>
                    <ContentRoute path="/templates" component={TemplateSet}/>
                    <ContentRoute path="/stock_detail" component={StockDetail}/>
                    <ContentRoute path="/dashboard" component={DashboardPage}/>
                    <ContentRoute path="/add_project" component={AddProject}/>
                    <ContentRoute path="/customers" component={Customers}/>
                    {type == 1 ? 
                    <>
                    <ContentRoute path="/users" component={Users}/>
                    <ContentRoute path="/company-subscription" component={CompanySubscription}/>
                    <ContentRoute path="/company_info" component={CompanyInfo}/>
                    </>:
                    ''}
                </>
                :''
                }
                
            </Switch>
        </Suspense>
    );
}
