import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';

import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { InputFieldCompanyInfo, InputFieldRedux, SelectStaticFieldRedux, renderSelectField, renderDatePickerField, AutoCompleteFieldRedux, TextareaAutosizeRedux } from '../../component/common/FieldComponent';
import { alertInfo } from '../../utils/common.helper';

import { ProjectActions } from "../../actions/project.action";
import { SiteActions } from "../../actions/site.action";
import { GlobalActions } from "../../actions/global.action";

class GeneralForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: "",
            site: "",
            start_date: null,
            name: '',
            labelWidth: 0,
        }
    }
    componentDidMount = () => {
        this.props.getCustomerListing({ per_page: 0 });
        this.props.getSiteListing({ per_page: 0 });
    }
    _handleSiteChange = (e) => {
        this.setState({ customer: e.target.value })
    }

    _handleCustomerChange = (e) => {
        this.setState({ site: e.value })
        this.props.resetSiteData();
        this.props.getSiteListing({ per_page: 0, customer_id: e.value })
    }

    openSidebarSite = () => {
        this.props.openRightSidebar('add_new_site')
    }

    openSidebar = () => {
        this.props.openRightSidebar('add_new_customer')
    }

    _startDateSelect = (e) => {
        let date = e;
        let start_date;
        start_date = new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    onChangeFieldValue = (data) => {

        alertInfo('info', "Don't forget to save before creating the manual.");
        // this.props.onChangeFieldValue({
        //     fieldName: 'carried_out', 
        //     fieldValue: []
        // })
    }

    render() {
        const { customer, site, name, labelWidth } = this.state
        const { customerlisting, sitelisting, templates, templateSet, workType, selectedCustomer, user } = this.props;

        let manual = <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-top" >Edit the underlying document or add new ones in the templates area</Tooltip>}
        >
            <div>Manual <i className="fa fa-info-circle tooltip-icon" aria-hidden="true"></i></div>
        </OverlayTrigger>;

        let templatesOptions = []
        templateSet.data.map((temp, index) => {
            templatesOptions.push({ id: temp.id, label: temp.template_set_name && temp.template_set_name })
        })
        //  templatesOptions =  _.orderBy(templatesOptions, ['title'], ['asc']);
        templatesOptions = _.sortBy(templatesOptions, 'label');
        
        return (
            <div className="card-body pv-5 mb-8">
                <div className="row">
                    <div className="col-lg-6 col-md-6 mt-6">
                        <Field
                            name="project_ref"
                            label="Project Reference"
                            margin="normal"
                            variant="outlined"
                            readOnly={user.type < 3 ?false:true}
                            component={InputFieldCompanyInfo}
                        />
                    </div>

                    {/* <div className="col-lg-6 col-md-6">
                            <Field
                                name="status"
                                label="Status"
                                margin="normal"
                                variant="outlined"
                                readOnly={false}
                                component={InputFieldCompanyInfo}
                            />
                        </div> */}
                    <div className="col-lg-6 col-md-6">
                        <div className="" id="customer">
                            <div className="d-flex custom-select-field-with-plus">
                                <Field
                                    label='Status'
                                    name="status"
                                    component={AutoCompleteFieldRedux}
                                    disabled={user.type < 3 ?false:true}
                                    options={[{ id: 'Pending', label: 'Pending' }, { id: 'In Progress', label: 'In Progress' }, { id: 'Complete', label: 'Complete' }, { id: 'Invoiced', label: 'Invoiced' }, { id: 'On Hold', label: 'On Hold' }]}
                                // onChange={this._handleCustomerChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <Field
                            name="project_created_at"
                            label="Project Created at "
                            margin="normal"
                            variant="outlined"
                            disabled={true}
                            component={InputFieldRedux}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <Field
                            name="pdf_created_at"
                            label="Job Manual PDF created at"
                            margin="normal"
                            variant="outlined"
                            disabled={true}
                            component={InputFieldRedux}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="" id="customer">
                            <div className="d-flex custom-select-field-with-plus">
                                <Field
                                    label={customerlisting.requesting ? 'Loading Customers...' : 'Customer'}
                                    name="customer"
                                    component={AutoCompleteFieldRedux}
                                    options={customerlisting.data}
                                    disabled={user.type < 3 ?false:true}
                                    onChange={this._handleCustomerChange}
                                />
                                <button onClick={this.openSidebar} disabled={user.type < 3 ?false:true} type="button" className="add_button fa fa-plus text-primary"></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="d-flex custom-select-field-with-plus">
                            <Field
                                label={sitelisting.requesting ? 'Loading sites....' : 'Site'}
                                name="site"
                                component={AutoCompleteFieldRedux}
                                options={sitelisting.data}
                                disabled={(selectedCustomer && user.type < 3)? (sitelisting.requesting ? true : false) : true}
                            />
                            <button onClick={this.openSidebarSite} disabled={user.type < 3 ?false:true} type="button" className="add_button fa fa-plus text-primary"></button>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-lg-3 col-md-6 mt-5">
                        <Field
                            label="Job Completion Date"
                            margin="normal"
                            variant="outlined"
                            name="completion_date"
                            type="date"
                            component={renderDatePickerField}
                            placeholder="Job Completion Date"
                            defaultValue={new Date()}
                            minDate={(new Date('1-01-1970'))}
                            // maxDate={new Date()}
                            onChange={this._startDateSelect}
                            disabled={user.type < 3 ?false:true}
                        />

                    </div>
                    <div className="col-lg-3 col-md-6 mt-8">
                        <Field
                            label="Author Name"
                            name="auther_name"
                            margin="normal"
                            variant="outlined"
                            onChange={this.onChangeFieldValue}
                            readOnly={user.type < 3 ?false:true}
                            component={InputFieldCompanyInfo}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 mt-5">
                        <Field
                            label={manual}
                            onChange={this.onChangeFieldValue}
                            name="template_set_id"
                            component={AutoCompleteFieldRedux}
                            disabled={user.type < 3 ?false:true}
                            options={templatesOptions}
                        />
                    </div>
                    {/* <div className='col-lg-12 col-md-12 mt-12'>
                        <label>Project Description</label>
                        <Field 
                            placeholder="Wrte Project Description"
                            required={true}
                            minRows={4}
                            maxRows={6}
                            width={1}
                            name="project_description"
                            component={TextareaAutosizeRedux}
                        />
                    </div> */}

                </div>

                {/* <div className="row">
                        <div className="col-lg-6 col-md-6 mt-5">
                            <Field
                                name="manual_type"
                                label="Manual Type"
                                margin="normal"
                                variant="outlined"
                                disabled={true}
                                component={InputFieldRedux}
                            />
                            <input type='hidden'name = "manual_id" />
                        </div>
                    </div> */}
            </div>
        )
    }
}
GeneralForm = reduxForm({
    form: 'ProjectForm',
    destroyOnUnmount: false,
})(GeneralForm)
const selector = formValueSelector('ProjectForm');
const mapStateToProps = (state, ownProps) => {
    const { dropdownListing: { data, requesting }, customerlisting, sitelisting } = state.project;
    let selected = state.project.selectedDropdown;
    let project_details = state.project.project_details
    // let manual_id;
    // _.map(data, (i) =>{
    //     if(i.shortcode == selected){
    //         selected = i.title
    //         manual_id = i.id
    //     }
    // });
    let selectedCustomer = selector(state, 'customer');
    return {
        // workType : state.templates.workType,
        user: state.auth.user,
        templateSet: state.templates.templateSet,
        templates: state.templates.templates,
        selected,
        customerlisting,
        sitelisting,
        selectedCustomer: selectedCustomer && selectedCustomer.value,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getSiteListing: (data) => dispatch(ProjectActions.getSiteListing(data)),
    getCustomerListing: (data) => dispatch(ProjectActions.getCustomerListing(data)),
    onChangeFieldValue: ({ fieldName, fieldValue }) => dispatch(change('ProjectForm', fieldName, fieldValue)),
    openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
    closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
    resetSiteData: () => dispatch(change('ProjectForm', 'site', '')),
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralForm);