import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';

function* getSchema({ payload }) {
    try {
        yield put({ 
            type: "IMPORTER/SCHEMA/GET/REQUEST", 
          });
        const response = yield race({
            success: call(getResponse, {
                data: payload.values,
                method: 'GET',
                url: `/api/v1/${payload.data}/schema`
            })
        });
        if (response.success.data) {
            yield put({ 
                type: "IMPORTER/SCHEMA/GET/SUCCESS", 
                payload: response.success.data.data
              });
        }
    } catch (error) {
        yield put({ 
            type: "IMPORTER/SCHEMA/GET/ERROR", 
          });
        if(error.response){
            const { response: { data } } = error;
            alertInfo('error', data.message)
        }
    }
        
}

function* postImporterData({ payload }){
    try{
        yield put({ 
            type: "IMPORTER/IMPORT/POST/REQUEST", 
        });
        const response = yield race({
            success: call(getResponse, {
                data: payload.data,
                method: 'POST',
                url: `/api/v1/${payload.values.module}/import`
            })
        });
        if (response.success.data) {
            yield put({ 
                type: "IMPORTER/IMPORT/POST/SUCCESS", 
                payload: response.success.data.data
              });
        }

    }catch( error ){
        yield put({ 
            type: "IMPORTER/IMPORT/POST/ERROR", 
          });
        if(error.response){
            const { response: { data } } = error;
            alertInfo('error', data.message)
        }
    }
}

export default function* importerSaga() {
    yield all([
        takeLatest("IMPORTER/SCHEMA/GET", getSchema),
        takeLatest("IMPORTER/IMPORT/POST", postImporterData),
    ]);
}