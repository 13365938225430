import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { InputFieldRedux } from './FieldComponent'

class SearchComponent extends React.Component {

  render(){
    const { label, type, margin, helperText, name, handleChange } = this.props;
    return(
        <Field
          label={label}
          type={type}
          margin={margin}
          helperText={helperText}
          name={name}
          component={InputFieldRedux}
          onChange={handleChange}
        />
    )
  }
}

const validate = values => {
  const errors = {}
  if (/(^\s+|\s+$)/g.test(values.search_field)) {
		  errors.search_field = 'Spaces not allowed at the start/end';
	} else if (values.search_field && (values.search_field.length < 3 || values.search_field.length > 10)) {
      errors.search_field = 'Need 3-10 characters to search'
  } else if (/[0-9]$/g.test(values.search_field)) {
      errors.search_field = 'Numbers not allowed in search field'
  }
  return errors
}

SearchComponent = reduxForm({
  form: 'Search',
  // validate,
  enableReinitialize: true
})(SearchComponent);

export { SearchComponent };