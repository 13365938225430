export const ContractorActions = {
  create,
  getAll,
  getOne,
  updateOne,
  updateOneAccess,
  deleteOne,
  saveFilterValue,
  toggleSidebar,
  getAllContractor
}

function create(data){
  return { type : "CONTRACTOR/CREATE", payload: data }
}

function getAll(data){
  return { type : "CONTRACTOR/GET/ALL", payload: data }
}

function getOne(id){
  return { type : "CONTRACTOR/GET/ONE", payload: id }
}

function updateOne(id){
  return { type : "CONTRACTOR/UPDATE/ONE", payload: id }
}

function updateOneAccess(data){
  return { type : "CONTRACTOR/UPDATE/ONE/ACCESS", payload: data }
}

function deleteOne(id){
  return { type : "CONTRACTOR/DELETE/ONE", payload: id }
}

function saveFilterValue(data){
  return { type : "CONTRACTOR/FILTER/SAVE", payload: data }
}

function toggleSidebar(){
  return { type : "CONTRACTOR/SIDEBAR/TOGGLE" }
}

function getAllContractor(data){
  return { type : "CONTRACTOR/GET/ALL/CONTRACTORS/REQUEST", payload: data }
}