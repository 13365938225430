import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { templateActions } from '../../../../app/actions/template.action';
import Checkbox from '@material-ui/core/Checkbox';

export const WorkTypeSelectList = (props) => {
    let { workType, getWorkType, selectWorkType, userDetails, selectedWorkType, getSelectWorkType } = props

    useEffect(() => {
        getWorkType()
        getSelectWorkType();
        handleSelectWorkType();
    }, []);

    useEffect(() => {
        handleSelectWorkType();
    }, [selectedWorkType, workType])

    const [finalWorkType, setFinalWorkType] = useState([])
    const [allSelected, setAllSelected] = useState(false)
    const [isSorted, setIsSorted] = useState(true)
    const [sortTriger, setSortTrigger] = useState(false)


    const handleCheckBox = (e, id) => {
        const data = {
            userId: userDetails.id,
            workId: id,
            checked: e.target.checked
        };
        selectWorkType(data);

        if(!e.target.checked) {
            setAllSelected(false);
        }

    };

    const toggleWorkTypeCheckBox = (e) => {
        const data = {
            userId: userDetails.id,
            workId: 'all',
            checked: e.target.checked
        };
        selectWorkType(data);
        setAllSelected(!allSelected);
    };

    const handleSelectWorkType = () => {

        if (isSorted) {
            sortWork('asc');    
            // console.log("call from handleSelectWorkType if sortTriger")

        } else {
            sortWork('desc');
            // document.querySelector('.fa-sort').click();  
            // console.log("call from handleSelectWorkType if !sortTriger")

            // var finalWorkTypeList = workType.map(workTypeObj => ({ ...workTypeObj, ...selectedWorkType.find(selectedWorkTypeObj => selectedWorkTypeObj.id === workTypeObj.id) }))
            // setFinalWorkType(finalWorkTypeList)
            // console.log(finalWorkTypeList, "finalWorkTypeListfinalWorkTypeList")

        }

        let checkedTypeCount = selectedWorkType.filter(wtype => {
            if(wtype.checked == true) return true;
        })

        // console.log('workType f', workType);
        // console.log('selectedWorkType f', selectedWorkType);

        // console.log('checkedTypeCount', checkedTypeCount, checkedTypeCount.length, selectedWorkType.length );

        // console.log(checkedTypeCount.length === selectedWorkType.length);

        if( (checkedTypeCount.length === workType.length) && selectedWorkType.length > 0) {
            setAllSelected(true);
        }

    };
    const sortWork = (type) => {
            function compare_item(a, b) {
                if(type == "asc"){
                    // a should come before b in the sorted order
                    if (a.label < b.label) {
                        return -1;
                        // a should come after b in the sorted order
                    } else if (a.label > b.label) {
                        return 1;
                        // and and b are the same
                    } else {
                        return 0;
                    }

                }else{

                    if (a.label > b.label) {
                        return -1;
                        // b should come after a in the sorted order
                    } else if (a.label < b.label) {
                        return 1;
                        // b and and a are the same
                    } else {
                        return 0;
                    }
                }
            }
            var finalWorkTypeList = workType.map(workTypeObj => ({ ...workTypeObj, ...selectedWorkType.find(selectedWorkTypeObj => selectedWorkTypeObj.id === workTypeObj.id) }))
            var list = finalWorkTypeList.sort(compare_item)
            setFinalWorkType(list);
            // console.log(list, "finalWorkType.sort(compare_item)");
            
        }

        const sortWorkTypeList = () => {
            setIsSorted(!isSorted);
            if (isSorted) {
                // console.log("call from sortWorkTypeList if sorted")
                sortWork("desc");
            } else {
                // console.log("call from sortWorkTypeList if !sorted")
                sortWork("asc");
            }
        }

        return (
            <div>
                <table className="table table-striped">
                    <thead className='table-dark'>
                        <tr>
                            <th scope="col">Work Type</th>
                            <th></th>
                            <th>
                                <a>
                                    <i className="fa fa-sort" aria-hidden="true" onClick={sortWorkTypeList}></i>
                                </a>
                            </th>
                            <th scope="col">Visible?</th>
                        </tr>
                    </thead>
                    <tbody>
                       <tr>
                            <td className='text-primary'><strong> Select All </strong></td>
                            <td className='text-primary'></td>
                            <td className='text-primary'></td>
                            <td className='text-primary'>
                                <input onChange={e => toggleWorkTypeCheckBox(e)} control={Checkbox} type="checkbox" checked={allSelected === true} />
                            </td>
                        </tr>
                        {
                            finalWorkType.map((work, i) =>
                                <tr key={i}>
                                    <td>{work.label}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <input onChange={e => handleCheckBox(e, work.id)} control={Checkbox} type="checkbox" checked={work.checked === true} />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div >
        )
    }

    const mapStateToProps = (state) => ({
        workType: state.templates.workType.data,
        selectedWorkType: state.templates.templates.selectedData,
        userDetails: state.auth.user
    })

    const mapDispatchToProps = (dispatch) => ({
        getWorkType: () => dispatch(templateActions.getWorkType()),
        selectWorkType: (data) => dispatch(templateActions.selectWorkType(data)),
        getSelectWorkType: (data) => dispatch(templateActions.getSelectWorkType(data)),
    })

    export default connect(mapStateToProps, mapDispatchToProps)(WorkTypeSelectList)
