import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, reset, change } from 'redux-form';
import { Form } from 'react-bootstrap'
import _ from 'lodash';
import { FormControlLabel,RadioGroup, Radio, MenuItem, FormControl, Button } from '@material-ui/core';
import { FileFieldRedux, SelectStaticFieldRedux, } from '../common/FieldComponent'
import ModalToMapData from './modalToMapData';
import {ImporterActions} from '../../actions/importer.action';

 const radioButton = ({ input, meta: { touched, error }, children, ...rest }) => (
    <FormControl>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
      {touched &&
        ((error && <span style={{ color: 'red' }}>{error}</span>))
      }
    </FormControl>
  )
const validate = (values)=>{
    const errors = {}
    if (!values.file) {
        errors.file = 'Select a csv/txt file to import'
    }
    if (!values.headers) {
        errors.headers = 'Required Field'
    }
    if (!values.module) {
        errors.module = 'Required Field'
    }
    return errors
}
let reader = new FileReader();
class ImporterForm extends Component{

    state = {
        module: "",
        headers:null,
      }

    constructor(props){
        super(props)
        reader.onload = (e) =>{
            if(e.target.result){
                let firstLine = e.target.result.split('\n')[0];
                console.log("firstLine====>",e.target.result)
                this.props.insertCsvFirstLine(firstLine.split(','))
            }
        }
    }

    _resetForm = ()=>{
        this.setState({
            module: "",
            headers:null,
        })
        document.getElementById('upload_file_csv').value = ""
        document.getElementsByName('module')[0].value = ""
        this.props.dispatch(change("ImporterForm","upload_file_csv",""));
        this.props.dispatch(change("ImporterForm","module",""));
        // this.props.dispatch(change("ImporterForm","upload_file_csv",""))
        this.props.resetForm()
    }

     handleChange = (e) => {

      this.setState({ module: e.target.value});
      if(e.target.value){
        this.props.getSchema(e.target.value);
      }
    }

    _toggleHeader = (value)=>{
        this.setState({
            headers:value.target.value
        })
    }

    handleSubmit = (form_Data) => {
      const data = new FormData();
      if (_.isObject(form_Data.file) && form_Data.file) {
            data.append('file', form_Data.file[0])
            reader.readAsText(form_Data.file[0]);
        }
        const {firstRow} = this.props
        _.map(firstRow,(option,i)=>{
            this.props.dispatch(change("ImporterForm","mapping_to["+i+"]","exclude"))
        })
        this.props.toggleModal();
    }


    render(){
        const { handleSubmit, submitting,
                pristine, importerOptions,
                modalOpen, firstRow,
                schema, reset } = this.props;
                let showSchema = _.filter(schema.data,(i)=>i != 'exclude')
        const options = _.map(importerOptions, (option, i)=><MenuItem value={i}>{option}</MenuItem>)
        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="row">
                    <div className="col-lg-6 mb-4">
                    <Field
                        name="module"
                        label="Import data"
                        id="select-module"
                        variant="outlined"
                        // value={this.state.module}
                        onChange={this.handleChange}
                        component={SelectStaticFieldRedux}
                    >{options}
                    </Field>
                    </div>
                    <div className="col-lg-6">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>First record is?</Form.Label><br />
                            <Field name="headers" component={radioButton}>
                                <FormControlLabel value={true} control={<Radio />} label="Header record" onChange={this._toggleHeader}  checked={this.state.headers=='true' && true}/>
                                <FormControlLabel value={false} control={<Radio />} label="Data record" onChange={this._toggleHeader} checked={this.state.headers=='false' && true}/>
                            </Field>
                        </Form.Group>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <p>
                            <span className='schema-modal'>{this.state.module && this.state.module + "s: "}</span>
                            <span className='schema-list'> { this.props.schema.requesting ? 'Loading...' : this.state.module && showSchema.join(', ')}</span>
                        </p>
                    </div>
                    <div className="col-lg-6">
                    <Field
                        name="file"
                        component={FileFieldRedux}
                    />
                    </div>
                    <div className="col-lg-12">
                    <div className="d-flex justify-content-end mt-4">
                    <Button 
                        variant="contained" 
                        color="secondary"
                        size="medium"
                        disabled={pristine || submitting} 
                        type="submit">
                        Next
                    </Button>
                    </div>
                    </div>
                </div>
                { !_.isEmpty(firstRow) && <ModalToMapData firstRow = {firstRow} schema={schema} formReset = {this._resetForm}/>}
            </form>
        )
    }
}

ImporterForm = reduxForm({
    form: 'ImporterForm',
    validate,
  })(ImporterForm);

  const mapStateToProps = (state, ownProps) => {
    const { importerOptions, modalOpen, firstRow, schema} = state.importer;
    return {
        importerOptions,
        modalOpen,
        firstRow,
        schema
    }
  }
  const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleModal: ()=>{dispatch({ type : 'IMPORTER/TOGGLE'})},
    insertCsvFirstLine:(data)=>{dispatch({type: 'IMPORTER/FIRST/ROW/DATA', data})},
    getSchema: (data)=>{dispatch(ImporterActions.getSchema({data}))},
    resetForm : ()=>{dispatch(reset('ImporterForm'))}
  })

  export default connect(mapStateToProps, mapDispatchToProps)(ImporterForm)