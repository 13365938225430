import React, { Component } from 'react';
import ProjectForm from '../component/ProjectForm/ProjectForm';

class AddProject extends Component {

    render() {
    	return (<>
	        <div className="row">
	            <div className=" col-xxl-12">
	                <ProjectForm {...this.props}/>
	            </div>
	        </div>
	    </>);	
    }
    
}

export default AddProject;