import React from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, } from '@material-ui/core';
import SVG from "react-inlinesvg";
import moment from 'moment';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { DialogTitle } from '@material-ui/core';
import { Form, Card, Accordion, Button } from 'react-bootstrap';
import _, { forEach } from 'lodash'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { GlobalActions } from "../../../actions/global.action";
import { ProjectActions } from "../../../actions/project.action";
import { toAbsoluteUrl, } from "../../../../_metronic/_helpers";
import { StockDetailActions } from "../../../actions/stock_detail.action";
import { SearchComponent } from '../../common/SearchComponent'
import { alertInfo, getTemplateID } from '../../../utils/common.helper';
import { Field } from 'formik';

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
    { id: 'manufacturer', numeric: false, disablePadding: false, label: 'Manufacturer' },
    { id: 'fitting_accessory', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'range_part', false: false, disablePadding: false, label: 'Part #' },
    { id: 'attachments', numeric: false, disablePadding: false, label: 'Attachments' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="remove-head-lines">
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={orderBy === row.id ? createSortHandler(row.id) : null}
                            hideSortIcon={row.id !== 'title'}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

class ProjectStockListing extends React.Component {

    state = {
        order: 'asc',
        orderBy: 'title',
        page: 0,
        dense: false,
        open: false,
        deleteRecord: {},
        rowsPerPage: 10,
        group: null,
        manufacturer: '',
        hideData:"hide-row",
        iconState: 'icon add_button ki ki-plus'
    }

    _toggleDeleteModal = (data) => {
        this.setState({
            open: !this.state.open,
            deleteRecord: data
        })
    }
    handleToggle = (event) => {
        // console.log("eve", event.target.innerHTML);
        if (event.target.innerHTML == '−'){
            event.target.innerHTML = '+';
        }else{
            event.target.innerHTML = '−';
        }
    }

    _toggleHideShowModal = (data) => {
        // let ele = document.getElementsByClassName(data.id);
        // ele.forEach(element => {
        //     console.log("ele   ============>", element.className);
        //     (this.state.hideData == "hide-row") ? element.remove('hide-row') : element.add('hide-row')
        // });
        this.setState({
            hideData: this.state.hideData == "hide-row" ? "" :"hide-row",
        })
    }
    

    componentDidMount() {
        let { workType, templates, templateSet } = this.props,
        selected_template_id = this.props.match.params.template_id,
        carried_out_id = this.props.match.params.id,
        template_id = getTemplateID(selected_template_id, carried_out_id, workType, templates, templateSet, "MANU_SCHED")
        let workTypeObj = _.find(workType, ['id', this.props.match.params.id]);
        this.props.getAllProjectStock({
            project_id: this.props.project_details.id,
            workType: workTypeObj.shortcode,
        });
    }

    handleRequestSort = (e, property) => {
        let { order, orderBy, page} = this.state;
        if(!order){
            order = 'asc';
        }
        const isDesc = orderBy === property && order === 'desc';
        this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property }, () => {
            this.props.getAllProjectStock({ sort: property, order: order === 'asc' ? -1 : 1, page, group: this.props.group_id })
        })
    }

    handleChangePage = (e, newPage) => {
        const { rowsPerPage, order, orderBy } = this.state;
        const new_page = newPage + 1;
        if (this.props.project_stocks_listing.length) {
            this.setState({ page: newPage })
            let extraFilters = {};
            if(order && orderBy){
                extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
            }
            this.props.getAllProjectStock({ current_page: new_page, per_page: rowsPerPage, ...extraFilters, group: this.props.group_id });
        }
    }

    openSidebar = () => {
        this.props.getAllProjectStock('add_new_stock_details')
    }
    handleChanged = (event, data) => {
        data.isChecked = event.target.checked;
        console.log("Eve => ", data);
        if (data.id){

            this.props.stockAttachmentCheckbox(data);
        }
    }

    handleChangeRowsPerPage = (e) => {
        this.setState({ 
            rowsPerPage: e.target.value,
            page: 0,
         })
        this.props.getAllProjectStock({ per_page: e.target.value });
    }

    handleDelete = (id) => this.props.deleteProjectStock(id)

    render() {
        const { dense, rowsPerPage, page, order, orderBy, } = this.state;
        const { project_stocks_listing, vvv, stock_group_listing, pagination, requesting, user} = this.props;
        if(project_stocks_listing && project_stocks_listing.length > 0){
            
            project_stocks_listing.map((row, index) => {
                // console.log("vvv=====>", vvv, row);
                
            })
        } 

        return (
            <>
                <div style={{ width: '100%', marginTop: '24px', }}>
                    <Paper 
                        style={{ width: '100%', marginBottom: '16px', }}
                        ref={element => {
                            if (element) element.style.setProperty('box-shadow', 'none', 'important');
                        }}
                    >
                        <div style={{ overflowX: 'auto' }}>
                            <Table
                                style={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                className="stock-table"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    listing={project_stocks_listing}
                                />
                                <TableBody>
                                    {project_stocks_listing && project_stocks_listing.length === 0 && (
                                        <TableRow tabIndex={-1}>
                                            <TableCell colSpan={5} align={"center"}>No Records Found!</TableCell>
                                        </TableRow>
                                    )}
                                    {project_stocks_listing && project_stocks_listing.length > 0 && project_stocks_listing.map((row, index) => {
                                        
                                        // console.log("row====>", row);
                                        // console.log("row.stock_attachment====>", row.stock_attachment);
                                        var unselectedAttachments = _.remove(row.stock_attachment, function (obj) {
                                            //remove if color is green or yellow
                                            return (obj.isChecked === true);
                                        });

                                        // console.log("unselectedAttachments====>", unselectedAttachments);

                                        // var unselectedArray = [];
                                        var unselectedArray = row.stock_attachment && row.stock_attachment.map(value => value.id);
                                        // console.log("unselectedArray====>", unselectedArray);
                                        
                                        const selectedStock= [];
                                        const tableRowData = [];
                                            (row.stock_id && !_.isEmpty(row.stock_id.stock_attachment)) && (row.stock_id.stock_attachment.attachments.map(element => {
                                                // console.log("here=====>", element.files, row);
                                                let hiddenClass = row.id + ' remove-underline';
                                                 return <>
                                                    {element.files.map((file, index) => {
                                                        var sel = unselectedArray && unselectedArray.includes(file.id) ? "" : "true";
                                                        var filename = file.label.replace(/^.*[\\\/]/, '');
                                                        // console.log("file===>", file);
                                                        let upload_time = file.id;
                                                        let uploader_name = file.user_name;
                                                        // console.log("isSelected====>", sel);
                                                        tableRowData.push( <Table>
                                                            <TableRow className={hiddenClass} >
                                                                <TableCell ><input type="checkbox" disabled={user.type < 4 ?false:true} defaultChecked={sel} onChange={(e) => this.handleChanged(e, { id: file.id, stock_id: row.stock_id.id, job_id: row.job_id, job_manual_stock_id: row.id })} className="checkboxs" /></TableCell>
                                                                <TableCell style={{textAlign:"left"}}>
                                                                    {filename}
                                                                </TableCell>
                                                                <TableCell>
                                                                    Uploaded by: {uploader_name?uploader_name:'N/A'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(upload_time).format("DD/MM/YYYY hh:mm:ss")}
                                                                </TableCell>
                                                                <TableCell style={{textAlign:'right'}}>
                                                                    <a href={file.value} target="_blank" >
                                                                        <i className="bg-transparent text-primary border-0 flaticon-eye"></i>
                                                                    </a>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                        )
                                                    })
                                                    }
                                                </>
                                            }))
                                        selectedStock.push(
                                            <Card key={row.id}>
                                                <Card.Header className="d-flex align-items-center justify-content-between card-pad">
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={row.id} onClick={() => { this.state.iconState == 'icon add_button ki ki-plus' ? this.setState({ iconState: 'icon add_button ki ki-minus' }) : this.setState({ iconState: 'icon add_button ki ki-plus' })}}>
                                                        <Table
                                                            style={{ minWidth: 800 }}
                                                            aria-labelledby="tableTitle"
                                                            size={dense ? 'small' : 'medium'}
                                                            className="left-margin-row"
                                                        >
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={row.id}
                                                                id={row.id}
                                                                className="row-size-handle"
                                                            >
                                                                <TableCell className="fix-column-fize-1">{row.stock_id && row.stock_id.manufacturer || ''}</TableCell>
                                                                <TableCell className="fix-column-fize-2">{row.stock_id && row.stock_id.fitting_accessories || ''}</TableCell>
                                                                <TableCell className="fix-column-fize-3">{row.stock_id && row.stock_id.range_part || ''}</TableCell>
                                                                <TableCell className="fix-column-fize-4">{(row.stock_id && !_.isEmpty(row.stock_id.stock_attachment)) && (row.stock_id.stock_attachment.attachment_count || "0")}</TableCell>
                                                                <TableCell className="fix-column-fize-5">
                                                                    <button className="trash_button text-danger" disabled={user.type < 4 ?false:true} onClick={() => this._toggleDeleteModal(row)}>
                                                                        <HighlightOffIcon />
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                        {(!_.isEmpty(row.stock_id.stock_attachment)) && (row.stock_id.stock_attachment.attachment_count > 0) ?
                                                            <span className="iconData" onClick={(e) => this.handleToggle(e)}>+</span> : <span className="iconData">+</span>}
                                                        {/* <i className="icon add_button ki ki-plus" onClick={(e) => this.handleToggle(e)}></i> */}
                                                        {/* <i className="icon add_button ki ki-minus hide" onClick={(e)=> this.handleToggle(e)}></i> */}
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                { (!_.isEmpty(row.stock_id.stock_attachment)) && (row.stock_id.stock_attachment.attachment_count > 0) &&
                                                <Accordion.Collapse eventKey={row.id}>
                                                    <Card.Body collapsible>
                                                        {tableRowData}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                                }
                                            </Card>

                                        )
                                        
                                        return (<>
                                            
                                            <TableRow 
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                                id={row.id}
                                                >
                                                <TableCell className="manage-row-space" colspan="5">
                                                    <Accordion className="accordion_custom_style">
                                                        {selectedStock}
                                                    </Accordion>
                                                </TableCell>
                                            </TableRow>

                                        </>
                                        )
                                    })}
                                    {requesting && <div className="spinner"></div>}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>

                    <Dialog
                        className="model-support"
                        modal={false}
                        contentClassName="model-top-overlay no-overlay"
                        open={this.state.open}
                    >
                        <DialogTitle id="alert-dialog-title">Project Stock</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this stock item from the project?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button
                                onClick={this._toggleDeleteModal}
                                type="button"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                            >
                                No
                            </button>
                            <button
                                onClick={() => { this.handleDelete(this.state.deleteRecord); this._toggleDeleteModal() }}
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                            >
                            <span>Remove</span>
                            </button>
                            </DialogActions>
                    </Dialog>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let project_stocks_listing = _.sortBy(state.project.project_stocks_listing.listing, (obj)=> obj.stock_id.manufacturer);
    return {
        stock_group_listing: state.project.stock_group_listing.listing,
        project_stocks_listing,
        user : state.auth.user,
        pagination: state.project.project_stocks_listing.pagination,
        requesting : state.project.project_stocks_listing.requesting,
        vvv: state.project.project_stocks_listing.listing,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllProjectStock: (data) => dispatch(ProjectActions.getAllProjectStock(data)),
    deleteProjectStock: (data) => dispatch(ProjectActions.deleteProjectStock(data)),
    openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
    stockAttachmentCheckbox: (data) => dispatch(ProjectActions.stockAttachmentCheckbox(data)),
    
})

export default connect(mapStateToProps,mapDispatchToProps)(ProjectStockListing);