import React, { Component } from 'react';
import {TextField} from "@material-ui/core";
import Listing from '../../container/customers/Listing'
import CustomerSidebar from '../../../_metronic/layout/components/right-sidebar/CustomerSidebar'

class Demo1Dashboard extends Component {
      constructor(props) {
            super(props);
            this.openSidebar = this.openSidebar.bind(this);
        }

       openSidebar() {
            document.body.classList.add("openRightBar");
        }

   render() {
    return (<>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body  border-bottom-light border-bottom pv-5 d-flex justify-content-between align-items-center flex-row">
                            <h3>Customers</h3>
                            <button onClick={this.openSidebar} type="button" className="btn btn-primary">Add New</button>
                        </div>
                        <div className="card-body pv-5 pt-0">
                            <div className="card_header d-flex align-items-center mb-5 justify-content-between">
                                <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                  <TextField
                                    label="Search"
                                    margin="normal"
                                  />
                                </div>
                            </div>
                              <Listing />
                        </div>
                    </div>
                </div>
            </div>
            <CustomerSidebar />
    </>);
  }
}


export default Demo1Dashboard;
