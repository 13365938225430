import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { InputFieldCompanyInfo, renderImageField } from '../common/FieldComponent';
import { CompanyActions } from '../../actions/company.action';
import ConfirmationPopup from '../common/ConfirmationPopup';
import WelcomePopup from '../common/WelcomePopup';
import ReasonPopup from './ReasonPopup';


const validate = values => {
    const errors = {}
    if (!values.contact_email) {
        errors.contact_email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
        errors.contact_email = 'Invalid email address'
    }

    if (!values.account_email) {
        errors.account_email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.account_email)) {
        errors.account_email = 'Invalid email address'
    }

    return errors
}
class CompanyAccountForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenConfirm: false
        }
        // console.log("const", props.isSign);
    }

    componentDidMount = () => {
        this.props.accountInfo();
    }
    _handleSubmit = (values) => {
        this.props.companyAccountInfoUpdate(values);
    }
    _cancelAlert = () => {

    }
    render() {
        const { handleSubmit, submitting, subscription_details, allowance, isCanceled } = this.props

        let total_allowance = allowance && allowance.trial && !allowance.subscribed && allowance.days + " days left";
        total_allowance = allowance && allowance.subscribed ? allowance.allowance : total_allowance
        console.log("allowance1", total_allowance);
        // let total_allowance = "";
        // if(allowance){
        // }
        let planTitle = subscription_details ? subscription_details.title : 'Select Plan';
        let planType = subscription_details ? subscription_details.duration_type : 'Not Selected';
        return (<>
            <div className="card card-custom mb-6">
                <div className="card-body border-bottom-light border-bottom d-flex justify-content-between align-items-center flex-row p-4">
                    <h3 className="mb-0">Accounts Info</h3>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                {/* <TextField
                                    label="Contact Email"
                                    margin="normal"
                                    variant="outlined"
                                /> */}
                                <Field
                                    name="contact_email"
                                    label="Contact Email"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                {/* <TextField
                                    label="Account Email"
                                    margin="normal"
                                    variant="outlined"
                                /> */}
                                <Field
                                    name="account_email"
                                    label="Account Email"
                                    margin="normal"
                                    variant="outlined"
                                    component={InputFieldCompanyInfo}
                                />
                            </div>
                        </div>
                        <div className="subscription_list">
                            <ul className="p-0 m-0 border-light border-top my-6">
                                <li className="border-light border-bottom p-4 d-flex justify-content-between p-0 m-0">
                                    <span className="">Subscription</span>
                                    <span className="plan-indicator cursor-pointer"><Link className="plan-indicator" to="/company-subscription">{planTitle} <i className="plan-indicator fa fa-edit" aria-hidden="true"></i></Link></span>
                                </li>
                                <li className="border-light border-bottom p-4 d-flex justify-content-between p-0 m-0">
                                    <span className="">Billing Plan</span>
                                    <span className="">{planType} </span>
                                </li>
                                <li className="border-light border-bottom p-4 d-flex justify-content-between p-0 m-0">
                                    <span className="">Projects Available</span>
                                    <span className=""> {total_allowance} </span>
                                </li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-between">
                                    <Link to='#' onClick={(e) => { this.setState({ isOpenConfirm: true }) }} className="btn text-danger mt-4 align-self-end">
                                        <i className="text-danger fa fa-trash"></i> Cancel Subscription
                                    </Link>
                                    <button type="submit" className="btn btn-primary mt-4 align-self-end" disabled={submitting}>{submitting ? 'Submitting....' : 'Update'}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <ConfirmationPopup
                    heading="Remove subscription"
                    message="Do you really want to cancel your Dokkit subscription? it will remove all of the data associated to your account - uploaded files, created users, your projects etc"
                    noButtonText="No"
                    yesButtonText="Yes"
                    isOpen={this.state.isOpenConfirm}
                    onClickYes={this.props.cancelSubscription}
                    onClickNo={() => { this.setState({ isOpenConfirm: false }) }}
                />
                {/* <ReasonPopup
                    heading="We hate to see you go."
                    message="Please tell us why you're leaving so that we can improve"
                    component={InputFieldCompanyInfo}
                    noButtonText="No"
                    yesButtonText="Submit"
                    userId={this.props.userId}
                    isOpen={isCanceled}
                    onClickYes={this.props.submitReason}
                /> */}
            </div>

        </>)
    }
}

CompanyAccountForm = reduxForm({
    form: 'AccountInfoForm',
    validate,
    enableReinitialize: true
})(CompanyAccountForm)

const mapStateToProps = (state, ownProps) => {
    // const selector = formValueSelector("AccountInfoForm");
    let company_account_info = state.company.company_details && state.company.company_details.account_info
    let company_id = state.company.company_details && state.company.company_details.company_id
    let allowance = state.company.trial
    // console.log("company_id", company_id);


    return {
        subscription_details: company_account_info && company_account_info.subscription_details,
        allowance,
        userId: state.auth.user && state.auth.user.id,
        isCanceled: state.company.cancelSubscription.isCanceled,
        initialValues: {
            contact_email: company_account_info && company_account_info.contact_email,
            account_email: company_account_info && company_account_info.account_email,
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    accountInfo: () => { dispatch(CompanyActions.companyAccountInfo()) },
    companyAccountInfoUpdate: (values) => { dispatch(CompanyActions.companyAccountInfoUpdate({ values })) },
    cancelSubscription: () => { dispatch(CompanyActions.cancelSubscription()) },
    // submitReason: (values) => { dispatch(CompanyActions.submitReason(values))},
})
// export default (CompanyAccountForm);
export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccountForm)