import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import { TextField } from "@material-ui/core";
import { Field, reduxForm, reset, } from 'redux-form';
import { CustomerActions } from '../../../../app/actions/customer.action';
import { InputFieldRedux, SelectFieldRedux, AutoCompleteFieldRedux } from "../../../../app/component/common/FieldComponent";
import validate from '../right-sidebar/customers/validation'

class CustomerSidebar extends Component {
    constructor(props) {
        super(props);
    }

    hideSidebar = (event) => {
        document.body.classList.remove("openRightBar");
        document.getElementById('customer-sidebar').style.display = 'none';
        // d.classList.remove("openRightBar");
        // this.props.resetForm();
    }

    handleSubmit = (data) => {
        data.form_name = 'CustomerSidebarForm'
        this.props.createCustomer({ data, resetForm: this.hideSidebar });
    }

    render() {
        const { handleSubmit, submitting, pristine } = this.props
        return (
            <>
                <div className="right_sidebar card card-custom card-stretch gutter-b" id="customer-sidebar" style={{ display: 'none' }}>
                    <div className="card-body scroll_y_100vh">
                        <div className="card_header d-flex align-items-center justify-content-between mb-4">
                            <h3 className="mb-0">Create new Customer</h3>
                            <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <form autoComplete="off" onSubmit={handleSubmit(this.handleSubmit)}>
                                    <Field
                                        label={'Customer Name'}
                                        placeholder="Enter customer name"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="title"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'Email'}
                                        placeholder="Enter email"
                                        type="email"
                                        margin="normal"
                                        variant="outlined"
                                        name="email"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'Address Line 1'}
                                        placeholder="Enter address line 1"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="address_line_1"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'Address Line 2'}
                                        placeholder="Enter address line 2"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="address_line_2"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'City'}
                                        placeholder="Enter city"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="city"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'County'}
                                        placeholder="Enter county"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="county"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'Postcode'}
                                        placeholder="Enter postcode"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="postcode"
                                        component={InputFieldRedux}
                                    />
                                    <Field
                                        label={'Reference'}
                                        placeholder="Enter reference"
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        name="reference"
                                        component={InputFieldRedux}
                                    />
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={submitting || pristine}
                                            type="submit">
                                            Save
                                  </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

CustomerSidebar = reduxForm({
    form: 'CustomerSidebarForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true,
})(CustomerSidebar);

const mapDispatchToProps = (dispatch) => ({
    createCustomer: (values) => dispatch(CustomerActions.create(values)),
    toggleSidebar: () => dispatch(CustomerActions.toggleSidebar()),
    resetForm: () => { dispatch(reset('CustomerSidebarForm')) }
})

export default CustomerSidebar;