const validate = values => {
  const errors = {}
  
  if (!values.name) {
    errors.name = 'Required Field';
  }
  if (!values.email) {
    errors.email = 'Required Field';
  }
  if (!values.role) {
    errors.role = 'Required Field';
  }
  // if (!values.stock_attachment_id) {
  //   errors.stock_attachment_id = 'Required Field';
  // }
  return errors
}

export default validate;