import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SVG from "react-inlinesvg";
import { Field} from 'redux-form';
import { toAbsoluteUrl, } from "../../../../_metronic/_helpers";
import { InputFieldRedux } from '../../../../app/component/common/FieldComponent';
import { templateActions } from '../../../../app/actions/template.action';
import { StockDetailActions } from '../../../../app/actions/stock_detail.action';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';

import { alertInfo } from '../../../../app/utils/common.helper';
import _ from 'lodash'

export const AttachmentGroupsList = (props) => {
    let { addNewAttachmentGroup, deleteAttachmentGroup, selectAttachmentGroup, userDetails, selectedAttachmentGroup, getSelectAttachmentGroup, getAttachmentGroups, group_attachments } = props
    useEffect(() => {
        getAttachmentGroups();
        getSelectAttachmentGroup();
        handleSelectAttachmentGroup();
    }, []);

    useEffect(() => {
        // handleSelectWorkType();
        handleSelectAttachmentGroup();
    }, [selectedAttachmentGroup, group_attachments])

    const [finalAttachment, setFinalAttachment] = useState([])
    const [allSelected, setAllSelected] = useState(false)
    const [isSorted, setIsSorted] = useState(true)
    const [groupName, setGroupName] = useState(false)
    const [sortTriger, setSortTrigger] = useState(false)
    const [newGroupName, setNewGroupName] = useState(false)

    const handleGroupName = (e) => {
        let group_name = e.target.value;
        // let group_name = (e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '';
        setGroupName(group_name)
    }

    const addNewStockGroup = () => {
        if (groupName){
            var regex = /^[A-Za-z0-9 ]+$/
 
            //Validate TextBox value against the Regex.
            var isValid = regex.test(groupName);
            if (!isValid) {
                alertInfo("error", "Please remove characters such as '&' and numbers from the attachment group name and try again.");
            }else{
                
                function convertToRoman(num) {
                    var roman = {M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1};
                    var str = '';
                  
                    for (var i of Object.keys(roman)) {
                      var q = Math.floor(num / roman[i]);
                      num -= q * roman[i];
                      str += i.repeat(q);
                    }
                  
                    return str;
                }
                var hasNumber = /\d/;
                // var labelArray = templateOptions.Coverlabel.split(" ");
                let result = groupName;
                if(hasNumber.test(groupName)){
                var r = /\d+/g;
                var m;
                console.log("yahoo1===>", result);
                while ((m = r.exec(groupName)) != null) {
                    let roman = convertToRoman(m[0])
                    console.log(m[0], roman);
                    result = groupName.replace(m[0], roman);
                }
                console.log("yahoo2===>", result);
                }
                addNewAttachmentGroup(result);
                getAttachmentGroups();
                setGroupName(false);
                setNewGroupName(result)
                setTimeout(function() {
                    document.getElementsByName('group_name')[0].value = ""
                }, 1000);
            }
            
        } else {
            alertInfo('error', 'Please add group name first')
        }

    }

    const deleteStockGroup = (id) => {
        // console.log("ID", id);
        if (id){
            deleteAttachmentGroup(id);
            getAttachmentGroups();    
        } else {
            alertInfo('error', 'Unable to delete group, please refresh the page first')
        }

    }

    const handleCheckBox = (e, id) => {
        // console.log("Check box", e.target.checked);
        const data = {
            userId: userDetails.id,
            attachmentGroupId: id,
            checked: e.target.checked
        };
        selectAttachmentGroup(data);
        setNewGroupName(false)
        if(!e.target.checked) {
            setAllSelected(false);
        }

    };

    const toggleWorkTypeCheckBox = (e) => {
        const data = {
            userId: userDetails.id,
            workId: 'all',
            checked: e.target.checked
        };
        selectAttachmentGroup(data);
        setAllSelected(!allSelected);
    };

    const handleSelectAttachmentGroup = () => {

        if (isSorted) {
            sortWork('asc');    
            // console.log("call from handleSelectAttachmentGroup if sortTriger")

        } else {
            sortWork('desc');
            // console.log("call from handleSelectAttachmentGroup if !sortTriger")

        }

        
        let checkedTypeCount = selectedAttachmentGroup && selectedAttachmentGroup.filter(wtype => {
            if(wtype.checked == true) return true;
        })
        // console.log("uuu2", checkedTypeCount);

        if( ( checkedTypeCount && checkedTypeCount.length === group_attachments.length) && selectedAttachmentGroup && selectedAttachmentGroup.length > 0) {
            setAllSelected(true);
        }

    };
    const sortWork = (type) => {
            function compare_item(a, b) {
                if(type == "asc"){
                    // a should come before b in the sorted order
                    if (a.label < b.label) {
                        return -1;
                        // a should come after b in the sorted order
                    } else if (a.label > b.label) {
                        return 1;
                        // and and b are the same
                    } else {
                        return 0;
                    }

                }else{

                    if (a.label > b.label) {
                        return -1;
                        // b should come after a in the sorted order
                    } else if (a.label < b.label) {
                        return 1;
                        // b and and a are the same
                    } else {
                        return 0;
                    }
                }
            }
            var finalWorkTypeList = [];
            // console.log("finalWorkTypeList list", list);
            // console.log(list, "finalWorkType.sort(compare_item)");
            var finalWorkTypeList = group_attachments.map(attachmentGroupObj => ({ ...attachmentGroupObj, ...selectedAttachmentGroup.find(selectedAttachmentGroupObj => selectedAttachmentGroupObj.id === attachmentGroupObj.id) }))
            var list = finalWorkTypeList.sort(compare_item)
            setFinalAttachment(list);
            
        }

        const sortWorkTypeList = () => {
            setIsSorted(!isSorted);
            if (isSorted) {
                // console.log("call from sortWorkTypeList if sorted")
                sortWork("desc");
            } else {
                // console.log("call from sortWorkTypeList if !sorted")
                sortWork("asc");
            }
        }

        return (
            <div>
                <div className="card-body p-0 mb-5 d-flex res_card_header justify-content-between align-items-center flex-row">
                    <div className="col-md-8">
                        <p className="session-group-text">To add a custom attachment group type the name and click 'Add Group' </p>

                    </div>
                    <div className="col-md-4">
                        <div className="d-flex full_width_res custom_width_select">
                            <Field
                                name="group_name"
                                label="Group Name"
                                variant="outlined"
                                readOnly={false}
                                component={InputFieldRedux}
                                onChange={handleGroupName}
                                className="group_name"
                            />
                            <button
                                type="button"
                                className="btn py-2 ml-3 btn-primary"
                                onClick={addNewStockGroup}
                            >
                                Add Group
                            </button>
                        </div>
                    </div>
                </div>
                <table className="table table-striped">
                    <thead className='table-dark'>
                        <tr>
                            <th scope="col">Groups</th>
                            <th>
                                <a>
                                    <i className="fa fa-sort" aria-hidden="true" onClick={sortWorkTypeList}></i>
                                </a>
                            </th>
                            <th>
                                custom Group?
                            </th>
                            <th scope="col">Visible?</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                       {/* <tr>
                            <td className='text-primary'><strong> Select All </strong></td>
                            <td className='text-primary'></td>
                            <td className='text-primary'></td>
                            <td className='text-primary'>
                                <input onChange={e => toggleWorkTypeCheckBox(e)} control={Checkbox} type="checkbox" checked={allSelected === true} />
                            </td>
                        </tr> */}
                        {
                            finalAttachment.map((work, i) =>
                                <tr key={i}>
                                    <td>{work.label}</td>
                                    <td></td>
                                    <td>{work.company_id?<button  className="d-block trash_button check-button"><CheckIcon /></button>:""}</td>
                                    <td>
                                        <input onChange={e => handleCheckBox(e, work.id)} control={Checkbox} type="checkbox" checked={work.checked === true || work.label === newGroupName} />
                                    </td>
                                    <td>{work.company_id?<button className="trash_button text-danger" onClick={() => {deleteStockGroup(work.id)}}> <SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}/></button>:""}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div >
        )
    }

    const mapStateToProps = (state) => ({
        group_attachments : _.sortBy(state.templates.templates.attachmentGroups,'label'),
        selectedAttachmentGroup: state.templates.templates.selectedAttachmentGroup??[],
        userDetails: state.auth.user
    })

    const mapDispatchToProps = (dispatch) => ({
        getAttachmentGroups: (data) => dispatch(templateActions.getAttachmentGroups(data)),
        selectAttachmentGroup: (data) => dispatch(templateActions.selectAttachmentGroup(data)),
        getSelectAttachmentGroup: (data) => dispatch(templateActions.getSelectAttachmentGroup(data)),
        addNewAttachmentGroup: (data) => dispatch(StockDetailActions.addNewAttachmentGroup(data)),
        deleteAttachmentGroup: (data) => dispatch(StockDetailActions.deleteAttachmentGroup(data)),
    })

    export default connect(mapStateToProps, mapDispatchToProps)(AttachmentGroupsList)
