import axios from 'axios'
import React from 'react';
import _ from 'lodash'
import SVG from "react-inlinesvg";
import moment from 'moment';
import { connect } from 'react-redux'
import { Form, Accordion, Card } from 'react-bootstrap'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import { toAbsoluteUrl } from "../../../../../_helpers";
import { alertInfo } from '../../../../../../app/utils/common.helper';
import { StockDetailActions } from '../../../../../../app/actions/stock_detail.action';
import DragDropArea from '../../../../../../app/component/common/DragDropArea';

const limit = 100 * 1024 * 1024;
const fileValidation = ['application/pdf', 'image/vnd.dwg', 'image/vnd.dxf', 'image/png', 'image/jpg', 'image/jpeg']


const DragHandle = sortableHandle(() => <div className="drag_handle">
    {/* <SVG src={toAbsoluteUrl("/media/svg/icons/custom/drag_handle.svg")} /> */}
    <img src="/media/img/cursor.png" width="10"></img>
</div>)


const SortableItem = sortableElement((props) => {
    // console.log("props==========>",props)
    let str = props.data.value;
    let upload_time = props.data.id;
    let uploader_name = props.data.user_name
    const url = str.split('.com/');
    // console.log("match", url);
    let url_label = url[1].split("/")
    let extension = props.data.value.split('.').pop()

    return <Card className="mb-2">
        <Card.Body>
            <Card.Header className="rounded-top rounded-0 d-flex">
                <DragHandle />
                {/* {extension == 'pdf'?
                <a href={str} target="_blank" style={{marginRight:"10px"}}>
                    <img src={toAbsoluteUrl("/media/img/pdf.png")} width="30" height="30"/>
                </a>:
                <a href={str} target="_blank" style={{marginRight:"10px"}} >
                    <img src={str} alt={props.data.label} width="30" height="30" ></img>
                </a>
                } */}

                <h4>{url_label.slice(-1)[0] }</h4>
                <h4>Uploaded by: {uploader_name?uploader_name:'N/A'}</h4>
                <h4>{moment(upload_time).format("DD/MM/YYYY hh:mm:ss")}</h4>
                <a href={str} target="_blank" >
                    <i className="bg-transparent text-primary border-0 flaticon-eye"></i>
                </a>
                <button type="button" disabled={props.user.type < 3?false:true } onClick={props.onClick} className="trash_button">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")} />
                </button>
            </Card.Header>
        </Card.Body>
    </Card>
});

const SortableContainer = sortableContainer(({children}) => {
    return <div className="div_custom_style mt-5 attachment-list-move" defaultActiveKey={0}>{children}</div>;
});

class FileUpload extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            loading: false
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => { 
        let shortcode = this.props.stockData.shortcode
        this.props.attachmentFileDrag({oldIndex, newIndex, shortcode})
    };

    removefile = (data) => {
        if(this.props.user.type < 3){
            let updateData = {
                ...this.props.stockData,
                ...data
            }
            this.props.removeAttachmentFile(updateData)
        }
    }

    onChange = (event) => {
        this.loaderActivate()
        let selectedFiles;
        if(event.dataTransfer){
            event.preventDefault();
            selectedFiles = event.dataTransfer.files;
        }else{
            selectedFiles = event.target.files
        }
        
        for (let i = 0; i < selectedFiles.length; i++) {
            if(selectedFiles.length > 10){
                alertInfo('error', `Only 10 files are allowed to upload at once.`);
                this.loaderDeactivate()
                return false
            }
            if (selectedFiles[i]){
                // console.log("Selected file==>", selectedFiles[i]);
                if (selectedFiles[i].size > limit) {
                    alertInfo('error', `Attachment size should not be greater than ${limit / 1024 / 1024} MB`);
                    this.loaderDeactivate()
                    return false
                } else if (fileValidation.indexOf(selectedFiles[i].type) === -1) {
                    // console.log("ggggggggg", selectedFiles[i].type);
                    var fileExt = selectedFiles[i].name.split('.').pop();
                    let allowExt = ['dwg', 'dxf', 'DWG', 'DXF']
                    if(allowExt.indexOf(fileExt) === -1){
                        alertInfo('error', 'Only .png, .jpg, .jpeg .dwg, .dxf & .pdf format allowed!')
                        this.loaderDeactivate()
                        return false
                    }
                } 
            }
        }
        
        const data = new FormData();
        // console.log("data====>", selectedFiles, this.props);
        // console.log("On change", this.props.user.name);
        let user_name = this.props.user.name;
        let company_id = this.props.project_details.company_id;
        
        for (var i = 0; i < selectedFiles.length; i++) {
            data.append("arrayOfFilesName", selectedFiles[i]);
        }
        // selectedFiles.forEach(file=>{
        //     data.append("arrayOfFilesName", file);
        //   });
        data.append("user_name", user_name)
        let response = axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}api/v1/stock/attachments/${company_id}`,
            data: data,
            headers:{
                Authorization: `Bearer ${this.props.token}`,
            }
        })

        const d = new Date().getTime();
        console.log(d);

        response.then(success => {
            success.data.forEach(element => {
                if(element.error){
                    alertInfo('error', element.errorMesage);
                    this.loaderDeactivate()
                }else{
                    let stockGroupId  = this.props.stockAttachment && this.props.stockAttachment.map((e) => e.id);
                    let isSubmit = stockGroupId && stockGroupId.indexOf(this.props.stockData.id) < 0;
                    let newData = {
                        label: element.key,
                        value: element.url,
                        shortcode: this.props.stockData.shortcode,
                        user_name:element.user_name,
                        id: d
                    }
                    this.props.addNewAttachmentFile(newData)
                    this.fileInput.value = "";  
                    this.loaderDeactivate()
                    if(this.props.submitForm && isSubmit){
                        this.props.submitForm();
                    }
                }
            });
        }).catch(error => {
            // console.log('error', error.response);
            alertInfo('error', error.response.data.message || error.message)
            this.loaderDeactivate()
        })
    }

    // handleDrop = (e) =>{
    //     e.preventDefault();
    //     this.loaderActivate()
    //     const selectedFile = e.dataTransfer.files[0];
    //     if (selectedFile.size > limit) {
    //         alertInfo('error', `Attachment size should not be greater than ${limit / 1024 / 1024} MB`);
    //         this.loaderDeactivate()
    //         return false
    //     } else if (fileValidation.indexOf(selectedFile.type) === -1) {
    //         alertInfo('error', 'Only .png, .jpg, .jpeg & .pdf format allowed!')
    //         this.loaderDeactivate()
    //         return false
    //     } 

    //     const data = new FormData();
    //     data.append("file", selectedFile)
    //     let response = axios({
    //         method: 'post',
    //         url: `${process.env.REACT_APP_API_URL}api/v1/stock/attachment`,
    //         data: data,
    //         headers:{
    //             Authorization: `Bearer ${this.props.token}`,
    //         }
    //     })

    //     response.then(success => {
    //         let newData = {
    //             label: success.data.data.key,
    //             value: success.data.data.url,
    //             shortcode: this.props.stockData.shortcode
    //         }
    //         this.props.addNewAttachmentFile(newData)
    //         this.fileInput.value = "";  
    //         this.loaderDeactivate()
    //     }).catch(error => {
    //         alertInfo('error', error.response.data.message || error.message)
    //         this.loaderDeactivate()
    //     })
    // }

    loaderActivate = () => {
        this.setState({loading: true})
    }

    loaderDeactivate = () => {
        this.setState({loading: false})
    }

    render(){
        let { stockData, user } = this.props
        let { loading } = this.state
        return (
            <div>
                <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                    {!_.isEmpty(stockData) && stockData.files.map((item, index) => (  
                        <SortableItem 
                            key={`item-${index}`} 
                            index={index}
                            user={user} 
                            uniqueIndex={index} 
                            data={item} 
                            onClick={()=>this.removefile(item)}
                        />
                    ))}
                </SortableContainer>
                {user.type < 4 &&<>
                    <DragDropArea handleDrop={this.onChange} disabled={this.state.loading} />
                    <Form.Group controlId={`upload_file_csv_${stockData.id}`} className="upload_custom_btn">
                        <div className="pos-relative">
                                <>
                                    <Form.Control  disabled={this.state.loading || user.type < 4 ?false:true} ref={ref => this.fileInput = ref} onChange={(e) => this.onChange(e)} className="hide" name="uploadFiles" type="file" multiple/>
                                    <span></span>
                                    <label className="upload_file_btn" htmlFor={`upload_file_csv_${stockData.id}`}>
                                        <i className="fa fa-upload"></i> {this.state.loading === false ? 'Upload' : 'Uploading...' }
                                    </label>
                                </>
                            
                        </div>
                    </Form.Group>
                </>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        project_details: state.project.project_details,
        user: state.auth.user,
        token: state.auth.user ? state.auth.user.token : '',
        stockAttachment: state.stock_detail.stockattachmentlisting && state.stock_detail.stockattachmentlisting.listing
    }
}

const mapDispatchToProps = (dispatch) => ({
    addNewAttachmentFile: (data) => dispatch(StockDetailActions.addNewAttachmentFile(data)),
    attachmentFileDrag: (data) => dispatch(StockDetailActions.attachmentFileDrag(data)),
    removeAttachmentFile: (data) => dispatch(StockDetailActions.removeAttachmentFile(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)