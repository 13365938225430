/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {checkIsActive , toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import store from '../../../../../app/utils/store'
import AsideMenuBootUp from "./AsideMenuBootUp"; 
import AsideEmailSupport from "./AsideEmailSupport"; 

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  let user = store.getState().auth.user;
  return (
      <>
        <AsideMenuBootUp />
        <div className="user_detail my-3 d-flex align-items-center px-4">
          <figure>
            <img src={user.logo || "/media/users/default.jpg"} alt="user thumbnail" />
          </figure>
          <div className="user_details">
            <h6 className="text-white mb-1 font-size-sm">{user.name}</h6>
            <label className="mb-0 text-dark-50 font-size-xs">{user.email}</label>
          </div>
        </div>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav flex-one ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
            <NavLink className="menu-link menu-toggle" style={(user.profile_completed  == 0)? { pointerEvents: "none" } : null} to="/projects">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/custom/projects.svg")} />
              </span>
              <span className="menu-text">Projects</span>
              
            </NavLink>
          </li>
          { user.type < 3 && <>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/templates">
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/custom/templates.svg")} />
                    </span>
                    <span className="menu-text">Templates</span>
                    
                  </NavLink>
                </li>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/customers">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/custom/people.svg")} />
                    </span>
                    <span className="menu-text">Customers</span>
                    
                  </NavLink>
                </li>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/contractors">
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/custom/contractor.svg")} />
                    </span>
                    <span className="menu-text">Contractors</span>
                    
                  </NavLink>
                </li>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/sites">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/custom/site.svg")} />
                    </span>
                    <span className="menu-text">Sites</span>
                    
                  </NavLink>
                </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/stock">
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/custom/stock.svg")} />
                    </span>
                    <span className="menu-text">Stock</span>
                    
                  </NavLink>
                </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/", true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                <NavLink className="menu-link menu-toggle" style={(user.profile_completed == 0) ? { pointerEvents: "none" } : null} to="/setup">
                    <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/custom/setup.svg")} />
                    </span>
                    <span className="menu-text">Setup</span>
                    
                  </NavLink>
                </li>
              </>
            }
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/help", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
            <a className="menu-link menu-toggle" target="_blank" href="http://www.dokkit.co.uk/app-support">
              <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/custom/help.svg")} />
              </span>
              <span className="menu-text">Help</span>
              
            </a>
          </li>
        </ul>
        <ul className={`menu-nav flex1 ${layoutProps.ulClasses}`}>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/support", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
              <NavLink className="menu-link menu-toggle" to="#" onClick={ ()=>store.dispatch({ type : "COMPANY/EMAIL/OPTION/TOGGLE" }) }>
                <span className="svg-icon menu-icon">
                <i className="fa fa-headphones"></i>
                </span>
                <span className="menu-text">Support</span>
              </NavLink>
            </li>
          </ul>
          <AsideEmailSupport />
        {/* end::Menu Nav */}
      </>
  );
}
