import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
let initialValues = {
    user: null,
    register: {
        data:null,
        showotp:false,
    },
}
const initialAuthState = {
    user: undefined
  };

export const authReducer = persistReducer(
    { storage, key: "v705-demo1-auth", whitelist: ["user"] },
    (state = initialValues, action) =>{
        switch(action.type){
            // login start
            case 'AUTH/LOGIN/SUCCESS':
                return {
                    ...state,
                    user: {
                        ...state.user,
                        ...action.payload.user,
                    },
                }

            case 'AUTH/UPDATE/SUCCESS':
                return {
                    ...state,
                    user: {
                        ...state.user,
                        name:action.payload.name,
                        company_name:action.payload.company_name,
                        profile_completed:action.payload.profile_completed,
                        logo: action.payload.logo
                    },
                }
            case 'AUTH/LOGOUT': {
                // TODO: Change this code. Actions in reducer aren't allowed.
                return initialValues;
                }
            // login ends

            // register start

            case 'AUTH/REGISTER/SEND_OTP':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        data:action.payload.values
                    },
                }
            case 'AUTH/REGISTER/SEND_OTP/SUCCESS':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        requesting: false,
                        showotp:true,
                        // isSignup:true
                    },
                }
            case 'AUTH/REGISTER/SEND_OTP/ERROR':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        requesting: false,
                        errors: action.payload.data
                    },
                }

            case 'AUTH/REGISTER/CHANGE_DETAILS':
                return{
                    ...state,
                    register:{
                        ...state.register,
                        showotp:action.payload.data,
                    }
                }

            case 'AUTH/REGISTER/USER/REQUESTING':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        otprequesting: true,
                    },
                }
            case 'AUTH/REGISTER/USER/SUCCESS':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        otprequesting: false,
                        showotp:false
                    },
                }
            case 'AUTH/REGISTER/USER/ERROR':
                return {
                    ...state,
                    register: {
                        ...state.register,
                        otprequesting: false,
                        errors: action.payload.data
                    },
                }
            // register ends
            default:
                return {
                    ...state
                }
        }
    }
)