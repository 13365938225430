/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {ContentRoute} from "../../_metronic/layout"
import Login from "../component/Auth/Login";
import Registration from "../component/Auth/Registration";
import ForgotPassword from "../component/Auth/ForgotPassword.js";
import ResetPassword from "../component/Auth/ResetPassword.js";
import "../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login_page_background login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white p-15"
              id="kt_login"
          >

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0 login_page_background">
                <Switch>
                <ContentRoute path="/auth/login" component={Login}/>
                <ContentRoute path="/auth/registration" component={Registration}/>
                <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                />
                <ContentRoute
                    path="/auth/reset-password"
                    component={ResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect to="/auth/login"/>
              </Switch>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
           
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
