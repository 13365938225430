export const SiteActions = {
  create,
  getAll,
  getOne,
  updateOne,
  deleteOne,
  saveFilterValue,
  toggleSidebar
}

function create(data){
  return { type : "SITE/CREATE", payload: data }
}

function getAll(data){
  return { type : "SITE/GET/ALL", payload: data }
}

function getOne(id){
  return { type : "SITE/GET/ONE", payload: id }
}

function updateOne(id){
  return { type : "SITE/UPDATE/ONE", payload: id }
}

function deleteOne(id){
  return { type : "SITE/DELETE/ONE", payload: id }
}

function saveFilterValue(data){
  return { type : "SITE/FILTER/SAVE", payload: data }
}

function toggleSidebar(){
  return { type : "SITE/SIDEBAR/TOGGLE" }
}