import _ from 'lodash'

let initialValues = {
  right_sidebars: []
}

export const globalReducer = (state = initialValues, action) => {
    switch (action.type) {

       case "GLOBAL/OPEN_SIDEBAR/SUCCESS": 
            let activate_sidebar = state.right_sidebars
                activate_sidebar[action.payload] = true
                document.body.classList.add("openRightBar");
            return {
                ...state,
                right_sidebars: activate_sidebar
            }
        break;

        case "GLOBAL/CLOSE_SIDEBAR/SUCCESS": 
            let deactivate_sidebar = state.right_sidebars
                deactivate_sidebar[action.payload] = false
                document.body.classList.remove("openRightBar");
            return {
                ...state,
                right_sidebars: deactivate_sidebar
            }
        break;

        default:
            return {
                ...state
            }
    }
}