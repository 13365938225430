export const CompanyActions = {
    companyDetailForm,
    getCompanyInfo,
    companyAccountInfo,
    companyAccountInfoUpdate,
    cancelSubscription,
    submitReason,
    getTrialPeriod,
    sendSupportEmail
}

function companyDetailForm(companyData) {
    return { type: "COMPANY/DETAIL/FORM", payload: companyData }
}

function getCompanyInfo(companyData) {
    return { type: "COMPANY/GET/DETAIL/REQUEST", payload: companyData }
}

function companyAccountInfo() {
    return { type: "COMPANY/GET/ACCOUNT/INFO/REQUEST"}
}

function companyAccountInfoUpdate({values}) {
    return { type: "COMPANY/POST/ACCOUNT/INFO/REQUEST", payload: values}
}

function cancelSubscription(){
    return { type: "COMPANY/POST/CANCEL/SUBSCRIPTION/REQUEST" }
}

function submitReason(values) {
    return { type: "COMPANY/POST/CANCEL/SUBSCRIPTION/REASON", payload: values}
}

function getTrialPeriod(){
    return { type: "COMPANY/GET/TRIAL_PERIOD/REQUEST" } 
}

function sendSupportEmail(data){
    return { type: "COMPANY/POST/SUPPORT_EMAIL/REQUEST", payload: data } 
}