import React from 'react';
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';
import { blue } from '@material-ui/core/colors';
import AddNewService from '../../../../../app/component/common/AddNewService';
import { Field, reduxForm, reset, } from 'redux-form';
import { MainServiceActions } from '../../../../../app/actions/main_service.action';
import { ContractorActions } from '../../../../../app/actions/contractor.action';
import { InputFieldRedux, SelectFieldRedux, AutoCompleteFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'
import { GlobalActions } from "../../../../../app/actions/global.action";

class UpdateContractor extends React.Component {

    state = {
      addService: false,
    }
    componentDidMount(){
      this.props.mainServiceListing({ per_page: 0 });
    }

    _toggleAddService = () =>{
      this.setState({
        addService: !this.state.addService
      })
    }

    hideSidebar = () => {
        this.props.closeRightSidebar('update_contractor')
        // document.body.classList.remove("openRightBar");
        // document.getElementById('update-contractor-sidebar').style.display = 'none';
        // this.props.toggleSidebar();
        this.setState({
          addService: false
        })
    }

    handleSubmit = (data) => {
      const service_id = data.service_id ? data.service_id.value : null;
      if(!service_id){
        delete data.service_id
      }else{
        data.service_id = data.service_id.value;
      }
      const newData = { ...data, ...{ id : (this.props.contractor_detail ? this.props.contractor_detail.id : 0) }}
      this.props.updateContractor({newData, resetForm: this.hideSidebar});
    }

    render(){
      const { handleSubmit, submitting, right_sidebar, pristine, main_service_listing,requesting } = this.props
      return (
        <>
          {right_sidebar && 
            <div id="update-contractor-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Update Contractor</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                        {requesting ? <div class="spinner"></div> :
                        <>
                            <Field
                              label={'Contractor Name'}
                              placeholder="Enter contractor name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="name"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Email'}
                              placeholder="Enter email"
                              type="email"
                              margin="normal"
                              variant="outlined"
                              name="email"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Contact Number'}
                              placeholder="Enter Contact Number"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="contact"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Website'}
                              placeholder="Enter Website Address"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="website"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 1'}
                              placeholder="Enter address line 1"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_1"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 2'}
                              placeholder="Enter address line 2"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_2"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'City'}
                              placeholder="Enter city"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'County'}
                              placeholder="Enter county"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="county"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Postcode'}
                              placeholder="Enter postcode"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="postcode"
                              component={InputFieldRedux}
                            />
                              {this.state.addService ?
                              <AddNewService toggleAddService = {this._toggleAddService}/>
                              :
                              <div className="d-flex custom-select-field-with-plus">
                                <Field
                                  label={'Main Service'}
                                  name="service_id"
                                  component={AutoCompleteFieldRedux}
                                  options={main_service_listing}
                                />
                                <button className="add_button fa fa-plus text-primary ml-2"  onClick={this._toggleAddService}></button>
                              </div>
                              }
                             
                            <div className="d-flex justify-content-end mt-3">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Update
                              </Button>
                            </div>
                        </>}
                            
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}

UpdateContractor = reduxForm({
  form: 'UpdateContractor',
  validate,
  enableReinitialize: true
})(UpdateContractor);

UpdateContractor = connect(state => {
  const { detail } = state.contractor.contractorlisting
  const main_service = {
    label: detail.main_service && (detail.main_service.service_name || ""),
    value: detail.main_service && (detail.main_service.id || ""),
  }
  let initialValues = {
    name: detail.label,
    contact: detail.contact,
    address_line_1: detail.address_line_1,
    address_line_2: detail.address_line_2,
    city: detail.city,
    county: detail.county,
    postcode: detail.postcode,
    website: detail.website,
    service_id: main_service,
  };
  if(detail.email){
    initialValues.email = detail.email;
  }
  return {
    right_sidebar: state.global.right_sidebars['update_contractor'],
    initialValues
  }
},
)(UpdateContractor)

const mapStateToProps = (state, ownProps) => {
  const { mainservicelisting: { data, } } = state.main_service;
  const { contractorlisting: { detail, requestingDetail } } = state.contractor;
  return {
    main_service_listing: data,
    contractor_detail: detail,
    requesting:requestingDetail,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateContractor: (values) => dispatch(ContractorActions.updateOne(values)),
  mainServiceListing: (values) => dispatch(MainServiceActions.getAll(values)),
  toggleSidebar: () => dispatch(ContractorActions.toggleSidebar()),
  resetForm:()=>{dispatch(reset('UpdateContractor'))},
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContractor)
