import React from 'react';
import { shallowEqual, useSelector } from "react-redux";

import PlanListing from '../../_metronic/container/subscription/planListing';
import CardPaymentDetail from '../../_metronic/container/subscription/cardPaymentDetail';


const CompanySubscription = (props) => {
    let {company} = useSelector(
        ({company}) => ({
            company: company.trial,
        }),
        shallowEqual
    );
    return (<>
        <div className="row">
            {company && !company.trial && !company.subscribed && 
            <div className="col-lg-12 col-xxl-12 bg-warning pos-relative p-4 mb-5 text-center d-flex align-items-center justify-content-center">
                <p className="m-0">Your trial period has ended but we'd hate to see you go</p>
                {/* <button className="bg-transparent border-0 text-white flaticon-cancel"></button> */}
            </div>
            }
            <PlanListing {...props}/>
            <CardPaymentDetail {...props}/>
        </div>
    </>);
}

export default CompanySubscription;
