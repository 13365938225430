import React, { Component } from "react";
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'; 
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { AuthActions } from '../../../actions/auth.action';
import { CompanyActions } from '../../../actions/company.action';
import { InputFieldAuth, InputFieldCompanyInfo } from "../../common/FieldComponent";
import ReasonPopup from '../../companyinfo/ReasonPopup';


const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email field is required!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }
    if (!values.password) {
        errors.password = 'Password field is required!'
    }
    return errors
}

class LoginForm extends Component{
 
    render(){
        const { handleSubmit, submitting, pristine, login, isCanceled } = this.props
        return(
            <>
                <form
                    onSubmit={handleSubmit(login)}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="text-center mb-10 mb-lg-15">
                        <h3 className="font-size-h1">Sign In</h3>
                    </div>
                    
                    <Field 
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="email"
                        component={InputFieldAuth}
                    />

                    <Field 
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="password"
                        component={InputFieldAuth}
                    />
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Link
                        to="/auth/forgot-password"
                        className="text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={submitting||pristine}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        {submitting ? 'Submitting..': 'Submit' }
                        {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                    </button>
                    </div>
                    <div className="text-center">
                        <span className="font-weight-bold ">Don't have an account yet here?</span>
                        <Link  to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
                    </div>
                    <div className="text-center mt-4">
                        <span className="font-weight-bold ">If you are a simPRO user please click</span>
                        <a target="_blank" href="https://app.dokkit.co.uk/login" className="font-weight-bold ml-2">here</a>
                    </div>
                </form>
                <ReasonPopup
                    heading="We hate to see you go."
                    message="Please tell us why you're leaving so that we can improve"
                    component={InputFieldCompanyInfo}
                    noButtonText="No"
                    yesButtonText="Submit"
                    userId={this.props.userId}
                    isOpen={isCanceled}
                    onClickYes={this.props.submitReason}
                />
            </>
        )
    }
}
LoginForm = reduxForm({
    form: 'LoginForm',
    validate,
    enableReinitialize: true
})(LoginForm);

const mapStateToProps = (state, ownProps) => {
    return {
        userId: state.company.cancelSubscription.user_id,
        isCanceled: state.company.cancelSubscription.isCanceled,
      }
}

const mapDispatchToProps = (dispatch) => ({
    login: (values) => {dispatch(AuthActions.login({values}))},
    submitReason: (values) => { dispatch(CompanyActions.submitReason(values))},
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)