import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form'; 

import { InputFieldAuth } from "../common/FieldComponent";
import { AuthActions } from "../../actions/auth.action";

const validate = values => {
    const errors = {}
    if (!values.otp) {
        errors.otp = 'Required'
    }
    return errors
}

class ValidateEmail extends Component {

    constructor(props) {
        super(props);
        this.state={
            open:this.props.showOtp,
        }
    }
    // submit otp
    submitOtp = (values) =>{
        let valuesToSend = {...this.props.userDetails.values,...values}
        this.props.register(valuesToSend);
    }

    _handleClose = () => {
        this.setState({
            open:false
        });
        this.props.changeDetails();
    };

    _handleSubmit = (values) =>{
        let {registerData, register} = this.props
        let valuesToSend = {...registerData,...values}
        register(valuesToSend)
    }

    render() {
        const { handleSubmit, submitting, pristine } = this.props
        return (
                <Dialog 
                    className="model-support"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.open}
                >
                    <DialogTitle id="alert-dialog-title">{"Please enter your One Time Password sent to your email address:"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Field 
                            placeholder="OTP"
                            type="otp"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="otp"
                            component={InputFieldAuth}/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                        onClick={this._handleClose}
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                        >
                        Change Email
                        </button>
                        <button
                            // type="submit"
                            onClick={handleSubmit(this._handleSubmit)}
                            disabled={submitting||pristine}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            <span>{submitting ? 'Submitting..': 'Submit' }</span>
                            {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>
                    </DialogActions>                   
                </Dialog>    
        );
    }
}

ValidateEmail = reduxForm({
    form: 'ValidateEmail',
    validate,
    enableReinitialize: true
})(ValidateEmail);

const mapStateToProps = (state, ownProps) => {
	return {
        showOtp: state.auth.register.showotp,
        registerData: state.auth.register.data
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    changeDetails: (data) => { dispatch({ type : "AUTH/REGISTER/CHANGE_DETAILS", payload: {data: false} }) },
    register: (data) => { dispatch(AuthActions.register(data)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmail);