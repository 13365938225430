import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

// Get all stocks
function* getAll({ payload }){
    try {
        const response =  yield race({
            success: call(getResponse, {
                url:'/api/v1/stock/group',
                method: 'GET',
                params: payload,
            })
        });
        if(response.success){
            yield put({
                type: "STOCK/GET/ALL/SUCCESS",
                payload: response.success.data.data
             });
        }
    } catch(error) {
        
        yield put({
            type: "STOCK/GET/ALL/ERROR",
         });
        if(error.response){
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

// Create Stock
function* create({ payload }){
  const form_name = 'AddStock'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try{
      const response =  yield race({
          success: call(getResponse, {
              url:'/api/v1/stock/group',
              method: 'POST',
              data: payload.data,
          })
      });
      if(response.success){
          yield put({ 
            type: "STOCK/CREATE/SUCCESS", 
            payload: response.success.data.data
          });
          alertInfo('success', 'Stock group created successfully')
        //   document.body.classList.remove("openRightBar");
        //   this.props.dispatch(reset(form_name));
          payload.resetForm();
          yield put({ type: "STOCK/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch (error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      if(error.response){
          const { response: { data } } = error
          yield put({ 
              type: "GLOBAL/FORM_ERROR", 
              payload: {
                  error: data.data, 
                  form_name: form_name
              } 
          });
          //alertInfo('error', error.response.data);
      }
      
  }
}

// Delete one stock
function* deleteOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/stock/group/${payload.data.id}`,
              method: 'DELETE',
              data: payload.data
          })
      });
      if(response.success){
          yield put({
              type: "STOCK/DELETE/ONE/SUCCESS",
              payload: response.success.data.data
           });
           payload.refreshList(payload.listData);
           alertInfo('success', 'Stock deleted successfully')
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}


// Get one Stock
function* getOne({ payload }){
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/stock/group/${payload}`,
              method: 'GET',
          })
      });
      if(response.success){
          yield put({
              type: "STOCK/GET/ONE/SUCCESS",
              payload: response.success.data.data
           });
      }
  } catch(error) {
      if(error.response){
          const { response: { data } } = error
          alertInfo('error', data.message)
      }
  }
}

// Update one Stock
function* updateOne({ payload }){
  const stock_id = payload.id;
  delete payload.id;
  const form_name = 'UpdateStock'
  yield put({ type: "GLOBAL/FORM_START", form_name });
  try {
      const response =  yield race({
          success: call(getResponse, {
              url:`/api/v1/stock/group/${stock_id}`,
              method: 'PUT',
              data: payload
          })
      });
      if(response.success){
          yield put({
              type: "STOCK/UPDATE/ONE/SUCCESS",
              payload: response.success.data.data
           });
          alertInfo('success', 'Stock updated successfully')
          document.body.classList.remove("openRightBar");
          this.props.dispatch(reset(form_name));
          yield put({ type: "STOCK/SIDEBAR/TOGGLE", });
      }
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
  } catch(error) {
      yield put({ type: "GLOBAL/FORM_STOP", form_name });
      const { response: { data } } = error
      yield put({ 
          type: "GLOBAL/FORM_ERROR", 
          payload: {
              error: data.data, 
              form_name: form_name
          } 
      });
  }
}

export default function* stockSaga() {
    yield all([
      takeLatest("STOCK/GET/ALL", getAll),
      takeLatest("STOCK/CREATE", create),
      takeLatest("STOCK/DELETE/ONE", deleteOne),
      takeLatest("STOCK/GET/ONE", getOne),
      takeLatest("STOCK/UPDATE/ONE", updateOne),
  ]);
}