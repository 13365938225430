import React, { Component } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap'
import { Tooltip } from '@material-ui/core';
import SVG from "react-inlinesvg";
import _ from 'lodash';
import { connect } from 'react-redux'
import { Field, reduxForm, reset } from 'redux-form';
import {templateActions} from '../actions/template.action';
import { toAbsoluteUrl, } from "../../_metronic/_helpers";
import AddNewTemplateSet from '../component/template/AddNewTemplateSet';
import DeleteTemplateSet from '../component/template/DeleteTemplateSet';
import Template from '../component/template/Template';
import Ripples from 'react-ripples'


class TemplateSet extends Component {

   state = {
      deleteTempSet: {},
      readMoreState : false,
    }

   componentDidMount = () =>{
      this.props.getTemplateSetListing()
      this.props.getTemplatesListing()
      this.props.getWorkType()
   }

   _handleSubmit = () => {

      let tmpset_id = this.state.deleteTempSet.id;
      this.props.deleteTemplateSet({tmpset_id, clearModal:this._clearDeleteTemplateSet})
	}

   _clearDeleteTemplateSet = () => {
		this.setState({
			deleteTempSet: {},
      })
      this.props.deleteToggle()
	}

  handleReadMoreText = () => {
    this.setState({
			readMoreState: this.state.readMoreState == false?true:false,
      })
  }

	_setTempSetToBeDeleted = (tmpSet) => {
		this.setState({
			deleteTempSet: tmpSet
      })
      this.props.deleteToggle()
	}

   render() {

      const templateSet = []
      let morinfo;
      let companyTemplateSet = this.props.templateSet.data;
      companyTemplateSet.map((set, index) => {

			let btnDelete = null
			if (index > 0) {
				btnDelete = (
					<button  className="trash_button" title="Delete Template Set" onClick={(e) => {
						e.stopPropagation();
						this._setTempSetToBeDeleted(set)
					}}> <SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}/></button>
				)
			}

      morinfo = <div className='readmore' key="morinfo">
          <Accordion.Toggle as={Button} variant="link" eventKey={index} onClick={this.handleReadMoreText}>
          <h3 className="mb-0 text-capitalize font-size-heading">
            {this.state.readMoreState == false? "More details":"Less View"}
          </h3>
          </Accordion.Toggle>
        <Accordion.Collapse eventKey={index}>
          <Card.Body collapsible="true">
              <p>The Front Page of the templates area is where you can add your company logo and branding. If you would like us to create a complimentary design for this page please contact support by clicking the link in the bottom left.</p>

              <p>Each workgroup has three distinct files Main, Cover Sheet and Manufacturers Schedule.</p>

              <p>The Main file contains the majority of boiler plate info but also where the dynamic information for the scope of works will go so be careful not to remove those curly brackets.</p>

              <p>The Cover Sheet is updated automatically from the attachment groups you add on a project. You can add your logo, headers, footers etc. on this page.</p>

              <p>The Manufacturers Schedule contains the table that will reflect the stock chosen on the project. You can change the table design and the headers but don't remove the information in the table body with curly brackets.</p>

              <p>To edit these download the word file and make any necessary adjustments and re-upload the word file. If you need to make substantial changes to the main file these need to be reflected on the Front Page Table of Contents. Unless you very confident doing this we would suggest you send the file along with the Front Page to <b>support@dokkit.co.uk</b> and we will ensure that these are updated correctly.</p>

          </Card.Body>
        </Accordion.Collapse>
      </div>

			templateSet.push(
				<Card key={index}>
               <Card.Header className="d-flex align-items-center justify-content-between p-2 border-bottom border-bottom-light">
                      <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                      <h3 className="mb-0 text-capitalize font-size-heading">
                                {set.template_set_name}
                        {(set.template_set_name == 'Master template') ? 
                          
                                <Tooltip arrow placement="right" title="Select Help from the navigation menu for more information on changing your templates"><i className="fas fa-info-circle ml-2"></i></Tooltip>
                          : ""}
                          </h3>
                        <i className="icon add_button ki ki-plus"></i>
                      </Accordion.Toggle>
                      {btnDelete}
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body collapsible="true">
                          <Template template_set={set} key={`tmp_set_${set._id}`} />
                      </Card.Body>
                    </Accordion.Collapse>
				</Card>

			)

      })

    return (<>
            <div className="row">
                <Accordion className="accordion_custom_style">
                    {morinfo}
                </Accordion>
                <div className="col-xxl-12">
                    <div className="card">
                        <Accordion className="accordion_custom_style">
                           {templateSet}
                          </Accordion>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end mt-5">
                                      <Ripples color="rgba(0,0,0,0.2)">   
                                        <button className="btn add_templete_button mt-4 align-self-end" onClick={this.props.addNewTemplateToggle}>Add New Template Set</button>
                                      </Ripples>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.showTemplateSetFormDelete && <DeleteTemplateSet deleteTempSet={this.state.deleteTempSet} clearModal={this._clearDeleteTemplateSet}/>}
                        {this.props.showTemplateSetForm && <AddNewTemplateSet/>}
                    </div>
                
            </div>
    </>);
  }
}

const mapStateToProps = (state, ownProps) => {
    const { templates: { workType, templateSet, templates } } = state;
    return {
      workType,
      templateSet,
      templates,
      showTemplateSetForm: templateSet.showTemplateSetForm,
      showTemplateSetFormDelete:templateSet.showTemplateSetFormDelete
    }
  }
  
const mapDispatchToProps = (dispatch, ownProps) => ({
    getWorkType: () => dispatch(templateActions.getWorkType()),
    getTemplateSetListing: () => dispatch(templateActions.getTemplateSet()),
    getTemplatesListing: () => dispatch(templateActions.getTemplates()),
    deleteTemplateSet: (data) => dispatch(templateActions.deleteTemplateSet(data)),
    deleteToggle: () => { dispatch({ type : "TEMPLATES/TEMPLATESET/DELETE/TOGGLE"}) },
    addNewTemplateToggle: ()=>{ dispatch({ type : "TEMPLATES/TEMPLATESET/TOGGLE"}) },
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSet)
