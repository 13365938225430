import React, { Component } from "react";
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'; 
import { Link } from "react-router-dom";

import { AuthActions } from '../../../actions/auth.action';
import { InputFieldAuth, CheckBoxTermRedux } from "../../common/FieldComponent";
import ValidateEmail from '../../common/ValidateEmail';


const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email field is required!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address!'
    }
    if (!values.password) {
        errors.password = 'Password field is required!'
    } else if (!/^(?=.*\d).{6,}$/.test(values.password)) {
        errors.password = 'Must be minimum of 6 characters with one number'
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Confirm Password field is required!'
    } else if ( values.password !== values.confirmPassword ) {
        errors.confirmPassword = 'Password and Confirm Password does not match'
    }

    if (!values.acceptTerms) {
        errors.acceptTerms = 'Please Accept T & C'
    }
    return errors
}

class RegisterForm extends Component{
 
    render(){
        const { handleSubmit, submitting, pristine, sendOtp, showOtp } = this.props
        return(
            <>
                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={handleSubmit(sendOtp)}
                >
                    <div className="text-center mb-10 mb-lg-15">
                    <h3 className="font-size-h1">Sign Up</h3>
                    </div>
                    
                    {/* begin: email */}
                        <Field 
                            placeholder="User email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="email"
                            component={InputFieldAuth}/>
                    {/* end: email */}

                    {/* begin: Password */}
                        <Field 
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="password"
                            component={InputFieldAuth}/>
                    {/* end: Password */}

                    {/* begin: Confirm Password */}
                        <Field 
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="confirmPassword"
                            component={InputFieldAuth}/>
                    {/* end: Confirm Password */}

                    {/* begin: Terms and Conditions */}
                    <div className="d-flex align-items-center justify-content-end term-condition">
                        {/* <Field
                            component={CheckBoxTermRedux} 
                            type="checkbox"
                            name="acceptTerms"
                            className="mb-0"/>}
                        {/* end: Terms and Conditions */}
                        <div className="form-group d-flex flex-wrap justify-content-between mb-0">
                        <button
                            type="submit"
                            disabled={submitting||pristine}
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        >
                            {submitting ? 'Submitting..': 'Submit' }
                            {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                        </button>

                        {/* <Link to="/auth/login">
                            <button
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                            >
                            Cancel
                            </button>
                        </Link> */}
                        </div>
                    </div>

                    <div className="text-center mt-5">
                        <span className="font-weight-bold ">Already have an account?</span>
                        <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">Sign In!</Link>
                    </div>
                    <div className="text-center mt-4">
                        <span className="font-weight-bold ">If you are a simPRO user please click</span>
                        <a target="_blank" href="https://app.dokkit.co.uk/signup?plan=1" className="font-weight-bold ml-2">here</a>
                    </div>
                </form>
                {showOtp && <ValidateEmail userDetails={""} />}
            </>
        )
    }
}
RegisterForm = reduxForm({
    form: 'RegisterForm',
    validate,
    enableReinitialize: true
})(RegisterForm);


const mapStateToProps = (state, ownProps) => {
	return {
        showOtp: state.auth.register.showotp,
     }
}

const mapDispatchToProps = (dispatch) => ({
    sendOtp: (values)=>{dispatch(AuthActions.sendOtp({values}))}
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)