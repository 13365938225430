import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TextField } from "@material-ui/core";
import Listing from '../../../_metronic/container/projects/Listing';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Ripples from 'react-ripples'
import { SearchComponent } from '../../component/common/SearchComponent';
import { ProjectActions } from "../../../app/actions/project.action";


class Header extends Component {

    constructor(props) {
        super(props);
        this._handleChange = _.debounce(this.searchContractor, 800)
        this.lastSearchItem = "";
    }

    componentDidMount = () => {
        // this.props.dropdownListing();
    }
    _handleSelect = (event) => {
        this.props.selectedDropdown(event.target.value);
    }

    searchContractor = (e) => {
        const value = e.target.value.replace(/\s+/g, ' ').trim()
        if (this.lastSearchItem == value) {
            return false;
        }
        if (value.length === 0 || value.length > 2) {
            this.props.saveFilterValue(value);
            this.props.projectListing({ title: value })
            this.lastSearchItem = value;
        }
    }

    render() {
        const { listing, requesting, selected, user } = this.props;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card card-custom card-stretch gutter-b">
                            <div className="card-body border-bottom-light border-bottom pv-5 res_card_header d-flex justify-content-between align-items-center flex-row">
                                <h3>Projects</h3>
                                <div className="d-flex align-items-center full_width_res wraps">
                                    {/* <label className="text-dark-50 mr-3 mb-0">Manual:</label> */}
                                    {/* <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                                        <Form.Control
                                            as="select"
                                            onChange={this._handleSelect}
                                        >
                                            <option> All</option>
                                            {listing.map((data, index) => {
                                                return (
                                                    <option value={data.shortcode} key={index}> {data.title}</option>
                                                )
                                            })
                                            }
                                        </Form.Control>
                                    </Form.Group> */}

                                    {/* <Link to={selected ? "add_project" : "#"} className="btn ml-3 btn-primary" */}
                                    {(user.type>2)?<div className='cursor-not-allowed'>
                                        <Link to="add_project" disable={true} className="btn ml-3 btn-primary cursor-not-allowed-inner"
                                        // style={!selected ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                                    >New Project</Link>
                                    </div>
                                    :
                                    <Link to="add_project" className="btn ml-3 btn-primary"
                                    >New Project</Link>
                                    
                                    }
                                </div>
                            </div>
                            <div className="card-body pv-5 pt-0 pb-2">
                                <div className="card_header d-flex align-items-center mb-5 justify-content-between">
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <SearchComponent
                                            label={"Search"}
                                            margin={"normal"}
                                            name={"search_field"}
                                            handleChange={this._handleChange}
                                            helperText={"Enter at least 3 characters to search"}
                                        />
                                    </div>
                                </div>
                                <Listing {...this.props}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        );
    } 
}

const mapStateToProps = (state, ownProps) => {
    const { dropdownListing: { data, requesting } } = state.project;
    let selected = state.project.selectedDropdown;
    return {
        user : state.auth.user,
        listing: data,
        requesting: requesting,
        selected
    }
}


const mapDispatchToProps = (dispatch) => ({
    dropdownListing: (data) => dispatch(ProjectActions.getDropdownListing(data)),
    projectListing: (data) => dispatch(ProjectActions.getAll(data)),
    saveFilterValue: (data) => dispatch(ProjectActions.saveFilterValue(data)),
    selectedDropdown: (data) => dispatch(ProjectActions.selectedDropdown(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);