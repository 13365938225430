let initialValues = {
    plan: {
        requesting: false,
        planData: [],
        taxData: []
    },
    pre_selected_plan: null,
    selected_subscription: null,
    selected_priority:null,
    payment_detail_requesting:false,
    payment_detail_success_message: "",
    client_secret_for_auth:null,
    three_d_secure_processing: false,
}

export const subscriptionReducer = (state = initialValues, action) => {
    switch (action.type) {
        case "SUBSCRIPTION/GET/ALLPLAN/SUCCESS":
            return {
                ...state,
                plan: {
                    requesting: false,
                    planData: action.payload && action.payload.subscription ? action.payload.subscription : [],
                    taxData: action.payload && action.payload.taxes ? action.payload.taxes : []
                },
            }
        case "SUBSCRIPTION/GET/ALLPLAN/FAILURE":
            return {
                plan: {
                    requesting: false,
                    planData: [],
                    taxData: []
                }
            }
        case "SUBSCRIPTION/PLAN/SUBSCRIBE/SUCCESS":
            return {

            }
        case "SUBSCRIPTION/SELECTEDPLAN":
            return {
                ...state,
                selected_subscription: action.payload,
            }

        case "SUBSCRIPTION/CREATEPAYMENTINTENT":
            return {
                ...state,
                payment_detail_requesting:true
            }
        case "SUBSCRIPTION/PAYMENTMETHOD/REQUEST":
            return{
                ...state,
                payment_detail_requesting:true
            }
        case "SUBSCRIPTION/UPDATE/PLAN/REQUEST":
            return{
                ...state,
                payment_detail_requesting:true
            }

        case "SUBSCRIPTION/CREATEPAYMENTINTENT/ERROR":
            return {
                ...state,
                payment_detail_requesting:false
            }

        case "SUBSCRIPTION/CREATEPAYMENTINTENT/SUCCESS":
            return{
                ...state,
                client_secret_for_auth: action.payload || null,
                payment_detail_requesting:false,
                payment_detail_success_message: action.payload.message
            }
        case "SUBSCRIPTION/CREATEPAYMENTINTENT/RESET":
            return{
                ...state,
                client_secret_for_auth: null
            }
        case "SUBSCRIPTION/CURRENT/PLAN/SUCCESS":
            return{
                ...state,
                pre_selected_plan:action.payload.subscription_id,
                selected_subscription: action.payload.subscription_id,
                selected_priority: action.payload.priority % 2 == 0 ? true : false
            }
        case "SUBSCRIPTION/CURRENT/PAYMENTSUCCESS":
            return{
                ...state,
                pre_selected_plan:action.payload.subscription_id,
            }

        case "SUBSCRIPTION/CHANGE/SUBSCRIPTION/TYPE":
            return{
                ...state,
                selected_priority: !state.selected_priority
            }
        case "SUBSCRIPTION/THREE_D_SECURE/TOGGLE":
            return{
                ...state,
                three_d_secure_processing: !state.three_d_secure_processing
            }
        default: {
            return {
                ...state
            }
        }
    }
}