import React, {Component} from 'react';
import ProjectEditForm from '../component/ProjectForm/ProjectEditForm';

class EditProject extends Component {

    render() {
    	return (<>
	        <div className="row">
	            <div className=" col-xxl-12">
	                <ProjectEditForm {...this.props}/>
	            </div>
	        </div>
	    </>);	
    }
    
}

export default EditProject;