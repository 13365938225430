import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { Field, reduxForm, reset } from 'redux-form'; 

import { InputFieldAuth } from "../common/FieldComponent";
import { templateActions } from "../../actions/template.action";
import Ripples from 'react-ripples'


const validate = values => {
    const errors = {}
    if (!values.title) {
        errors.title = 'Title field is required!'
    }
    return errors
}

class AddNewTemplateSet extends Component {

    constructor(props) {
        super(props);
        this.state={
            open:this.props.showTemplateModal,
        }
    }

    _handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancelNewTemplateSet();
    };

    _handleSubmit = (values) =>{
        let {createTemplateSet} = this.props
        createTemplateSet({data:values, resetForm: this.props.resetForm})
    }

    render() {
        const { handleSubmit, submitting, pristine } = this.props
        return (
                <Dialog 
                    className="model-support template_modal_dialog"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.open}
                >
                    <DialogTitle id="alert-dialog-title">{"Add New Template Set"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Field 
                            placeholder="Template Set Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="title"
                            component={InputFieldAuth}/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Ripples color="rgba(0,0,0,0.2)">  
                            <button
                            onClick={this._handleClose}
                            type="button"
                            className="btn btn-light-primary font-weight-bold px-9 py-4"
                            >
                            Cancel
                            </button>
                        </Ripples>    
                        <Ripples color="rgba(0,0,0,0.2)"> 
                            <button
                                // type="submit"
                                onClick={handleSubmit(this._handleSubmit)}
                                disabled={submitting||pristine}
                                className="btn btn-primary font-weight-bold px-9 py-4"
                            >
                                <span>{submitting === true ? "Submitting.." : "Submit"}</span>
                                {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
                            </button>
                        </Ripples>    
                    </DialogActions>                   
                </Dialog>    
        );
    }
}

AddNewTemplateSet = reduxForm({
    form: 'AddNewTemplateSet',
    validate,
    enableReinitialize: true
})(AddNewTemplateSet);

const mapStateToProps = (state, ownProps) => {
    const { templates: { workType, templateSet, templates } } = state;
	return {
        showTemplateModal: templateSet.showTemplateSetForm,
        // registerData: state.auth.register.data
     }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    cancelNewTemplateSet: (data) => { dispatch({ type : "TEMPLATES/TEMPLATESET/TOGGLE"}) },
    createTemplateSet: (data) => { dispatch(templateActions.createTemplateSet(data)) },
    resetForm:()=>{dispatch(reset('AddNewTemplateSet'))}
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTemplateSet);