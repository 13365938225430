import React from 'react';
import _ from 'lodash'
import SVG from "react-inlinesvg";
import { connect } from 'react-redux'
import { Button, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Accordion, Card } from 'react-bootstrap'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import FileUpload from './FileUpload'
import { toAbsoluteUrl } from "../../../../../_helpers";

const DragHandle = sortableHandle(() => <div className="drag_handle">
    {/* <SVG src={toAbsoluteUrl("/media/svg/icons/custom/drag_handle.svg")} /> */}
    <img src="/media/img/cursor.png" width="10"></img>
</div>)

const SortableItem = sortableElement((props) => { 

    return <Card className="mb-3">
        <Card.Header className="border-bottom rounded-top rounded-0 d-flex">
            <DragHandle />
            <Accordion.Toggle eventKey={props.uniqueIndex} className="accordion_header_text accordion_button_click">
                <span><b className="drag_icon"></b> {props.data.label} </span>
                <i className="icon add_button ki ki-plus"></i>
            </Accordion.Toggle>
            <button type="button" disabled={props.user.type < 3 ? false:true} onClick={props.onClick} className="trash_button">
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/custom/close.svg")} /> */}
                <HighlightOffIcon />
            </button>
        </Card.Header>
        <Accordion.Collapse eventKey={props.uniqueIndex}>
            <FileUpload stockData={props.data} submitForm={props.submitForm}/>
        </Accordion.Collapse>
    </Card>
})

const SortableContainer = sortableContainer(({children}) => {
    return <Accordion className="accordion_custom_style mt-5 attachment-list-move" defaultActiveKey={0}>{children}</Accordion>;
})

class AddStockAttachment extends React.Component {
    
    constructor(props){
        super(props)
    }

    render(){
        let { data, user } = this.props
        return (
            <div>
               

                <SortableContainer onSortEnd={this.props.onSortEnd} useDragHandle>
                    {!_.isEmpty(data) && data.map((item, index) => (
                        <SortableItem 
                            key={index} 
                            index={index} 
                            user={user}
                            uniqueIndex={index} 
                            data={item} 
                            onClick={()=>this.props.onRemove(item)}
                            submitForm={this.props.submitForm}
                        />
                    ))}
                </SortableContainer> 
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user:state.auth.user
     }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStockAttachment)