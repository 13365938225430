import React, { Component, Fragment } from 'react';
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import BarLoader from "react-spinners/BarLoader";

import SitesSidebar from './SiteForm';
import GeneralForm from './GenrealForm';
import CustomerSidebar from './CustomerForm'
import ContractorForm from './ContractorForm';
import WorkedCarriedOutForm from './WorkedCarriedOutForm';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ProjectActions } from '../../actions/project.action';
import { templateActions } from '../../actions/template.action';
import AddContractor from '../../../_metronic/layout/components/right-sidebar/contractors/AddContractor'
import ConfirmationPopup from '../common/ConfirmationPopup'
import { downloadPdf } from '../../utils/common.helper';
import Ripples from 'react-ripples'


const validate = values => {

    const errors = {}

    if (values.project_ref) {
        if (!/^[\w\-\s]+$/i.test(values.project_ref)) {
            errors.project_ref = 'Reference is invalid (must be Alphanumeric)'
        }
    }

    if (!values.status) {
        errors.status = 'status is required'
    }

    if (!values.completion_date) {
        errors.completion_date = 'Job completion date is required'
    }

    if (!values.customer) {
        errors.customer = 'Customer is required'
    }

    if (!values.site) {
        errors.site = 'Site is required'
    }

    if (!values.template_set_id) {
        errors.template_set_id = 'Select Manual'
    }

    // if (!values.contractors || _.isEmpty(values.contractors)) {
    //     errors.contractors = 'Contractors is required'
    // }

    if (!values.carried_out || _.isEmpty(values.carried_out)) {
        errors.carried_out = 'Select work type'
    }

    return errors
}

class ProjectEditForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            temps:false,
            isOpen: false,
            isRemovePopup: false,
            project_id: null,
            time: null,
            importStatus: false,
            mergeStatus: false,
            optimizeStatus: false,
            exportStatus: false,
            pdfStoreStatus: false,
            pdfConvertStatus: false,
            contractorState:[]
        }
    }

    componentDidMount = () => {
        let params = (new URL(document.location)).searchParams;
        let jobId = params.get("job");
        // console.log("JobId==>", jobId);
        if(jobId){
            localStorage.removeItem(jobId)
        }
        // console.log("hhh props", this.props);
        this.props.getProjectDetails({ project_id: this.props.match.params.id })
        // this.props.getTemplateSetListing()
        this.props.getTemplateSetListingByJobId(this.props.match.params.id)
        this.props.getTemplatesListingByJobId(this.props.match.params.id)
        // this.props.getTemplatesListing()
        
        this.props.getWorkType()
        // console.log("hhh props2", this.props);
        this.setState({ project_id: this.props.match.params.id })
        this.interval = setInterval(() => {
            this.setState({ time: Date.now() })
            if (this.state.isOpen && this.props.create_manual.requesting) {
                this.getManualStatus()
                // console.log("manualCreationStatus===>", this.props.manualStatus)
            }else{
                // console.log("clearInterval called")
                // clearInterval(this.interval)
            }
        }, 1000)

    }

    createManual = () => {
        this.props.createManual({
            project_id: this.props.match.params.id
        })
        this.setState({ isOpen: true })
        this.getManualStatus();
    }

    getManualStatus = () => {
        this.props.getManualStatus({
            project_id: this.props.match.params.id
        })
        if (this.props.manualStatus.includes("import/url")) {
            this.setState({ importStatus: true })
            if (this.props.manualStatus.includes("merge")) {
                this.setState({ mergeStatus: true })
                if (this.props.manualStatus.includes("optimize")) {
                    this.setState({ optimizeStatus: true })
                    if (this.props.manualStatus.includes("pdf/store")) {
                        this.setState({ pdfStoreStatus: true })
                        if (this.props.manualStatus.includes("export/s3")) {
                            this.setState({ exportStatus: true })
                            if (this.props.manualStatus.includes("pdf/convert")) {
                                this.setState({
                                    pdfConvertStatus: true,
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    handleSubmit = (values, params = '') => {
        // console.log("values==>", this.state.contractorState);
        let data = {},
            carried_out = [],
            contractors = []

        if (values.carried_out) {
            values.carried_out.map((item, index) => {
                carried_out.push(item.id)
            })
        }

        if (values.contractors) {
            values.contractors.map((item, index) => {
                contractors.push(item.id)
            })
        }
        data.project_id = this.props.match.params.id
        data.manual_id = values.manual_id
        data.manual_type = values.manual_type
        data.project_ref = values.project_ref
        data.completion_date = moment(values.completion_date, 'MM/DD/YYYY').format('MM/DD/YYYY')
        data.customer_id = values.customer.value
        data.site_id = values.site.value
        data.template_set_id = values.template_set_id.value
        data.status = values.status.value
        data.workType_id = carried_out
        data.contractors_id = contractors
        data.hide_alert = params
        data.auther_name = values.auther_name
        data.project_description = values.project_description
        data.contractor_works = this.state.contractorState.length>0?this.state.contractorState:this.props.project_details.contractor_works

        this.props.updateProject(data)


    }

    onCloseModel = () => {
        // console.log("this.props.match.params.id",this.props.match.params.id);
        var object = {value: this.props.match.params.id, timestamp: new Date().getTime()}
        localStorage.setItem(this.props.match.params.id, JSON.stringify(object));
        this.props.history.goBack()
        this.setState({ isOpen: false })
        this.setState({
            importStatus: false,
            mergeStatus: false,
            optimizeStatus: false,
            exportStatus: false,
            pdfStoreStatus: false,
            pdfConvertStatus: false
        })
        this.props.onCloseManualStatus({
            project_id: this.props.match.params.id
        })
    }

    closeCloseProjectPopup = () => {
        this.setState({ isRemovePopup: false })
    }

    onRemoveProject = () => {
        this.setState({ isRemovePopup: true })
    }

    downloadPdf = (data) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.target = "_blank";
        a.href = data;
        a.click();
    }

    removeProject = () => {
        this.props.removeProject({ project_id: this.props.match.params.id })
        this.setState({ isRemovePopup: false })
    }
    contractorWorks = (value) =>{
        this.setState({contractorState:value})
    }
    render() {
        const { handleSubmit, submitting, create_manual, project_details, manualStatus, user } = this.props
        
        return (
            <Fragment>
                {_.isEmpty(project_details) && <div className="spinner"></div>}
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <div className="card-stretch gutter-b">

                        <div className="card card-custom mb-8">
                            <div className="card-body border-bottom-light border-bottom d-flex justify-content-between align-items-center flex-row p-4">
                                <h3 className="mb-0">
                                    <Link to="/projects">
                                        <i className="icon text-primary cursor-pointer fas fa-long-arrow-alt-left mr-1">
                                        </i>
                                    </Link>
                                    Project #{project_details.id_to_show}
                                </h3>
                                {project_details.pdf_key &&
                                    <div className="d-flex align-items-center">
                                        <a href="javaScript:void(0)" onClick={() => downloadPdf(`https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_DEFAULT_REGION}.amazonaws.com/${project_details.pdf_key}`)} className="btn ml-3 btn-primary bg-green border-green">Download Manual</a>
                                    </div>
                                }
                            </div>
                            <GeneralForm />
                        </div>

                        <ContractorForm contractorWorks = {this.contractorWorks} success_message='false' />
                        <WorkedCarriedOutForm {...this.props} isEdit={true} trigger={handleSubmit((values) => this.handleSubmit(values, 'hide_alert'))} />

                        { user.type < 3 && <div className="card card-custom mb-6">
                                <div className="card-body border-bottom-light border-bottom res_card_header d-flex justify-content-between align-items-center flex-row p-4">
                                    <button onClick={this.onRemoveProject} type="button" className="trash_button">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")} />
                                        <span>Remove Project</span>
                                    </button>
                                    <div className="d-flex align-items-center">

                                    </div>
                                    <div className="d-flex align-items-right">
                                        <Ripples color="rgba(0,0,0,0.2)">
                                            <Button
                                                className="bg-green border-green mr-5"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disabled={create_manual.requesting || submitting}
                                                type="button"
                                                onClick={this.createManual}
                                                style={{ color: 'white' }}
                                            >
                                                {create_manual.requesting ? 'Creating Manual' : 'Create Manual'}
                                            </Button>
                                        </Ripples>
                                        <Ripples color="rgba(0,0,0,0.2)">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                disabled={submitting}
                                                type="submit"
                                                style={{ color: 'white' }}
                                            >
                                                {submitting ? "Submitting" : "save"}
                                            </Button>
                                        </Ripples>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                </form>
                <CustomerSidebar />
                <SitesSidebar />
                <AddContractor isProject={true} />

                <Dialog
                    className="model-support template_modal_dialog security_steps_wrap_modal"
                    modal={false}
                    contentClassName="model-top-overlay no-overlay"
                    open={this.state.isOpen}
                >
                    {/* <DialogTitle id="alert-dialog-title">Create Manual</DialogTitle> */}
                    <DialogContent>
                        {create_manual.requesting === true &&
                            <>
                                <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Creating Manual...</h3>
                                {/* <div className="sweet-loading">
                                   <BarLoader
                                       height={15}
                                       width={500}
                                       size={150}
                                       color={"#3699ff"}
                                       loading={true}
                                   />
                               </div>*/}
                                <div className="security_steps_wrap">
                                    <ul>
                                        <li className={this.state.importStatus ? "active complete" : "active"}>
                                            <span className="lable_step">Preparing Files</span>
                                            <span className="step_check"></span>
                                        </li>
                                        <li className={this.state.mergeStatus && this.state.importStatus ? "active complete" : !this.state.mergeStatus && this.state.importStatus ? "active" : !this.state.mergeStatus && !this.state.importStatus ? "lable_step" : "" }>
                                            <span className="lable_step">Merging Files</span>
                                            <span className="step_check"></span>
                                        </li>
                                        <li className={this.state.optimizeStatus && this.state.mergeStatus ? "active complete" : !this.state.optimizeStatus && this.state.mergeStatus ? "active" : !this.state.optimizeStatus && !this.state.mergeStatus ? "lable_step" : ""}>
                                            <span className="lable_step">Compressing Files</span>
                                            <span className="step_check"></span>
                                        </li>
                                        <li className={this.state.pdfStoreStatus && this.state.optimizeStatus ? "active complete" : !this.state.pdfStoreStatus && this.state.optimizeStatus ? "active" : !this.state.pdfStoreStatus && !this.state.optimizeStatus ? "lable_step" : ""}>
                                            <span className="lable_step">Storing Files</span>
                                            <span className="step_check"></span>
                                        </li>
                                        <li className={this.state.pdfConvertStatus && this.state.pdfStoreStatus ? "active complete" : !this.state.pdfConvertStatus && this.state.pdfStoreStatus ? "active" : !this.state.pdfConvertStatus && this.state.pdfStoreStatus ? "lable_step": ""}>
                                            <span className="lable_step">Finalizing Files</span>
                                            <span className="step_check"></span>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                        {create_manual.error_message &&
                            <div style={{ textAlign: 'center' }}>
                                <h2 style={{ color: 'red' }}>{create_manual.error_message.message}</h2>
                                <p className="mb-2">{create_manual.error_message.data.err.error}</p>
                                <p className="mb-2">{create_manual.error_message.data.err.templateset}</p>
                                <p>{create_manual.error_message.data.errorMessage}</p>
                            </div>
                        }
                        {create_manual.success_message && <div style={{ textAlign: 'center' }}><p className="mt-5" style={{ color: 'green' }}>{create_manual.success_message}</p></div>}
                    </DialogContent>
                    {create_manual.requesting === false &&
                        <DialogActions>
                            <Ripples color="rgba(0,0,0,0.2)">
                                <button
                                    onClick={this.onCloseModel}
                                    type="button"
                                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
                                >
                                    Close
                                </button>
                            </Ripples>
                        </DialogActions>
                    }
                </Dialog>

                <ConfirmationPopup
                    heading="Remove Project"
                    message="Do you want to remove project"
                    noButtonText="No"
                    yesButtonText="Yes"
                    isOpen={this.state.isRemovePopup}
                    onClickYes={this.removeProject}
                    onClickNo={this.closeCloseProjectPopup}
                />

            </Fragment>
        )
    }
}

ProjectEditForm = reduxForm({
    validate,
    form: 'ProjectForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
})(ProjectEditForm);


const mapStateToProps = (state, ownProps) => {
    let project_details = state.project.project_details
    let user = state.auth.user
    let manualStatus = [...new Set(state.project.manual_task_status)]
    let initialValues = {
        auther_name : project_details.user?  project_details.user.name : '',
        contractors: project_details.contractors,
        contractor_works: project_details.contractor_works,
        carried_out: project_details.workType,
        status: {
            value: project_details.status && project_details.status || '',
            label: project_details.status && project_details.status || ''
        },
        project_created_at: moment(project_details.created_at).format('MM-DD-YYYY HH:MM'),
        pdf_created_at: project_details.pdf_created_at && moment(project_details.pdf_created_at).format('MM-DD-YYYY HH:MM'),
        customer: {
            value: project_details.customer_id && project_details.customer_id._id || '',
            label: project_details.customer_id && project_details.customer_id.title || ''
        },
        site: {
            value: project_details.customer_id && project_details.site.id || '',
            label: project_details.customer_id && project_details.site.title || ''
        },
        completion_date: project_details.completion_date && moment(project_details.completion_date, "YYYY-MM-DD").toDate(),
        template_set_id: {
            value: project_details.template_set && project_details.template_set.id || '',
            label: project_details.template_set && project_details.template_set.template_set_name || ''
        },
        manual_type: project_details.manual && project_details.manual.title || '',
        manual_id: project_details.manual && project_details.manual.id || '',
    }
    if(project_details.project_description){
        initialValues.project_description = project_details.project_description
    }
    if (project_details.project_ref) {
        initialValues.project_ref = project_details.project_ref
    }
    return {
        user,
        project_details,
        create_manual: state.project.create_manual,
        initialValues,
        manualStatus
    }
}

const mapDispatchToProps = (dispatch) => ({
    createManual: (data) => dispatch(ProjectActions.createManual(data)),
    getManualStatus: (data) => dispatch(ProjectActions.getManualStatus(data)),
    onCloseModel: (data) => dispatch({ type: 'PROJECT/CREATE/MANUAL/REQUEST/STOP' }),
    onCloseManualStatus: (data) => dispatch({ type: 'PROJECT/CREATE/MANUAL/STATUS/CLOSE' }),
    updateProject: (data) => dispatch(ProjectActions.updateProject(data)),
    getProjectDetails: (data) => dispatch(ProjectActions.getProjectDetails(data)),
    removeProject: (data) => dispatch(ProjectActions.removeProject(data)),
    resetProjectDetails: () => dispatch(ProjectActions.resetProjectDetails()),
    getWorkType: () => dispatch(templateActions.getWorkType()),
    getTemplateSetListing: () => dispatch(templateActions.getTemplateSet()),
    getTemplatesListing: () => dispatch(templateActions.getTemplates()),
    getTemplateSetListingByJobId: (data) => dispatch(templateActions.getTemplateSetByJobId(data)),
    getTemplatesListingByJobId: (data) => dispatch(templateActions.getTemplatesByJobId(data)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditForm)
