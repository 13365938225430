import React, { Component, Fragment } from 'react';
import Listing from '../../../_metronic/container/projects/contractors/Listing';
import { Form, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'
import { Field, reduxForm, reset, change, formValueSelector } from 'redux-form';
import { FormControl, MenuItem, InputLabel} from '@material-ui/core';
import { ContractorActions } from "../../../app/actions/contractor.action";
import { connect } from 'react-redux';
import _ from 'lodash'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import SVG from "react-inlinesvg";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { GlobalActions } from "../../actions/global.action";
import { toAbsoluteUrl, } from "../../../_metronic/_helpers";
import { Accordion, Card, Button } from 'react-bootstrap'

import { default as ReactSelect } from "react-select";
import Select from 'react-select';
import { components } from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

let tooltipIcon = <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">Only used if the Contractor is given access to the system.</Tooltip>}
    >
        <p>Service Allocated <i className="fa fa-info-circle tooltip-icon" aria-hidden="true"></i></p>
    </OverlayTrigger>;

const headRows = [
    { id: 'contractors', numeric: false, disablePadding: true, label: 'Contractors' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'contact', numeric: false, disablePadding: false, label: 'Contact No' },
    { id: 'service_provide', false: false, disablePadding: false, label: 'Service Provided' },
    { id: 'service_allocated', false: false, disablePadding: false, label: tooltipIcon },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
];

const ErrorInput = ({ meta: { submitFailed, error } })  => {
    return <p className="full-width text-right">
        {submitFailed && error && <span style={{color: 'red'}}>{error}</span>}
    </p>
}


function EnhancedTableHead(props) {

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                    >
                        {row.id != 'service_allocated'?<TableSortLabel>{row.label}</TableSortLabel>:row.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const Option = (props) => {
    // console.log("Props--> ", props);
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
};
  

class ContractorForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionSelected: null,
            selected_options : []
            // selectedOption:null
        }
    }

    componentDidMount(){
        this.props.getAllContractor({ per_page: 0, sort:'label',order:1 });
    }

    openSidebar = () => {
        this.props.openRightSidebar('add_new_contractor')
    }

    handleSelect = (eventKey, event) => {
        this.setState({ selectedOption:eventKey.id });
        if(eventKey !== 'addNew'){
            let selected_contractors = [...this.props.contractors],
            findIndex = _.findIndex(selected_contractors, (item) => item.id == eventKey.id )
            
            if(eventKey && findIndex === -1){
                // selected_contractors.push(this.props.listing[eventKey.id])
                let selectedContractorsIndex = _.findIndex(this.props.listing, function (item) { return item.id == eventKey.id });
                if (selectedContractorsIndex !== -1) selected_contractors.push(this.props.listing[selectedContractorsIndex])        
                this.setState({ selected_contractors })
                this.onChangeFieldValue(selected_contractors)
            }
        }
    }

    handleChange = (selected, contractor_email) => {
        function upsert(array, element) { // (1)
            const i = array.findIndex(_element => _element.contractor_email === element.contractor_email);
            if (i > -1) array[i] = element; // (2)
            else array.push(element);
        }
       
        let arr = this.state.selected_options.length>0?this.state.selected_options:this.props.contractor_works;
        upsert(arr, {contractor_email:contractor_email, selected:selected})

        this.setState({
            optionSelected: selected,
            selected_options:arr,
        });
        this.props.contractorWorks(arr)
    };

    onRemove = (id, contractor_email) => {
        let selected_contractors = _.remove(this.props.contractors, (item) => item.id != id )
       
        let arr = this.state.selected_options.length>0?this.state.selected_options:this.props.contractor_works;
        const indexOfObject = arr.findIndex(object => {
            return object.contractor_email === contractor_email;
        });
        if(indexOfObject != -1){
            arr.splice(indexOfObject, 1);          
        }
       
        this.setState({ 
            selected_contractors,
            selected_options:arr
         })
        this.onChangeFieldValue(selected_contractors)
    }

    onChangeFieldValue = (data) => {
        this.props.onChangeFieldValue({
            fieldName: 'contractors', 
            fieldValue: data
        })
    }
    multiValueContainer = ({ selectProps, data }) => {
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex((selected) => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? ` ${allSelected.length} work types selected` : "";
        const val = `${labelSuffix}`;
        return val;
      };
    
    customStyles = {
        control: (styles) => ({ ...styles, width: "200px" }),
    }
    render() {
        let { listing, contractors, user, selectedWorkTypes, contractor_works } = this.props
        let contractors_after_sort;
        contractors_after_sort = _.sortBy(contractors, 'label');

        let contractors_after_sort_ids = contractors_after_sort.map(a => a.id);
        listing = listing.map(object => {
            if (contractors_after_sort_ids.includes(object.id)) {
                return {...object, isdisabled: true};
            }else{
                return {...object}
            }
        });
        
        const { multiValueContainer } = this;
        return (
            <div className="card card-custom mb-6">
                <div className="card-body border-bottom-light border-bottom res_card_header d-flex justify-content-between align-items-center flex-row p-4">
                    <h3 className="mb-0">Contractors</h3>
                    <div className={`d-flex align-items-center flex-column ${user.type < 3 ?'cursor-pointer':'cursor-not-allowed'}`}>
                        <div className="d-flex align-items-center">
                            <label className="text-dark-50 mr-3 mb-0">Select contractor:</label>
                            {/* <DropdownButton
                                as={ButtonGroup}
                                key={2}
                                id={`dropdown-variants`}
                                variant={'variant'}
                                className="custom_list_down_dropdown"
                                title={_.isEmpty(contractors) ? 'Select contractors type' : `${contractors.length} Contractors Selected`}
                                onSelect={this.handleSelect}
                            >
                            <div className="up_listing">
                                {listing.map((item, i) => (
                                    <Dropdown.Item 
                                        key={item.id} 
                                        disabled={_.findIndex(contractors_after_sort, {id: item.id}) !== -1 ? true : false} 
                                        eventKey={i}>
                                            {item.name}
                                    </Dropdown.Item>
                                ))}
                            </div>
                                <Dropdown.Item eventKey="addNew" onClick={this.openSidebar} className="d-flex align-items-center text-primary"><i className="flaticon2-add-1 mr-2 text-primary"></i> Add New Contractor</Dropdown.Item>
                            </DropdownButton> */}
                            <Select
                                styles={this.customStyles} 
                                value={this.state?this.state.selectedOption : null}
                                onChange={this.handleSelect}
                                options={listing}
                                isSearchable={false}
                                isDisabled={user.type < 3 ?false:true}
                                placeholder={contractors_after_sort ? `${contractors_after_sort.length} contractors selected`:'Select...' } 
                                isOptionDisabled={(option) => option.isdisabled}
                                // isOptionDisabled={(listing) => _.find(contractors_after_sort, function(contractor) {
                                //     console.log("We are here", contractors_after_sort, contractor);
                                //     if (contractor.id == listing.id) {
                                //         return true;
                                //     }
                                // })}
                            />
                        </div>
                        <Field
                            name="contractors"
                            component={ErrorInput}
                        />
                    </div>    
                </div>

                <div className="card-body project-contractors-listing">
                    <div style={{ 'width': '100%' }}>
                        <Paper style={{ 'width': '100%', 'marginBottom': '16px', 'boxShadow': 'none !important' }}
                            ref={element => {
                            if (element) element.style.setProperty('box-shadow', 'none', 'important');
                        }}>
                            <Accordion className="accordion_custom_style card card-custom mb-6">
                                <Card key={"index"}>
                                    <Card.Header className="d-flex align-items-center justify-content-between p-2 border-bottom border-bottom-light">
                                        <Accordion.Toggle as={Button} className={`${user.type != 3 ?'cursor-pointer':'cursor-not-allowed disabled'}`} variant="link" eventKey={"index"}>
                                            <h3 className="mb-0 text-capitalize font-size-heading">Selected Contractors</h3>
                                            <i className="icon add_button ki ki-plus"></i>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={"index"}>
                                        <Card.Body collapsible>
                                            <div style={{ 'overflowX': 'auto' }}>
                                                <Table
                                                    style={{ 'minWidth': '750' }}
                                                    aria-labelledby="tableTitle"
                                                    size={'medium'}
                                                >
                                                    <EnhancedTableHead listing={listing} />
                                                    <TableBody>
                                                        {contractors_after_sort && contractors_after_sort.length === 0 && (
                                                            <TableRow tabIndex={-1}>
                                                                <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                                                            </TableRow>
                                                        )}
                                                        {user.type != 3 && contractors_after_sort && contractors_after_sort.map((row, index) => {
                                                            let final_data = this.state.selected_options.length>0?this.state.selected_options:contractor_works;
                                                            let result = final_data.find(({ contractor_email }) => contractor_email === row.email);
                                                            if(result){
                                                                result.selected = result && result.selected && result.selected.filter(a => selectedWorkTypes.some(b => a.id === b.id));
                                                            }
                                                            
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                >
                                                                    <TableCell>{row.label}</TableCell>
                                                                    <TableCell>{row.email}</TableCell>
                                                                    <TableCell>{row.contact}</TableCell>
                                                                    <TableCell>{row.main_service && row.main_service.service_name || 'N/A'}</TableCell>
                                                                    <TableCell>
                                                                        <Select
                                                                            options={selectedWorkTypes}
                                                                            isMulti
                                                                            closeMenuOnSelect={false}
                                                                            hideSelectedOptions={false}
                                                                            isDisabled={user.type < 3 ?false:true}
                                                                            components={{
                                                                                MultiValueContainer: multiValueContainer
                                                                                // Option
                                                                            }}
                                                                            placeholder={'Select...' }
                                                                            maxMenuHeight={150}
                                                                            menuPlacement="auto" 
                                                                            onChange={event => this.handleChange(event, row.email)}
                                                                            value={result && result.contractor_email == row.email?result.selected:[]}
                                                                            
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <button 
                                                                            disabled={user.type < 3?false:true}
                                                                            className="trash_button" 
                                                                            onClick={() => this.onRemove(row.id, row.email)}
                                                                        >
                                                                            <HighlightOffIcon />
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }
}

ContractorForm = reduxForm({
    form: 'ProjectForm',
    destroyOnUnmount: false
})(ContractorForm)

const selector = formValueSelector('ProjectForm');

const mapStateToProps = (state, ownProps) => {

    let selectedWorkTypes = state.form.ProjectForm.values.carried_out;
    selectedWorkTypes && selectedWorkTypes.map(obj => { 
        obj.value = obj.label;
    })
    
    return {
        contractors: selector(state, 'contractors') || [],
        contractor_works: selector(state, 'contractor_works') || [],
        user : state.auth.user,
        selectedWorkTypes:state.form.ProjectForm.values.carried_out,
        listing: state.contractor.contractorsDropdown
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllContractor: (data) => dispatch(ContractorActions.getAllContractor(data)),
    toggleSidebar: () => dispatch(ContractorActions.toggleSidebar()),
    onChangeFieldValue: ({fieldName, fieldValue}) => dispatch(change('ProjectForm', fieldName,  fieldValue)),
    openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
    closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(ContractorForm);
