import React, {useMemo} from "react";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import WelcomePopup from '../../../../../app/component/common/WelcomePopup';
import DemoPopup from '../../../../../app/component/common/DemoPopup';

export function HeaderMenuWrapper() {
    
    const stateData = useSelector(state => ({
        is_company_created: state.company.is_company_created,
        is_demo_project_created: state.company.is_demo_project_created,
    }));
    const userImage = '/media/img/welcome.png';
    const setupGif = '/media/img/setup.gif'

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);
    const getHeaderLogo = () => {
        let result = "logo-light.png";
        if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
            result = "logo-dark.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };
    
    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {layoutProps.disabledAsideSelfDisplay && (
                <>
                    {/*begin::Header Logo*/}
                    <div className="header-logo">
                        <Link to="/">
                            <img alt="logo" src={getHeaderLogo()}/>
                        </Link>
                    </div>
                    {/*end::Header Logo*/}
                </>
            )}
           
        </div>
        {/*Header Menu Wrapper*/}

        <WelcomePopup
            heading=""
            message={<html className="welcome-note">
                <center><span className="welcome-note-head">WELCOME TO DOKKIT</span></center>
                <div className="welcome-note-body">
                    <div className="welcome-note-content">
                        <center className="welcome-image">
                            <img src={userImage} alt="Company Profile" />
                        </center>
                        <div class=" list-group">
                            <div className="welcome-note-list">
                                <div className="row">
                                    <div className="col-md-1">
                                        <span class="welcome-note-list-count">1</span>
                                    </div>
                                    <div className="col-md-11">
                                        To get you started we`ve given you a test project to play with. Just click on the Projects to get started.
                                    </div>
                                </div>
                            </div>

                            <div className="welcome-note-list">
                                <div className="row">
                                    <div className="col-md-1">
                                        <span class="welcome-note-list-count">2</span>
                                    </div>
                                    <div className="col-md-11">
                                        click the Work Types (in blue) in the project to edit the scope of works and add a few attachments.
                                    </div>
                                </div>
                            </div>

                            <div className="welcome-note-list">
                                <div className="row">
                                    <div className="col-md-1">
                                        <span class="welcome-note-list-count">3</span>
                                    </div>
                                    <div className="col-md-11">
                                        You`re ready to create your first manual, just click the 'Create Manual' button on the main project page.
                                    </div>
                                </div>
                            </div>

                        </div>

                        <center>
                            <button type="button" onClick={() => { 
                                setTimeout(function(){
                                    window.location.href = "/projects"; 
                                }, 1000); 
                                }} className={`lets-go`} > <h3>LETS GO!</h3> </button>
                        </center>

                        <p className="welcome-note-pera">If you get stuck click Help on the left to go to the help pages or Support to send us a message</p>
                    </div>
                </div>
            </html>}
            isOpen={stateData.is_company_created}
        />

        <DemoPopup
            heading=""
            message={<html className="welcome-note">
                <center><h2 >Hang on...</h2></center>
                <div className="welcome-note-body">
                    <div className="welcome-note-content">
                        <center className="welcome-image">
                            <img src={setupGif} alt="Setup Company Profile" />
                            <p>Just a moment we are finalizing your data.</p>
                        </center>
                        
                    </div>
                </div>
            </html>}
            isOpen={stateData.is_demo_project_created}
        />
    </>
}
