import React from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, } from "../../_helpers";
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import _ from 'lodash'
import { GlobalActions } from "../../../app/actions/global.action";

import { StockDetailActions } from "../../../app/actions/stock_detail.action";

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
  { id: 'manufacturer', numeric: false, disablePadding: false, label: 'Manufacturer' },
  { id: 'fitting_accessory', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'range_part', false: false, disablePadding: false, label: 'Part #' },
  { id: 'attachments', numeric: false, disablePadding: false, label: 'Attachments' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length > 1}
              direction={order}
              // onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              onClick={(row.label == 'Actions' || row.label == 'Attachments') ? null: createSortHandler(row.id)}

              hideSortIcon={row.id !== 'title' || listing.length < 2}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

class Listing extends React.Component {

  state = {
    order: 'asc',
    orderBy: 'created_at',
    page: 0,
    dense: false,
    open: false,
    deleteRecord: null,
    rowsPerPage: 10
  }

  _toggleDeleteModal = (id) => {
    this.setState({
      open: !this.state.open,
      deleteRecord: id
    })
  }

  componentDidMount() {
    if (this.props.group_id) {
      this.props.stockDetailListing({ group: this.props.group_id });
    }
  }

  handleRequestSort = (e, property) => {
    let { rowsPerPage, order, orderBy, page} = this.state;
    if(!order){
      order = 'asc';
    }
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc', orderBy: property, page:0 }, () => {
      if(property=='fitting_accessory'){
        property='fitting_accessories'
      }
      this.props.stockDetailListing({ sort: property, order: order === 'asc' ? -1 : 1, per_page:rowsPerPage, page, group: this.props.group_id })
    })
  }

  handleChangePage = (e, newPage) => {
    let { rowsPerPage, order, orderBy } = this.state;
    const new_page = newPage + 1;
    if (this.props.listing.length) {
      this.setState({ page: newPage })
      let extraFilters = {};
      if(order && orderBy){
        if(orderBy=='fitting_accessory'){
          orderBy='fitting_accessories'
        }
        extraFilters = { sort: orderBy, order: order === 'asc' ? 1 : -1 }
      }
      this.props.stockDetailListing({ current_page: new_page, per_page: rowsPerPage, ...extraFilters, group: this.props.group_id });
    }
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
        page: 0,
        order: 'asc',
        orderBy: 'created_at',
     })
    this.props.stockDetailListing({ per_page: e.target.value, group: this.props.group_id });
  }

  handleDelete = (id) => this.props.deleteStockDetail(id)

  openUpdateStockDetailSidebar = (id) => {
    this.props.getStockDetail(id);
    this.props.openRightSidebar('update_stock_details')
    // document.getElementById('add-stock-detail-sidebar').style.display = 'none';
    // document.getElementById('update-stock-detail-sidebar').style.display = 'block';
    // document.body.classList.add("openRightBar");
    // this.props.toggleSidebar();
  }

  render() {
    const { dense, rowsPerPage, page, order, orderBy, } = this.state;
    const { listing, pagination, requesting} = this.props;
    return (
      <div style={{ width: '100%', marginTop: '24px', }}>
        <Paper style={{ width: '100%', marginBottom: '16px', }}
          ref={element => {
            if (element) element.style.setProperty('box-shadow', 'none', 'important');
          }}>
          <div style={{ overflowX: 'auto' }}>
            <Table
              style={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}

            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                listing={listing}
              />
              <TableBody>
                {listing && listing.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={5} align={"center"}>No Records Found!</TableCell>
                  </TableRow>
                )}
                {listing && listing.length > 0 && listing.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateStockDetailSidebar(row.id)}>
                        {row.manufacturer}
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateStockDetailSidebar(row.id)}>{row.fitting_accessories}</TableCell>
                      <TableCell style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateStockDetailSidebar(row.id)}>{row.range_part}</TableCell>
                      <TableCell style={{ cursor: 'pointer' }}
                        onClick={() => this.openUpdateStockDetailSidebar(row.id)}>{!_.isEmpty(row.stock_attachment) && (row.stock_attachment.attachment_count || "N/A")}</TableCell>
                      <TableCell>
                        {/* <button className="trash_button" onClick={() => this.handleDelete(row.id)}><SVG src={toAbsoluteUrl("/media/svg/icons/custom/delete-instance.svg")}
                        /></button> */}
                        <button
                          type="button"
                          className="text-danger flaticon-delete bg-transparent border-0"
                          onClick={() => this._toggleDeleteModal(row.id)}
                        ></button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {requesting && <div className="spinner"></div>}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={pagination ? pagination.total : -1}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              if (pagination === null) {
                return `0-0 of 0`
              }
              return `${from}-${to} of ${count}`
            }}
          />
        </Paper>
                {/* Delete Modal start */}

                <Dialog
          className="model-support"
          modal={false}
          contentClassName="model-top-overlay no-overlay"
          open={this.state.open}
        >
          <DialogTitle id="alert-dialog-title">Delete Stock Item Services?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure, you want to delete this stock item?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this._toggleDeleteModal}
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3"
            >
              No
            </button>
            <button
              onClick={() => { this.handleDelete(this.state.deleteRecord); this._toggleDeleteModal() }}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3"
            >
              <span>Delete</span>
            </button>
          </DialogActions>
        </Dialog>
        {/* Delete Modal start */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { stockdetaillisting: { data, pagination, requesting, } } = state.stock_detail;
  const { stocklisting: { detail, } } = state.stock;
  return {
    listing: data,
    pagination: pagination,
    requesting,
    stock: detail
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  stockDetailListing: (data) => dispatch(StockDetailActions.getAll(data)),
  deleteStockDetail: (data) => dispatch(StockDetailActions.deleteOne(data)),
  getStockDetail: (data) => dispatch(StockDetailActions.getOne(data)),
  toggleSidebar: () => dispatch(StockDetailActions.toggleSidebar()),
  openRightSidebar: (data) => dispatch(GlobalActions.openRightSidebar(data)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);