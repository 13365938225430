import React from "react";
import RegisterForm from "./container/RegisterForm"

function Registration(props) {
  
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-10">
        <figure>
          <img className="login-logo" src="/media/img/logo_white.png" alt="logo-white" />
        </figure>
      </div>
        <RegisterForm />
        {/* <div className="p-6 mt-8 text-center text-white">
          <p>Free Trial Signup Summary</p>
          <h3>Dokkit Business Starter Monthly</h3>
          <p>Today's charge: <strong>£0.00</strong></p>
          <p><small>After 14 days: £54.00 per month inc. VAT</small></p>
      </div> */}
    </div>
  );
}

// const mapStateToProps = (state, ownProps) => {
// 	return {
//         showOtp: state.auth.register && state.auth.register.showotp,
//         requesting: state.auth.register && state.auth.register.requesting,
//         formErrors: state.auth.register && state.auth.register.errors,
//         initialValues:{
//             email: "",
//             password: "",
//             confirmPassword: "",
//             acceptTerms: false,
//         }
//      }
// }
export default Registration;
