import { call, put, all, race, takeLatest } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
import { reset } from 'redux-form';

// Get all Work type
function* getAllWorkType() {
    const form_name = 'Template'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/work-type',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/WORKTYPE/GET/SUCCESS",
                payload: response.success.data
            });
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getTemplateSetByJobId(payload) {
    // console.log("hhh payload set==>", payload);
    yield put({
        type: "TEMPLATES/TEMPLATESET/GET/REQUEST"
    });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/worktypes/${payload.payload}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATESET/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "TEMPLATES/TEMPLATESET/GET/ERROR"
            });
        }
    }
}

function* getTemplateSet() {
    yield put({
        type: "TEMPLATES/TEMPLATESET/GET/REQUEST"
    });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATESET/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "TEMPLATES/TEMPLATESET/GET/ERROR"
            });
        }
    }
}

function* getTemplatesByJobId(payload) {
    // console.log("hhh templates Payload==>", payload);
    yield put({
        type: "TEMPLATES/TEMPLATES/GET/REQUEST"
    });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/templates/${payload.payload}`,
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATES/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "TEMPLATES/TEMPLATES/GET/ERROR"
            });
        }
    }
}

function* getTemplates() {
    yield put({
        type: "TEMPLATES/TEMPLATES/GET/REQUEST"
    });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/templates',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATES/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "TEMPLATES/TEMPLATES/GET/ERROR"
            });
        }
    }
}

function* deleteTemplateSet({ payload }) {
    const form_name = 'DeleteTemplateSet'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/${payload.tmpset_id}`,
                method: 'DELETE',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATESET/DELETE/SUCCESS",
                payload: response.success.data
            });
            payload.clearModal();
            alertInfo('info', "Template Set Deleted")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* createTemplateSet({ payload }) {
    const form_name = 'AddNewTemplateSet'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual`,
                method: 'POST',
                data: payload.data
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/TEMPLATESET/CREATE/SUCCESS",
                payload: response.success.data
            });
            payload.resetForm();
            alertInfo('info', "Template Set Created")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

function* uploadTemplate({ payload }) {
    const form_name = 'UpdateTemplate'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/templates/${payload.id}`,
                method: 'POST',
                data: payload.data
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/UPDATETEMPLATE/TOGGLE",
                payload: response.success.data
            });
            payload.resetForm();
            alertInfo('info', "Template Updated")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

function* addManualTemplateSet({ payload }) {
    const form_name = 'AddManualModalToggle'
    yield put({ type: "GLOBAL/FORM_START", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: `api/v1/manual/add-manual/`,
                method: 'POST',
                data: payload.data
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/ADDMANAUAL/SUCCESS",
                payload: response.success.data
            });
            //  payload.resetForm();
            alertInfo('info', "Manuals added to template set")
        }
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
    } catch (error) {
        yield put({ type: "GLOBAL/FORM_STOP", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
            yield put({
                type: "GLOBAL/FORM_ERROR",
                payload: {
                    error: data.data,
                    form_name: form_name
                }
            });
        }
    }
}

function* selectAttachmentGroup({ payload }) {
    const form_name = 'Template'
    yield put({ type: "TEMPLATES/SELECTATTACHMENT/GET/REQUEST", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/select-attachment',
                method: 'POST',
                data: payload
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/ATTACHMENT/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        yield put({ type: "TEMPLATES/ATTACHMENT/GET/ERROR", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* selectWorkType({ payload }) {
    const form_name = 'Template'
    yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/REQUEST", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/select-type',
                method: 'POST',
                data: payload
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/SELECTWORKTYPE/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/ERROR", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getSelectWorkType({ payload }) {
    const form_name = 'Template'
    yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/REQUEST", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/selected-work-type',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/SELECTWORKTYPE/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/ERROR", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getSelectAttachmentGroup({ payload }) {
    const form_name = 'Template'
    // yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/REQUEST", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/manual/selected-attachment-group',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/SELECTATTACHMENTGROUP/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/ERROR", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getAttachmentGroups({ payload }) {
    const form_name = 'Template'
    yield put({ type: "TEMPLATES/SELECTWORKTYPE/GET/REQUEST", form_name });
    try {
        const response = yield race({
            success: call(getResponse, {
                url: 'api/v1/project/attachment/group',
                method: 'GET',
            })
        });
        if (response.success) {
            yield put({
                type: "TEMPLATES/ATTACHMENTGROUP/GET/SUCCESS",
                payload: response.success.data
            });
        }
    } catch (error) {
        yield put({ type: "TEMPLATES/ATTACHMENTGROUP/GET/ERROR", form_name });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

export default function* templateSaga() {
    yield all([
        takeLatest("TEMPLATES/WORKTYPE", getAllWorkType),
        takeLatest("TEMPLATES/TEMPLATESET", getTemplateSet),
        takeLatest("TEMPLATES/TEMPLATESET/BYJOBID", getTemplateSetByJobId),
        takeLatest("TEMPLATES/TEMPLATES/BYJOBID", getTemplatesByJobId),
        takeLatest("TEMPLATES/TEMPLATES", getTemplates),
        takeLatest("TEMPLATES/TEMPLATESET/DELETE", deleteTemplateSet),
        takeLatest("TEMPLATES/TEMPLATESET/POST", createTemplateSet),
        takeLatest("TEMPLATES/TEMPLATE/UPDATE", uploadTemplate),
        takeLatest("TEMPLATES/ADDMANAUAL/POST", addManualTemplateSet),
        takeLatest("TEMPLATES/WORKTYPE/SELECT", selectWorkType),
        takeLatest("TEMPLATES/ATTACHMENTGROUP/SELECT", selectAttachmentGroup),
        takeLatest("TEMPLATES/SELECTEDWORKTYPE", getSelectWorkType),
        takeLatest("TEMPLATES/SELECTATTACHMENTGROUP", getSelectAttachmentGroup),
        takeLatest("TEMPLATES/ATTACHMENTGROUP", getAttachmentGroups)
    ]);
}