// import { takeEvery, takeLatest } from 'redux-saga';
import { call, put, all, race, takeLatest, delay } from 'redux-saga/effects';
import { getResponse, alertInfo } from '../utils/common.helper';
// import _ from 'lodash';

// import history from '../utils/history.helper'


// Get all subscripiton plan
function* getAllPlan({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/subscription',
                method: 'GET',
                params: payload,
            })
        });
        if (response.success) {
            yield put({
                type: 'SUBSCRIPTION/GET/ALLPLAN/SUCCESS',
                payload: response.success.data.data
            });

           yield put({
               type:"SUBSCRIPTION/CURRENT/PLAN/REQUEST"
           })
        }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}
function* subscribePlan({ payload }) {
    try {
        const response = yield race({
            success: call(getResponse, {
                url: '/api/v1/subscription/subscribe-plan',
                method: 'POST',
                data: payload,
            })
        });
        // if (response.success) {
        //     yield put({
        //         type: "SUBSCRIPTION/PLAN/SUBSCRIBE/SUCCESS",
        //         payload: response.success.data.data
        //     });
        // }
    } catch (error) {
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* createPaymentIntent({payload}){
    try{
        const response = yield race({
           success: call(getResponse, {
                url: '/api/v1/subscription/create-payment-intent',
                method: 'POST',
                data: payload
           })
        });
        if (response.success) {
            yield put({
                type: "SUBSCRIPTION/CREATEPAYMENTINTENT/SUCCESS",
                payload: response.success.data.data
            });
            if(!response.success.data.data.requires_action){
                alertInfo('success', response.success.data.data.message)
                yield put({
                    type: "SUBSCRIPTION/CURRENT/PAYMENTSUCCESS",
                    payload
                })
                yield put({
                    type: 'COMPANY/GET/TRIAL_PERIOD/SUCCESS',
                    payload:response.success.data.data.trial
                })
                yield delay(200)
                window.location.reload();
            }

        }
    }catch(error){
        yield put({
            type: "SUBSCRIPTION/CREATEPAYMENTINTENT/ERROR"
        });
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

function* getCurrentPlan(){
    try{
        const response = yield race({
            success: call(getResponse, {
                 url: '/api/v1/subscription/current-plan',
                 method: 'GET',
            })
         });
         const trial = yield race({
            success: call(getResponse, {
                url: 'api/v1/company/get-trial-status',
                method: 'GET'
            })
        });
         if (response.success) {
             yield put({
                 type: "SUBSCRIPTION/CURRENT/PLAN/SUCCESS",
                 payload: response.success.data.data
             });
         }

         if (trial.success) {
            yield put({
                type: "COMPANY/GET/TRIAL_PERIOD/SUCCESS",
                payload: trial.success.data.data
            });
        }

    }catch(error){
        if (error.response) {
            const { response: { data } } = error
            // alertInfo('error', data.message)
        }
    }
}  

function* updatePlan({payload}){
    try{
        const response = yield race({
            success: call(getResponse, {
                 url: '/api/v1/subscription/change-subscribe-plan',
                 method: 'POST',
                 data: payload
            })
         });
         if (response.success) {
             yield put({
                 type: "SUBSCRIPTION/CREATEPAYMENTINTENT/SUCCESS",
                 payload: response.success.data.data
             });
             if(!response.success.data.data.requires_action){
                alertInfo('success', response.success.data.data.message)
                 yield put({
                     type: "SUBSCRIPTION/CURRENT/PAYMENTSUCCESS",
                     payload
                 })
                 const getAllowance = yield race({
                    success: call(getResponse, {
                        url: 'api/v1/company/get-trial-status',
                        method: 'GET'
                    })
                }); 
                if (getAllowance.success) {
                    yield put({
                        type: "COMPANY/GET/TRIAL_PERIOD/SUCCESS",
                        payload: getAllowance.success.data.data
                    });
                }
             }
         }
    }catch(error){
        if (error.response) {
            const { response: { data } } = error
            alertInfo('error', data.message)
        }
    }
}

export default function* subscriptionSaga() {
    yield all([
        takeLatest("SUBSCRIPTION/ALLPLAN", getAllPlan),
        takeLatest("SUBSCRIPTION/PLAN", subscribePlan),
        takeLatest("SUBSCRIPTION/CREATEPAYMENTINTENT", createPaymentIntent),
        takeLatest("SUBSCRIPTION/CURRENT/PLAN/REQUEST", getCurrentPlan),
        takeLatest("SUBSCRIPTION/UPDATE/PLAN/REQUEST", updatePlan),
    ]);
}