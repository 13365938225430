import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dateFormat, downloadPdf } from "../../../app/utils/common.helper"
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {Link , useHistory} from 'react-router-dom';
import axios from 'axios'

import { ProjectActions } from "../../../app/actions/project.action";
import {CompanyActions} from "../../../app/actions/company.action";
import EmailToCustomer from  "./EmailToCustomer";

const rowsPerPageOptions = [10, 15, 25, 50];

const headRows = [
  { id: 'id_to_show', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'client', numeric: false, disablePadding: false, label: 'Customer' },
  { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
  { id: 'date_create', false: false, disablePadding: false, label: 'Date Created' },
  { id: 'user', false: false, disablePadding: false, label: 'User' },
  { id: 'reference', false: false, disablePadding: false, label: 'Reference' },
  { id: 'manual', false: false, disablePadding: false, label: 'Manual' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, listing } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id && listing.length > 1}
              direction={order}
              onClick={orderBy === row.id ? createSortHandler(row.id) : null}
              hideSortIcon={row.id !== 'title' || listing.length < 2}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number,
};

class Listing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'id_to_show',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 10,
      open: false,
      deleteRecord: null
    }
  }

  componentDidMount() {
    this.props.projectListing();
    this.props.getTrialPeriod();
  }

  _handleRequestSort = (e, property) => {
    const { order, orderBy, } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.projectListing({ sort: property, order: order === 'asc' ? -1 : 1 })
    })
  }


  _handleChangePage = (e, newPage) => {
    const { rowsPerPage } = this.state;
    const new_page = newPage + 1;
    if (this.props.listing.length) {
      this.setState({ page: newPage })
      this.props.projectListing({ current_page: new_page, per_page: rowsPerPage });
    }
  }

  _handleChangeRowsPerPage = (e) => {
    this.setState({ 
      rowsPerPage: e.target.value,
      page: 0,
     })
    this.props.projectListing({ per_page: e.target.value });
  }

  onEdit = (data) => {
      // console.log("localStorage.getItem(data.id)==>", localStorage.getItem(data.id));
      
      if(data.pdf_request_process_url){
        localStorage.removeItem(data.id)
      }

      var object = JSON.parse(localStorage.getItem(data.id))??"",
      dateString = object.timestamp,
      value = object.value,
      now = new Date().getTime().toString();

      let msInHour = 1000 * 60 * 60;
      let timeDiffInHour = (now-dateString)/msInHour
      // console.log("Value===>", value, timeDiffInHour );
    if(data.id != value || timeDiffInHour >= 1){
      this.props.history.push(`edit_project/${data.id}`)
    }
  }

    downloadPdfPreview = (url) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.target = "_blank";
        a.href = url;
        a.click();
    }

      downloadPdf = (url) => {
        downloadPdf(url)
      }

    sendMail = (id) => {
        this.props.sendMail({id})
    }

  render() {
    let { order, orderBy, selected, rowsPerPage, page, dense } = this.state;
    const { listing, pagination, requesting, showProjectEmail, current_user} = this.props;
    const isSelected = name => selected.indexOf(name) !== -1;

    return (
      <div style={{ width: '100%', marginTop: '24px' }}>
        <Paper style={{ width: '100%', marginBottom: '16px', }}
          ref={element => {
            if (element) element.style.setProperty('box-shadow', 'none', 'important');
          }}>
          <div style={{ overflowX: 'auto' }}>
            <Table
              style={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                listing={listing}
                onRequestSort={this._handleRequestSort}
              />
              <TableBody>
                {!requesting && listing && listing.length === 0 && (
                  <TableRow tabIndex={-1}>
                    <TableCell colSpan={9} align={"center"}>No Records Found!</TableCell>
                  </TableRow>
                )}
                {listing && listing.map((row, index) => {
                  let project_ref = row.project_ref+(row.company_name?" / "+row.company_name:'')
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      id = {row.id}
                    >
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}> {row.id_to_show || 'N/A'}</TableCell>
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}>{row.customer_id ? row.customer_id.title : 'N/A'}</TableCell>
                      <TableCell style={{cursor: 'pointer', color: "#3699FF"}} onClick={()=>this.onEdit(row)}>{row.site.title ? row.site.title : "N/A"}</TableCell>
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}>{dateFormat(row.created_at)}</TableCell>
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}>{row.user.name ? row.user.name : "N/A"}</TableCell>
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}>{project_ref}</TableCell>
                      <TableCell style={{cursor: 'pointer'}} onClick={()=>this.onEdit(row)}>{row.template_set.template_set_name}</TableCell> 
                      <TableCell>
                        {row.pdf_request_process_url && 
                            <>
                                <button disabled={current_user.type != 3?false:true} onClick={()=>this.downloadPdfPreview(`https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_DEFAULT_REGION}.amazonaws.com/${row.pdf_key}`)} className="bg-transparent text-primary border-0 flaticon-eye font-14"></button>
                                <button disabled={current_user.type != 3?false:true} onClick={()=>this.downloadPdf(`https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_DEFAULT_REGION}.amazonaws.com/${row.pdf_key}`)} className="bg-transparent text-green border-0 flaticon-download-1 font-14"></button>
                                {/* {(row.requesting === false || row.requesting === undefined) ? <button onClick={()=>this.sendMail(row.id)} className="bg-transparent text-dark border-0 flaticon2-black-back-closed-envelope-shape font-14"></button> : */}
                                {(row.requesting === false || row.requesting === undefined) ? <button disabled={current_user.type != 3?false:true} onClick={()=>this.props.showProjectEmail(row.customer_id.email, row.id)} className="bg-transparent text-dark border-0 flaticon2-black-back-closed-envelope-shape font-14"></button> :
                                 <button className="bg-transparent text-dark border-0">Sending...</button> 
                                }
                            </>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
                {showProjectEmail && <EmailToCustomer />}
              </TableBody>
            </Table>
                {requesting && <div className="spinner"></div>}
          </div>
          {}
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={pagination ? pagination.total : -1}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={this._handleChangePage}
            onChangeRowsPerPage={this._handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              if (pagination) {
                return `${pagination.current_page}-${pagination.last_page} of ${pagination.total}`
              }
            }}
          />
        </Paper>
      </div >
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { projectlisting: { data, pagination, requesting, }, showProjectEmail } = state.project;

  return {
    current_user:state.auth.user,
    listing: data,
    pagination: pagination,
    showProjectEmail,
    requesting
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  projectListing: (data) => dispatch(ProjectActions.getAll(data)),
  sendMail: (data) => dispatch(ProjectActions.sendMail(data)),
  downloadFile: (data) => dispatch(ProjectActions.downloadFile(data)),
  getTrialPeriod: () => dispatch(CompanyActions.getTrialPeriod()),
  showProjectEmail: (email, id) => { dispatch({ type : "PROJECT/EMAIL/OPTION/TOGGLE", payload: {email, id} }) },
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listing);