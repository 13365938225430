import _ from 'lodash'

let initialValues = {
  stocklisting: {
    requesting: false,
    data: [],
    pagination: null,
    detail: {},
    filter: "",
    sort:{},
  },
//   isSidebarOpen: false,
}

export const stockReducer = (state = initialValues, action) => {
    switch (action.type) {
        //Toggle sidebar
        // case 'STOCK/SIDEBAR/TOGGLE':
        //     return {
        //         ...state,
        //         isSidebarOpen: !state.isSidebarOpen,
        //     }
        //Get all stocks
        case 'STOCK/GET/ALL':
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    requesting: true,
                },
        }
        case 'STOCK/GET/ALL/SUCCESS':
            return {
                ...state,
                stocklisting: {
                      ...state.stocklisting,
                      data: [ ...action.payload.data ],
                      pagination: { ...action.payload.pagination },
                      requesting: false,
                },
        }
        case 'STOCK/GET/ALL/ERROR':
            return {
                ...state,
                stocklisting: {
                      ...state.stocklisting,
                    //   errors: action.payload.data,
                      requesting: false,
                },
        }
        //Delete one stock
        case 'STOCK/DELETE/ONE':
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    requesting: true,
                },
        }
        case 'STOCK/DELETE/ONE/SUCCESS':
            const id_del = action.payload.id;
            const newStockListing = _.filter(state.stocklisting.data, stock => stock.id !== id_del)
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    data: newStockListing,
                    pagination: {
                      total: state.stocklisting.pagination.total - 1
                    },
                    requesting: false,
                },
        }
        case 'STOCK/DELETE/ONE/ERROR':
            return {
                ...state,
                stocklisting: {
                      ...state.stocklisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        // Get one stock
        case 'STOCK/GET/ONE':
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    requesting: true,
                },
        }
        case 'STOCK/GET/ONE/SUCCESS':
            const stock = action.payload;
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    detail: { ...stock},
                    requesting: false,
                },
        }
        case 'STOCK/GET/ONE/ERROR':
            return {
                ...state,
                stocklisting: {
                      ...state.stocklisting,
                      errors: action.payload.data,
                      requesting: false,
                },
        }
        // Create one stock
        case 'STOCK/CREATE':
          return {
              ...state,
              stocklisting: {
                    ...state.stocklisting,
                    // requesting: true,
              },
        }
        case 'STOCK/CREATE/SUCCESS':
          return {
              ...state,
              stocklisting: {
                    ...state.stocklisting,
                    data: [ action.payload, ...state.stocklisting.data ],
                    pagination: {
                      total: state.stocklisting.pagination.total + 1
                    },
                    requesting: false,
              },
        }
        case 'STOCK/CREATE/ERROR':
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    errors: action.payload.data,
                    // requesting: false,
                },
        }
        // Update one stock
        case 'STOCK/UPDATE/ONE':
          return {
              ...state,
              stocklisting: {
                    ...state.stocklisting,
                    requesting: true,
              },
        }
        case 'STOCK/UPDATE/ONE/SUCCESS':
          const id_edit = action.payload.id;
          const indexOfUpdatedStock = _.findIndex(state.stocklisting.data, stock => stock.id === id_edit)
          if(indexOfUpdatedStock !== -1){
            state.stocklisting.data.splice(indexOfUpdatedStock, 1, action.payload)
          }
          return {
            ...state,
            stocklisting: {
                ...state.stocklisting,
                data: state.stocklisting.data,
                requesting: false,
            },
        }
        case 'STOCK/UPDATE/ONE/ERROR':
            return {
                ...state,
                stocklisting: {
                    ...state.stocklisting,
                    errors: action.payload.data,
                    requesting: false,
                },
        }
        // Save filter value
        case 'STOCK/FILTER/SAVE':
          return {
              ...state,
              stocklisting: {
                    ...state.stocklisting,
                    filter: action.payload
              },
        }

        //Clear detail of StockDetail
        case 'STOCK/DETAIL/REMOVE':
            return {
              ...state,
              stocklisting: {
                    ...state.stocklisting,
                    detail: {}
              },
        }
        default:
            return {
                ...state
            }
    }
}