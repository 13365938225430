import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Form } from 'react-bootstrap'

class Accordion extends React.Component {
    render() {
      const { title, expand, onClick } = this.props;
      
      return (
        <div>
          <dt className={expand ? 'title is-expanded mb-0 d-flex align-items-center  justify-content-between' : 'title d-flex align-items-center  justify-content-between'} onClick={onClick}>
            <span>{title}</span>
            <div>
                <button  className="trash_button p-0">
                    <HighlightOffIcon />
                </button>
            </div>
          </dt>
          <dd className={expand ? 'content is-expanded upload_custom_btn' : 'content upload_custom_btn'} onClick={onClick}>
                <div className="pos-relative">
                    <Form.Control type="file" id="as_build" />
                    <span></span>
                    <label className="upload_file_btn" htmlFor="as_build">
                        <i className="fa fa-upload"></i>
                        Upload
                    </label>
                </div>
          </dd>
        </div>
      );
      
    }
  }
  
  class Application extends React.Component {  
    constructor (props) {
      super(props);
      this.state = {
        block1: true,
        block2: false,
        block3: false,
      };
    }
    
    toggle = (index) => () => {
      this.setState({ [`block${index}`]: !this.state[`block${index}`] });
    }
    
    toggleExpand = (expand = false) => () => {
      this.setState({
        block1: expand,
        block2: expand,
        block3: expand,
      });
    }
    
    render() {
      const accordionList = [{ title: 'As Building Drawing' }, { title: 'Certificates' }, { title: 'User Guide' }];
      
      return <>
        <dl className="accordion_custom">
          {
            accordionList.map((item, index) => (
              <Accordion title={item.title} onClick={this.toggle(index + 1)} expand={this.state[`block${index+1}`]} />
            ))
          }
        </dl>
      </>;
    }
  }
  
  export default Application;