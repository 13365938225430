import React from 'react';
import { connect } from 'react-redux'
import { Button,
  // Icon, FormControl, InputLabel, Select, MenuItem
   } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Field, reduxForm, reset } from 'redux-form';
import { SiteActions } from '../../../../../app/actions/site.action';
import { CustomerActions } from '../../../../../app/actions/customer.action';
import { InputFieldRedux, SelectFieldRedux, AutoCompleteFieldRedux } from "../../../../../app/component/common/FieldComponent";
import validate from './validation.js'
import { GlobalActions } from "../../../../../app/actions/global.action";

class AddSite extends React.Component {

    componentDidMount(){
        this.props.customerListing({ per_page: 0 });
    }

    hideSidebar = () => {
        this.props.closeRightSidebar('add_new_site')
        // document.body.classList.remove("openRightBar");
        // this.props.toggleSidebar();
        this.props.resetForm();
    }

    handleSubmit = (data) => { 
        const customer_id = data.customer_id && data.customer_id.value
        const newData = { ...data, customer_id}
        this.props.createSite({newData, resetForm: this.hideSidebar})
    }

    render(){
      const { handleSubmit, right_sidebar, submitting, pristine, customer_listing, } = this.props
      
      return ( 
        <>
          {right_sidebar &&
            <div id="add-site-sidebar" className="right_sidebar card card-custom card-stretch gutter-b">
                <div className="card-body scroll_y_100vh">
                    <div className="card_header d-flex align-items-center justify-content-between mb-4">
                        <h3 className="mb-0">Create new Site</h3>
                        <button type="button" onClick={this.hideSidebar} className="close_btn icon ki ki-close"></button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <form onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field
                              label={'Select Customer'}
                              name="customer_id"
                              component={AutoCompleteFieldRedux}
                              options={customer_listing}
                            />
                            <Field
                              label={'Site Name'}
                              placeholder="Enter site name"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="title"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 1'}
                              placeholder="Enter address line 1"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_1"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Address Line 2'}
                              placeholder="Enter address line 2"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="address_line_2"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'City'}
                              placeholder="Enter city"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="city"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'County'}
                              placeholder="Enter county"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="county"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Postcode'}
                              placeholder="Enter postcode"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="postcode"
                              component={InputFieldRedux}
                            />
                            <Field
                              label={'Reference'}
                              placeholder="Enter reference"
                              type="text"
                              margin="normal"
                              variant="outlined"
                              name="reference"
                              component={InputFieldRedux}
                            />
                            <div className="d-flex justify-content-end">
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                disabled={submitting || pristine} 
                                type="submit">
                                  Save
                              </Button>
                            </div>
                        </form>  
                        </div>
                    </div>
                </div>
            </div>
          }
        </>
      )
    }
}

AddSite = reduxForm({
  form: 'AddSite',
  validate,
  enableReinitialize: true
})(AddSite);

const mapStateToProps = (state, ownProps) => {
  const { customerlisting: { data } } = state.customer;
  const { isSidebarOpen } = state.site;

  return {
    customer_listing: data,
    isSidebarOpen,
    right_sidebar: state.global.right_sidebars['add_new_site']
  }
}

const mapDispatchToProps = (dispatch) => ({
  createSite: (data) => dispatch(SiteActions.create(data)),
  customerListing: (data) => dispatch(CustomerActions.getAll(data)),
  toggleSidebar: () => dispatch(SiteActions.toggleSidebar()),
  resetForm:()=>{dispatch(reset('AddSite'))},
  closeRightSidebar: (data) => dispatch(GlobalActions.closeRightSidebar(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSite)
